import React, { useEffect, useState } from "react";
import styles from "./app.module.css";
import { Routes } from "./pages/Routes";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import useCookies from "react-cookie/cjs/useCookies";
import { useDispatch } from "react-redux";
import { authorizeUser, checkToken } from "./state/actions";
import { UpdateYourBrowser } from "./components/Screen/UpdateYourBrowser";

import { setDefaultLanguage, setTranslations } from "react-multi-lang";
import ru from "./translations/ru.json";
import en from "./translations/en.json";
import { getDataFromLocalStorage, saveToLocalStorage } from "./utils/LocalStorageWorker";
import buildData from "./metadata.json";

setTranslations({ ru, en });

function App() {
  const dispatch = useDispatch();

  const [cookies] = useCookies(["token"]);

  const [isAllowed, setIsAllowed] = useState(true);
  const [browserName, setBrowserName] = useState("");

  const checkBrowserCompability = (array) => {
    const [browser, version] = array;
    if (browser.toLowerCase() === "firefox")
      if (version < 64) {
        setIsAllowed(false);
        setBrowserName(browser);
      }
    if (browser.toLowerCase() === "chrome")
      if (version < 30) {
        setIsAllowed(false);
        setBrowserName(browser);
      }
    if (browser.toLowerCase() === "safari")
      if (version < 9) {
        setIsAllowed(false);
        setBrowserName("chrome");
      }
    if (browser.toLowerCase() === "ie") {
      setIsAllowed(false);
      setBrowserName("chrome");
    }
    if (browser.toLowerCase() === "edge") {
      setIsAllowed(false);
      setBrowserName("chrome");
    }
  };

  useEffect(() => {
    if (getDataFromLocalStorage("language") instanceof Error) {
      saveToLocalStorage(`language`, "ru");
      setDefaultLanguage("ru");
    } else setDefaultLanguage(getDataFromLocalStorage("language"));
    if (window.location.pathname.includes("guest")) {
      dispatch(authorizeUser("demo@sixhands.co", "Qq123456"));
    }
    if (cookies.token) {
      dispatch(checkToken(cookies.token, window.location.href));
    }
    navigator.sayswho = (function () {
      let ua = navigator.userAgent,
        tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return "IE " + (tem[1] || "");
      }
      if (M[1] === "Chrome") {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
      return M;
    })();

    checkBrowserCompability(navigator.sayswho);
    // eslint-disable-next-line
  }, []);

  if (isAllowed)
    return (
      <Router>
        <p
          style={{ display: "none" }}
        >{`ERP Frontend build version /${buildData.build}/ on date: ${buildData.date}`}</p>
        <div className={styles.fullScreenWrapper}>
          <Routes />
          {/*{commonState.integratorLoading && <Spinner type={'global'} className={styles.spinner}/>}*/}
          {!cookies.token && !window.location.pathname.includes("guest") && <Redirect to="/auth" />}
        </div>
      </Router>
    );
  else return <UpdateYourBrowser browserName={browserName} />;
}

export default App;
