import React from "react";
import PropTypes from "prop-types";
import Input from "./Input";
import styles from "./styles/DefaultInput.module.css";
import visability from "../../assets/icons/visibility.svg";

const DefaultInput = React.forwardRef(
  ({ className, label, placeholder, onChange, error, type, onKeyPress, onFocus, onBlur, onClick, isPassword }, ref) => {
    return (
      <div className={`${styles.wrapper} ${className}`} ref={ref}>
        {label && <div className={error ? styles.inputLabelError : styles.inputLabel}>{label}</div>}
        <Input
          className={error ? styles.defaultInputError : styles.defaultInput}
          placeholder={placeholder}
          onChange={onChange}
          type={type}
          onKeyPress={onKeyPress}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        {isPassword && (
          <span className={styles.passwordIcon} onClick={onClick}>
            <img src={visability} alt={""} />
          </span>
        )}
      </div>
    );
  }
);

DefaultInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string
};

DefaultInput.defaultProps = {
  className: ""
};

export { DefaultInput };
