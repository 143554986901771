export const animationDurationInMsFromCSSVar = (variableName) => {
  const timeString = getComputedStyle(document.documentElement).getPropertyValue(variableName);
  if (timeString === "") {
    console.warn("CSS variable is not defined");
    return 0;
  }
  const timeInt = parseInt(timeString);
  const decimalPart = parseFloat(timeString) - timeInt;
  return timeInt * 1000 + decimalPart * 1000;
};
