import React from "react";
import PropTypes from "prop-types";

const CloseIcon = ({ className, color, width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width} height={height} viewBox="0 0 17 17">
      <path
        d="M7.36 15.937v-6.3h-6.3a1.143 1.143 0 010-2.28h6.3v-6.3a1.142 1.142 0 012.279 0v6.3h6.3a1.143 1.143 0 010 2.28h-6.3v6.3a1.142 1.142 0 01-2.279 0z"
        fill={color}
      />
    </svg>
  );
};

CloseIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

CloseIcon.defaultProps = {
  className: ""
};

export { CloseIcon };
