import React from "react";
import styles from "./styles/Screen.module.css";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { ModalWindow } from "./ModalWindow";
import UpperModalWindow from "./UpperModalWindow";
import { ModalWindowErrorCatcher } from "./ModalWindowErrorCatcher";
import { useSelector } from "react-redux";

const Screen = ({ children }) => {
  const authorizationState = useSelector((state) => state.authorizationReducer);
  const modalWindowState = useSelector((state) => state.modalWindow);
  const companyUsersState = useSelector((state) => state.companyUsersReducer);
  return (
    <div className={styles.wrapper}>
      <UpperModalWindow />
      <ModalWindowErrorCatcher
        user={companyUsersState.users.find((user) => user.user_id === authorizationState.user_id)}
      >
        {modalWindowState.showStatus && <ModalWindow />}
      </ModalWindowErrorCatcher>
      {children}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

Screen.propTypes = {
  children: PropTypes.node.isRequired
};

export { Screen };
