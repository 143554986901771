import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles/UploadFile.module.css";
import { useDispatch, useSelector } from "react-redux";
import { deleteDocumentFromTask, showUpperModalWindow } from "../../../../state/actions";
import { DocumentIcon } from "../../../ReactSVGIcons/DocumentIcon";
import { CloseIcon } from "../../../ReactSVGIcons/CloseIcon";
import { URL_ADDRESSES } from "../../../../config/CONSTANTS";

const UploadFile = React.memo(({ progressMax, currentProgress, fileName, documentId, permissions }) => {
  const [isError, setIsError] = useState(false);

  const dispatch = useDispatch();
  const href = `${URL_ADDRESSES.DOWNLOAD_DOCUMENT_BY_DOCUMENT_ID(documentId)}`;
  const unsetDeadlineTaskButtonClickHandler = (event) => {
    dispatch(deleteDocumentFromTask(documentId));
  };

  const modalWindowState = useSelector((state) => state.modalWindow);

  const onImageClickHandler = () => {
    dispatch(
      showUpperModalWindow(
        "image",
        href,
        fileName,
        modalWindowState.options.taskDocuments.findIndex((doc) => doc.doc_id === documentId)
      )
    );
  };

  if (isError)
    return (
      <div className={styles.document}>
        <a href={href} download>
          <DocumentIcon className={styles.documentIcon} documentBodyClassName={styles.documentIconBody} />
        </a>
        <div className={styles.documentInfo}>
          {permissions.can_read === 1 ? (
            <a href={href} download style={{ color: "black", textDecoration: "none" }}>
              <p>{fileName}</p>
            </a>
          ) : (
            <p>{fileName}</p>
          )}
          <progress
            max={progressMax}
            value={currentProgress}
            style={Number(currentProgress) === 100 ? { visibility: "hidden" } : {}}
          />
        </div>
        {documentId && permissions.can_delete === 1 && (
          <button className={styles.deleteDocumentFromTaskButton} onClick={unsetDeadlineTaskButtonClickHandler}>
            <CloseIcon className={styles.closeIcon} />
          </button>
        )}
      </div>
    );
  else
    return (
      <div className={styles.document}>
        {!isError ? (
          <img
            src={href}
            onClick={onImageClickHandler}
            alt={fileName}
            className={styles.imagePreview}
            onError={() => setIsError(true)}
          />
        ) : (
          <a href={href} download>
            <DocumentIcon className={styles.documentIcon} documentBodyClassName={styles.documentIconBody} />
          </a>
        )}
        <div className={!isError ? styles.imageInfo : styles.documentInfo}>
          {permissions.can_read === 1 ? (
            <a href={href} download style={{ color: "black", textDecoration: "none" }}>
              <p>{fileName}</p>
            </a>
          ) : (
            <p>{fileName}</p>
          )}
          <progress
            max={progressMax}
            value={currentProgress}
            style={Number(currentProgress) === 100 ? { visibility: "hidden" } : {}}
          />
        </div>
        {documentId && permissions.can_delete === 1 && (
          <button className={styles.deleteDocumentFromTaskButton} onClick={unsetDeadlineTaskButtonClickHandler}>
            <CloseIcon className={styles.closeIcon} />
          </button>
        )}
      </div>
    );
});

UploadFile.propTypes = {
  progressMax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  currentProgress: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fileName: PropTypes.string.isRequired,
  documentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export { UploadFile };
