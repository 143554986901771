import React from "react";
import PropTypes from "prop-types";
import styles from "./styles/AccentButton.module.css";
import plusIcon from "../../assets/icons/whitePlus.svg";

const AccentButton = React.memo(({ label, className, displayPlusSymbol, onClick, disabled, type }) => {
  return (
    <button
      className={`${styles.button} ${displayPlusSymbol ? styles.displayPlus : ""} ${
        disabled ? styles.buttonError : className
      }`}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {displayPlusSymbol && <img src={plusIcon} alt={""} />}
      <p>{label}</p>
    </button>
  );
});

AccentButton.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  displayPlusSymbol: PropTypes.bool,
  onClick: PropTypes.func
};

AccentButton.defaultProps = {
  className: "",
  displayPlusSymbol: true
};

export { AccentButton };
