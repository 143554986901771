import React from "react";
import PropTypes from "prop-types";

const ArrowIcon = ({ className, color, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "5.2314mm"}
      height={height ? height : "2.7274mm"}
      version="1.1"
      viewBox="0 0 5.2314 2.7274"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-182.31 -158.64)">
        <path
          d="m182.49 158.82 2.4805 2.3624 2.386-2.3624v0"
          fill="#ffffff00"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth=".365"
        />
      </g>
    </svg>
  );
};

ArrowIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

ArrowIcon.defaultProps = {
  className: ""
};

export { ArrowIcon };
