import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles/RoleUserChanger.module.css";
import { Avatar } from "../../../../components/Avatars/Avatar";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSearchUserParams,
  filterCompanyUsers,
  setFilterOptionSortableHeader,
  setImplementerToTask,
  setInitiatorToTask,
  setOptionsToModalWindow,
  setVerificatorToTask,
  showModalWindow
} from "../../../../state/actions";
import { MODAL_WINDOW_TYPES } from "../../../../components/Screen/ModalWindow";
import closeIcon from "../../../../assets/icons/closeDecline.svg";
import { AccentButton } from "../../../../components/Buttons/AccentButton";
import { useTranslation } from "react-multi-lang";

const RoleUserChanger = React.forwardRef(
  (
    { className, type, userType, taskId, closeWindowFunction, localUpdateFunction, displayState, onSelectUser },
    ref
  ) => {
    const companyUsersReducer = useSelector((state) => state.companyUsersReducer);

    const translate = useTranslation();

    const workspaceUsers = useSelector((state) => state.workspaceUsersReducer);

    const authorizationState = useSelector((state) => state.authorizationReducer);

    const [isMounted, setIsMounted] = useState(false);

    const [selectedUser, setSelectedUser] = useState(null);
    const [userIndex, setUserIndex] = useState(0);

    const dispatch = useDispatch();

    if (userType === "verificator")
      companyUsersReducer.users = companyUsersReducer.users.sort((user) =>
        user.user_id === authorizationState.user_id ? -1 : 1
      );

    const userSearchbarInputHandler = (event) => {
      setUserIndex(0);
      dispatch(filterCompanyUsers(event.target.value));
    };

    const addUserButtonClickHandler = (event) => {
      dispatch(
        setOptionsToModalWindow({
          type: MODAL_WINDOW_TYPES.USERS_EDITOR,
          closeHeaderButton: true,
          closeOnWrapperClick: true,
          text: "-",
          header: translate("taskTracker.commonWords.usersEdit"),
          showOkButton: false,
          origin: "addUserToTask",
          isTyping: true,
          taskId: taskId,
          userType: userType
        })
      );
      closeWindowFunction();
      dispatch(showModalWindow());
    };

    const setUserOnTaskRole = (taskId, userId, userType) => {
      if (userType === "verificator") {
        dispatch(setVerificatorToTask(taskId, userId));
      } else if (userType === "implementer") {
        dispatch(setImplementerToTask(taskId, userId));
      } else if (userType === "initiator") {
        dispatch(setInitiatorToTask(taskId, userId));
      }
      closeWindowFunction();
    };

    const setFilterUser = (userId, userType) => {
      onSelectUser(userId);
      dispatch(setFilterOptionSortableHeader(userType, userId));
      closeWindowFunction();
    };

    const unsetFilterUser = () => {
      onSelectUser(null);
      dispatch(setFilterOptionSortableHeader(userType, null));
      closeWindowFunction();
    };

    let searchInputRef = useRef(null);

    useEffect(() => {
      dispatch(clearSearchUserParams());
      setIsMounted(true);
      searchInputRef.current.focus();
      setSelectedUser(
        companyUsersReducer.filteredUsers.length !== 0
          ? companyUsersReducer.filteredUsers[0].user_id
          : companyUsersReducer.users[0].user_id
      );
      let user_ids = [];
      workspaceUsers.users.forEach((user) => user_ids.push(user.user_id));
      companyUsersReducer.users
        .sort((a, b) => (a.user_last_name > b.user_last_name ? -1 : 1))
        .sort((a, b) => {
          if (user_ids.includes(a.user_id)) return -1;
          else return 1;
        });
      // eslint-disable-next-line
        }, [])

    useEffect(() => {
      setSelectedUser(
        companyUsersReducer.filteredUsers.length !== 0
          ? companyUsersReducer.filteredUsers[0].user_id
          : companyUsersReducer.users[0].user_id
      );
    }, [companyUsersReducer]);

    const roleUserChangerKeyPressHandler = (event) => {
      if (event.key === "Escape") closeWindowFunction();
      if (event.key === "ArrowDown") {
        if (
          userIndex ===
          (companyUsersReducer.filteredUsers.length !== 0
            ? companyUsersReducer.filteredUsers.length - 1
            : companyUsersReducer.users.length - 1)
        ) {
          setUserIndex(0);
          setSelectedUser(
            companyUsersReducer.filteredUsers.length !== 0
              ? companyUsersReducer.filteredUsers[0].user_id
              : companyUsersReducer.users[0].user_id
          );
        } else {
          setSelectedUser(
            companyUsersReducer.filteredUsers.length !== 0
              ? companyUsersReducer.filteredUsers[userIndex + 1].user_id
              : companyUsersReducer.users[userIndex + 1].user_id
          );
          setUserIndex(userIndex + 1);
        }
      }
      if (event.key === "ArrowUp") {
        if (userIndex === 0) {
          setUserIndex(
            companyUsersReducer.filteredUsers.length !== 0
              ? companyUsersReducer.filteredUsers.length - 1
              : companyUsersReducer.users.length - 1
          );
          setSelectedUser(
            companyUsersReducer.filteredUsers.length !== 0
              ? companyUsersReducer.filteredUsers[companyUsersReducer.filteredUsers.length - 1].user_id
              : companyUsersReducer.users[companyUsersReducer.users.length - 1].user_id
          );
        } else {
          setSelectedUser(
            companyUsersReducer.filteredUsers.length !== 0
              ? companyUsersReducer.filteredUsers[userIndex - 1].user_id
              : companyUsersReducer.users[userIndex - 1].user_id
          );
          setUserIndex(userIndex - 1);
        }
      }
      if (event.key === "Enter" && selectedUser) {
        if (taskId) {
          setUserOnTaskRole(taskId, selectedUser, userType);
        } else if (type === "filter") {
          setFilterUser(selectedUser, userType);
        } else {
          localUpdateFunction(companyUsersReducer.users.find((user) => user.user_id === selectedUser));
          closeWindowFunction();
        }
      }
    };

    useEffect(() => {
      document.addEventListener("keydown", roleUserChangerKeyPressHandler);
      return () => {
        document.removeEventListener("keydown", roleUserChangerKeyPressHandler);
      };
    });

    return (
      <div
        className={`${type === "filter" ? styles.containerGrid : styles.container} ${className} ${
          isMounted ? styles.show : ""
        }`}
        ref={ref}
      >
        <h4>
          {type === "adder" ? "Добавить" : type === "filter" ? "Фильтр по " : "Сменить"}{" "}
          {userType === "verificator"
            ? type === "filter"
              ? "верификатору"
              : "верификатора"
            : userType === "implementer"
            ? type === "filter"
              ? "исполнителю"
              : "исполнителя"
            : userType === "initiator"
            ? type === "filter"
              ? "постановщику"
              : "постановщика"
            : "его"}
        </h4>
        <div className={styles.closeIcon} onClick={closeWindowFunction}>
          <img src={closeIcon} alt={""} />
        </div>
        <div className={styles.fancyLabelContainer}>
          <div className={styles.fancyTextInputLabel}>Введите имя или фамилию</div>
          <input className={styles.fancyTextInput} onChange={userSearchbarInputHandler} ref={searchInputRef} />
        </div>
        <div
          className={`${styles.usersList} ${
            type === "filter" ? styles.maxHeightWithButton : styles.maxHeightWithoutButton
          }`}
        >
          {companyUsersReducer.filteredUsers.length !== 0 ? (
            companyUsersReducer.filteredUsers.map((user) => (
              <div
                className={`${selectedUser === user.user_id ? styles.userListElementSelected : styles.userListElement}`}
                key={user.user_id}
                onClick={() => {
                  if (taskId) {
                    setUserOnTaskRole(taskId, user.user_id, userType);
                  } else if (type === "filter") {
                    setFilterUser(user.user_id, userType);
                  } else {
                    localUpdateFunction(user);
                    closeWindowFunction();
                  }
                }}
              >
                <Avatar
                  src={user.user_photo}
                  alt={`${user.user_first_name} ${user.user_last_name} ${
                    user.user_middle_name ? user.user_middle_name : ""
                  }`.trim()}
                  type="rounded"
                  className={styles.userPhoto}
                />
                <p>
                  {`${user.user_first_name} ${user.user_last_name}  ${
                    user.user_middle_name ? user.user_middle_name : ""
                  }`.trim()}
                </p>
              </div>
            ))
          ) : isMounted && companyUsersReducer.searchFilter === "" ? (
            companyUsersReducer.users.map((user) => (
              <div
                className={`${selectedUser === user.user_id ? styles.userListElementSelected : styles.userListElement}`}
                key={user.user_id}
                onClick={() => {
                  if (taskId) {
                    setUserOnTaskRole(taskId, user.user_id, userType);
                  } else if (type === "filter") {
                    setFilterUser(user.user_id, userType);
                  } else {
                    localUpdateFunction(user);
                    closeWindowFunction();
                  }
                }}
              >
                <Avatar
                  src={user.user_photo}
                  alt={`${user.user_first_name} ${user.user_last_name} ${
                    user.user_middle_name ? user.user_middle_name : ""
                  }`.trim()}
                  type="rounded"
                  className={styles.userPhoto}
                />
                <p>
                  {`${user.user_first_name} ${user.user_last_name} ${
                    user.user_middle_name ? user.user_middle_name : ""
                  }`.trim()}
                </p>
              </div>
            ))
          ) : (
            <div className={styles.userListElement}>
              <p style={{ textAlign: "center", display: "block" }}>Ничего не найдено</p>
            </div>
          )}
        </div>
        {type !== "filter" && (
          <button className={styles.addUserButton} onClick={addUserButtonClickHandler}>
            <div className={styles.plusButton}>+</div>
            Добавить пользователя
          </button>
        )}
        {type === "filter" && (
          <AccentButton
            label={"Сбросить фильтр"}
            displayPlusSymbol={false}
            className={styles.resetFilterButton}
            onClick={unsetFilterUser}
          />
        )}
      </div>
    );
  }
);

RoleUserChanger.propTypes = {
  className: PropTypes.string,
  taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(["adder", "changer", "filter"]),
  userType: PropTypes.oneOf(["implementer", "verificator", "initiator", ""]),
  closeWindowFunction: PropTypes.func,
  localUpdateFunction: PropTypes.func
};

RoleUserChanger.defaultProps = {
  className: "",
  type: "changer",
  userType: "",
  closeWindowFunction: () => {},
  localUpdateFunction: () => {}
};

export { RoleUserChanger };
