import React, { useEffect, useState } from "react";
import styles from "./styles/UpperModalWindow.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AccentButton } from "../Buttons/AccentButton";
import { hideModalWindow, hideUpperModalWindow } from "../../state/actions";
import { URL_ADDRESSES } from "../../config/CONSTANTS";

const UpperModalWindow = () => {
  const modalWindowState = useSelector((state) => state.modalWindow);

  const dispatch = useDispatch();

  const [imageSrc, setImage] = useState(modalWindowState.imageSrc);
  const [getIndex, setIndex] = useState(modalWindowState.documentId);
  const [name, setName] = useState(modalWindowState.imageName);
  const [isError, setError] = useState(false);

  const closeWindow = () => {
    dispatch(hideUpperModalWindow());
  };

  const closeBothWindows = () => {
    dispatch(hideUpperModalWindow());
    dispatch(hideModalWindow());
  };

  const onWrapperClickHandler = (event) => {
    if (!event.target.closest(`.${styles.imageWindow}`) && modalWindowState.showUpperModalStatus)
      dispatch(hideUpperModalWindow());
  };

  const upperModalWindowKeyPressHandler = (event) => {
    if (event.key === "Escape") dispatch(hideUpperModalWindow());

    if (
      event.key === "ArrowRight" &&
      getIndex < (modalWindowState.options.taskDocuments && modalWindowState.options.taskDocuments.length) - 1
    ) {
      setImage(
        `${URL_ADDRESSES.DOWNLOAD_DOCUMENT_BY_DOCUMENT_ID(modalWindowState.options.taskDocuments[getIndex + 1].doc_id)}`
      );
      setName(modalWindowState.options.taskDocuments[getIndex + 1].doc_original_name);
      setIndex(getIndex + 1);
    }
    if (event.key === "ArrowLeft" && getIndex > 0) {
      setImage(
        `${URL_ADDRESSES.DOWNLOAD_DOCUMENT_BY_DOCUMENT_ID(modalWindowState.options.taskDocuments[getIndex - 1].doc_id)}`
      );
      setName(modalWindowState.options.taskDocuments[getIndex - 1].doc_original_name);
      setIndex(getIndex - 1);
    }
  };

  const leftSideClickHandler = () => {
    if (getIndex > 0) {
      setImage(
        `${URL_ADDRESSES.DOWNLOAD_DOCUMENT_BY_DOCUMENT_ID(modalWindowState.options.taskDocuments[getIndex - 1].doc_id)}`
      );
      setName(modalWindowState.options.taskDocuments[getIndex - 1].doc_original_name);
      setIndex(getIndex - 1);
    }
  };

  const rightSideClickHandler = () => {
    if (getIndex < modalWindowState.options.taskDocuments.length - 1) {
      setImage(
        `${URL_ADDRESSES.DOWNLOAD_DOCUMENT_BY_DOCUMENT_ID(modalWindowState.options.taskDocuments[getIndex + 1].doc_id)}`
      );
      setName(modalWindowState.options.taskDocuments[getIndex + 1].doc_original_name);
      setIndex(getIndex + 1);
    }
  };

  const onErrorHandler = () => {
    setError(true);
  };

  useEffect(() => {
    document.addEventListener("click", onWrapperClickHandler);
    document.addEventListener("keydown", upperModalWindowKeyPressHandler);
    return () => {
      document.removeEventListener("click", onWrapperClickHandler);
      document.removeEventListener("keydown", upperModalWindowKeyPressHandler);
    };
  });

  useEffect(() => {
    setImage(modalWindowState.imageSrc);
    setIndex(modalWindowState.documentId);
    setName(modalWindowState.imageName);
    // eslint-disable-next-line
    }, [modalWindowState.showUpperModalStatus])

  useEffect(() => {
    setError(false);
  }, [imageSrc]);

  switch (modalWindowState.typeModal) {
    case "image":
      return (
        <div
          className={`${styles.wrapper} ${modalWindowState.showUpperModalStatus ? "" : styles.hide}`}
          onClick={onWrapperClickHandler}
        >
          <div className={`${styles.imageWindow} ${styles.modalWindowBigSize}`}>
            <div className={styles.modalImageHeader}>
              <h2>{name ? (name.length > 25 ? name.slice(0, 25) + "..." : name) : ""}</h2>
              <div
                className={`${styles.modalImageCloseButton} ${
                  modalWindowState.options.closeHeaderButton ? "" : styles.hide
                }`}
                onClick={() => {
                  closeWindow();
                }}
              >
                &times;
              </div>
            </div>
            <div className={styles.body}>
              <div className={styles.leftSide} onClick={leftSideClickHandler} />
              <div className={styles.rightSide} onClick={rightSideClickHandler} />
              {!isError ? (
                <img
                  className={styles.image}
                  src={imageSrc}
                  alt={modalWindowState.imageName}
                  onError={onErrorHandler}
                />
              ) : (
                <div className={styles.error}>
                  <h2>Не удалось загрузить файл для предпросмотра.</h2>
                  <a href={imageSrc} download>
                    <AccentButton label={"Скачать"} displayPlusSymbol={false} className={styles.downloadButton} />
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    default:
      return (
        <div className={`${styles.wrapper} ${modalWindowState.showUpperModalStatus ? "" : styles.hide}`}>
          <div className={`${styles.notificationWindow} ${styles.modalWindowDefaultSize}`}>
            <div className={styles.modalDefaultHeader}>
              <h2>Внимание</h2>
              <div
                className={`${styles.closeButton} ${modalWindowState.options.closeHeaderButton ? "" : styles.hide}`}
                onClick={() => {
                  closeWindow();
                }}
              >
                &times;
              </div>
            </div>
            {modalWindowState.typeModal !== "emptyNameDescr" ? (
              <div className={styles.body}>Отменить создание задачи? Введенные данные не будут сохранены</div>
            ) : (
              <div className={styles.body}>Поля "Название задачи" и "Описание задачи должны быть заполнены"</div>
            )}
            {modalWindowState.typeModal !== "emptyNameDescr" && (
              <div className={`${styles.footer} ${modalWindowState.options.showOkButton ? "" : styles.hide}`}>
                <AccentButton
                  label="ДА"
                  displayPlusSymbol={false}
                  className={styles.okButton}
                  onClick={() => {
                    closeBothWindows();
                  }}
                />
              </div>
            )}
            {modalWindowState.typeModal === "emptyNameDescr" && (
              <div className={`${styles.footer} ${modalWindowState.options.showOkButton ? "" : styles.hide}`}>
                <AccentButton
                  label="Ок"
                  displayPlusSymbol={false}
                  className={styles.okButton}
                  onClick={() => {
                    closeWindow();
                  }}
                />
              </div>
            )}
          </div>
        </div>
      );
  }
};

export default UpperModalWindow;
