import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Input from "../Inputs/Input.js";
import styles from "./styles/DropdownSearch.module.css";
import loupe from "../../assets/icons/loupe.svg";
import { useDispatch, useSelector } from "react-redux";
import { searchWorkspace } from "../../state/actions/index.js";
import { Link, useHistory } from "react-router-dom";
import { SearchIllustrationIcon } from "../ReactSVGIcons/SearchIllustrationIcon.js";
import { resetSearchWorkspaces } from "../../state/actions";
import { useTranslation } from "react-multi-lang";

const DropdownSearch = ({ className }) => {
  const translate = useTranslation();
  const HELP_SEARCH_MESSAGE = `${translate("taskTracker.commonWords.enterTitle")} Workspace`;
  const SEARCH_NOT_FOUND_MESSAGE = translate("taskTracker.commonWords.nothingFound");
  const containerRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [displayHelper, setDisplayHelper] = useState(true);
  const [displaySearchResult, setDisplaySearchResult] = useState(false);
  const [searchMessage, setSearchMessage] = useState(HELP_SEARCH_MESSAGE);
  const [selectedWorkspace, setSelectedWorkspace] = useState(-1);

  const inputRef = useRef(null);

  const workspacesState = useSelector((state) => state.workspacesReducer.searchedWorkspaces);

  const dispatch = useDispatch();
  const history = useHistory();

  const authorizationState = useSelector((state) => state.authorizationReducer);

  const searchQueryInputHandler = (event) => {
    dispatch(searchWorkspace(event.target.value.toLowerCase().trim()));
    if (event.target.value.length > 0) {
      setDisplayHelper(false);
      setDisplaySearchResult(true);
    } else {
      setDisplayHelper(true);
      setDisplaySearchResult(false);
      setSearchMessage(HELP_SEARCH_MESSAGE);
    }
  };

  const focusInputFieldHandler = () => {
    setOpen(true);
  };

  const blurInputFieldHandler = () => {
    inputRef.current.value = "";
  };
  // eslint-disable-next-line
    const onClickHandler = (event) => {
    if (!event.target.closest(`.${containerRef.current.className.split(" ")[0]}`) && open) {
      setOpen(false);
    }
  };

  const onDropDownHandler = (event) => {
    if (event.key === "Escape") {
      setOpen(false);
      event.target.blur();
    }
    if (event.key === "ArrowDown") {
      if (selectedWorkspace === workspacesState.length - 1) setSelectedWorkspace(0);
      else setSelectedWorkspace(selectedWorkspace + 1);
    }
    if (event.key === "ArrowUp") {
      if (selectedWorkspace === 0) setSelectedWorkspace(workspacesState.length - 1);
      else setSelectedWorkspace(selectedWorkspace - 1);
    }
    if (event.key === "Enter") {
      history.push(
        `/${authorizationState.company_id}/tasks/workspace/${workspacesState[selectedWorkspace].workspace_id}`
      );
      document.title = workspacesState[selectedWorkspace].workspace_name;
      inputRef.current.blur();
      setOpen(false);
    }
  };

  useEffect(() => {
    if (workspacesState.length === 0 && inputRef.current.value.length !== 0) {
      setDisplayHelper(true);
      setDisplaySearchResult(false);
      setSearchMessage(SEARCH_NOT_FOUND_MESSAGE);
    }
    document.addEventListener("click", onClickHandler);
    return () => {
      document.removeEventListener("click", onClickHandler);
    };
  }, [workspacesState.length, onClickHandler, SEARCH_NOT_FOUND_MESSAGE]);

  useEffect(() => {
    setSelectedWorkspace(-1);
    dispatch(resetSearchWorkspaces());
    setDisplayHelper(true);
    setDisplaySearchResult(false);
    setSearchMessage(HELP_SEARCH_MESSAGE);
    // eslint-disable-next-line
    }, [open])


  return (
    <div ref={containerRef} className={`${styles.container} ${className} ${open ? styles.isOpen : ""}`}>
      <div
        className={`${styles.searchInputLineContainer} ${open ? styles.isOpen : ""}`}
        onClick={(event) => {
          const target = event.target.querySelector("input");
          if (target) {
            target.focus();
          }
        }}
      >
        <object type="image/svg+xml" data={loupe} className={styles.loupeSVG}>
          search icon
        </object>
        <Input
          placeholder={`${translate("taskTracker.commonWords.search")}...`}
          onChange={searchQueryInputHandler}
          onFocus={focusInputFieldHandler}
          onBlur={blurInputFieldHandler}
          onKeyDown={onDropDownHandler}
          className={styles.inputField}
          ref={inputRef}
        />
      </div>
      {open && displayHelper && (
        <div className={styles.searchHelper}>
          <SearchIllustrationIcon className={styles.svgIllustration} />
          <p>{searchMessage}</p>
        </div>
      )}
      {open && displaySearchResult && (
        <div className={styles.searchResult}>
          <span className={styles.searchResultGroupName}>workspaces</span>
          {workspacesState.map((result, index) => (
            <Link
              key={result.workspace_id}
              to={`/${authorizationState.company_id}/tasks/workspace/${result.workspace_id}`}
              onClick={() => {
                setOpen(false);
              }}
            >
              <div className={index === selectedWorkspace ? styles.selectedWorkspace : styles.searchResultListElement}>
                {result.workspace_name}
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

DropdownSearch.propTypes = {
  className: PropTypes.string
};

DropdownSearch.defaultProps = {
  className: ""
};

export { DropdownSearch };
