import React from "react";
import styles from "./styles/ProjectLogo.module.css";
import PropTypes from "prop-types";

const ProjectLogo = ({ letters, color, className }) => {
  return (
    <div className={`${styles.logo} ${className}`} style={{ backgroundColor: color }}>
      {letters}
    </div>
  );
};

ProjectLogo.propTypes = {
  letters: PropTypes.string.isRequired,
  className: PropTypes.string
};

ProjectLogo.defaultValues = {
  className: ""
};

export { ProjectLogo };
