import React, { useEffect, useRef, useState } from "react";
import styles from "../styles/ModalWindow.module.css";
import modalWindowStyles from "../styles/ModalWindow.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AccentButton } from "../../Buttons/AccentButton";
import { addLinksToLead } from "../../../state/actions";

const AddLinksToLeadWindow = ({ closeWindow }) => {
  const dispatch = useDispatch();

  const modalWindowOptionsState = useSelector((state) => state.modalWindow.options);
  const modalWindowState = useSelector((state) => state.modalWindow);
  const leadInformation = useSelector((state) => state.leadsReducer);

  const smetaRef = useRef(null);
  const kpRef = useRef(null);

  const [isControl, setIsControl] = useState(false);

  const wrapperClickHandler = (event) => {
    if (event.target.className.includes(modalWindowStyles.wrapper) && modalWindowOptionsState.closeOnWrapperClick) {
      closeWindow();
    }
  };

  const onClickHandler = () => {
    dispatch(
      addLinksToLead(
        smetaRef.current.value,
        kpRef.current.value,
        modalWindowOptionsState.selectedLead.id,
        modalWindowOptionsState.moveToSection
          ? modalWindowOptionsState.moveToSection
          : modalWindowOptionsState.selectedLead.status,
        modalWindowOptionsState.selectedLead.status
      )
    );
    closeWindow();
  };
  const onKeyDownHandler = (event) => {
    if (event.key === "Control") setIsControl(true);
    if (event.key === "Enter" && isControl) {
      onClickHandler();
    }
  };

  const onKeyUpHandler = (event) => {
    if (event.key === "Control") setIsControl(false);
  };

  useEffect(() => {
    smetaRef.current.focus();
  }, []);

  const currencyOptions = [];

  leadInformation.leadCurrencies.map((currency) => currencyOptions.push({ id: currency.id, label: currency.name }));

  useEffect(() => {
    document.addEventListener("keydown", onKeyDownHandler);
    document.addEventListener("keyup", onKeyUpHandler);
    return () => {
      document.removeEventListener("keydown", onKeyDownHandler);
      document.removeEventListener("keyup", onKeyUpHandler);
    };
  });

  return (
    <div
      className={`${styles.wrapper} ${modalWindowState.showStatus ? "" : modalWindowStyles.hide}`}
      onClick={wrapperClickHandler}
    >
      <div className={`${styles.taskDeclineWindow} ${styles.modalWindowDefaultSize}`}>
        <div className={styles.modalDefaultHeader}>
          <h2>{modalWindowState.options.header}</h2>
          <div
            className={`${styles.closeButton} ${modalWindowState.options.closeHeaderButton ? "" : styles.hide}`}
            onClick={() => {
              closeWindow();
            }}
          >
            &times;
          </div>
        </div>
        <div className={styles.leadContainer}>
          <div className={styles.leadBody}>
            <div className={styles.column}>
              <div className={styles.row} style={{ flexBasis: "100%", marginTop: "0" }}>
                <p>Ссылка на Смету</p>
                <input className={styles.inputMessage} ref={smetaRef} />
              </div>
            </div>
            <div className={styles.column}>
              <div className={styles.row} style={{ flexBasis: "100%", marginTop: "0" }}>
                <p>Ссылка на КП</p>
                <input className={styles.inputMessage} ref={kpRef} />
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.footer} ${modalWindowState.options.showOkButton ? "" : styles.hide}`}>
          <button className={styles.declineButton} onClick={() => closeWindow()}>
            Отменить
          </button>
          <AccentButton
            label="Добавить"
            displayPlusSymbol={false}
            className={styles.okButton}
            onClick={onClickHandler}
          />
        </div>
      </div>
    </div>
  );
};

export { AddLinksToLeadWindow };
