import React, { useEffect, useRef } from "react";
import styles from "./styles/knowledgeItem.module.css";
import arrowIcon from "../../assets/icons/linkArrow.svg";

const KnowledgeItem = ({ name, description, children, url }) => {
  const descriptionRef = useRef(null);

  useEffect(() => {
    if (descriptionRef.current) descriptionRef.current.innerHTML = description;
    // eslint-disable-next-line
    },[])

  return (
    <div className={styles.container}>
      <div className={styles.name}>
        <h1>
          {url ? (
            <a href={url} target={"_blank"} rel="noopener noreferrer">
              {name}
              <img src={arrowIcon} alt={""} />
            </a>
          ) : (
            name
          )}
        </h1>
      </div>
      {description && <div className={styles.description} ref={descriptionRef} />}
      {children?.length > 0 && (
        <div className={styles.children}>
          {children.map((child) => (
            <KnowledgeItem name={child.name} description={child.description} children={child.objects} url={child.url} />
          ))}
        </div>
      )}
    </div>
  );
};

export { KnowledgeItem };
