import React, { useEffect, useState } from "react";
import styles from "./styles/leadsSource.module.css";
import { useDispatch } from "react-redux";
import { setLeadSource } from "../../../state/actions";
import { LightenDarkenColor } from "lighten-darken-color";

const SourceItem = React.memo(({ onSourceClickHandler, source }) => {
  const [isOver, setIsOver] = useState(false);
  return (
    <div
      className={styles.options}
      onMouseEnter={() => setIsOver(true)}
      onMouseLeave={() => setIsOver(false)}
      style={isOver ? { backgroundColor: LightenDarkenColor(source.color, -10) } : { backgroundColor: source.color }}
      onClick={() => onSourceClickHandler(source.id, source)}
    >
      <p className={styles.name}>{source.name}</p>
    </div>
  );
});

const LeadSource = React.memo(
  ({ sourceId, sourcesList, leadId, editable, status, shouldReturnValue, returnValueFunction }) => {
    const [showSources, setShowSources] = useState(false);
    const [source, setSource] = useState(
      sourcesList.find((source) => source.id === sourceId) ? sourcesList.find((source) => source.id === sourceId) : null
    );

    const dispatch = useDispatch();

    const [isOver, setIsOver] = useState(false);

    const onClickHandler = () => {
      setShowSources(!showSources);
    };

    const onSourceClickHandler = (id, source) => {
      setSource(source);
      shouldReturnValue ? returnValueFunction(id) : dispatch(setLeadSource(leadId, id, sourceId, status));
      setShowSources(false);
    };

    const onWrapperClickHandler = (event) => {
      if (!event.target.closest(`.${styles.body}`)) {
        setShowSources(false);
      }
    };

    useEffect(() => {
      setSource(
        sourcesList.find((source) => source.id === sourceId)
          ? sourcesList.find((source) => source.id === sourceId)
          : null
      );
      // eslint-disable-next-line
    }, [leadId])

    useEffect(() => {
      setSource(
        sourcesList.find((source) => source.id === sourceId)
          ? sourcesList.find((source) => source.id === sourceId)
          : null
      );
      // eslint-disable-next-line
    }, [sourceId])

    useEffect(() => {
      document.addEventListener("click", onWrapperClickHandler);
      document.addEventListener("keydown", () => setShowSources(false));
      return () => {
        document.removeEventListener("click", onWrapperClickHandler);
        document.removeEventListener("keydown", () => setShowSources(false));
      };
    });

    return (
      <div className={styles.body} style={editable ? {} : { cursor: "default" }}>
        <div
          className={styles.container}
          onClick={editable ? onClickHandler : undefined}
          onMouseEnter={() => setIsOver(true)}
          onMouseLeave={() => setIsOver(false)}
          style={
            isOver && editable
              ? source
                ? { backgroundColor: LightenDarkenColor(source.color, -10) }
                : { backgroundColor: "var(--backgroundDarkGray)" }
              : source
              ? { backgroundColor: source.color }
              : { backgroundColor: "var(--lightGrayBorder)" }
          }
        >
          <p className={styles.name}>{source ? source.name : "Не выбрано"}</p>
        </div>
        {showSources && (
          <div className={styles.sourcesList}>
            {sourcesList
              .filter((sourceL) => sourceL.id !== sourceId)
              .map((source) => (
                <SourceItem onSourceClickHandler={onSourceClickHandler} source={source} />
              ))}
          </div>
        )}
      </div>
    );
  }
);

export { LeadSource };
