import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles/Avatars.module.css";
import userGraySVG from "../../assets/icons/user-gray.svg";
import userSVG from "../../assets/icons/user-gray.svg";

let Avatar = React.memo(({ className, type, src, alt, onClick, style, status }) => {
  const [isError, setIsError] = useState(false);

  if (src === "" || src === null || src === userGraySVG) {
    src = userGraySVG;
  }

  useEffect(() => {
    setIsError(false);
  }, [src]);

  switch (type) {
    case "rounded":
      return (
        <img
          className={`${styles.container} ${styles.rounded} ${className}`}
          src={isError ? userSVG : src}
          alt=""
          title={alt}
          onClick={onClick}
          style={style}
          onError={() => setIsError(true)}
        />
      );
    case "square":
      return (
        <img
          className={`${styles.container} ${styles.squared} ${className}`}
          src={isError ? userSVG : src}
          alt=""
          title={alt}
          onClick={onClick}
          style={style}
          onError={() => setIsError(true)}
        />
      );
    case "unset":
      return (
        <img
          className={`${styles.container} ${className}`}
          src={isError ? userSVG : src}
          alt=""
          title={alt}
          onClick={onClick}
          style={style}
          onError={() => setIsError(true)}
        />
      );
    default:
      return (
        <img
          className={`${styles.container} ${styles.squared} ${className}`}
          src={src}
          alt=""
          title={alt}
          onClick={onClick}
          style={style}
        />
      );
  }
});

Avatar.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  type: PropTypes.oneOf(["rounded", "square", "unset"]).isRequired,
  onClick: PropTypes.func
};

Avatar.defaultProps = {
  className: "",
  src: userSVG,
  type: "square",
  alt: "user",
  onClick: () => {}
};

export { Avatar };
