import React from "react";
import PropTypes from "prop-types";
import styles from "./styles/NavigationTab.module.css";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserTasks,
  getInfoAboutUser,
  getUserTasks,
  resetSelectedWorkspace,
  setUserTasksLoader
} from "../../../state/actions";
import { LockIcon } from "../../ReactSVGIcons/LockIcon";

export const TabLink = ({ tabName, isLocked, linkTo, children }) => {
  const dispatch = useDispatch();
  const workspaceInformationState = useSelector((state) => state.workspaceInformationReducer);

  const tabNameClickHandler = (tabName) => {
    dispatch(resetSelectedWorkspace(tabName));
  };

  return (
    <Link
      onClick={() => tabNameClickHandler(tabName)}
      to={isLocked ? `/locked` : linkTo}
      style={{ textDecoration: "none" }}
    >
      {children ? (
        children
      ) : (
        <div className={`${styles.tab} ${workspaceInformationState.selectedTab === tabName ? styles.selectedTab : ""}`}>
          {tabName}
          {isLocked === 1 ? <LockIcon className={styles.lockedTab} /> : ""}
        </div>
      )}
    </Link>
  );
};

const NavigationTab = React.memo(({ tabName, isLocked }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const authorizationState = useSelector((state) => state.authorizationReducer);

  const userTasksState = useSelector((state) => state.userTasksReducer);
  const workspaceInformationState = useSelector((state) => state.workspaceInformationReducer);

  const onWorkspaceClickHandler = () => {
    if (
      location.pathname !== `/${authorizationState.company_id}/tasks/user/${userTasksState.user_info.user_id}/tasks`
    ) {
      dispatch(setUserTasksLoader(true));
      dispatch(fetchUserTasks(authorizationState.user_id));
    } else {
      dispatch(getInfoAboutUser(authorizationState.user_id));
      dispatch(getUserTasks(authorizationState.user_id));
    }
  };

  switch (tabName) {
    case "Отчеты":
      return (
        <TabLink
          tabName={tabName}
          isLocked={isLocked}
          linkTo={`/${authorizationState.company_id}/reports/${authorizationState.user_id}`}
        />
      );
    case "Пользователи":
      return <TabLink tabName={tabName} isLocked={isLocked} linkTo={`/${authorizationState.company_id}/users`} />;
    case "База знаний":
      return (
        <TabLink tabName={tabName} isLocked={isLocked} linkTo={`/${authorizationState.company_id}/tasks/knowledge`} />
      );
    case "Лиды":
      return (
        <TabLink tabName={tabName} isLocked={isLocked} linkTo={`/${authorizationState.company_id}/leads/requests`} />
      );
    case "Задачи":
      return (
        <TabLink
          tabName={tabName}
          isLocked={isLocked}
          linkTo={`/${authorizationState.company_id}/tasks/user/${authorizationState.user_id}/tasks`}
          children={
            <div
              className={`${styles.tab} ${workspaceInformationState.selectedTab === tabName ? styles.selectedTab : ""}`}
              onClick={onWorkspaceClickHandler}
            >
              {tabName}
            </div>
          }
        />
      );
    case "КП":
      return <TabLink tabName={tabName} isLocked={isLocked} linkTo={`/kp`} />;
    default:
      return <div className={styles.tab}>{tabName}</div>;
  }
});

NavigationTab.propTypes = {
  tabName: PropTypes.string
};

NavigationTab.defaultProps = {
  tabName: "tab name"
};

export { NavigationTab };
