import React from "react";
import styles from "./styles/userRank.module.css";
import { Avatar } from "../Avatars/Avatar";
import { useSelector } from "react-redux";

const UserRank = React.memo(({ grade, sixhandsAmount, isReversed }) => {
  const companyUsersState = useSelector((state) => state.companyUsersReducer);

  const authorizationState = useSelector((state) => state.authorizationReducer);

  const convertNumberToRomanNumber = (num) => {
    if (isNaN(num)) return NaN;
    let digits = String(+num).split(""),
      key = [
        "",
        "C",
        "CC",
        "CCC",
        "CD",
        "D",
        "DC",
        "DCC",
        "DCCC",
        "CM",
        "",
        "X",
        "XX",
        "XXX",
        "XL",
        "L",
        "LX",
        "LXX",
        "LXXX",
        "XC",
        "",
        "I",
        "II",
        "III",
        "IV",
        "V",
        "VI",
        "VII",
        "VIII",
        "IX"
      ],
      romanNumber = "",
      i = 3;
    while (i--) romanNumber = (key[+digits.pop() + i * 10] || "") + romanNumber;
    return Array(+digits.join("") + 1).join("M") + romanNumber;
  };

  // const countSixhands = (amount) => {
  //     amount = amount.toString()
  //     if(amount.split('.').length>1)
  //         amount = amount.split('.')[1][0]
  //     else amount = amount[amount.length-1]
  //     console.log(amount)
  // }
  //
  // countSixhands(sixhandsAmount)

  return (
    <div className={styles.container}>
      {authorizationState.role_id > 1 && (
        <>
          <Avatar
            type={"rounded"}
            className={styles.avatar}
            alt={""}
            // src={companyUsersState.users.length > 0 && companyUsersState.users.find((user)=>user.user_id === authorizationState.user_id).user_photo}
          />
          <div className={`${styles.info} ${isReversed ? styles.reversed : ""}`}>
            <div className={styles.name}>
              <h1>
                {companyUsersState.users.length > 0 &&
                  companyUsersState.users.find((user) => user.user_id === authorizationState.user_id)
                    .user_first_name}{" "}
                &nbsp;
                {companyUsersState.users.length > 0 &&
                  companyUsersState.users.find((user) => user.user_id === authorizationState.user_id).user_last_name}
              </h1>
              {grade > 0 && <p>Грейд: {grade ? convertNumberToRomanNumber(grade) : ""}</p>}
            </div>
            {grade > 0 && (
              <div className={styles.row}>
                <p>Всего: </p>
                <p>
                  {sixhandsAmount && sixhandsAmount.toString().split(".")[1]
                    ? sixhandsAmount.toFixed(1)
                    : sixhandsAmount}{" "}
                  Шестируков
                </p>
              </div>
            )}
            {/*<div className={styles.row}>*/}
            {/*    <p>За текущий месяц: </p>*/}
            {/*    <p>0.0 Шестируков</p>*/}
            {/*</div>*/}
            {/*<div className={styles.row}>*/}
            {/*    <p>Осталось до нового Грейда: </p>*/}
            {/*    <p>2 Шестирука</p>*/}
            {/*</div>*/}
          </div>
        </>
      )}
    </div>
  );
});

export { UserRank };
