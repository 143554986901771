import useCookies from "react-cookie/cjs/useCookies";
import { logoutUser } from "../../state/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const useLogOut = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // eslint-disable-next-line
    const [cookies, setCookie, removeCookie] = useCookies(['token']);

  return () => {
    removeCookie("token", { path: "/" });
    dispatch(logoutUser());
    history.push("/auth");
  };
};

export { useLogOut };
