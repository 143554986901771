import lockIcon from "../../assets/icons/lockIcon.svg";
import styles from "./styles/icons.module.css";
import React from "react";

const LockIcon = ({ type, className }) => {
  switch (type) {
    case "navigation":
      return <img src={lockIcon} className={`${styles.lockIcon} ${styles.navigation} ${className}`} alt={""} />;
    case "user_tasks":
      return <img src={lockIcon} className={`${styles.lockIconUserTasks} ${className}`} alt={""} />;
    default:
      return <img src={lockIcon} className={`${styles.lockIcon} ${className}`} alt={""} />;
  }
};

export { LockIcon };
