import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles/DropdownButton.module.css";
import { ArrowIcon } from "../ReactSVGIcons/ArrowIcon";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const DropdownButton = React.memo(
  ({
    buttonLabel,
    dropDownMenuOptions,
    className,
    showArrowWithoutHover,
    customStylesToOptionsList,
    customStylesToOptionsListElement,
    customStylesToButtonOnOpenMenu,
    customStylesToButton,
    customArrowStyles,
    onSelectOptionFunction,
    disabled,
    editable,
    isCalledFromUserTasks,
    isFilter,
    shouldNotHaveShadowOnHover,
    defaultLabel,
    onParentClickHandler,
    readonly
  }) => {
    const containerRef = useRef(null);

    const [open, setOpen] = useState(false);
    const [buttonSelectOptionLabel, setButtonSelectOptionLabel] = useState(buttonLabel);
    let location = useLocation();
    const leadsInformation = useSelector((state) => state.leadsReducer);

    const workspaceInformation = useSelector((state) => state.workspaceInformationReducer);

    const onClickHandler = (event) => {
      if (!event.target.closest(`.${customStylesToOptionsList}`) && open && !readonly) {
        setOpen(false);
      }
    };

    const onClickButtonHandler = () => {
      onParentClickHandler && onParentClickHandler();
      setOpen((open) => !open);
    };

    const onOptionClickHandler = (event) => {
      if (!readonly) {
        setButtonSelectOptionLabel(event.target.innerText);
        setOpen((open) => !open);
        isCalledFromUserTasks
          ? onSelectOptionFunction(event.target.getAttribute("data-key"))
          : onSelectOptionFunction(event.target.innerText);
      }
    };

    useEffect(() => {
      document.addEventListener("click", onClickHandler);
      return () => {
        document.removeEventListener("click", onClickHandler);
      };
    });

    useEffect(() => {
      setButtonSelectOptionLabel(buttonLabel);
    }, [buttonLabel]);

    useEffect(() => {
      setButtonSelectOptionLabel(buttonLabel);
      // eslint-disable-next-line
    }, [location, workspaceInformation.new_task]);

    useEffect(() => {
      setButtonSelectOptionLabel(buttonLabel);
      // eslint-disable-next-line
    }, [leadsInformation.displayState])

    return (
      <div className={`${styles.container} ${className}`} ref={containerRef} title={buttonSelectOptionLabel}>
        <button
          className={`${shouldNotHaveShadowOnHover ? styles.buttonWithOutShadow : styles.button} ${
            open ? customStylesToButtonOnOpenMenu : ""
          } ${customStylesToButton}`}
          disabled={disabled}
          type="button"
          onClick={editable ? onClickButtonHandler : undefined}
          style={
            !editable
              ? { cursor: "default" }
              : isFilter && buttonSelectOptionLabel !== defaultLabel
              ? { fontWeight: "600" }
              : {}
          }
        >
          <p>{buttonSelectOptionLabel}</p>
          {!disabled && (
            <ArrowIcon
              className={`${styles.arrow} ${open ? styles.isOpen : ""} ${
                showArrowWithoutHover ? "" : styles.hideWithoutHover
              } ${customArrowStyles}`}
            />
          )}
        </button>
        {open && (
          <div className={`${customStylesToOptionsList === "" ? styles.dropdownMenu : customStylesToOptionsList}`}>
            {dropDownMenuOptions &&
              dropDownMenuOptions
                .filter((option) => option.label !== buttonLabel)
                .map((option) => {
                  return (
                    <div
                      className={`${
                        customStylesToOptionsListElement === "" ? styles.option : customStylesToOptionsListElement
                      }`}
                      key={option.id}
                      data-key={option.id}
                      onClick={editable ? onOptionClickHandler : undefined}
                      title={option.label}
                    >
                      {option.label}
                    </div>
                  );
                })}
          </div>
        )}
      </div>
    );
  }
);

DropdownButton.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  dropDownMenuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  className: PropTypes.string,
  showArrowWithoutHover: PropTypes.bool,
  customStylesToOptionsList: PropTypes.string,
  customStylesToOptionsListElement: PropTypes.string,
  customStylesToButtonOnOpenMenu: PropTypes.string,
  customStylesToButton: PropTypes.string,
  customArrowStyles: PropTypes.string,
  onSelectOptionFunction: PropTypes.func,
  disabled: PropTypes.bool
};

DropdownButton.defaultProps = {
  className: "",
  showArrowWithoutHover: true,
  customStylesToOptionsList: "",
  customStylesToOptionsListElement: "",
  customStylesToButtonOnOpenMenu: "",
  customStylesToButton: "",
  customArrowStyles: "",
  onSelectOptionFunction: () => {},
  disabled: false,
  readonly: false
};

export { DropdownButton };
