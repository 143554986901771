import React from "react";
import PropTypes from "prop-types";
import styles from "./styles/PeoplesStacker.module.css";
import { Avatar } from "../Avatars/Avatar";

const PeoplesStacker = ({ numOfPeoples, setLastBlock, avatarType, arrayOfPeoples, onClick }) => {
  return (
    <div className={styles.peoplesStack} onClick={onClick}>
      {arrayOfPeoples.slice(0, numOfPeoples).map((user) => (
        <div key={user.user_id} className={styles.peoplesSpacer}>
          <Avatar
            type={avatarType}
            src={user.user_photo}
            alt={`${user.user_first_name} ${user.user_last_name} ${
              user.user_middle_name !== null ? user.user_middle_name : ""
            }`.trim()}
          />
        </div>
      ))}
      {setLastBlock && (
        <div className={styles.peoplesSpacer}>
          <div className={styles.lastStackBlock}>
            <p>{arrayOfPeoples.length}</p>
          </div>
        </div>
      )}
    </div>
  );
};

PeoplesStacker.propTypes = {
  numOfPeoples: PropTypes.number.isRequired,
  setLastBlock: PropTypes.bool.isRequired,
  avatarType: PropTypes.oneOf(["rounded", "square"]),
  arrayOfPeoples: PropTypes.arrayOf(
    PropTypes.shape({
      user_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      user_first_name: PropTypes.string.isRequired,
      user_last_name: PropTypes.string.isRequired,
      user_middle_name: PropTypes.string,
      user_photo: PropTypes.string
    })
  ).isRequired
};

export { PeoplesStacker };
