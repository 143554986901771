import styles from "./styles/navigationBar.module.css";
import { getWorkspaceColor } from "../../pages/Workspace/components/WorkspaceHeader/WorkspaceHeader";
import { LockIcon } from "../ReactSVGIcons/LockIcon";
import { Link } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import { guestId } from "../../config/CONSTANTS";

const WorkspaceTab = React.memo(({ workspace }) => {
  const workspaceInformationState = useSelector((state) => state.workspaceInformationReducer);

  const authorizationState = useSelector((state) => state.authorizationReducer);

  return (
    <Link
      key={workspace.workspace_id}
      to={`/${authorizationState.company_id}/tasks/workspace/${workspace.workspace_id}`}
    >
      <div
        className={`${styles.workspaceListElement} ${
          workspace.workspace_id === workspaceInformationState.information.workspace_id ? styles.current : ""
        }`}
      >
        <div
          className={styles.workspaceMarker}
          style={
            workspace.workspace_id === workspaceInformationState.information.workspace_id
              ? {
                  backgroundColor: getWorkspaceColor(workspace.workspace_id),
                  boxShadow: `0 0 6px ${getWorkspaceColor(workspace.workspace_id)}`
                }
              : {
                  backgroundColor: getWorkspaceColor(workspace.workspace_id)
                }
          }
        />
        <p
          className={`${styles.workspaceElement} ${
            workspace.workspace_id === workspaceInformationState.information.workspace_id ? styles.current : ""
          }`}
          style={authorizationState.user_id === guestId || authorizationState.role_id === 1 ? { maxWidth: "75%" } : {}}
        >
          {workspace.workspace_name}
        </p>
        {workspace.permissions.can_read !== 1 && <LockIcon type={"navigation"} />}
      </div>
    </Link>
  );
});

export { WorkspaceTab };
