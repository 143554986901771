import {
  ACCEPT_ACTION_USER_TASK_FAILURE,
  ACCEPT_ACTION_USER_TASK_START,
  ACCEPT_ACTION_USER_TASK_SUCCESS,
  ADD_NEW_CARD_TO_USER_TASKS_SUCCESS,
  CHANGE_FILTER_OPTION_USER_TASKS,
  CHANGE_TASK_DEADLINE_DATE_USER_TASKS_FAILURE,
  CHANGE_TASK_DEADLINE_DATE_USER_TASKS_START,
  CHANGE_TASK_DESCRIPTION_FAILURE,
  CHANGE_TASK_DESCRIPTION_START,
  CHANGE_TASK_DESCRIPTION_SUCCESS,
  CHANGE_TASK_HEADER_FAILURE,
  CHANGE_TASK_HEADER_START,
  CHANGE_TASK_HEADER_SUCCESS,
  CHANGE_TASK_PRIORITY_USER_TASKS_FAILURE,
  CHANGE_TASK_PRIORITY_USER_TASKS_START,
  CHANGE_TASK_TOPIC_USER_TASKS_FAILURE,
  CHANGE_TASK_TOPIC_USER_TASKS_START,
  CLEAR_SEARCH_USER_PARAMS,
  DECLINE_ACTION_USER_TASKS_TASK_FAILURE,
  DECLINE_ACTION_USER_TASKS_TASK_START,
  DECLINE_ACTION_USER_TASKS_TASK_SUCCESS,
  DELETE_TASK_FROM_USER_TASKS_FAILURE,
  DELETE_TASK_FROM_USER_TASKS_STARTED,
  FILTER_USER_TASKS,
  FILTER_USER_TASKS_BY_FILTER,
  GET_ALL_TASK_PRIORITY_STATUSES_SUCCESS,
  GET_ALL_TASK_TOPICS_SUCCESS,
  GET_ALL_USERS_FROM_COMPANY_SUCCESS,
  GET_INFO_ABOUT_TASK_USER_TASKS_SUCCESS,
  GET_USER_INFO_SUCCESS,
  GET_USER_TASKS_FAILURE,
  GET_USER_TASKS_START,
  GET_USER_TASKS_SUCCESS,
  MARK_TASK_AS_DONE_USER_TASKS_FAILURE,
  MARK_TASK_AS_DONE_USER_TASKS_START,
  MARK_TASK_AS_DONE_USER_TASKS_SUCCESS,
  MARK_TASK_AS_UNDONE_USER_TASKS_FAILURE,
  MARK_TASK_AS_UNDONE_USER_TASKS_START,
  MARK_TASK_AS_UNDONE_USER_TASKS_SUCCESS,
  MOVE_TASK_TO_ANOTHER_WORKSPACE_START,
  RESET_FILTER_OPTIONS_USER_TASKS,
  RESET_NEW_TASK_USER_TASKS,
  RESET_USER_TASKS_ERROR,
  SEARCH_USER_TASKS,
  SET_IMPLEMENTER_TO_USER_TASKS_TASK_START,
  SET_INITIATOR_TO_TASK_USER_TASKS_FAILURE,
  SET_INITIATOR_TO_TASK_USER_TASKS_START,
  SET_INITIATOR_TO_TASK_USER_TASKS_SUCCESS,
  SET_USER_TASKS_LOADER,
  SET_USER_TASKS_USERS_SEARCH_FILTER,
  SET_VERIFICATOR_TO_TASK_USER_TASKS_FAILURE,
  SET_VERIFICATOR_TO_TASK_USER_TASKS_START,
  SET_VERIFICATOR_TO_TASK_USER_TASKS_SUCCESS,
  UNSET_IMPLEMENTER_TO_TASK_USER_TASKS_FAILURE,
  UNSET_IMPLEMENTER_TO_TASK_USER_TASKS_START,
  UNSET_IMPLEMENTER_TO_TASK_USER_TASKS_SUCCESS,
  UNSET_INITIATOR_TO_TASK_USER_TASKS_FAILURE,
  UNSET_INITIATOR_TO_TASK_USER_TASKS_START,
  UNSET_INITIATOR_TO_TASK_USER_TASKS_SUCCESS,
  UNSET_TASK_DEADLINE_DATE_USER_TASKS_FAILURE,
  UNSET_TASK_DEADLINE_DATE_USER_TASKS_START,
  UNSET_VERIFICATOR_TO_TASK_USER_TASKS_FAILURE,
  UNSET_VERIFICATOR_TO_TASK_USER_TASKS_START,
  UNSET_VERIFICATOR_TO_TASK_USER_TASKS_SUCCESS
} from "../../actions/actionTypes";

const initialState = {
  initial_user_tasks: [],
  user_tasks: [],
  loading: true,
  tasksLoading: false,
  error: null,
  filtered_user_tasks: [],
  user_info: [],
  priorityList: [],
  topicsList: [],
  users: [],
  filteredUsers: [],
  searchFilter: "",
  tasksSearchQuery: "",
  sortFilter: {
    taskStatus: 2,
    taskType: 0,
    deadlineStatus: 0,
    dateId: null,
    topicId: 0,
    priorityId: 0,
    implementer: null,
    initiator: null,
    verificator: null,
    displayTasks: 1
  },
  userRank: {
    user_grade: 0,
    user_shestiruk_amount: 0,
    percentage: 0
  }
};

function userTasksReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER_INFO_SUCCESS: {
      return {
        ...state,
        user_info: action.user_info
      };
    }

    case GET_USER_TASKS_START: {
      return {
        ...state,
        tasksLoading: true
      };
    }

    case GET_USER_TASKS_FAILURE: {
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        tasksLoading: false
      };
    }

    case GET_USER_TASKS_SUCCESS: {
      return {
        ...state,
        user_tasks: action.user_tasks,
        initial_user_tasks: action.user_tasks,
        tasksLoading: false
        // loading: action.loading,
        // tasksLoading: false,
      };
    }

    case FILTER_USER_TASKS: {
      let workspacesList = [];
      state.user_tasks.forEach((task) => {
        if (!workspacesList.find((item) => item.workspace_id === task.workspace_id))
          workspacesList.push({
            workspace_name: task.workspace_name,
            workspace_id: task.workspace_id,
            tasks: state.user_tasks
              .filter((new_task) => new_task.workspace_id === task.workspace_id)
              .sort((a, b) => {
                if (new Date(a.createdAt) > new Date(b.createdAt)) return -1;
                else return 1;
              })
          });
      });
      return {
        ...state,
        filtered_user_tasks: workspacesList
      };
    }

    case GET_ALL_TASK_PRIORITY_STATUSES_SUCCESS: {
      return {
        ...state,
        priorityList: action.data
      };
    }

    case GET_ALL_TASK_TOPICS_SUCCESS: {
      return {
        ...state,
        topicsList: action.data
      };
    }

    case GET_ALL_USERS_FROM_COMPANY_SUCCESS: {
      return {
        ...state,
        users: action.data
      };
    }

    case CHANGE_TASK_DEADLINE_DATE_USER_TASKS_START: {
      let new_tasks = state.user_tasks.map((task) =>
        task.task_id === action.taskId
          ? Object.assign({}, task, {
              task_deadline_dt: action.newDeadlineDate
            })
          : task
      );
      return {
        ...state,
        user_tasks: new_tasks,
        initial_user_tasks: new_tasks
      };
    }

    case CHANGE_TASK_DEADLINE_DATE_USER_TASKS_FAILURE: {
      let new_tasks = state.user_tasks.map((task) =>
        task.task_id === action.taskId
          ? Object.assign({}, task, {
              task_deadline_dt: action.previousDeadlineDate
            })
          : task
      );
      return {
        ...state,
        user_tasks: new_tasks,
        initial_user_tasks: new_tasks
      };
    }

    case UNSET_TASK_DEADLINE_DATE_USER_TASKS_START: {
      const newTaskState = [...state.user_tasks];
      newTaskState.find((task) => task.task_id === action.taskId).task_deadline_dt = null;
      return {
        ...state,
        user_tasks: newTaskState,
        initial_user_tasks: newTaskState
      };
    }

    case UNSET_TASK_DEADLINE_DATE_USER_TASKS_FAILURE: {
      const newTaskState = [...state.user_tasks];
      newTaskState.find((task) => task.task_id === action.taskId).task_deadline_dt = action.previousDate;
      return {
        ...state,
        user_tasks: newTaskState,
        initial_user_tasks: newTaskState
      };
    }

    case CHANGE_TASK_PRIORITY_USER_TASKS_START: {
      let newTasks = state.user_tasks.map((task) =>
        task.task_id === action.taskId ? Object.assign({}, task, { task_priority_id: action.newPriority }) : task
      );
      return {
        ...state,
        user_tasks: newTasks
        // initial_user_tasks: newTasks,
      };
    }
    case CHANGE_TASK_PRIORITY_USER_TASKS_FAILURE: {
      let new_tasks = state.user_tasks.map((task) =>
        task.task_id === action.task_id
          ? Object.assign({}, task, {
              task_priority_id: action.previousPriority
            })
          : task
      );
      return {
        ...state,
        user_tasks: new_tasks
        // initial_user_tasks: new_tasks,
      };
    }

    case CHANGE_TASK_TOPIC_USER_TASKS_START: {
      let new_tasks = state.user_tasks.map((task) =>
        task.task_id === action.taskId
          ? Object.assign({}, task, {
              task_topic_id: action.newTopicId
            })
          : task
      );
      return {
        ...state,
        user_tasks: new_tasks
        // initial_user_tasks: new_tasks,
      };
    }
    case CHANGE_TASK_TOPIC_USER_TASKS_FAILURE: {
      let new_tasks = state.user_tasks.map((task) =>
        task.task_id === action.taskId
          ? Object.assign({}, task, {
              task_topic_id: action.previousTopicId
            })
          : task
      );
      return {
        ...state,
        user_tasks: new_tasks
        // initial_user_tasks: new_tasks,
      };
    }
    case CHANGE_FILTER_OPTION_USER_TASKS: {
      const newSortFilterState = Object.assign({}, state.sortFilter, {
        [action.filterOptionName]: action.filterOptionValue
      });
      return {
        ...state,
        sortFilter: newSortFilterState
      };
    }

    case FILTER_USER_TASKS_BY_FILTER: {
      let newTasks = [];
      if (
        state.sortFilter.taskStatus === 0 &&
        state.sortFilter.taskType === 0 &&
        state.sortFilter.deadlineStatus === 0 &&
        state.sortFilter.priorityId === 0 &&
        !state.sortFilter.dateId &&
        state.sortFilter.topicId === 0 &&
        !state.sortFilter.implementer &&
        !state.sortFilter.initiator &&
        !state.sortFilter.verificator &&
        state.sortFilter.displayTasks === 0
      )
        newTasks = state.initial_user_tasks;
      else if (state.sortFilter.taskStatus === 0) {
        newTasks = state.initial_user_tasks;
        if (state.sortFilter.taskType === 1) newTasks = newTasks.filter((task) => task.task_type === "REGULAR");
        if (state.sortFilter.taskType === 2) newTasks = newTasks.filter((task) => task.task_type === "VERIFICATION");
        if (state.sortFilter.deadlineStatus === 1)
          newTasks = newTasks.filter(
            (task) =>
              task.task_deadline_dt !== null &&
              new Date(task.task_deadline_dt).toISOString().substring(0, 10) < new Date().toISOString().substring(0, 10)
          );
        if (state.sortFilter.deadlineStatus === 2)
          newTasks = newTasks.filter(
            (task) =>
              task.task_deadline_dt !== null &&
              new Date(task.task_deadline_dt).toISOString().substring(0, 10) ===
                new Date().toISOString().substring(0, 10)
          );
        if (state.sortFilter.deadlineStatus === 3)
          newTasks = newTasks.filter(
            (task) =>
              task.task_deadline_dt !== null &&
              new Date(task.task_deadline_dt).toISOString().substring(0, 10) >=
                new Date().toISOString().substring(0, 10)
          );
        if (state.sortFilter.deadlineStatus === 4) newTasks = newTasks.filter((task) => task.task_deadline_dt !== null);
        if (state.sortFilter.deadlineStatus === 5) newTasks = newTasks.filter((task) => task.task_deadline_dt === null);
        if (state.sortFilter.deadlineStatus === 6) {
          let date_now = new Date();
          let tomorrow = new Date(date_now.getFullYear(), date_now.getMonth(), date_now.getDate() + 1);
          newTasks = newTasks.filter(
            (task) =>
              task.task_deadline_dt !== null &&
              new Date(new Date(task.task_deadline_dt).setHours(0, 0, 0, 0)).toString() ===
                new Date(tomorrow.setHours(0, 0, 0, 0)).toString()
          );
        }
        if (state.sortFilter.deadlineStatus === 7) {
          let date_now = new Date();
          let tomorrow = new Date(date_now.getFullYear(), date_now.getMonth(), date_now.getDate() + 1);
          newTasks = newTasks.filter(
            (task) =>
              (task.task_deadline_dt !== null &&
                new Date(new Date(task.task_deadline_dt).setHours(0, 0, 0, 0)).toString() ===
                  new Date(tomorrow.setHours(0, 0, 0, 0)).toString()) ||
              (task.task_deadline_dt !== null &&
                new Date(task.task_deadline_dt).toISOString().substring(0, 10) >=
                  new Date().toISOString().substring(0, 10))
          );
        }
        if (state.sortFilter.dateId === 0)
          newTasks = newTasks.sort(function (a, b) {
            if (a.task_deadline_dt < b.task_deadline_dt) {
              return 1;
            }
            if (a.task_deadline_dt > b.task_deadline_dt) {
              return -1;
            }
            if (a.task_deadline_dt === b.task_deadline_dt) {
              if (a.task_id < b.task_id) {
                return 1;
              }
              if (a.task_id > b.task_id) {
                return -1;
              }
            }
            return 0;
          });
        if (state.sortFilter.dateId === 1)
          newTasks = newTasks.sort(function (a, b) {
            if (a.task_deadline_dt > b.task_deadline_dt) {
              return 1;
            }
            if (a.task_deadline_dt < b.task_deadline_dt) {
              return -1;
            }
            if (a.task_deadline_dt === b.task_deadline_dt) {
              if (a.task_id > b.task_id) {
                return 1;
              }
              if (a.task_id < b.task_id) {
                return -1;
              }
            }
            return 0;
          });
        if (state.sortFilter.priorityId !== 0)
          newTasks = newTasks.filter((task) => task.task_priority_id === state.sortFilter.priorityId);
        if (state.sortFilter.topicId !== 0)
          newTasks = newTasks.filter((task) => task.task_topic_id === state.sortFilter.topicId);
        if (state.sortFilter.initiator)
          newTasks = newTasks.filter((task) => task.initiator_user_id === state.sortFilter.initiator);
        if (state.sortFilter.verificator)
          newTasks = newTasks.filter((task) => task.verificator_user_id === state.sortFilter.verificator);
        if (state.sortFilter.implementer)
          newTasks = newTasks.filter((task) => task.implementer_user_id === state.sortFilter.implementer);
        if (state.sortFilter.displayTasks === 1)
          newTasks = newTasks.filter((task) => task.implementer_user_id === state.user_info.user_id);
        if (state.sortFilter.displayTasks === 2)
          newTasks = newTasks.filter((task) => task.verificator_user_id === state.user_info.user_id);
        if (state.sortFilter.displayTasks === 3)
          newTasks = newTasks.filter((task) => task.initiator_user_id === state.user_info.user_id);
      } else if (state.sortFilter.taskStatus === 1) {
        newTasks = state.initial_user_tasks.filter((task) => task.task_status === "DONE");
        if (state.sortFilter.taskType === 1) newTasks = newTasks.filter((task) => task.task_type === "REGULAR");
        if (state.sortFilter.taskType === 2) newTasks = newTasks.filter((task) => task.task_type === "VERIFICATION");
        if (state.sortFilter.deadlineStatus === 1)
          newTasks = newTasks.filter(
            (task) =>
              task.task_deadline_dt !== null &&
              new Date(task.task_deadline_dt).toISOString().substring(0, 10) < new Date().toISOString().substring(0, 10)
          );
        if (state.sortFilter.deadlineStatus === 2)
          newTasks = newTasks.filter(
            (task) =>
              task.task_deadline_dt !== null &&
              new Date(task.task_deadline_dt).toISOString().substring(0, 10) ===
                new Date().toISOString().substring(0, 10)
          );
        if (state.sortFilter.deadlineStatus === 3)
          newTasks = newTasks.filter(
            (task) =>
              task.task_deadline_dt !== null &&
              new Date(task.task_deadline_dt).toISOString().substring(0, 10) >=
                new Date().toISOString().substring(0, 10)
          );
        if (state.sortFilter.deadlineStatus === 4) newTasks = newTasks.filter((task) => task.task_deadline_dt !== null);
        if (state.sortFilter.deadlineStatus === 5) newTasks = newTasks.filter((task) => task.task_deadline_dt === null);
        if (state.sortFilter.deadlineStatus === 6) {
          let date_now = new Date();
          let tomorrow = new Date(date_now.getFullYear(), date_now.getMonth(), date_now.getDate() + 1);
          newTasks = newTasks.filter(
            (task) =>
              task.task_deadline_dt !== null &&
              new Date(new Date(task.task_deadline_dt).setHours(0, 0, 0, 0)).toString() ===
                new Date(tomorrow.setHours(0, 0, 0, 0)).toString()
          );
        }
        if (state.sortFilter.deadlineStatus === 7) {
          let date_now = new Date();
          let tomorrow = new Date(date_now.getFullYear(), date_now.getMonth(), date_now.getDate() + 1);
          newTasks = newTasks.filter(
            (task) =>
              (task.task_deadline_dt !== null &&
                new Date(new Date(task.task_deadline_dt).setHours(0, 0, 0, 0)).toString() ===
                  new Date(tomorrow.setHours(0, 0, 0, 0)).toString()) ||
              (task.task_deadline_dt !== null &&
                new Date(task.task_deadline_dt).toISOString().substring(0, 10) >=
                  new Date().toISOString().substring(0, 10))
          );
        }
        // newTasks = newTasks.filter((task) => (
        //     task.task_deadline_dt !== null &&
        //     new Date(task.task_deadline_dt)
        //         .toISOString()
        //         .substring(0, 10) ===
        //     new Date().toISOString().substring(0, 10)
        // ))
        if (state.sortFilter.dateId === 0)
          newTasks = newTasks.sort(function (a, b) {
            if (a.task_deadline_dt < b.task_deadline_dt) {
              return 1;
            }
            if (a.task_deadline_dt > b.task_deadline_dt) {
              return -1;
            }
            if (a.task_deadline_dt === b.task_deadline_dt) {
              if (a.task_id < b.task_id) {
                return 1;
              }
              if (a.task_id > b.task_id) {
                return -1;
              }
            }
            return 0;
          });
        if (state.sortFilter.dateId === 1)
          newTasks = newTasks.sort(function (a, b) {
            if (a.task_deadline_dt > b.task_deadline_dt) {
              return 1;
            }
            if (a.task_deadline_dt < b.task_deadline_dt) {
              return -1;
            }
            if (a.task_deadline_dt === b.task_deadline_dt) {
              if (a.task_id > b.task_id) {
                return 1;
              }
              if (a.task_id < b.task_id) {
                return -1;
              }
            }
            return 0;
          });
        if (state.sortFilter.dateId === 0)
          newTasks = newTasks.sort(function (a, b) {
            if (a.task_deadline_dt < b.task_deadline_dt) {
              return 1;
            }
            if (a.task_deadline_dt > b.task_deadline_dt) {
              return -1;
            }
            if (a.task_deadline_dt === b.task_deadline_dt) {
              if (a.task_id < b.task_id) {
                return 1;
              }
              if (a.task_id > b.task_id) {
                return -1;
              }
            }
            return 0;
          });

        if (state.sortFilter.dateId === 1)
          newTasks = newTasks.sort(function (a, b) {
            if (a.task_deadline_dt > b.task_deadline_dt) {
              return 1;
            }
            if (a.task_deadline_dt < b.task_deadline_dt) {
              return -1;
            }
            if (a.task_deadline_dt === b.task_deadline_dt) {
              if (a.task_id > b.task_id) {
                return 1;
              }
              if (a.task_id < b.task_id) {
                return -1;
              }
            }
            return 0;
          });
        if (state.sortFilter.priorityId !== 0)
          newTasks = newTasks.filter((task) => task.task_priority_id === state.sortFilter.priorityId);
        if (state.sortFilter.topicId !== 0)
          newTasks = newTasks.filter((task) => task.task_topic_id === state.sortFilter.topicId);
        if (state.sortFilter.initiator)
          newTasks = newTasks.filter((task) => task.initiator_user_id === state.sortFilter.initiator);
        if (state.sortFilter.verificator)
          newTasks = newTasks.filter((task) => task.verificator_user_id === state.sortFilter.verificator);
        if (state.sortFilter.implementer)
          newTasks = newTasks.filter((task) => task.implementer_user_id === state.sortFilter.implementer);
        if (state.sortFilter.displayTasks === 1)
          newTasks = newTasks.filter((task) => task.implementer_user_id === state.user_info.user_id);
        if (state.sortFilter.displayTasks === 2)
          newTasks = newTasks.filter((task) => task.verificator_user_id === state.user_info.user_id);
        if (state.sortFilter.displayTasks === 3)
          newTasks = newTasks.filter((task) => task.initiator_user_id === state.user_info.user_id);
      } else if (state.sortFilter.taskStatus === 2) {
        newTasks = state.initial_user_tasks.filter((task) => task.task_status === "NOT_PROCESSED");
        if (state.sortFilter.taskType === 1) newTasks = newTasks.filter((task) => task.task_type === "REGULAR");
        if (state.sortFilter.taskType === 2) newTasks = newTasks.filter((task) => task.task_type === "VERIFICATION");
        if (state.sortFilter.deadlineStatus === 1)
          newTasks = newTasks.filter(
            (task) =>
              task.task_deadline_dt !== null &&
              new Date(task.task_deadline_dt).toISOString().substring(0, 10) < new Date().toISOString().substring(0, 10)
          );
        if (state.sortFilter.deadlineStatus === 2)
          newTasks = newTasks.filter(
            (task) =>
              task.task_deadline_dt !== null &&
              new Date(task.task_deadline_dt).toISOString().substring(0, 10) ===
                new Date().toISOString().substring(0, 10)
          );
        if (state.sortFilter.deadlineStatus === 3)
          newTasks = newTasks.filter(
            (task) =>
              task.task_deadline_dt !== null &&
              new Date(task.task_deadline_dt).toISOString().substring(0, 10) >=
                new Date().toISOString().substring(0, 10)
          );
        if (state.sortFilter.deadlineStatus === 4) newTasks = newTasks.filter((task) => task.task_deadline_dt !== null);
        if (state.sortFilter.deadlineStatus === 5) newTasks = newTasks.filter((task) => task.task_deadline_dt === null);
        if (state.sortFilter.deadlineStatus === 6) {
          let date_now = new Date();
          let tomorrow = new Date(date_now.getFullYear(), date_now.getMonth(), date_now.getDate() + 1);
          newTasks = newTasks.filter(
            (task) =>
              task.task_deadline_dt !== null &&
              new Date(new Date(task.task_deadline_dt).setHours(0, 0, 0, 0)).toString() ===
                new Date(tomorrow.setHours(0, 0, 0, 0)).toString()
          );
        }
        if (state.sortFilter.deadlineStatus === 7) {
          let date_now = new Date();
          let tomorrow = new Date(date_now.getFullYear(), date_now.getMonth(), date_now.getDate() + 1);
          newTasks = newTasks.filter(
            (task) =>
              (task.task_deadline_dt !== null &&
                new Date(new Date(task.task_deadline_dt).setHours(0, 0, 0, 0)).toString() ===
                  new Date(tomorrow.setHours(0, 0, 0, 0)).toString()) ||
              (task.task_deadline_dt !== null &&
                new Date(task.task_deadline_dt).toISOString().substring(0, 10) ===
                  new Date().toISOString().substring(0, 10))
          );
        }
        if (state.sortFilter.dateId === 0)
          newTasks = newTasks.sort(function (a, b) {
            if (a.task_deadline_dt < b.task_deadline_dt) {
              return 1;
            }
            if (a.task_deadline_dt > b.task_deadline_dt) {
              return -1;
            }
            if (a.task_deadline_dt === b.task_deadline_dt) {
              if (a.task_id < b.task_id) {
                return 1;
              }
              if (a.task_id > b.task_id) {
                return -1;
              }
            }
            return 0;
          });
        if (state.sortFilter.dateId === 1)
          newTasks = newTasks.sort(function (a, b) {
            if (a.task_deadline_dt > b.task_deadline_dt) {
              return 1;
            }
            if (a.task_deadline_dt < b.task_deadline_dt) {
              return -1;
            }
            if (a.task_deadline_dt === b.task_deadline_dt) {
              if (a.task_id > b.task_id) {
                return 1;
              }
              if (a.task_id < b.task_id) {
                return -1;
              }
            }
            return 0;
          });
        if (state.sortFilter.priorityId !== 0)
          newTasks = newTasks.filter((task) => task.task_priority_id === state.sortFilter.priorityId);
        if (state.sortFilter.topicId !== 0)
          newTasks = newTasks.filter((task) => task.task_topic_id === state.sortFilter.topicId);
        if (state.sortFilter.initiator)
          newTasks = newTasks.filter((task) => task.initiator_user_id === state.sortFilter.initiator);
        if (state.sortFilter.verificator)
          newTasks = newTasks.filter((task) => task.verificator_user_id === state.sortFilter.verificator);
        if (state.sortFilter.implementer)
          newTasks = newTasks.filter((task) => task.implementer_user_id === state.sortFilter.implementer);
        if (state.sortFilter.displayTasks === 1)
          newTasks = newTasks.filter((task) => task.implementer_user_id === state.user_info.user_id);
        if (state.sortFilter.displayTasks === 2)
          newTasks = newTasks.filter((task) => task.verificator_user_id === state.user_info.user_id);
        if (state.sortFilter.displayTasks === 3)
          newTasks = newTasks.filter((task) => task.initiator_user_id === state.user_info.user_id);
      }

      if (state.tasksSearchQuery !== "")
        newTasks = newTasks.filter((task) =>
          task.task_name?.toLowerCase().includes(state.tasksSearchQuery.toLowerCase())
        );

      return {
        ...state,
        user_tasks: newTasks
      };
    }

    case MARK_TASK_AS_DONE_USER_TASKS_START: {
      const newTaskState = [...state.user_tasks];
      const currentTaskIndex = newTaskState.findIndex((task) => task.task_id === action.taskId);
      const taskHaveVerificator = newTaskState[currentTaskIndex].verificator_user_id !== null;
      newTaskState[currentTaskIndex].task_status = taskHaveVerificator ? "ON_VERIFICATION" : "DONE";
      return {
        ...state,
        user_tasks: newTaskState
      };
    }

    case MARK_TASK_AS_DONE_USER_TASKS_FAILURE: {
      const newTaskState = [...state.user_tasks];
      newTaskState.find((task) => task.task_id === action.taskId).task_status = action.previousStatus;
      return {
        ...state,
        tasks: newTaskState
      };
    }

    case MARK_TASK_AS_DONE_USER_TASKS_SUCCESS: {
      const newTaskState = [...state.user_tasks];
      const updatedTask = newTaskState.find((task) => task.task_id === action.taskId);
      if (action.taskData.new_task) newTaskState.push(action.taskData.new_task);
      Object.assign(updatedTask, action.taskData);
      return {
        ...state,
        user_tasks: [...newTaskState]
      };
    }

    case MARK_TASK_AS_UNDONE_USER_TASKS_START: {
      const newTaskState = [...state.user_tasks];
      const currentTaskIndex = newTaskState.findIndex((task) => task.task_id === action.taskId);
      const taskHaveVerificator = newTaskState[currentTaskIndex].verificator_user_id !== null;
      newTaskState[currentTaskIndex].task_status = taskHaveVerificator ? "ON_VERIFICATION" : "NOT_PROCESSED";
      return {
        ...state,
        user_tasks: newTaskState
      };
    }

    case MARK_TASK_AS_UNDONE_USER_TASKS_FAILURE: {
      const newTaskState = [...state.user_tasks];
      newTaskState.find((task) => task.task_id === action.taskId).task_status = action.previousStatus;
      return {
        ...state,
        user_tasks: newTaskState
      };
    }

    case MARK_TASK_AS_UNDONE_USER_TASKS_SUCCESS: {
      const newTaskState = [...state.user_tasks];
      const updatedTask = newTaskState.find((task) => task.task_id === action.taskId);
      if (action.taskData.deleted_tasks) {
        for (let i = 0; i < newTaskState.length; i++) {
          for (let j = 0; j < action.taskData.deleted_tasks.length; j++) {
            if (newTaskState[i].task_id === action.taskData.deleted_tasks[j].task_id) {
              newTaskState.splice(i, 1);
              break;
            }
          }
        }
      }
      Object.assign(updatedTask, action.taskData.current_task);
      return {
        ...state,
        user_tasks: [...newTaskState]
      };
    }

    case SET_USER_TASKS_USERS_SEARCH_FILTER: {
      const filter = action.searchFilter.trim().toLowerCase();
      return {
        ...state,
        searchFilter: filter,
        filteredUsers: state.users
          .filter((user) =>
            `${user.user_last_name} ${user.user_first_name} ${user.user_first_name ? user.user_first_name : ""}`
              .trim()
              .toLowerCase()
              .includes(filter)
          )
          .sort((a, b) => (a.user_last_name > b.user_last_name ? 1 : -1))
      };
    }
    case CLEAR_SEARCH_USER_PARAMS: {
      return {
        ...state,
        filteredUsers: [],
        searchFilter: ""
      };
    }

    case ACCEPT_ACTION_USER_TASK_START: {
      const newTaskState = [...state.user_tasks];
      const changedTask = newTaskState.find((task) => task.task_id === action.taskId);
      changedTask.task_status = "DONE";
      changedTask.task_decision = "ACCEPTED";
      return {
        ...state,
        user_tasks: newTaskState
        // loading: action.loading,
        // error: action.error,
      };
    }
    case ACCEPT_ACTION_USER_TASK_FAILURE: {
      const newTaskState = [...state.user_tasks];
      const changedTask = newTaskState.find((task) => task.task_id === action.taskId);
      changedTask.task_status = action.previousTaskStatus;
      changedTask.task_decision = action.previousTaskDecision;
      return {
        ...state,
        user_tasks: newTaskState
        // loading: action.loading,
        // error: action.error,
      };
    }

    case ACCEPT_ACTION_USER_TASK_SUCCESS: {
      const newTaskState = [...state.user_tasks];
      const changedTask = newTaskState.find((task) => task.task_id === action.taskId);
      Object.assign(changedTask, action.changedFields);
      try {
        const ChangedParentTask = newTaskState.find((task) => task.task_id === action.parentTaskId);
        Object.assign(ChangedParentTask, action.parentTaskChanges);
      } catch (e) {}
      return {
        ...state,
        user_tasks: newTaskState
        // loading: action.loading,
        // error: action.error,
      };
    }

    case DECLINE_ACTION_USER_TASKS_TASK_START: {
      const newTaskState = [...state.user_tasks];
      const changedTask = newTaskState.find((task) => task.task_id === action.taskId);
      changedTask.task_status = "DONE";
      changedTask.task_decision = "DECLINED";
      return {
        ...state,
        user_tasks: newTaskState
        // loading: action.loading,
        // error: action.error,
      };
    }
    case DECLINE_ACTION_USER_TASKS_TASK_FAILURE: {
      const newTaskState = [...state.user_tasks];
      const changedTask = newTaskState.find((task) => task.task_id === action.taskId);
      changedTask.task_status = action.previousTaskStatus;
      changedTask.task_decision = action.previousTaskDecision;
      return {
        ...state,
        user_tasks: newTaskState
        // loading: action.loading,
        // error: action.error,
      };
    }
    case DECLINE_ACTION_USER_TASKS_TASK_SUCCESS: {
      const newTaskState = [...state.user_tasks];
      const changedTask = newTaskState.find((task) => task.task_id === action.taskId);
      Object.assign(changedTask, action.changedFields);
      try {
        const ChangedParentTask = newTaskState.find((task) => task.task_id === action.parentTaskId);
        Object.assign(ChangedParentTask, action.parentTaskChanges);
      } catch (e) {}
      return {
        ...state,
        user_tasks: newTaskState
        // loading: action.loading,
        // error: action.error,
      };
    }

    case CHANGE_TASK_HEADER_START: {
      //taskId, newHeader
      // eslint-disable-next-line
            state.filtered_user_tasks.map(workspace=>{
        if (workspace.tasks.find((task) => task.task_id === action.taskId))
          workspace.tasks.find((task) => task.task_id === action.taskId).task_name = action.newHeader;
      });

      return {
        ...state,
        initial_user_tasks: state.initial_user_tasks.map((task) =>
          task.task_id === action.taskId
            ? Object.assign({}, task, {
                task_name: action.newHeader
              })
            : task
        ),
        user_tasks: state.user_tasks.map((task) =>
          task.task_id === action.taskId
            ? Object.assign({}, task, {
                task_name: action.newHeader
              })
            : task
        )
      };
    }
    case CHANGE_TASK_HEADER_FAILURE: {
      //taskId, previousHeader
      return {
        ...state,
        user_tasks: state.user_tasks.map((task) =>
          task.task_id === action.taskId
            ? Object.assign({}, task, {
                task_name: action.previousHeader
              })
            : task
        )
      };
    }
    case CHANGE_TASK_HEADER_SUCCESS: {
      return {
        ...state
      };
    }
    case CHANGE_TASK_DESCRIPTION_START: {
      //taskId, newDescription
      return {
        ...state,
        initial_user_tasks: state.initial_user_tasks.map((task) =>
          task.task_id === action.taskId
            ? Object.assign({}, task, {
                task_descr: action.newDescription
              })
            : task
        )
      };
    }
    case CHANGE_TASK_DESCRIPTION_FAILURE: {
      //taskId, previousDescription
      return {
        ...state,
        user_tasks: state.user_tasks.map((task) =>
          task.task_id === action.taskId
            ? Object.assign({}, task, {
                task_descr: action.previousDescription
              })
            : task
        )
      };
    }
    case CHANGE_TASK_DESCRIPTION_SUCCESS: {
      return {
        ...state
      };
    }

    case GET_INFO_ABOUT_TASK_USER_TASKS_SUCCESS: {
      const newTasksState = [...state.user_tasks];
      const currentTask = newTasksState.find((task) => task.task_id === action.taskId);
      Object.assign(currentTask, action.data);
      return {
        ...state,
        user_tasks: newTasksState
      };
    }

    case SET_IMPLEMENTER_TO_USER_TASKS_TASK_START: {
      const newTasksState = [...state.initial_user_tasks];
      const taskIndex = newTasksState.findIndex((task) => task.task_id === action.taskId);
      newTasksState[taskIndex].implementer_user_id = action.implementerId;
      return {
        ...state,
        user_tasks: newTasksState
        // initial_user_tasks: newTasksState,
      };
    }

    case UNSET_IMPLEMENTER_TO_TASK_USER_TASKS_START: {
      const newTaskState = [...state.initial_user_tasks];
      newTaskState.find((task) => task.task_id === action.taskId).implementer_user_id = null;
      return {
        ...state,
        user_tasks: newTaskState
        // initial_user_tasks: newTaskState,
      };
    }
    case UNSET_IMPLEMENTER_TO_TASK_USER_TASKS_FAILURE: {
      const newTaskState = [...state.user_tasks];
      newTaskState.find((task) => task.task_id === action.taskId).implementer_user_id = action.implementerUserId;
      return {
        ...state,
        user_tasks: newTaskState
      };
    }
    case UNSET_IMPLEMENTER_TO_TASK_USER_TASKS_SUCCESS: {
      const newTasksState = [...state.user_tasks];
      const currentTask = newTasksState.find((task) => task.task_id === action.taskId);
      Object.assign(currentTask, action.taskChanges);
      return {
        ...state,
        user_tasks: newTasksState
      };
    }

    case SET_VERIFICATOR_TO_TASK_USER_TASKS_START: {
      const newTasksState = [...state.initial_user_tasks];
      const taskIndex = newTasksState.findIndex((task) => task.task_id === action.taskId);
      newTasksState[taskIndex].verificator_user_id = action.verificatorId;
      return {
        ...state,
        user_tasks: newTasksState
        // initial_user_tasks: newTasksState,
      };
    }
    case SET_VERIFICATOR_TO_TASK_USER_TASKS_FAILURE: {
      return {
        ...state
      };
    }
    case SET_VERIFICATOR_TO_TASK_USER_TASKS_SUCCESS: {
      return {
        ...state
      };
    }
    case UNSET_VERIFICATOR_TO_TASK_USER_TASKS_START: {
      const newTaskState = [...state.initial_user_tasks];
      newTaskState.find((task) => task.task_id === action.taskId).verificator_user_id = null;
      return {
        ...state,
        user_tasks: newTaskState
        // initial_user_tasks: newTaskState,
      };
    }
    case UNSET_VERIFICATOR_TO_TASK_USER_TASKS_FAILURE: {
      const newTaskState = [...state.user_tasks];
      newTaskState.find((task) => task.task_id === action.taskId).verificator_user_id = action.verificatorUserId;
      return {
        ...state,
        user_tasks: newTaskState
      };
    }
    case UNSET_VERIFICATOR_TO_TASK_USER_TASKS_SUCCESS: {
      const newTasksState = [...state.user_tasks];
      const currentTask = newTasksState.find((task) => task.task_id === action.taskId);
      Object.assign(currentTask, action.taskChanges);
      return {
        ...state,
        user_tasks: newTasksState
      };
    }
    case SET_INITIATOR_TO_TASK_USER_TASKS_START: {
      //initiatorId, taskId
      const newTasksState = [...state.user_tasks];
      const taskIndex = newTasksState.findIndex((task) => task.task_id === action.taskId);
      newTasksState[taskIndex].initiator_user_id = action.initiatorId;
      return {
        ...state,
        user_tasks: newTasksState
      };
    }
    case SET_INITIATOR_TO_TASK_USER_TASKS_FAILURE: {
      return {
        ...state
      };
    }
    case SET_INITIATOR_TO_TASK_USER_TASKS_SUCCESS: {
      return {
        ...state
      };
    }
    case UNSET_INITIATOR_TO_TASK_USER_TASKS_START: {
      const newTaskState = [...state.user_tasks];
      newTaskState.find((task) => task.task_id === action.taskId).initiator_user_id = null;
      return {
        ...state,
        user_tasks: newTaskState
      };
    }
    case UNSET_INITIATOR_TO_TASK_USER_TASKS_FAILURE: {
      const newTaskState = [...state.user_tasks];
      newTaskState.find((task) => task.task_id === action.taskId).initiator_user_id = action.initiatorUserId;
      return {
        ...state,
        user_tasks: newTaskState
      };
    }
    case UNSET_INITIATOR_TO_TASK_USER_TASKS_SUCCESS: {
      const newTasksState = [...state.user_tasks];
      const currentTask = newTasksState.find((task) => task.task_id === action.taskId);
      Object.assign(currentTask, action.taskChanges);
      return {
        ...state,
        user_tasks: newTasksState
      };
    }

    case DELETE_TASK_FROM_USER_TASKS_STARTED: {
      const newTasksState = [...state.user_tasks];
      newTasksState.splice(action.deletedTaskIndex, 1);
      return {
        ...state,
        user_tasks: newTasksState,
        initial_user_tasks: newTasksState
      };
    }
    case DELETE_TASK_FROM_USER_TASKS_FAILURE: {
      const newTasksState = [...state.user_tasks];
      newTasksState.splice(action.deletedTaskIndex, 0, action.taskObject);
      return {
        ...state,
        user_tasks: newTasksState,
        initial_user_tasks: newTasksState
      };
    }

    case RESET_FILTER_OPTIONS_USER_TASKS: {
      const newSortFilter = initialState.sortFilter;
      if (action.all_users) newSortFilter.displayTasks = 0;
      return {
        ...state,
        sortFilter: newSortFilter,
        searchFilter: "",
        tasksSearchQuery: ""
      };
    }

    case ADD_NEW_CARD_TO_USER_TASKS_SUCCESS: {
      const newTasksState = [...state.initial_user_tasks];
      newTasksState.push(action.newTask);
      if (state.filtered_user_tasks.find((workspace) => workspace.workspace_id === action.newTask.workspace_id))
        state.filtered_user_tasks
          .find((workspace) => workspace.workspace_id === action.newTask.workspace_id)
          .tasks.unshift(action.newTask);
      else {
        state.filtered_user_tasks.push({
          workspace_id: action.newTask.workspace_id,
          workspace_name: action.newTask.workspace_name,
          tasks: [action.newTask]
        });
      }

      return {
        ...state,
        initial_user_tasks: newTasksState,
        newCardId: !action.isCalledFromCreateModal ? action.newTask.task_id : null
      };
    }

    case RESET_NEW_TASK_USER_TASKS: {
      return {
        ...state,
        newCardId: null
      };
    }

    case SET_USER_TASKS_LOADER: {
      return {
        ...state,
        loading: action.loader
      };
    }

    case SEARCH_USER_TASKS: {
      return {
        ...state,
        tasksSearchQuery: action.query
      };
    }

    case RESET_USER_TASKS_ERROR: {
      return {
        ...state,
        error: initialState.error
      };
    }

    case MOVE_TASK_TO_ANOTHER_WORKSPACE_START: {
      state.initial_user_tasks.find((task) => task.task_id === action.taskId).workspace_id = action.workspaceId;
      state.user_tasks.find((task) => task.task_id === action.taskId).workspace_id = action.workspaceId;
      return {
        ...state
      };
    }

    default:
      return state;
  }
}

export default userTasksReducer;
