import React from "react";
import styles from "./styles/NotesIcon.module.css";
import { TripleDotMenuIcon } from "../../../../components/ReactSVGIcons/TripleDotMenuIcon";
import { useDispatch } from "react-redux";
import { setOptionsToModalWindow, showModalWindow } from "../../../../state/actions";
import { MODAL_WINDOW_TYPES } from "../../../../components/Screen/ModalWindow";

const NotesIcon = React.memo(({ workspaceId }) => {
  const dispatch = useDispatch();

  const onClickHandler = () => {
    dispatch(
      setOptionsToModalWindow({
        type: MODAL_WINDOW_TYPES.WORKSPACE_NOTES_EDITOR,
        closeHeaderButton: true,
        closeOnWrapperClick: true,
        header: "Заметки Workspace",
        workspaceId: workspaceId
      })
    );
    dispatch(showModalWindow());
  };

  return (
    <div className={styles.container} onClick={onClickHandler}>
      <TripleDotMenuIcon color={"#c5c5c5"} height={"1.7em"} width={"1.75em"} />
    </div>
  );
});

export { NotesIcon };
