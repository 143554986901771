import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles/ThemeSelector.module.css";
import { useDispatch, useSelector } from "react-redux";
import { changeTaskTopic, changeTaskTopicUserTasks } from "../../../../state/actions";
import { useTranslation } from "react-multi-lang";
import { Search } from "../../../../components/Search/Search";

const ThemeSelector = React.memo(
  ({ taskId, currentLabel, themesList, className, disabled, editable, isCalledFromUserTasks }) => {
    const containerRef = useRef(null);
    const translate = useTranslation();

    const commonState = useSelector((state) => state.commonReducer);

    const [open, setOpen] = useState(false);
    const [buttonSelectOptionLabel, setButtonSelectOptionLabel] = useState(
      currentLabel !== null ? currentLabel : translate("taskTracker.commonWords.selectTopic")
    );
    const [selectedTopicLabel, setSelectedTopicLabel] = useState(translate("taskTracker.commonWords.selectTopic"));

    const [filteredThemesList, setFilteredThemesList] = useState(themesList);

    const [firstListItem, setFirstListItem] = useState(
      themesList.find((theme) => theme.task_topic_name === buttonSelectOptionLabel)
    );

    useEffect(() => {
      setButtonSelectOptionLabel(
        currentLabel !== null ? currentLabel : translate("taskTracker.commonWords.selectTopic")
      );
      setFirstListItem(themesList.find((theme) => theme.task_topic_name === currentLabel));
      // eslint-disable-next-line
    }, [currentLabel, themesList]);

    const onClickButtonHandler = () => {
      setOpen((open) => (!disabled ? !open : false));
    };

    const dispatch = useDispatch();

    const onOptionClickHandler = (event) => {
      const previousTheme = themesList.find((theme) => theme.task_topic_name === buttonSelectOptionLabel);
      const selectedTheme = themesList.find((theme) => theme.task_topic_name === event.target.innerText.trim());

      !isCalledFromUserTasks
        ? dispatch(
            changeTaskTopic(taskId, selectedTheme.task_topic_id, previousTheme ? previousTheme.task_topic_id : null)
          )
        : dispatch(
            changeTaskTopicUserTasks(
              taskId,
              selectedTheme.task_topic_id,
              previousTheme ? previousTheme.task_topic_id : null
            )
          );
      setFilteredThemesList(themesList);
      setOpen((open) => (!disabled ? !open : false));
    };

    const onResetTopicClickHandler = () => {
      const previousTheme = themesList.find((theme) => theme.task_topic_name === buttonSelectOptionLabel);

      !isCalledFromUserTasks
        ? dispatch(changeTaskTopic(taskId, null, previousTheme ? previousTheme.task_topic_id : null))
        : dispatch(changeTaskTopicUserTasks(taskId, null, previousTheme ? previousTheme.task_topic_id : null));

      setFilteredThemesList(themesList);
      setOpen((open) => (!disabled ? !open : false));
    };

    const onClickHandler = (event) => {
      if (
        !event.target.closest(`.${containerRef.current.className}`) &&
        !event.target.closest(`.${styles.searchBar}`) &&
        open
      ) {
        setFilteredThemesList(themesList);
        setOpen(false);
      }
    };

    const onSearchChangeHandler = (event) => {
      setFilteredThemesList(
        themesList.filter((theme) => theme.task_topic_name.toLowerCase().includes(event.target.value.toLowerCase()))
      );
    };

    useEffect(() => {
      document.addEventListener("click", onClickHandler);
      return () => {
        document.removeEventListener("click", onClickHandler);
      };
    });

    useEffect(() => {
      setSelectedTopicLabel(translate("taskTracker.commonWords.selectTopic"));
      setButtonSelectOptionLabel(
        currentLabel !== null ? currentLabel : translate("taskTracker.commonWords.selectTopic")
      );
      // eslint-disable-next-line
    }, [commonState.language])

    return (
      <div className={`${styles.container} ${className}`} ref={containerRef}>
        <div
          className={`${editable ? styles.theme : styles.themeDisabled} ${disabled ? styles.disabled : ""}`}
          onClick={editable ? onClickButtonHandler : undefined}
        >
          <p>{buttonSelectOptionLabel}</p>
        </div>
        {open && !disabled && editable && (
          <div className={styles.themesList}>
            {firstListItem && (
              <div className={`${styles.theme} ${styles.themeListElement}`} onClick={onOptionClickHandler}>
                <p>{firstListItem.task_topic_name}</p>
              </div>
            )}
            <Search className={`${styles.searchBar}`} focusStyles={styles.focus} onChange={onSearchChangeHandler} />
            <div key={100} className={`${styles.theme} ${styles.themeListElement}`} onClick={onResetTopicClickHandler}>
              <p>{selectedTopicLabel}</p>
            </div>
            {filteredThemesList.map((theme) => {
              return (
                <div
                  key={theme.task_topic_id}
                  className={`${styles.theme} ${styles.themeListElement}`}
                  onClick={onOptionClickHandler}
                >
                  <p>{theme.task_topic_name}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
);

ThemeSelector.propTypes = {
  currentLabel: PropTypes.string,
  themesList: PropTypes.arrayOf(
    PropTypes.shape({
      task_topic_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      task_topic_name: PropTypes.string.isRequired,
      task_topic_sort: PropTypes.number.isRequired
    })
  ),
  className: PropTypes.string,
  //taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool
};

ThemeSelector.defaultProps = {
  className: "",
  disabled: false
};

export { ThemeSelector };
