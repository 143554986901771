export const SHOW_MODAL_WINDOW = "SHOW_MODAL_WINDOW";
export const HIDE_MODAL_WINDOW = "HIDE_MODAL_WINDOW";
export const SHOW_UPPER_MODAL_WINDOW = "SHOW_UPPER_MODAL_WINDOW";
export const HIDE_UPPER_MODAL_WINDOW = "HIDE_UPPER_MODAL_WINDOW";
export const SET_OPTIONS_FOR_MODAL_WINDOW = "SET_OPTIONS_FOR_MODAL_WINDOW";
export const CHANGE_OPTIONS_FOR_MODAL_WINDOW = "CHANGE_OPTIONS_FOR_MODAL_WINDOW";
export const SET_SHOW_STATUS = "SET_SHOW_STATUS";

export const FETCH_USERS_IN_WORKSPACE_STARTED = "FETCH_USERS_IN_WORKSPACE_STARTED";
export const FETCH_USERS_IN_WORKSPACE_SUCCESS = "FETCH_USERS_IN_WORKSPACE_SUCCESS";
export const FETCH_USERS_IN_WORKSPACE_FAILURE = "FETCH_USERS_IN_WORKSPACE_FAILURE";

export const DELETE_USER_FROM_WORKSPACE_STARTED = "DELETE_USER_FROM_WORKSPACE_STARTED";
export const DELETE_USER_FROM_WORKSPACE_SUCCESS = "DELETE_USER_FROM_WORKSPACE_SUCCESS";
export const DELETE_USER_FROM_WORKSPACE_FAILURE = "DELETE_USER_FROM_WORKSPACE_FAILURE";

export const DELETE_TASK_FROM_USER_TASKS_STARTED = "DELETE_TASK_FROM_USER_TASKS_STARTED";
export const DELETE_TASK_FROM_USER_TASKS_SUCCESS = "DELETE_TASK_FROM_USER_TASKS_SUCCESS";
export const DELETE_TASK_FROM_USER_TASKS_FAILURE = "DELETE_TASK_FROM_USER_TASKS_FAILURE";

export const SET_WORKSPACE_USERS_SEARCH_FILTER = "SET_WORKSPACE_USERS_SEARCH_FILTER";
export const CLEAR_SEARCH_USER_PARAMS = "CLEAR_SEARCH_USER_PARAMS";
export const SET_COMPANY_USERS_SEARCH_FILTER = "SET_COMPANY_USERS_SEARCH_FILTER";

export const SET_USER_TASKS_USERS_SEARCH_FILTER = "SET_USER_TASKS_USERS_SEARCH_FILTER";

export const FETCH_USERS_FROM_COMPANY_STARTED = "FETCH_USERS_FROM_COMPANY_STARTED";
export const FETCH_USERS_FROM_COMPANY_SUCCESS = "FETCH_USERS_FROM_COMPANY_SUCCESS";
export const FETCH_USERS_FROM_COMPANY_FAILURE = "FETCH_USERS_FROM_COMPANY_FAILURE";
export const SET_FILTER_TO_USERS_FROM_COMPANY = "SET_FILTER_TO_USERS_FROM_COMPANY";
export const SET_FILTERED_USERS_FROM_COMPANY_BY_FIO = "SET_FILTERED_USERS_FROM_COMPANY_BY_FIO";

export const ADD_SELECTED_COMPANY_USER_TO_WORKSPACE_USERS = "ADD_SELECTED_COMPANY_USER_TO_WORKSPACE_USERS";
export const ADD_SELECTED_COMPANY_USER_TO_WORKSPACE_USERS_START = "ADD_SELECTED_COMPANY_USER_TO_WORKSPACE_USERS_START";
export const ADD_SELECTED_COMPANY_USER_TO_WORKSPACE_USERS_SUCCESS =
  "ADD_SELECTED_COMPANY_USER_TO_WORKSPACE_USERS_SUCCESS";
export const ADD_SELECTED_COMPANY_USER_TO_WORKSPACE_USERS_FAILURE =
  "ADD_SELECTED_COMPANY_USER_TO_WORKSPACE_USERS_FAILURE";

export const DELETE_SECTION_FROM_WORKSPACE = "DELETE_SECTION_FROM_WORKSPACE";
export const DELETE_SECTION_FROM_WORKSPACE_START = "DELETE_SECTION_FROM_WORKSPACE_START";
export const DELETE_SECTION_FROM_WORKSPACE_SUCCESS = "DELETE_SECTION_FROM_WORKSPACE_SUCCESS";
export const DELETE_SECTION_FROM_WORKSPACE_FAILURE = "DELETE_SECTION_FROM_WORKSPACE_FAILURE";

export const DELETE_TASK_FROM_WORKSPACE = "DELETE_TASK_FROM_WORKSPACE";
export const DELETE_TASK_FROM_WORKSPACE_START = "DELETE_TASK_FROM_WORKSPACE_START";
export const DELETE_TASK_FROM_WORKSPACE_SUCCESS = "DELETE_TASK_FROM_WORKSPACE_SUCCESS";
export const DELETE_TASK_FROM_WORKSPACE_FAILURE = "DELETE_TASK_FROM_WORKSPACE_FAILURE";

export const FETCH_WORKSPACE_INFORMATION = "FETCH_WORKSPACE_INFORMATION";
export const FETCH_WORKSPACE_INFORMATION_START = "FETCH_WORKSPACE_INFORMATION_START";
export const FETCH_WORKSPACE_INFORMATION_SUCCESS = "FETCH_WORKSPACE_INFORMATION_SUCCESS";
export const FETCH_WORKSPACE_INFORMATION_FAILURE = "FETCH_WORKSPACE_INFORMATION_FAILURE";

export const SET_GLOBAL_WORKSPACE_LOADER_STATUS = "SET_GLOBAL_WORKSPACE_LOADER_STATUS";
export const ADD_GLOBAL_WORKSPACE_LOADER_ELEMENT = "ADD_GLOBAL_WORKSPACE_LOADER_ELEMENT";
export const DELETE_GLOBAL_WORKSPACE_LOADER_STATUS = "DEL_GLOBAL_WORKSPACE_LOADER_ELEMENT";

export const FETCH_WORKSPACE_STATUSES_LIST = "FETCH_WORKSPACE_STATUSES_LIST";
export const FETCH_WORKSPACE_STATUSES_LIST_START = "FETCH_WORKSPACE_STATUSES_LIST_START";
export const FETCH_WORKSPACE_STATUSES_LIST_SUCCESS = "FETCH_WORKSPACE_STATUSES_LIST_SUCCESS";
export const FETCH_WORKSPACE_STATUSES_LIST_FAILURE = "FETCH_WORKSPACE_STATUSES_LIST_FAILURE";

export const FETCH_LIST_OF_SECTIONS_IN_WORKSPACE = "FETCH_LIST_OF_SECTIONS_IN_WORKSPACE";
export const FETCH_LIST_OF_SECTIONS_IN_WORKSPACE_START = "FETCH_LIST_OF_SECTIONS_IN_WORKSPACE_START";
export const FETCH_LIST_OF_SECTIONS_IN_WORKSPACE_SUCCESS = "FETCH_LIST_OF_SECTIONS_IN_WORKSPACE_SUCCESS";
export const FETCH_LIST_OF_SECTIONS_IN_WORKSPACE_FAILURE = "FETCH_LIST_OF_SECTIONS_IN_WORKSPACE_FAILURE";

export const FETCH_LIST_OF_TASKS_IN_WORKSPACE = "FETCH_LIST_OF_TASKS_IN_WORKSPACE";
export const FETCH_LIST_OF_TASKS_IN_WORKSPACE_START = "FETCH_LIST_OF_TASKS_IN_WORKSPACE_START";
export const FETCH_LIST_OF_TASKS_IN_WORKSPACE_SUCCESS = "FETCH_LIST_OF_TASKS_IN_WORKSPACE_SUCCESS";
export const FETCH_LIST_OF_TASKS_IN_WORKSPACE_FAILURE = "FETCH_LIST_OF_TASKS_IN_WORKSPACE_FAILURE";

export const FETCH_LIST_OF_TASK_PRIORITY_STATUSES = "FETCH_LIST_OF_TASK_PRIORITY_STATUSES";
export const FETCH_LIST_OF_TASK_PRIORITY_STATUSES_START = "FETCH_LIST_OF_TASK_PRIORITY_STATUSES_START";
export const FETCH_LIST_OF_TASK_PRIORITY_STATUSES_SUCCESS = "FETCH_LIST_OF_TASK_PRIORITY_STATUSES_SUCCESS";
export const FETCH_LIST_OF_TASK_PRIORITY_STATUSES_FAILURE = "FETCH_LIST_OF_TASK_PRIORITY_STATUSES_FAILURE";

export const FETCH_TASK_LOGS = "FETCH_TASK_LOGS";
export const FETCH_TASK_LOGS_START = "FETCH_TASK_LOGS_START";
export const FETCH_TASK_LOGS_SUCCESS = "FETCH_TASK_LOGS_SUCCESS";
export const FETCH_TASK_LOGS_FAILURE = "FETCH_TASK_LOGS_FAILURE";

export const FETCH_LIST_OF_TASK_DOCUMENTS = "FETCH_LIST_OF_TASK_DOCUMENTS";
export const FETCH_LIST_OF_TASK_DOCUMENTS_START = "FETCH_LIST_OF_TASK_DOCUMENTS_START";
export const FETCH_LIST_OF_TASK_DOCUMENTS_SUCCESS = "FETCH_LIST_OF_TASK_DOCUMENTS_SUCCESS";
export const FETCH_LIST_OF_TASK_DOCUMENTS_FAILURE = "FETCH_LIST_OF_TASK_DOCUMENTS_FAILURE";

export const SET_UPLOADED_DOCUMENT_NAME = "SET_UPLOADED_DOCUMENT_NAME";
export const SET_UPLOADED_DOCUMENT_UPLOAD_PERCENTAGE = "SET_UPLOADED_DOCUMENT_UPLOAD_PERCENTAGE";
export const SET_UPLOADED_DOCUMENT_SHOW_STATUS = "SET_UPLOADED_DOCUMENT_SHOW_STATUS";

export const DELETE_DOCUMENT_FROM_TASK = "DELETE_DOCUMENT_FROM_TASK";
export const DELETE_DOCUMENT_FROM_TASK_START = "DELETE_DOCUMENT_FROM_TASK_START";
export const DELETE_DOCUMENT_FROM_TASK_SUCCESS = "DELETE_DOCUMENT_FROM_TASK_SUCCESS";
export const DELETE_DOCUMENT_FROM_TASK_FAILURE = "DELETE_DOCUMENT_FROM_TASK_FAILURE";

export const DOWNLOAD_DOCUMENT = "DOWNLOAD_DOCUMENT";
export const DOWNLOAD_DOCUMENT_START = "DOWNLOAD_DOCUMENT_START";
export const DOWNLOAD_DOCUMENT_SUCCESS = "DOWNLOAD_DOCUMENT_SUCCESS";
export const DOWNLOAD_DOCUMENT_FAILURE = "DOWNLOAD_DOCUMENT_FAILURE";

export const SET_TASK_ID_TO_MODAL_WINDOW = "SET_TASK_ID_TO_MODAL_WINDOW";

export const CHANGE_TASK_PRIORITY = "CHANGE_TASK_PRIORITY";
export const CHANGE_TASK_PRIORITY_START = "CHANGE_TASK_PRIORITY_START";
export const CHANGE_TASK_PRIORITY_SUCCESS = "CHANGE_TASK_PRIORITY_SUCCESS";
export const CHANGE_TASK_PRIORITY_FAILURE = "CHANGE_TASK_PRIORITY_FAILURE";

export const CHANGE_TASK_PRIORITY_USER_TASKS_START = "CHANGE_TASK_PRIORITY_USER_TASKS_START";
export const CHANGE_TASK_PRIORITY_USER_TASKS_FAILURE = "CHANGE_TASK_PRIORITY_USER_TASKS_FAILURE";
export const CHANGE_TASK_PRIORITY_USER_TASKS_SUCCESS = "CHANGE_TASK_PRIORITY_USER_TASKS_SUCCESS";

export const CHANGE_TASK_DEADLINE_DATE = "CHANGE_TASK_DEADLINE_DATE";
export const CHANGE_TASK_DEADLINE_DATE_START = "CHANGE_TASK_DEADLINE_DATE_START";
export const CHANGE_TASK_DEADLINE_DATE_SUCCESS = "CHANGE_TASK_DEADLINE_DATE_SUCCESS";
export const CHANGE_TASK_DEADLINE_DATE_FAILURE = "CHANGE_TASK_DEADLINE_DATE_FAILURE";

export const CHANGE_TASK_DEADLINE_DATE_USER_TASKS_START = "CHANGE_TASK_DEADLINE_DATE_USER_TASKS_START";
export const CHANGE_TASK_DEADLINE_DATE_USER_TASKS_FAILURE = "CHANGE_TASK_DEADLINE_DATE_USER_TASKS_FAILURE";
export const CHANGE_TASK_DEADLINE_DATE_USER_TASKS_SUCCESS = "CHANGE_TASK_DEADLINE_DATE_USER_TASKS_SUCCESS";

export const UNSET_TASK_DEADLINE_DATE = "UNSET_TASK_DEADLINE_DATE";
export const UNSET_TASK_DEADLINE_DATE_START = "UNSET_TASK_DEADLINE_DATE_START";
export const UNSET_TASK_DEADLINE_DATE_SUCCESS = "UNSET_TASK_DEADLINE_DATE_SUCCESS";
export const UNSET_TASK_DEADLINE_DATE_FAILURE = "UNSET_TASK_DEADLINE_DATE_FAILURE";

export const UNSET_TASK_DEADLINE_DATE_USER_TASKS_START = "UNSET_TASK_DEADLINE_DATE_USER_TASKS_START";
export const UNSET_TASK_DEADLINE_DATE_USER_TASKS_SUCCESS = "UNSET_TASK_DEADLINE_DATE_USER_TASKS_SUCCESS";
export const UNSET_TASK_DEADLINE_DATE_USER_TASKS_FAILURE = "UNSET_TASK_DEADLINE_DATE_USER_TASKS_FAILURE";

export const CHANGE_TASK_HEADER = "CHANGE_TASK_HEADER";
export const CHANGE_TASK_HEADER_START = "CHANGE_TASK_HEADER_START";
export const CHANGE_TASK_HEADER_SUCCESS = "CHANGE_TASK_HEADER_SUCCESS";
export const CHANGE_TASK_HEADER_FAILURE = "CHANGE_TASK_HEADER_FAILURE";

export const CHANGE_TASK_DESCRIPTION = "CHANGE_TASK_DESCRIPTION";
export const CHANGE_TASK_DESCRIPTION_START = "CHANGE_TASK_DESCRIPTION_START";
export const CHANGE_TASK_DESCRIPTION_SUCCESS = "CHANGE_TASK_DESCRIPTION_SUCCESS";
export const CHANGE_TASK_DESCRIPTION_FAILURE = "CHANGE_TASK_DESCRIPTION_FAILURE";

export const CHANGE_EDITABLE_TEXT_STATE = "CHANGE_EDITABLE_TEXT_STATE";

export const CHANGE_TASK_TOPIC = "CHANGE_TASK_PRIORITY";
export const CHANGE_TASK_TOPIC_START = "CHANGE_TASK_TOPIC_START";
export const CHANGE_TASK_TOPIC_SUCCESS = "CHANGE_TASK_TOPIC_SUCCESS";
export const CHANGE_TASK_TOPIC_FAILURE = "CHANGE_TASK_TOPIC_FAILURE";

export const CHANGE_TASK_TOPIC_USER_TASKS_START = "CHANGE_TASK_TOPIC_USER_TASKS_START";
export const CHANGE_TASK_TOPIC_USER_TASKS_SUCCESS = "CHANGE_TASK_TOPIC_USER_TASKS_SUCCESS";
export const CHANGE_TASK_TOPIC_USER_TASKS_FAILURE = "CHANGE_TASK_TOPIC_USER_TASKS_FAILURE";

export const CHANGE_TASK_TYPE = "CHANGE_TASK_TYPE";
export const CHANGE_TASK_TYPE_START = "CHANGE_TASK_TYPE_START";
export const CHANGE_TASK_TYPE_SUCCESS = "CHANGE_TASK_TYPE_SUCCESS";
export const CHANGE_TASK_TYPE_FAILURE = "CHANGE_TASK_TYPE_FAILURE";

export const MARK_TASK_AS_DONE = "MARK_TASK_AS_DONE";
export const MARK_TASK_AS_DONE_START = "MARK_TASK_AS_DONE_START";
export const MARK_TASK_AS_DONE_SUCCESS = "MARK_TASK_AS_DONE_SUCCESS";
export const MARK_TASK_AS_DONE_FAILURE = "MARK_TASK_AS_DONE_FAILURE";

export const MARK_TASK_AS_UNDONE = "MARK_TASK_AS_DONE";
export const MARK_TASK_AS_UNDONE_START = "MARK_TASK_AS_UNDONE_START";
export const MARK_TASK_AS_UNDONE_SUCCESS = "MARK_TASK_AS_UNDONE_SUCCESS";
export const MARK_TASK_AS_UNDONE_FAILURE = "MARK_TASK_AS_UNDONE_FAILURE";

export const MARK_TASK_AS_DONE_USER_TASKS_START = "MARK_TASK_AS_DONE_USER_TASKS_START";
export const MARK_TASK_AS_DONE_USER_TASKS_SUCCESS = "MARK_TASK_AS_DONE_USER_TASKS_SUCCESS";
export const MARK_TASK_AS_DONE_USER_TASKS_FAILURE = "MARK_TASK_AS_DONE_USER_TASKS_FAILURE";

export const MARK_TASK_AS_UNDONE_USER_TASKS_START = "MARK_TASK_AS_UNDONE_USER_TASKS_START";
export const MARK_TASK_AS_UNDONE_USER_TASKS_SUCCESS = "MARK_TASK_AS_UNDONE_USER_TASKS_SUCCESS";
export const MARK_TASK_AS_UNDONE_USER_TASKS_FAILURE = "MARK_TASK_AS_UNDONE_USER_TASKS_FAILURE";

export const FETCH_LIST_OF_TOPICS = "FETCH_LIST_OF_TOPICS";
export const FETCH_LIST_OF_TOPICS_START = "FETCH_LIST_OF_TOPICS_START";
export const FETCH_LIST_OF_TOPICS_SUCCESS = "FETCH_LIST_OF_TOPICS_SUCCESS";
export const FETCH_LIST_OF_TOPICS_FAILURE = "FETCH_LIST_OF_TOPICS_FAILURE";

export const FETCH_LIST_OF_VISIBILITY = "FETCH_LIST_OF_VISIBILITY";
export const FETCH_LIST_OF_VISIBILITY_START = "FETCH_LIST_OF_VISIBILITY_START";
export const FETCH_LIST_OF_VISIBILITY_SUCCESS = "FETCH_LIST_OF_VISIBILITY_SUCCESS";
export const FETCH_LIST_OF_VISIBILITY_FAILURE = "FETCH_LIST_OF_VISIBILITY_FAILURE";

export const MODIFY_SECTION_IN_WORKSPACE = "MODIFY_SECTION_IN_WORKSPACE";
export const MODIFY_SECTION_IN_WORKSPACE_START = "MODIFY_SECTION_IN_WORKSPACE_START";
export const MODIFY_SECTION_IN_WORKSPACE_SUCCESS = "MODIFY_SECTION_IN_WORKSPACE_SUCCESS";
export const MODIFY_SECTION_IN_WORKSPACE_FAILURE = "MODIFY_SECTION_IN_WORKSPACE_FAILURE";

export const MODIFY_WORKSPACE_INFORMATION = "MODIFY_WORKSPACE_INFORMATION";
export const MODIFY_WORKSPACE_INFORMATION_START = "MODIFY_WORKSPACE_INFORMATION_START";
export const MODIFY_WORKSPACE_INFORMATION_SUCCESS = "MODIFY_WORKSPACE_INFORMATION_SUCCESS";
export const MODIFY_WORKSPACE_INFORMATION_FAILURE = "MODIFY_WORKSPACE_INFORMATION_FAILURE";

export const MODIFY_WORKSPACE_NAME_START = "MODIFY_WORKSPACE_NAME_START";
export const MODIFY_WORKSPACE_NAME = "MODIFY_WORKSPACE_NAME";
export const MODIFY_WORKSPACE_NAME_FAILURE = "MODIFY_WORKSPACE_NAME_FAILURE";
export const MODIFY_WORKSPACE_NAME_SUCCESS = "MODIFY_WORKSPACE_NAME_SUCCESS";

export const MODIFY_WORKSPACE_STATUS_START = "MODIFY_WORKSPACE_STATUS_START";
export const MODIFY_WORKSPACE_STATUS = "MODIFY_WORKSPACE_STATUS";
export const MODIFY_WORKSPACE_STATUS_FAILURE = "MODIFY_WORKSPACE_STATUS_FAILURE";
export const MODIFY_WORKSPACE_STATUS_SUCCESS = "MODIFY_WORKSPACE_STATUS_SUCCESS";

export const MODIFY_WORKSPACE_GITHUB_START = "MODIFY_WORKSPACE_GITHUB_START";
export const MODIFY_WORKSPACE_GITHUB = "MODIFY_WORKSPACE_GITHUB";
export const MODIFY_WORKSPACE_GITHUB_FAILURE = "MODIFY_WORKSPACE_GITHUB_FAILURE";
export const MODIFY_WORKSPACE_GITHUB_SUCCESS = "MODIFY_WORKSPACE_GITHUB_SUCCESS";

export const MODIFY_WORKSPACE_SORT_START = "MODIFY_WORKSPACE_SORT_START";
export const MODIFY_WORKSPACE_SORT = "MODIFY_WORKSPACE_SORT";
export const MODIFY_WORKSPACE_SORT_FAILURE = "MODIFY_WORKSPACE_SORT_FAILURE";
export const MODIFY_WORKSPACE_SORT_SUCCESS = "MODIFY_WORKSPACE_SORT_SUCCESS";

export const MODIFY_WORKSPACE_NOTES = "MODIFY_WORKSPACE_NOTES";

export const ADD_NEW_TASK_TO_WORKSPACE = "ADD_NEW_TASK_TO_WORKSPACE";
export const ADD_NEW_TASK_TO_WORKSPACE_START = "ADD_NEW_TASK_TO_WORKSPACE_START";
export const ADD_NEW_TASK_TO_WORKSPACE_SUCCESS = "ADD_NEW_TASK_TO_WORKSPACE_SUCCESS";
export const ADD_NEW_TASK_TO_WORKSPACE_FAILURE = "ADD_NEW_TASK_TO_WORKSPACE_FAILURE";

export const ADD_NEW_CARD_TO_SECTION = "ADD_NEW_CARD_TO_SECTION";
export const ADD_NEW_CARD_TO_SECTION_START = "ADD_NEW_CARD_TO_SECTION_START";
export const ADD_NEW_CARD_TO_SECTION_SUCCESS = "ADD_NEW_CARD_TO_SECTION_SUCCESS";
export const ADD_NEW_CARD_TO_SECTION_FAILURE = "ADD_NEW_CARD_TO_SECTION_FAILURE";

export const ADD_NEW_CARD_TO_SECTION_START_NEW = "ADD_NEW_CARD_TO_SECTION_START_NEW";

export const CHANGE_TASK_SECTION = "CHANGE_TASK_SECTION";
export const CHANGE_TASK_SECTION_START = "CHANGE_TASK_SECTION_START";
export const CHANGE_TASK_SECTION_SUCCESS = "CHANGE_TASK_SECTION_SUCCESS";
export const CHANGE_TASK_SECTION_FAILURE = "CHANGE_TASK_SECTION_FAILURE";

export const CHANGE_TASK_VISIBILITY = "CHANGE_TASK_VISIBILITY";
export const CHANGE_TASK_VISIBILITY_START = "CHANGE_TASK_VISIBILITY_START";
export const CHANGE_TASK_VISIBILITY_SUCCESS = "CHANGE_TASK_VISIBILITY_SUCCESS";
export const CHANGE_TASK_VISIBILITY_FAILURE = "CHANGE_TASK_VISIBILITY_FAILURE";

export const ACCEPT_ACTION_TASK = "ACCEPT_ACTION_TASK";
export const ACCEPT_ACTION_TASK_START = "ACCEPT_ACTION_TASK_START";
export const ACCEPT_ACTION_TASK_SUCCESS = "ACCEPT_ACTION_TASK_SUCCESS";
export const ACCEPT_ACTION_TASK_FAILURE = "ACCEPT_ACTION_TASK_FAILURE";

export const ACCEPT_ACTION_USER_TASK_START = "ACCEPT_ACTION_USER_TASK_START";
export const ACCEPT_ACTION_USER_TASK_SUCCESS = "ACCEPT_ACTION_USER_TASK_SUCCESS";
export const ACCEPT_ACTION_USER_TASK_FAILURE = "ACCEPT_ACTION_USER_TASK_FAILURE";

export const DECLINE_ACTION_USER_TASKS_TASK_START = "DECLINE_ACTION_USER_TASKS_TASK_START";
export const DECLINE_ACTION_USER_TASKS_TASK_FAILURE = "DECLINE_ACTION_USER_TASKS_TASK_FAILURE";
export const DECLINE_ACTION_USER_TASKS_TASK_SUCCESS = "DECLINE_ACTION_USER_TASKS_TASK_SUCCESS";

export const UPDATE_WORKSPACES = "UPDATE_WORKSPACES";

export const DECLINE_ACTION_TASK = "DECLINE_ACTION_TASK";
export const DECLINE_ACTION_TASK_START = "DECLINE_ACTION_TASK_START";
export const DECLINE_ACTION_TASK_SUCCESS = "DECLINE_ACTION_TASK_SUCCESS";
export const DECLINE_ACTION_TASK_FAILURE = "DECLINE_ACTION_TASK_FAILURE";

export const DRAG_TASK_START = "DRAG_TASK_START";
export const DRAG_TASK_SECTION_FAILURE = "DRAG_TASK_SECTION_FAILURE";
export const DRAG_TASK_SECTION_SUCCESS = "DRAG_TASK_SUCCESS";
export const DRAG_TASK_SORT_FAILURE = "DRAG_TASK_SORT_FAILURE";
export const DRAG_TASK_SORT_SUCCESS = "DRAG_TASK_SORT_SUCCESS";
export const DRAG_TASK = "DRAG_TASK";

export const ADD_NEW_SECTION_TO_WORKSPACE = "ADD_NEW_SECTION_TO_WORKSPACE";
export const ADD_NEW_SECTION_TO_WORKSPACE_START = "ADD_NEW_SECTION_TO_WORKSPACE_START";
export const ADD_NEW_SECTION_TO_WORKSPACE_SUCCESS = "ADD_NEW_SECTION_TO_WORKSPACE_SUCCESS";
export const ADD_NEW_SECTION_TO_WORKSPACE_FAILURE = "ADD_NEW_SECTION_TO_WORKSPACE_FAILURE";

export const SORT_SECTIONS_IN_WORKSPACE = "SORT_SECTIONS_IN_WORKSPACE";
export const MOVE_TASK_TO_ANOTHER_WORKSPACE_START = "MOVE_TASK_TO_ANOTHER_WORKSPACE_START";

export const CHANGE_FILTER_OPTION = "CHANGE_FILTER_OPTION";
export const UPDATE_FILTERED_TASKS = "UPDATE_FILTERED_TASKS";
export const UPDATE_FILTERED_TASKS_SORTABLE_HEADER = "UPDATE_FILTERED_TASKS_SORTABLE_HEADER";

export const CHANGE_FILTER_OPTION_USER_TASKS = "CHANGE_FILTER_OPTION_USER_TASKS";
export const FILTER_USER_TASKS_BY_FILTER = "FILTER_USER_TASKS_BY_FILTER";

export const RESET_FILTER_OPTIONS = "RESET_FILTER_OPTIONS";

export const FETCH_WORKSPACES_DATA = "FETCH_WORKSPACES_DATA";
export const FETCH_WORKSPACES_DATA_START = "FETCH_WORKSPACES_DATA_START";
export const FETCH_WORKSPACES_DATA_SUCCESS = "FETCH_WORKSPACES_DATA_SUCCESS";
export const FETCH_WORKSPACES_DATA_FAILURE = "FETCH_WORKSPACES_DATA_FAILURE";

export const CREATE_NEW_WORKSPACE = "CREATE_NEW_WORKSPACE";
export const CREATE_NEW_WORKSPACE_START = "CREATE_NEW_WORKSPACE_START";
export const CREATE_NEW_WORKSPACE_SUCCESS = "CREATE_NEW_WORKSPACE_SUCCESS";
export const CREATE_NEW_WORKSPACE_FAILURE = "CREATE_NEW_WORKSPACE_FAILURE";

export const SEARCH_WORKSPACE = "SEARCH_WORKSPACE";
export const RESET_SEARCH_WORKSPACES = "RESET_SEARCH_WORKSPACES";

export const SET_IMPLEMENTER_TO_TASK = "SET_IMPLEMENTER_TO_TASK";
export const SET_IMPLEMENTER_TO_TASK_START = "SET_IMPLEMENTER_TO_TASK_START";
export const SET_IMPLEMENTER_TO_TASK_SUCCESS = "SET_IMPLEMENTER_TO_TASK_SUCCESS";
export const SET_IMPLEMENTER_TO_TASK_FAILURE = "SET_IMPLEMENTER_TO_TASK_FAILURE";

export const SET_IMPLEMENTER_TO_USER_TASKS_TASK_START = "SET_IMPLEMENTER_TO_USER_TASKS_TASK_START";
export const SET_IMPLEMENTER_TO_USER_TASKS_TASK_FAILURE = "SET_IMPLEMENTER_TO_USER_TASKS_TASK_FAILURE";
export const SET_IMPLEMENTER_TO_USER_TASKS_TASK_SUCCESS = "SET_IMPLEMENTER_TO_USER_TASKS_TASK_SUCCESS";

export const UNSET_IMPLEMENTER_TO_TASK = "UNSET_IMPLEMENTER_TO_TASK";
export const UNSET_IMPLEMENTER_TO_TASK_START = "UNSET_IMPLEMENTER_TO_TASK_START";
export const UNSET_IMPLEMENTER_TO_TASK_SUCCESS = "UNSET_IMPLEMENTER_TO_TASK_SUCCESS";
export const UNSET_IMPLEMENTER_TO_TASK_FAILURE = "UNSET_IMPLEMENTER_TO_TASK_FAILURE";

export const UNSET_IMPLEMENTER_TO_TASK_USER_TASKS_START = "UNSET_IMPLEMENTER_TO_TASK_USER_TASKS_START";
export const UNSET_IMPLEMENTER_TO_TASK_USER_TASKS_FAILURE = "UNSET_IMPLEMENTER_TO_TASK_USER_TASKS_FAILURE";
export const UNSET_IMPLEMENTER_TO_TASK_USER_TASKS_SUCCESS = "UNSET_IMPLEMENTER_TO_TASK_USER_TASKS_SUCCESS";

export const RESET_NEW_WORKSPACE = "RESET_NEW_WORKSPACE";

export const SET_VERIFICATOR_TO_TASK = "SET_VERIFICATOR_TO_TASK";
export const SET_VERIFICATOR_TO_TASK_START = "SET_VERIFICATOR_TO_TASK_START";
export const SET_VERIFICATOR_TO_TASK_SUCCESS = "SET_VERIFICATOR_TO_TASK_SUCCESS";
export const SET_VERIFICATOR_TO_TASK_FAILURE = "SET_VERIFICATOR_TO_TASK_FAILURE";

export const UNSET_VERIFICATOR_TO_TASK = "UNSET_VERIFICATOR_TO_TASK";
export const UNSET_VERIFICATOR_TO_TASK_START = "UNSET_VERIFICATOR_TO_TASK_START";
export const UNSET_VERIFICATOR_TO_TASK_SUCCESS = "UNSET_VERIFICATOR_TO_TASK_SUCCESS";
export const UNSET_VERIFICATOR_TO_TASK_FAILURE = "UNSET_VERIFICATOR_TO_TASK_FAILURE";

export const SET_INITIATOR_TO_TASK = "SET_INITIATOR_TO_TASK";
export const SET_INITIATOR_TO_TASK_START = "SET_INITIATOR_TO_TASK_START";
export const SET_INITIATOR_TO_TASK_SUCCESS = "SET_INITIATOR_TO_TASK_SUCCESS";
export const SET_INITIATOR_TO_TASK_FAILURE = "SET_INITIATOR_TO_TASK_FAILURE";

export const UNSET_INITIATOR_TO_TASK = "UNSET_INITIATOR_TO_TASK";
export const UNSET_INITIATOR_TO_TASK_START = "UNSET_INITIATOR_TO_TASK_START";
export const UNSET_INITIATOR_TO_TASK_SUCCESS = "UNSET_INITIATOR_TO_TASK_SUCCESS";
export const UNSET_INITIATOR_TO_TASK_FAILURE = "UNSET_INITIATOR_TO_TASK_FAILURE";

export const CHANGE_DISPLAY_STATE = "CHANGE_DISPLAY_STATE";

export const AUTHORIZATION = "AUTHORIZATION";
export const AUTHORIZE_USER_START = "AUTHORIZE_USER_START";
export const AUTHORIZE_USER_SUCCESS = "AUTHORIZE_USER_SUCCESS";
export const AUTHORIZE_USER_FAILURE = "AUTHORIZE_USER_FAILURE";
export const AUTHORIZE_USER = "AUTRHORIZE_USER";

export const RESET_NEW_TASK = "RESET_NEW_TASK";
export const RESET_NEW_SECTION = "RESET_NEW_SECTION";
export const RESET_NEW_TASK_USER_TASKS = "RESET_NEW_TASK_USER_TASKS";

export const UPDATE_WORKSPACE_STARTED = "UPDATE_WORKSPACE_STARTED";
export const UPDATE_WORKSPACE_SUCCESS = "UPDATE_WORKSPACE_SUCCESS";

export const GET_USER_TASKS_START = "GET_USER_TASKS_START";
export const GET_USER_TASKS_FAILURE = "GET_USER_TASKS_FAILURE";
export const GET_USER_TASKS_SUCCESS = "GET_USER_TASKS_SUCCESS";

export const GET_INFO_ABOUT_TASK_SUCCESS = "GET_INFO_ABOUT_TASK_SUCCESS";

export const GET_USER_INFO_START = "GET_USER_INFO_START";
export const GET_USER_INFO_FAILURE = "GET_USER_INFO_FAILURE";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";

export const GET_ALL_TASK_PRIORITY_STATUSES_SUCCESS = "GET_ALL_TASK_PRIORITY_STATUSES_SUCCESS";

export const GET_ALL_TASK_TOPICS_SUCCESS = "GET_ALL_TASK_TOPICS_SUCCESS";

export const GET_ALL_USERS_FROM_COMPANY_SUCCESS = "GET_ALL_USERS_FROM_COMPANY_SUCCESS";

export const FILTER_USER_TASKS = "FILTER_USER_TASKS";
export const FILTER_USER_TASKS_BY_FILTER_SORTABLE_HEADER = "FILTER_USER_TASKS_BY_FILTER_SORTABLE_HEADER";

export const NOT_ALLOWED_TO_SEE_WORKSPACE = "NOT_ALLOWED_TO_SEE_WORKSPACE";

export const RESET_WORKSPACE_ID = "RESET_WORKSPACE_ID";

export const DRAG_WORKSPACE_FAILURE = "DRAG_WORKSPACE_FAILURE";

export const GET_INFO_ABOUT_TASK_USER_TASKS_SUCCESS = "GET_INFO_ABOUT_TASK_USER_TASKS_SUCCESS";
export const SET_USER_TASKS_LOADER = "SET_USER_TASKS_LOADER";

export const SET_VERIFICATOR_TO_TASK_USER_TASKS_START = "SET_VERIFICATOR_TO_TASK_USER_TASKS_START";
export const SET_VERIFICATOR_TO_TASK_USER_TASKS_FAILURE = "SET_VERIFICATOR_TO_TASK_USER_TASKS_FAILURE";
export const SET_VERIFICATOR_TO_TASK_USER_TASKS_SUCCESS = "SET_VERIFICATOR_TO_TASK_USER_TASKS_SUCCESS";

export const UNSET_VERIFICATOR_TO_TASK_USER_TASKS_START = "UNSET_VERIFICATOR_TO_TASK_USER_TASKS_START";
export const UNSET_VERIFICATOR_TO_TASK_USER_TASKS_FAILURE = "UNSET_VERIFICATOR_TO_TASK_USER_TASKS_FAILURE";
export const UNSET_VERIFICATOR_TO_TASK_USER_TASKS_SUCCESS = "UNSET_VERIFICATOR_TO_TASK_USER_TASKS_SUCCESS";

export const SET_INITIATOR_TO_TASK_USER_TASKS_START = "SET_INITIATOR_TO_TASK_USER_TASKS_START";
export const SET_INITIATOR_TO_TASK_USER_TASKS_FAILURE = "SET_INITIATOR_TO_TASK_USER_TASKS_FAILURE";
export const SET_INITIATOR_TO_TASK_USER_TASKS_SUCCESS = "SET_INITIATOR_TO_TASK_USER_TASKS_SUCCESS";

export const UNSET_INITIATOR_TO_TASK_USER_TASKS_START = "UNSET_INITIATOR_TO_TASK_USER_TASKS_START";
export const UNSET_INITIATOR_TO_TASK_USER_TASKS_FAILURE = "UNSET_INITIATOR_TO_TASK_USER_TASKS_FAILURE";
export const UNSET_INITIATOR_TO_TASK_USER_TASKS_SUCCESS = "UNSET_INITIATOR_TO_TASK_USER_TASKS_SUCCESS";

export const RESET_FILTER_OPTIONS_USER_TASKS = "RESET_FILTER_OPTIONS_USER_TASKS";
export const SEARCH_USER_TASKS = "SEARCH_USER_TASKS";
export const ADD_NEW_CARD_TO_USER_TASKS_SUCCESS = "ADD_NEW_CARD_TO_USER_TASKS_SUCCESS";

export const CHANGE_LEADS_DISPLAY_STATE = "CHANGE_LEADS_DISPLAY_STATE";

export const SHOW_LEAD_MODAL_WINDOW = "SHOW_LEAD_MODAL_WINDOW";

export const GET_AVAILABLE_LEADS_START = "GET_AVAILABLE_LEADS_START";
export const GET_AVAILABLE_LEADS_SUCCESS = "GET_AVAILABLE_LEADS_SUCCESS";

export const LOAD_AVAILABLE_LEADS_STARTED = "LOAD_AVAILABLE_LEADS_STARTED";

export const GET_INFO_ABOUT_LEAD_SOURCES = "GET_INFO_ABOUT_LEAD_SOURCES";
export const GET_INFO_ABOUT_LEAD_STATUSES = "GET_INFO_ABOUT_LEAD_STATUSES";
export const GET_INFO_ABOUT_LEAD_TOPICS = "GET_INFO_ABOUT_LEAD_TOPICS";
export const GET_INFO_ABOUT_LEAD_CURRENCIES = "GET_INFO_ABOUT_LEAD_CURRENCIES";
export const GET_INFO_ABOUT_MESSAGES_STATUSES = "GET_INFO_ABOUT_MESSAGES_STATUSES";

export const SET_SELECTED_LEAD = "SET_SELECTED_LEAD";

export const SET_LEAD_SOURCE_START = "SET_LEAD_SOURCE_START";
export const SET_LEAD_SOURCE_FAILURE = "SET_LEAD_SOURCE_FAILURE";

export const SET_LEAD_TOPIC_START = "SET_LEAD_TOPIC_START";
export const SET_LEAD_TOPIC_FAILURE = "SET_LEAD_TOPIC_FAILURE";

export const SET_LEAD_STATUS_START = "SET_LEAD_STATUS_START";
export const SET_LEAD_STATUS_FAILURE = "SET_LEAD_STATUS_FAILURE";

export const REMOVE_LEADS_LOADING_STATE = "REMOVE_LEADS_LOADING_STATE";

export const GET_LEAD_RESPONSE_SUCCESS = "GET_LEAD_RESPONSE_SUCCESS";

export const GET_MESSAGES_OF_LEAD_START = "GET_MESSAGES_OF_LEAD_START";
export const GET_MESSAGES_OF_LEAD_SUCCESS = "GET_MESSAGES_OF_LEAD_SUCCESS";

export const SEND_MESSAGE_TO_DIALOG_START = "SEND_MESSAGE_TO_DIALOG_START";
export const SEND_MESSAGE_TO_DIALOG_SUCCESS = "SEND_MESSAGE_TO_DIALOG_SUCCESS";
export const SEND_MESSAGE_TO_LEAD_DIALOG_SUCCESS = "SEND_MESSAGE_TO_LEAD_DIALOG_SUCCESS";

export const UPDATE_FILTERED_MESSAGES = "UPDATE_FILTERED_MESSAGES";
export const CHANGE_LEADS_FILTER_OPTION = "CHANGE_LEADS_FILTER_OPTION";
export const CHANGE_LEADS_FILTER_OPTION_FUNNEL = "CHANGE_LEADS_FILTER_OPTION_FUNNEL";
export const RESET_LEADS_FILTERS = "RESET_LEADS_FILTERS";

export const GET_ALL_LEADS_DIALOGS_START = "GET_ALL_LEADS_DIALOGS_START";
export const GET_ALL_LEADS_DIALOGS_SUCCESS = "GET_ALL_LEADS_DIALOGS_SUCCESS";

export const SET_SELECTED_DIALOG = "SET_SELECTED_DIALOG";

export const RESEND_MESSAGE_TO_DIALOG_START = "RESEND_MESSAGE_TO_DIALOG_START";
export const RESEND_MESSAGE_TO_DIALOG_FAILURE = "RESEND_MESSAGE_TO_DIALOG_FAILURE";

export const RESEND_RESPONSE_TO_LEAD_START = "RESEND_RESPONSE_TO_LEAD_START";
export const RESEND_RESPONSE_TO_LEAD_FAILURE = "RESEND_RESPONSE_TO_LEAD_FAILURE";

export const SEARCH_LEADS_BY_FILTER = "SEARCH_LEADS_BY_FILTER";

export const CHANGE_LEAD_USER_START = "CHANGE_LEAD_USER_START";

export const ADD_ESTIMATE_LINK_TO_LEAD_SUCCESS = "ADD_ESTIMATE_LINK_TO_LEAD_SUCCESS";
export const ADD_KP_LINK_TO_LEAD_SUCCESS = "ADD_KP_LINK_TO_LEAD_SUCCESS";

export const SEND_KP_LINK_SUCCESS = "SEND_KP_LINK_SUCCESS";

export const RESET_USER_TASKS_ERROR = "RESET_USER_TASKS_ERROR";

export const GET_LEAD_INFORMATION_SUCCESS = "GET_LEAD_INFORMATION_SUCCESS";

export const GET_USER_RANK_INFO_SUCCESS = "GET_USER_RANK_INFO_SUCCESS";

export const SAVE_DATA_ABOUT_LEAD = "SAVE_DATA_ABOUT_LEAD";

export const RESET_LEADS_IN_CATEGORY = "RESET_LEADS_IN_CATEGORY";

export const GET_LEAD_REPLY_SUCCESS = "GET_LEAD_REPLY_SUCCESS";

export const FETCH_DIALOGS_OF_SELECTED_LEAD_START = "FETCH_DIALOGS_OF_SELECTED_LEAD_START";
export const FETCH_MESSAGES_IN_DIALOG_OF_SELECTED_LEAD_START = "FETCH_MESSAGES_IN_DIALOG_OF_SELECTED_LEAD_START";
export const FETCH_MESSAGES_IN_DIALOG_OF_SELECTED_LEAD_SUCCESS = "FETCH_MESSAGES_IN_DIALOG_OF_SELECTED_LEAD_SUCCESS";

export const ADD_NEW_LEAD_SUCCESS = "ADD_NEW_LEAD_SUCCESS";

export const GET_KNOWLEDGE_BASE_DATA_STARTED = "GET_KNOWLEDGE_BASE_DATA_STARTED";
export const GET_KNOWLEDGE_BASE_DATA_SUCCESS = "GET_KNOWLEDGE_BASE_DATA_SUCCESS";

export const ADD_RESPONSE_MANUALLY_SUCCESS = "ADD_RESPONSE_MANUALLY_SUCCESS";

export const GET_INFO_ABOUT_UNREAD_DIALOGS = "GET_INFO_ABOUT_UNREAD_DIALOGS";

export const CLEAR_FETCHER_MESSAGES_IN_LEAD = "CLEAR_FETCHER_MESSAGES_IN_LEAD";

export const SET_MESSAGES_TIMER = "SET_MESSAGES_TIMER";

export const UPDATE_LEADS = "UPDATE_LEADS";

export const GET_INTEGRATOR_LEFT_MENU_START = "GET_INTEGRATOR_LEFT_MENU_START";
export const GET_INTEGRATOR_LEFT_MENU_SUCCESS = "GET_INTEGRATOR_LEFT_MENU_SUCCESS";

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

export const SET_SELECTED_USER = "SET_SELECTED_USER";

export const CHANGE_USER_FIELD_SUCCESS = "CHANGE_USER_FIELD_SUCCESS";
export const CHANGE_USER_FIELD_FAILURE = "CHANGE_USER_FIELD_FAILURE";
export const FILTER_USERS_LIST = "FILTER_USERS_LIST";
export const SORT_USERS_LIST = "SORT_USERS_LIST";
export const CHANGE_USER_STATUS_START = "CHANGE_USER_STATUS_START";
export const CHANGE_USER_STATUS_SUCCESS = "CHANGE_USER_STATUS_SUCCESS";
export const CHANGE_USER_STATUS_FAILURE = "CHANGE_USER_STATUS_FALIURE";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";

export const GET_LIST_OF_REPORT_STATUSES_SUCCESS = "GET_LIST_OF_REPORT_STATUSES_SUCCESS";
export const GET_LIST_OF_REPORT_TYPES_SUCCESS = "GET_LIST_OF_REPORT_TYPES_SUCCESS";
export const GET_LIST_OF_REPORTS_START = "GET_LIST_OF_REPORTS_START";
export const GET_LIST_OF_REPORTS_SUCCESS = "GET_LIST_OF_REPORTS_SUCCESS";

export const GET_PROGRAMS_NAMES_SUCCESS = "GET_PROGRAMS_NAMES_SUCCESS";
export const CHANGE_REPORT_DATA_INFO_FIELD_SUCCESS = "CHANGE_REPORT_DATA_INFO_FIELD_SUCCESS";
export const GET_REPORT_INFO_START = "GET_REPORT_INFO_START";
export const GET_REPORT_INFO_SUCCESS = "GET_REPORT_INFO_SUCCESS";
export const GET_REPORT_INFO_FAILURE = "GET_REPORT_INFO_FAILURE";
export const GET_ADDITIONAL_WORKS_LIST_SUCCESS = "GET_ADDITIONAL_WORKS_LIST_SUCCESS";
export const GET_PROJECTS_LIST_START = "GET_PROJECTS_LIST_START";
export const GET_PROJECTS_LIST_SUCCESS = "GET_PROJECTS_LIST_SUCCESS";
export const DELETE_ADDITIONAL_WORK_FROM_PROJECT_SUCCESS = "DELETE_ADDITIONAL_WORK_FROM_PROJECT_SUCCESS";

export const ADD_ADDITIONAL_WORK_TO_PROJECT_SUCCESS = "ADD_ADDITIONAL_WORK_TO_PROJECT";
export const CHANGE_REPORT_PROJECT_INFO_FAILURE = "CHANGE_REPORT_PROJECT_INFO_FAILURE";
export const REMOVE_REPORT_DATA = "REMOVE_REPORT_DATA";
export const REMOVE_REPORTS_DATA_ITEMS = "REMOVE_REPORTS_DATA_ITEMS";
export const SET_CURRENT_REPORT_ID = "SET_CURRENT_REPORT_ID";
export const SET_TEMPORAL_REPORT_DATE = "SET_TEMPORAL_REPORT_DATE";
export const UPDATE_LIST_OF_REPORTS_SUCCESS = "UPDATE_LIST_OF_REPORTS_SUCCESS";
export const SAVE_REPORT_START = "SAVE_REPORT_START";
export const SAVE_REPORT_SUCCESS = "SAVE_REPORT_SUCCESS";
export const SAVE_REPORT_FAILURE = "SAVE_REPORT_FAILURE";

export const GET_LEADS_ROBOTS_STATUSES_SUCCESS = "GET_LEADS_ROBOTS_STATUSES_SUCCESS";
