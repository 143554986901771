import React from "react";
import styles from "../../app.module.css";

class GlobalErrorCatcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, foundError: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (error.toString().includes("chunk")) document.location.reload();
    this.setState({ foundError: error.toString() });
  }

  // copyToClipboard = (e) => {
  //     this.errorMessage.select();
  //     document.execCommand('copy');
  //     // This is just personal preference.
  //     // I prefer to not show the whole text area selected.
  //     e.target.focus();
  //     this.setState({ copySuccess: 'Copied!' });
  // };

  render() {
    if (this.state.hasError) {
      // Можно отрендерить запасной UI произвольного вида
      return (
        <div className={styles.errorBlock}>
          <h1>Кажется, что-то пошло не так...</h1>
          <h4>
            Команда Sixhands приносит свои извинения. Просим назначить Салину Сергею задачу С ВЫСОКИМ ПРИОРИТЕТОМ и с
            последовательностью действий, которые привели к такому результату и текстом следующей ошибки:
          </h4>
          {/*<input onClick={this.copyToClipboard}  ref={(errorMessage) => this.errorMessage = errorMessage} value={this.state.foundError}/>*/}
          <p>{this.state.foundError}</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export { GlobalErrorCatcher };
