import React from "react";
import { ErrorModalWindow } from "./modalWindows/ErrorModalWindow";
import { getDataFromLocalStorage, saveToLocalStorage } from "../../utils/LocalStorageWorker";
import { telegramChatIdForErrorMessages } from "../../config/CONSTANTS";
require("dotenv").config();

class ModalWindowErrorCatcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, foundError: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (getDataFromLocalStorage("ErrorsStack").modalWindowErrors) {
      let data = getDataFromLocalStorage("ErrorsStack");
      if (data.modalWindowErrors.find((errorStorage) => errorStorage === error.toString())) {
        return;
      } else {
        let errors = data.modalWindowErrors;
        errors.push(error.toString());
        saveToLocalStorage("ErrorsStack", { modalWindowErrors: errors });
        fetch("https://erp.sixhands.co/api/logger/notification/telegram/send", {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_ADMIN_TOKEN}`
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: JSON.stringify({
            chatId: telegramChatIdForErrorMessages,
            message: `<b>У пользователя <u>${this.props.user.user_first_name} ${
              this.props.user.user_last_name
            }</u> возникла ошибка в ModalWindow</b><br><br>${error.toString()}`
          })
        });
      }
    } else {
      saveToLocalStorage("ErrorsStack", { modalWindowErrors: [error.toString()] });
      fetch("https://erp.sixhands.co/api/logger/notification/telegram/send", {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_ADMIN_TOKEN}`
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          chatId: telegramChatIdForErrorMessages,
          message: `<b>У пользователя <u>${this.props.user.user_first_name} ${
            this.props.user.user_last_name
          }</u> возникла ошибка в ModalWindow</b><br><br>${error.toString()}`
        })
      });
    }

    this.setState({ foundError: error.toString() });
  }

  onWrapperClickHandler = () => {
    this.setState({ hasError: false, foundError: null });
  };
  // copyToClipboard = (e) => {
  //     this.errorMessage.select();
  //     document.execCommand('copy');
  //     // This is just personal preference.
  //     // I prefer to not show the whole text area selected.
  //     e.target.focus();
  //     this.setState({ copySuccess: 'Copied!' });
  // };

  render() {
    if (this.state.hasError) {
      // Можно отрендерить запасной UI произвольного вида
      return (
        <>
          <ErrorModalWindow
            error={this.state.foundError}
            componentName={"Модальное окно"}
            onWrapperClickHandler={this.onWrapperClickHandler}
          />
        </>
      );
    }

    return this.props.children;
  }
}

export { ModalWindowErrorCatcher };
