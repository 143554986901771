import {
  FETCH_USERS_IN_WORKSPACE_FAILURE,
  FETCH_USERS_IN_WORKSPACE_STARTED,
  FETCH_USERS_IN_WORKSPACE_SUCCESS,
  DELETE_USER_FROM_WORKSPACE_STARTED,
  DELETE_USER_FROM_WORKSPACE_FAILURE,
  DELETE_USER_FROM_WORKSPACE_SUCCESS,
  ADD_SELECTED_COMPANY_USER_TO_WORKSPACE_USERS,
  ADD_SELECTED_COMPANY_USER_TO_WORKSPACE_USERS_SUCCESS,
  ADD_SELECTED_COMPANY_USER_TO_WORKSPACE_USERS_FAILURE,
  SET_WORKSPACE_USERS_SEARCH_FILTER,
  CLEAR_SEARCH_USER_PARAMS,
  ADD_SELECTED_COMPANY_USER_TO_WORKSPACE_USERS_START
} from "../../actions/actionTypes";

const initialState = {
  loading: false,
  error: null,
  users: [],
  searchFilter: "",
  filteredUsers: []
};

function usersReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USERS_IN_WORKSPACE_STARTED: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case FETCH_USERS_IN_WORKSPACE_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        users: action.users
      };
    }
    case FETCH_USERS_IN_WORKSPACE_FAILURE: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case DELETE_USER_FROM_WORKSPACE_STARTED: {
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        users: state.users.filter((user) => user.user_id !== action.deletedUser)
      };
    }
    case DELETE_USER_FROM_WORKSPACE_FAILURE: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case DELETE_USER_FROM_WORKSPACE_SUCCESS: {
      return {
        ...state,
        users: state.users.filter((user) => user.user_id !== action.deletedUser),
        loading: action.loading,
        error: action.error
      };
    }
    case ADD_SELECTED_COMPANY_USER_TO_WORKSPACE_USERS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }

    case ADD_SELECTED_COMPANY_USER_TO_WORKSPACE_USERS_START: {
      return {
        ...state,
        loading: action.loading,
        users: [...state.users, action.addedUser]
      };
    }

    case ADD_SELECTED_COMPANY_USER_TO_WORKSPACE_USERS_FAILURE: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case ADD_SELECTED_COMPANY_USER_TO_WORKSPACE_USERS_SUCCESS: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case CLEAR_SEARCH_USER_PARAMS: {
      return {
        ...state,
        filteredUsers: [],
        searchFilter: ""
      };
    }

    case SET_WORKSPACE_USERS_SEARCH_FILTER: {
      const filter = action.searchFilter.trim().toLowerCase();
      return {
        ...state,
        searchFilter: filter,
        filteredUsers: state.users.filter((user) =>
          `${user.user_last_name} ${user.user_first_name} ${user.user_middle_name ? user.user_middle_name : ""}`
            .trim()
            .toLowerCase()
            .includes(filter)
        )
      };
    }
    default:
      return state;
  }
}

export default usersReducer;
