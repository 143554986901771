import React, { useState } from "react";
import PropTypes from "prop-types";
import Input from "../Inputs/Input.js";
import styles from "./styles/Search.module.css";
import loupe from "../../assets/icons/loupe.svg";
import eraserIcon from "../../assets/icons/eraser.svg";
import { useTranslation } from "react-multi-lang";

const Search = React.forwardRef(
  (
    { className, focusStyles, onChange, value, onKeyPress, onBlur, haveErase = false, placeholder, eraserClickHandler },
    ref
  ) => {
    const [focusState, setFocusState] = useState(false);

    const translate = useTranslation();

    return (
      <div
        className={`${styles.container} ${className} ${focusState ? focusStyles : ""}`}
        onClick={(event) => {
          const target = event.target.querySelector("input");
          if (target) {
            target.focus();
          }
        }}
      >
        <object type="image/svg+xml" data={loupe} className={styles.loupeSVG}>
          search icon
        </object>
        <Input
          placeholder={placeholder || `${translate("taskTracker.commonWords.search")}...`}
          className={`${styles.inputField} ${haveErase ? styles.inputEraser : ""}`}
          onFocus={() => {
            setFocusState(true);
          }}
          onBlur={() => {
            onBlur && onBlur();
            setFocusState(false);
          }}
          onChange={onChange}
          value={value}
          ref={ref}
          onKeyPress={onKeyPress}
        />
        {haveErase && <img src={eraserIcon} className={styles.eraserIcon} onClick={eraserClickHandler} alt={""} />}
      </div>
    );
  }
);

Search.propTypes = {
  className: PropTypes.string,
  focusStyles: PropTypes.string,
  onChange: PropTypes.func
};

Search.defaultProps = {
  className: "",
  focusStyles: ""
};

export { Search };
