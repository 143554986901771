import {
  AUTHORIZATION,
  AUTHORIZE_USER_FAILURE,
  AUTHORIZE_USER_START,
  AUTHORIZE_USER_SUCCESS,
  RESET_WORKSPACE_ID
} from "../actions/actionTypes";

const initialState = {
  authorized: false,
  token: null,
  loading: false,
  error: false,
  knowledgeData: [],
  knowledgeDataLoading: false
};

function authorizationReducer(state = initialState, action) {
  switch (action.type) {
    case AUTHORIZATION: {
      return {
        ...state,
        authorized: action.authorized,
        token: action.token,
        user_id: action.user_id,
        company_id: action.company_id,
        role_id: action.role_id,
        url: action.url
      };
    }
    case AUTHORIZE_USER_START: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }

    case AUTHORIZE_USER_FAILURE: {
      return {
        ...state,
        authorized: false,
        error: action.error,
        loading: action.loading
      };
    }

    case AUTHORIZE_USER_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }

    case RESET_WORKSPACE_ID: {
      let newState = state.knowledgeDataLoading;
      if (action.selectedTab === "Knowledge Base") newState = true;
      return {
        ...state,
        knowledgeDataLoading: newState
      };
    }

    default:
      return state;
  }
}

export default authorizationReducer;
