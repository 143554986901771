import React, { useEffect, useState, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import modalWindowStyles from "../styles/ModalWindow.module.css";
import styles from "./styles/WorkspaceUsersEditor.module.css";
import { useSelector, useDispatch } from "react-redux";
import {
  setFilterToUsersFromCompany,
  filterUsersFromCompanyByFIO,
  deleteUserFromWorkspace,
  addSelectedCompanyUserToWorkspaceUsers,
  hideModalWindow,
  setImplementerToTask,
  setVerificatorToTask,
  setInitiatorToTask
} from "../../../state/actions";
import { useTable } from "react-table";
import { Avatar } from "../../Avatars/Avatar";
import { HeaderSearch } from "../../Search/HeaderSearch";

import trashIcon from "../../../assets/icons/Icon material-delete-forever.svg";
import { useTranslation } from "react-multi-lang";

const WorkspaceUsersEditor = ({ modalWindowState }) => {
  const workspaceUsersState = useSelector((state) => state.workspaceUsersReducer);

  const closeWindow = () => {
    dispatch(hideModalWindow());
  };

  const companyUsersState = useSelector((state) => state.companyUsersReducer);
  const modalWindowOptionsState = useSelector((state) => state.modalWindow.options);
  const workspaceInformation = useSelector((state) => state.workspaceInformationReducer);
  const dispatch = useDispatch();
  const translate = useTranslation();

  const [searchRequest, setSearchRequest] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [userIndex, setUserIndex] = useState(0);

  const inputRef = useRef(null);

  const userFinder = (request) => {
    setUserIndex(0);
    dispatch(setFilterToUsersFromCompany(request));
    dispatch(filterUsersFromCompanyByFIO());
  };

  const userEditorSearchInputHandler = (event) => {
    setSearchRequest(event.target.value);
    userFinder(event.target.value);
  };

  const addUserFromCompanyToWorkspace = (workspaceId, userId) => {
    dispatch(addSelectedCompanyUserToWorkspaceUsers(workspaceId, userId));
    setTimeout(() => {
      if (modalWindowState.options.taskId && modalWindowState.options.userType) {
        if (modalWindowState.options.userType === "verificator")
          dispatch(setVerificatorToTask(modalWindowState.options.taskId, userId));
        if (modalWindowState.options.userType === "implementer")
          dispatch(setImplementerToTask(modalWindowState.options.taskId, userId));
        if (modalWindowState.options.userType === "initiator")
          dispatch(setInitiatorToTask(modalWindowState.options.taskId, userId));
      }
    }, 500);
  };

  const [showSearchResults, setShowResults] = useState(false);

  const modalWindowKeyPressHandler = (event) => {
    if (event.key === "Escape") {
      modalWindowState.options.isTyping = false;
      closeWindow();
      setSearchRequest("");
    }
    if (event.key === "ArrowDown") {
      if (userIndex === companyUsersState.filteredUsers.slice(0, 5).length - 1) {
        setUserIndex(0);
        setSelectedUser(companyUsersState.filteredUsers.slice(0, 5)[0].user_id);
      } else {
        setSelectedUser(companyUsersState.filteredUsers.slice(0, 5)[userIndex + 1].user_id);
        setUserIndex(userIndex + 1);
      }
    }
    if (event.key === "ArrowUp") {
      if (userIndex === 0) {
        setUserIndex(companyUsersState.filteredUsers.slice(0, 5).length - 1);
        setSelectedUser(
          companyUsersState.filteredUsers.slice(0, 5)[companyUsersState.filteredUsers.slice(0, 5).length - 1].user_id
        );
      } else {
        setSelectedUser(companyUsersState.filteredUsers.slice(0, 5)[userIndex - 1].user_id);
        setUserIndex(userIndex - 1);
      }
    }
    if (event.key === "Enter" && selectedUser) {
      addUserFromCompanyToWorkspace(workspaceInformation.information.workspace_id, selectedUser);
      setShowResults(false);
      setSearchRequest("");
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", modalWindowKeyPressHandler);
    return () => {
      document.removeEventListener("keydown", modalWindowKeyPressHandler);
    };
  });

  const wrapperClickHandler = (event) => {
    if (event.target.className.includes(modalWindowStyles.wrapper) && modalWindowOptionsState.closeOnWrapperClick) {
      modalWindowState.options.isTyping = false;
      closeWindow();
      setSearchRequest("");
    }
  };

  const deleteUserFromWorkspaceHandler = (workspaceId, userId) => {
    dispatch(deleteUserFromWorkspace(workspaceId, userId));
  };

  const tableData = useMemo(
    () =>
      workspaceUsersState.users.map((user) => ({
        
        avatar: (
          <Avatar
            alt={`${user.user_last_name} ${user.user_first_name} ${
              user.user_middle_name !== null ? user.user_middle_name : ""
            }`.trim()}
            type="rounded"
            src={user.user_photo}
            className={styles.tableUserPhoto}
          />
        ),
        FIO: `${user.user_last_name} ${user.user_first_name} ${
          user.user_middle_name !== null ? user.user_middle_name : ""
        }`.trim(),
        role: user.user_position,
        delete: (
          <>
            {workspaceInformation.information.permissions &&
              workspaceInformation.information.permissions.user.can_delete === 1 && (
                <div
                  className={styles.trashButtonContainer}
                  onClick={() => {
                    deleteUserFromWorkspaceHandler(workspaceInformation.information.workspace_id, user.user_id);
                  }}
                >
                  <object type="image/svg+xml" data={trashIcon} className={styles.trashButton}>
                    delete button
                  </object>
                </div>
              )}
          </>
        )
      })),
    // eslint-disable-next-line
        [workspaceUsersState.users]
  );
  const tableColumns = useMemo(
    () => [
      {
        Header: translate("taskTracker.commonWords.employeeFio"),
        columns: [
          {
            accessor: "avatar"
          },
          {
            accessor: "FIO"
          }
        ]
      },
      {
        Header: translate("taskTracker.commonWords.role"),
        columns: [
          {
            accessor: "role"
          },
          {
            accessor: "delete"
          }
        ]
      }
    ],
    // eslint-disable-next-line
        []
  );

  const usersTableInstance = useTable({
    columns: tableColumns,
    data: tableData
  });

  useEffect(() => {
    setSelectedUser(
      companyUsersState.filteredUsers.length !== 0
        ? companyUsersState.filteredUsers.slice(0, 3)[0].user_id
        : companyUsersState.users.slice(0, 3)[0].user_id
    );
    // eslint-disable-next-line
    }, [])

  useEffect(() => {
    if (searchRequest.length > 0) {
      setShowResults(true);
    } else {
      setShowResults(false);
    }
    setSelectedUser(
      companyUsersState.filteredUsers.length !== 0
        ? companyUsersState.filteredUsers.slice(0, 3)[0].user_id
        : companyUsersState.users.slice(0, 3)[0].user_id
    );
    // eslint-disable-next-line
    }, [companyUsersState, searchRequest]);

  useEffect(() => {
    if (modalWindowState.options.isTyping && workspaceInformation.information.permissions.user.can_edit === 1)
      inputRef.current.focus();
    // eslint-disable-next-line
    })

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = usersTableInstance;

  if (
    (workspaceUsersState.loading === false || workspaceUsersState.loading === true) &&
    workspaceUsersState.error === null
  ) {
    return (
      <div className={`${modalWindowStyles.wrapper}`} onClick={wrapperClickHandler}>
        <div className={`${styles.usersEditorWindow} ${modalWindowStyles.modalWindowDefaultSize}`}>
          <div className={`${modalWindowStyles.modalDefaultHeader} ${styles.header}`}>
            <h2>{modalWindowState.options.header}</h2>
            <div
              className={`${modalWindowStyles.closeButton} ${
                modalWindowState.options.closeHeaderButton ? "" : modalWindowStyles.hide
              }`}
              onClick={() => {
                modalWindowState.options.isTyping = false;
                closeWindow();
                setSearchRequest("");
              }}
            >
              &times;
            </div>
          </div>
          <div className={styles.body}>
            {workspaceInformation.information.permissions &&
              workspaceInformation.information.permissions.user.can_edit === 1 && (
                <HeaderSearch
                  className={styles.search}
                  onChange={userEditorSearchInputHandler}
                  value={searchRequest}
                  ref={inputRef}
                />
              )}
            {showSearchResults && (
              <div className={styles.usersSearchResultList}>
                {companyUsersState.filteredUsers.length === 0 && (
                  <p style={{ padding: "10px" }}>По вашему запросу ничего не найдено</p>
                )}
                {companyUsersState.filteredUsers.slice(0, 5).map((user) => (
                  <div
                    key={user.user_id}
                    className={selectedUser === user.user_id ? styles.selected : styles.listElement}
                    onClick={() => {
                      addUserFromCompanyToWorkspace(workspaceInformation.information.workspace_id, user.user_id);
                      setShowResults(false);
                      setSearchRequest("");
                    }}
                  >
                    <Avatar
                      className={styles.avatar}
                      src={user.user_photo}
                      alt={`${user.user_first_name} ${user.user_last_name} ${
                        user.user_middle_name ? user.user_middle_name : ""
                      }`.trim()}
                      type="rounded"
                    />
                    <h4>
                      {`${user.user_first_name} ${user.user_last_name} ${
                        user.user_middle_name ? user.user_middle_name : ""
                      }`.trim()}
                    </h4>
                  </div>
                ))}
              </div>
            )}
            <div className={styles.tableHolder}>
              <div className={`${styles.tableDisabler}`} />
              <table {...getTableProps()} cellSpacing="0">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, index) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                        })}
                      </tr>
                    );
                  })}
                  <tr>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={`${styles.wrapper} ${modalWindowState.showStatus ? "" : modalWindowStyles.hide}`}>
        <div className={`${styles.usersEditorWindow} ${styles.modalWindowDefaultSize}`}>
          <div className={styles.modalDefaultHeader}>
            <h2>{modalWindowState.options.header}</h2>
            <div
              className={`${styles.closeButton} ${
                modalWindowState.options.closeHeaderButton ? "" : modalWindowStyles.hide
              }`}
              onClick={() => {
                modalWindowState.options.isTyping = false;
                closeWindow();
                setSearchRequest("");
              }}
            >
              &times;
            </div>
          </div>
          <div className={styles.body}>{`Ошибка: ${workspaceUsersState.error}`}</div>
        </div>
      </div>
    );
  }
};

WorkspaceUsersEditor.propTypes = {
  modalWindowState: PropTypes.shape({
    showStatus: PropTypes.bool.isRequired,
    options: PropTypes.shape({
      closeHeaderButton: PropTypes.bool,
      header: PropTypes.string,
      showOkButton: PropTypes.bool
    })
  }).isRequired,
  closeWindow: PropTypes.func.isRequired
};

export { WorkspaceUsersEditor };
