import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { addNewTaskToWorkspace, hideModalWindow } from "../../../state/actions";
import { animationDurationInMsFromCSSVar } from "./generalFunctionsToTripleCardModalWindow";
import styles from "../styles/ModalWindow.module.css";
import closeIcon from "../../../assets/icons/close.svg";
import { Avatar } from "../../Avatars/Avatar";
import { RoleUserChanger } from "../../../pages/Workspace/components/Components/RoleUserChanger";
import { DropdownButton } from "../../Buttons/DropdownButton";
import { InputDate } from "../../Inputs/InputDate";
import { PrioritySelector } from "../../../pages/Workspace/components/Components/PrioritySelector";
import { AccentButton } from "../../Buttons/AccentButton";
import { RoleUserChangerUserTasks } from "../../../pages/UserTasks/RoleUserChangerUserTasks";
import { EditableTaskNameModal } from "./components/EditableTaskNameModal";
import { DropDownButtonWithSearch } from "./components/DropDownButtonWithSearch";
import { useTranslation } from "react-multi-lang";

const CreateTaskModalWindow = ({ isModalClosing }) => {
  const modalWindowState = useSelector((state) => state.modalWindow);

  const dispatch = useDispatch();

  const [isClosing, setIsClosing] = useState(false);

  const translate = useTranslation();

  const closeWindow = () => {
    setIsClosing(true);
    // setTimeout(() => {
    //     dispatch(hideModalWindow());
    //     dispatch(
    //         changeOptionsToModalWindow({
    //             type: MODAL_WINDOW_TYPES.NOTIFICATION,
    //             closeHeaderButton: true,
    //             closeOnWrapperClick: true,
    //             text: 'Модальное окно',
    //             header: 'Модальное окно',
    //             showOkButton: true,
    //             showLeftSide: false,
    //             taskId: null,
    //         })
    //     );
    // }, 200)
    dispatch(hideModalWindow());
  };

  const hiddenDescriptionRef = useRef(null);
  const descriptionRef = useRef(null);

  const authorizationState = useSelector((state) => state.authorizationReducer);

  const workspaceInformationState = useSelector((state) => state.workspaceInformationReducer);

  const workspacesReducer = useSelector((state) => state.workspacesReducer);

  const userTasksState = useSelector((state) => state.userTasksReducer);

  const [newTaskNameText, setNewTaskNameText] = useState("");
  const [newTaskDescriptionText, setNewTaskDescriptionText] = useState("");
  const [newTaskImplementer, setNewTaskImplementer] = useState(null);
  const [newTaskVerificator, setNewTaskVerificator] = useState(null);
  const [newTaskDeadlineDate, setNewTaskDeadlineDate] = useState(null);
  const [newTaskPriority, setNewTaskPriority] = useState("");
  const [headerIsEdit, setHeaderIsEdit] = useState(false);
  const [descriptionIsEdit, setDescriptionIsEdit] = useState(false);
  const [isOnFocus, setOnFocus] = useState(true);
  const [newTaskType, setNewTaskType] = useState("Обычная");
  const [newTaskSection, setNewTaskSection] = useState("");
  const [newTaskTopic, setNewTaskTopic] = useState(translate("taskTracker.commonWords.notSelected"));

  let sectionsList = [];

  if (modalWindowState.options.isCalledFromUserTasks)
    sectionsList.push({
      id: 0,
      label: translate("taskTracker.commonWords.withoutWorkspace"),
      order: 0
    });

  if (modalWindowState.options.isCalledFromUserTasks)
    workspacesReducer.workspaces.map(
      (workspace) =>
        workspace.permissions.can_read === 1 &&
        sectionsList.push({
          id: workspace.workspace_id,
          label: workspace.workspace_name,
          order: 0
        })
    );
  else
    sectionsList = workspaceInformationState.sectionsList.map((section) => ({
      id: section.workspace_section_id,
      label: section.workspace_section_name,
      order: 0
    }));
  let topicsList;

  if (!modalWindowState.options.isCalledFromUserTasks)
    topicsList = workspaceInformationState.topicsList.map((topic) => ({
      id: topic.task_topic_id,
      label: topic.task_topic_name,
      order: topic.task_topic_sort
    }));
  else
    topicsList = userTasksState.topicsList.map((topic) => ({
      id: topic.task_topic_id,
      label: topic.task_topic_name,
      order: topic.task_topic_sort
    }));

  useEffect(() => {
    if (!modalWindowState.options.isCalledFromUserTasks) {
      setNewTaskPriority(
        workspaceInformationState.taskPriorityStatusesList ? workspaceInformationState.taskPriorityStatusesList[0] : ""
      );
      setNewTaskSection(
        workspaceInformationState.sectionsList.length > 0
          ? workspaceInformationState.sectionsList[0].workspace_section_name
          : "Backlog"
      );
    } else {
      setNewTaskPriority(userTasksState.priorityList ? userTasksState.priorityList[0] : "");
      setNewTaskSection(translate("taskTracker.commonWords.withoutWorkspace"));
    }
    // eslint-disable-next-line
    }, []);

  const onKeyPressHandler = (event) => {
    if (event.key === "Tab") event.preventDefault();
  };

  const [showTaskImplementerUserChanger, setShowTaskImplementerUserChanger] = useState(false);

  const [showTaskVerificatorUserChanger, setShowTaskVerificatorUserChanger] = useState(false);

  const wrapperClickHandler = (event) => {
    if (!event.target.closest(`.${styles.centralWindow}`) && modalWindowState.options.closeOnWrapperClick) {
      closeWindow();
    }
  };

  const closeModalWindowClickHandler = () => {
    closeWindow();
  };

  const newTaskNameBlurHandler = (event) => {
    setOnFocus(false);
    if (event.target.innerHTML.trim().length > 0) {
      setNewTaskNameText(event.target.innerHTML.trim());
    } else {
      setNewTaskNameText("");
    }
    setHeaderIsEdit(false);
  };

  const changeNewTaskDescriptionHandler = (event) => {
    setNewTaskDescriptionText(event.target.value);
  };

  const newTaskDescriptionBlurHandler = (event) => {
    if (event.target.value.trim().length > 0) {
      setNewTaskDescriptionText(event.target.value.trim());
    } else {
      setNewTaskDescriptionText("");
    }
    setDescriptionIsEdit(false);
  };

  const taskDescriptionFocusHandler = (event) => {
    setDescriptionIsEdit(true);
  };

  let textareaControlPressState = false;

  const textareaKeyDownHandler = (event) => {
    if (event.key === "Control") {
      textareaControlPressState = true;
    }
    if (event.key === "Enter" && textareaControlPressState) {
      event.target.blur();
    }
  };

  const textareaKeyUpHandler = (event) => {
    if (event.key === "Control") {
      textareaControlPressState = false;
    }
  };

  const textareaKeyPressHandler = (event) => {
    if (event.key === "Enter" && !textareaControlPressState) {
      const selectorPosition = event.target.selectionStart;
      event.target.value =
        event.target.value.slice(0, event.target.selectionStart) +
        event.target.value.slice(event.target.selectionEnd, event.target.value.length);
      event.target.selectionStart = selectorPosition + 1;
      event.target.selectionEnd = selectorPosition;
    }
    //console.log(event.target);
  };

  const createNewTaskInWorkspaceButtonClickHandler = (event) => {
    if (modalWindowState.options.isCalledFromUserTasks)
      dispatch(
        addNewTaskToWorkspace({
          workspaceId:
            newTaskSection !== translate("taskTracker.commonWords.withoutWorkspace")
              ? workspacesReducer.workspaces.find((workspace) => workspace.workspace_name === newTaskSection)
                  .workspace_id
              : 0,
          initiator_user_id: authorizationState.user_id,
          verificator_user_id: newTaskVerificator ? newTaskVerificator.user_id : null,
          implementer_user_id: newTaskImplementer ? newTaskImplementer.user_id : null,
          task_priority_id: newTaskPriority.task_priority_id,
          task_topic_id:
            newTaskTopic !== translate("taskTracker.commonWords.notSelected")
              ? userTasksState.topicsList.find((topic) => topic.task_topic_name === newTaskTopic).task_topic_id
              : null,
          task_deadline_dt: newTaskDeadlineDate,
          task_name: newTaskNameText,
          task_descr: newTaskDescriptionText,
          task_type: newTaskType === "Обычная" ? "REGULAR" : "VERIFICATION",
          isCalledFromUserTasks: true,

          isCalledFromCreateModal: true
        })
      );
    else
      dispatch(
        addNewTaskToWorkspace({
          workspaceId: workspaceInformationState.information.workspace_id,
          sectionId: workspaceInformationState.sectionsList.find(
            (section) => section.workspace_section_name === newTaskSection
          ).workspace_section_id,
          initiator_user_id: authorizationState.user_id,
          verificator_user_id: newTaskVerificator ? newTaskVerificator.user_id : null,
          implementer_user_id: newTaskImplementer ? newTaskImplementer.user_id : null,
          task_priority_id: newTaskPriority.task_priority_id,
          task_topic_id:
            newTaskTopic !== translate("taskTracker.commonWords.notSelected")
              ? workspaceInformationState.topicsList.find((topic) => topic.task_topic_name === newTaskTopic)
                  .task_topic_id
              : null,
          task_deadline_dt: newTaskDeadlineDate,
          task_name: newTaskNameText,
          task_descr: newTaskDescriptionText,
          task_type: newTaskType === "Обычная" ? "REGULAR" : "VERIFICATION",
          isCalledFromUserTasks: false,

          isCalledFromCreateModal: true
        })
      );
    closeWindow();
  };

  const closeUserSelectorFunction = () => {
    if (showTaskImplementerUserChanger) {
      setShowTaskImplementerUserChanger(false);
    }
    if (showTaskVerificatorUserChanger) {
      setShowTaskVerificatorUserChanger(false);
    }
  };

  const newTaskNameFocusHandler = () => {
    setHeaderIsEdit(true);
  };

  const newTaskImplementerUpdate = (user) => {
    setNewTaskImplementer(user);
  };
  const newTaskVerificatorUpdate = (user) => {
    setNewTaskVerificator(user);
  };
  const newTaskDeadlineDateUpdate = (date) => {
    setNewTaskDeadlineDate(date);
  };
  const unsetDeadlineTaskButtonClickHandler = () => {
    setNewTaskDeadlineDate(null);
  };
  const newTaskSectionUpdate = (section) => {
    setNewTaskSection(section);
  };
  const newTaskPriorityUpdate = (priority) => {
    setNewTaskPriority(priority);
  };
  const newTaskTopicUpdate = (topic) => {
    setNewTaskTopic(topic);
  };

  useEffect(() => {
    if (newTaskDescriptionText && newTaskDescriptionText.includes("--"))
      setNewTaskDescriptionText(newTaskDescriptionText.replace("--", "—"));
  }, [newTaskDescriptionText]);

  useEffect(() => {
    if (newTaskNameText && newTaskNameText.includes("--")) setNewTaskNameText(newTaskNameText.replace("--", "—"));
  }, [newTaskNameText]);

  useEffect(() => {
    setNewTaskImplementer(
      modalWindowState.options.isCalledFromUserTasks && modalWindowState.options.setUserTasksImplementor
        ? userTasksState.users.find((user) => user.user_id === modalWindowState.options.setUserTasksImplementor)
        : null
    );
    setNewTaskVerificator(null);
    setNewTaskDeadlineDate(null);
    if (modalWindowState.options.isCalledFromUserTasks)
      setNewTaskPriority(userTasksState.priorityList ? userTasksState.priorityList[0] : "");
    else
      setNewTaskPriority(
        workspaceInformationState.taskPriorityStatusesList ? workspaceInformationState.taskPriorityStatusesList[0] : ""
      );
    setHeaderIsEdit(false);
    setDescriptionIsEdit(false);
    setNewTaskType("Обычная");
    if (modalWindowState.options.isCalledFromUserTasks)
      setNewTaskSection(translate("taskTracker.commonWords.withoutWorkspace"));
    else
      setNewTaskSection(
        workspaceInformationState.sectionsList.length > 0
          ? workspaceInformationState.sectionsList[0].workspace_section_name
          : "Backlog"
      );
    setNewTaskTopic(translate("taskTracker.commonWords.notSelected"));
    setNewTaskDescriptionText("");
    setNewTaskNameText("");
    setShowTaskImplementerUserChanger(false);
    setShowTaskVerificatorUserChanger(false);
    // eslint-disable-next-line
    }, [modalWindowState.showStatus])

  const onClickHandler = (event) => {
    if (!event.target.closest(`.${styles.roleUserChanger}`) && showTaskImplementerUserChanger) {
      setShowTaskImplementerUserChanger(false);
    }
    if (!event.target.closest(`.${styles.roleUserChanger}`) && showTaskVerificatorUserChanger) {
      setShowTaskVerificatorUserChanger(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", onClickHandler);
    document.addEventListener("keydown", onKeyPressHandler);
    descriptionRef.current.style.height = hiddenDescriptionRef.current.getBoundingClientRect().height + "px";
    return () => {
      document.removeEventListener("click", onClickHandler);
      document.removeEventListener("keydown", onKeyPressHandler);
    };
  });

  useEffect(() => {
    if (descriptionIsEdit || headerIsEdit) document.removeEventListener("click", onClickHandler);
    else document.addEventListener("click", onClickHandler);
  });
  return (
    <CSSTransition
      in={modalWindowState.showStatus}
      timeout={animationDurationInMsFromCSSVar("--fastAnimationDuration")}
      classNames={{
        enterActive: styles.modalShowing,
        enterDone: styles.modalShow,
        exitActive: styles.modalClosing,
        exitDone: styles.modalClose
      }}
    >
      <div
        className={`${styles.wrapper} ${styles.tripleWindowTask} ${
          isClosing || isModalClosing ? styles.isClosing : styles.opened
        }`}
        onClick={descriptionIsEdit || headerIsEdit ? undefined : wrapperClickHandler}
      >
        <div className={styles.centralWindow}>
          <div
            className={`${styles.windowLabel} ${
              modalWindowState.options.showLeftSide || modalWindowState.options.showRightSide ? styles.show : ""
            }`}
          >
            {translate("taskTracker.commonWords.responseTask")}
          </div>
          <div className={styles.card}>
            <div className={styles.cardHeader}>
              <div className={styles.leftSideButtonsGroup}>
                <h1>{translate("taskTracker.commonWords.taskCreating")}</h1>
              </div>
              <div className={styles.rightSideButtonsGroup}>
                <div
                  className={`${styles.optionsButton} ${styles.closeButton} ${
                    modalWindowState.options.closeHeaderButton ? "" : styles.hide
                  }`}
                  onClick={closeModalWindowClickHandler}
                >
                  <object type="image/svg+xml" data={closeIcon}>
                    decline icon
                  </object>
                </div>
              </div>
            </div>
            <div className={styles.cardBody}>
              <EditableTaskNameModal
                text={newTaskNameText}
                permissions={{ can_edit: 1 }}
                headerIsEdit={headerIsEdit}
                onFocus={newTaskNameFocusHandler}
                onBlur={newTaskNameBlurHandler}
                shouldBeOnFocus={isOnFocus}
              />
              <div className={styles.taskForm}>
                <div className={styles.formElement}>
                  <div className={styles.label}>{translate("taskTracker.commonWords.implementer")}</div>
                  <div
                    className={`${styles.userInput} ${newTaskImplementer ? "" : styles.notChanged} ${styles.inputSize}`}
                    onClick={() => {
                      if (!showTaskImplementerUserChanger) setShowTaskImplementerUserChanger(true);
                    }}
                  >
                    <Avatar
                      src={newTaskImplementer ? newTaskImplementer.user_photo : ""}
                      className={styles.avatar}
                      alt={
                        newTaskImplementer
                          ? `${newTaskImplementer.user_last_name} ${newTaskImplementer.user_first_name} ${
                              newTaskImplementer.user_middle_name ? newTaskImplementer.user_middle_name : ""
                            }`.trim()
                          : ""
                      }
                      type="square"
                    />
                    {newTaskImplementer
                      ? `${newTaskImplementer.user_last_name} ${newTaskImplementer.user_first_name[0]}.`
                      : translate("taskTracker.commonWords.assign")}
                    {showTaskImplementerUserChanger && !modalWindowState.options.isCalledFromUserTasks && (
                      <RoleUserChanger
                        className={`${styles.roleUserChanger} ${showTaskImplementerUserChanger ? "" : styles.hide}`}
                        type={newTaskImplementer ? "changer" : "adder"}
                        userType="implementer"
                        closeWindowFunction={() => {
                          closeUserSelectorFunction();
                        }}
                        localUpdateFunction={newTaskImplementerUpdate}
                      />
                    )}
                    {showTaskImplementerUserChanger && modalWindowState.options.isCalledFromUserTasks && (
                      <RoleUserChangerUserTasks
                        className={`${styles.roleUserChanger} ${showTaskImplementerUserChanger ? "" : styles.hide}`}
                        type={newTaskImplementer ? "changer" : "adder"}
                        userType="implementer"
                        closeWindowFunction={() => {
                          closeUserSelectorFunction();
                        }}
                        localUpdateFunction={newTaskImplementerUpdate}
                      />
                    )}
                    {newTaskImplementer && (
                      <button
                        className={styles.deleteButtonOnTaskForm}
                        onClick={() => {
                          setNewTaskImplementer(null);
                          closeUserSelectorFunction();
                        }}
                      >
                        <object type="image/svg+xml" data={closeIcon}>
                          delete user
                        </object>
                      </button>
                    )}
                  </div>
                </div>
                <div className={styles.formElement}>
                  <div className={styles.label}>
                    {modalWindowState.options.isCalledFromUserTasks
                      ? "Workspace"
                      : translate("taskTracker.commonWords.section")}
                  </div>
                  {modalWindowState.options.isCalledFromUserTasks ? (
                    <DropDownButtonWithSearch items={sectionsList} onSelectOptionFunction={newTaskSectionUpdate} />
                  ) : (
                    <DropdownButton
                      buttonLabel={newTaskSection}
                      className={`${styles.inputSize}`}
                      customStylesToButton={styles.dropdownButton}
                      customStylesToOptionsList={styles.dropdownButtonOptionList}
                      customStylesToOptionsListElement={styles.dropdownButtonOptionListElement}
                      customStylesToButtonOnOpenMenu={styles.dropdownButtonIsOpen}
                      dropDownMenuOptions={sectionsList}
                      onSelectOptionFunction={newTaskSectionUpdate}
                      editable={true}
                    />
                  )}
                </div>
                <div className={styles.formElement}>
                  <div className={styles.label}>{translate("taskTracker.commonWords.verifier")}</div>
                  <div
                    className={`${styles.userInput} ${newTaskVerificator ? "" : styles.notChanged} ${styles.inputSize}`}
                    onClick={() => {
                      if (!showTaskVerificatorUserChanger) setShowTaskVerificatorUserChanger(true);
                    }}
                  >
                    <Avatar
                      src={newTaskVerificator ? newTaskVerificator.user_photo : ""}
                      className={styles.avatar}
                      alt={
                        newTaskVerificator
                          ? `${newTaskVerificator.user_last_name} ${newTaskVerificator.user_first_name} ${
                              newTaskVerificator.user_middle_name ? newTaskVerificator.user_middle_name : ""
                            }`.trim()
                          : ""
                      }
                      type="square"
                    />
                    {newTaskVerificator
                      ? `${newTaskVerificator.user_last_name} ${newTaskVerificator.user_first_name[0]}.`
                      : translate("taskTracker.commonWords.assign")}
                    {showTaskVerificatorUserChanger && !modalWindowState.options.isCalledFromUserTasks && (
                      <RoleUserChanger
                        className={`${styles.roleUserChanger} ${showTaskVerificatorUserChanger ? "" : styles.hide}`}
                        type={newTaskVerificator ? "changer" : "adder"}
                        userType="verificator"
                        closeWindowFunction={() => {
                          closeUserSelectorFunction();
                        }}
                        localUpdateFunction={newTaskVerificatorUpdate}
                      />
                    )}
                    {showTaskVerificatorUserChanger && modalWindowState.options.isCalledFromUserTasks && (
                      <RoleUserChangerUserTasks
                        className={`${styles.roleUserChanger} ${showTaskVerificatorUserChanger ? "" : styles.hide}`}
                        type={newTaskVerificator ? "changer" : "adder"}
                        userType="verificator"
                        closeWindowFunction={() => {
                          closeUserSelectorFunction();
                        }}
                        localUpdateFunction={newTaskVerificatorUpdate}
                      />
                    )}
                    {newTaskVerificator && (
                      <button
                        className={styles.deleteButtonOnTaskForm}
                        onClick={() => {
                          setNewTaskVerificator(null);
                          closeUserSelectorFunction();
                        }}
                      >
                        <object type="image/svg+xml" data={closeIcon}>
                          delete user
                        </object>
                      </button>
                    )}
                  </div>
                </div>
                <div className={styles.formElement}>
                  <div className={styles.label}>{translate("taskTracker.commonWords.topic")}</div>
                  <DropdownButton
                    buttonLabel={newTaskTopic}
                    className={`${styles.inputSize}`}
                    customStylesToButton={styles.dropdownButton}
                    customStylesToOptionsList={styles.dropdownButtonOptionList}
                    customStylesToOptionsListElement={styles.dropdownButtonOptionListElement}
                    customStylesToButtonOnOpenMenu={styles.dropdownButtonIsOpen}
                    dropDownMenuOptions={topicsList}
                    onSelectOptionFunction={newTaskTopicUpdate}
                    editable={true}
                  />
                </div>
                <div className={styles.formElement}>
                  <div className={styles.label}>{translate("taskTracker.commonWords.deadline")}</div>
                  <div
                    className={`${styles.dateInput} ${styles.inputSize} 
										${
                      newTaskDeadlineDate &&
                      new Date(new Date(newTaskDeadlineDate).toDateString()) < new Date(new Date().toDateString())
                        ? styles.deadline
                        : ""
                    }`}
                  >
                    <InputDate
                      className={styles.calendar}
                      containerClassName={styles.calendarContainer}
                      value={newTaskDeadlineDate}
                      taskId={null}
                      showImmutableDate={true}
                      editable={true}
                      displayState={true}
                      isCalledFromModal={true}
                      isCalledFromUserTasks={modalWindowState.options.isCalledFromUserTasks}
                      newTaskDeadlineDateUpdate={newTaskDeadlineDateUpdate}
                    />
                    {newTaskDeadlineDate && (
                      <button className={styles.deleteButtonOnTaskForm} onClick={unsetDeadlineTaskButtonClickHandler}>
                        <object type="image/svg+xml" data={closeIcon}>
                          delete deadline
                        </object>
                      </button>
                    )}
                  </div>
                </div>
                <div className={styles.formElement}></div>
                <div className={styles.formElement}>
                  <div className={styles.label}>{translate("taskTracker.commonWords.priority")}</div>
                  <div className={`${styles.priorityInput} ${styles.inputSize}`}>
                    <PrioritySelector
                      currentLabel={newTaskPriority.task_priority_name}
                      currentColor={newTaskPriority.task_priority_color}
                      prioritiesList={
                        modalWindowState.options.isCalledFromUserTasks
                          ? userTasksState.priorityList
                          : workspaceInformationState.taskPriorityStatusesList
                      }
                      className={styles.prioritySelector}
                      customStylesToDropdownList={styles.priorityList}
                      customStylesToSelectorButton={styles.priorityButton}
                      localUpdateFunction={newTaskPriorityUpdate}
                      editable={true}
                    />
                  </div>
                </div>
              </div>
              <div
                className={`${styles.taskDescriptionHidden} ${descriptionIsEdit ? styles.isEdit : ""}`}
                ref={hiddenDescriptionRef}
                style={{ minHeight: "200px" }}
              >
                {newTaskDescriptionText}
              </div>
              <textarea
                className={`${styles.taskDescription} ${descriptionIsEdit ? styles.isEdit : ""}`}
                style={{ minHeight: "200px" }}
                rows="5"
                value={newTaskDescriptionText}
                onChange={changeNewTaskDescriptionHandler}
                onFocus={taskDescriptionFocusHandler}
                onBlur={newTaskDescriptionBlurHandler}
                onKeyDown={textareaKeyDownHandler}
                onKeyUp={textareaKeyUpHandler}
                onKeyPress={textareaKeyPressHandler}
                placeholder={`${translate("taskTracker.commonWords.enterTaskDescription")}...`}
                ref={descriptionRef}
              />
              <AccentButton
                label="Создать задачу"
                onClick={createNewTaskInWorkspaceButtonClickHandler}
                className={styles.createNewTaskButton}
              />
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export { CreateTaskModalWindow };
