import React, { useEffect, useRef, useState } from "react";
import styles from "./styles/dropDownButtonWithSearch.module.css";
import { ArrowIcon } from "../../../ReactSVGIcons/ArrowIcon";
import { Search } from "../../../Search/Search";

const DropDownButtonWithSearch = ({ items, onSelectOptionFunction, defaultLabel, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filteredItems, setFilteredItems] = useState(items);
  const [selectedItem, setSelectedItem] = useState(defaultLabel || items[0].label);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [workspaceIndex, setWorkspaceIndex] = useState(-1);

  const searchRef = useRef(null);

  const onSearchChangeHandler = (event) => {
    setFilteredItems(items.filter((item) => item.label.toLowerCase().includes(event.target.value.toLowerCase())));
    setWorkspaceIndex(-1);
  };

  useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  const onClickHandler = (event) => {
    if (!event.target.closest(`.${styles.container}`)) setIsOpen(false);
  };

  const onSelectHandler = (optionId) => {
    const foundedItem = items.find((item) => item.id === optionId).label;
    setSelectedItem(foundedItem);
    onSelectOptionFunction(foundedItem);
    setIsOpen(false);
    setFilteredItems(items);
  };
 
  const onKeyDownHandler = (event) => {
    if (event.key === "ArrowDown") {
      if (workspaceIndex === (filteredItems.length !== 0 ? filteredItems.length - 1 : items.length - 1)) {
        setWorkspaceIndex(0);
        setSelectedWorkspace(filteredItems.length !== 0 ? filteredItems[0].id : items[0].id);
      } else {
        setSelectedWorkspace(
          filteredItems.length !== 0 ? filteredItems[workspaceIndex + 1].id : items[workspaceIndex + 1].id
        );
        setWorkspaceIndex(workspaceIndex + 1);
      }
    }
    if (event.key === "ArrowUp") {
      if (workspaceIndex === 0) {
        setWorkspaceIndex(filteredItems.length !== 0 ? filteredItems.length - 1 : items.length - 1);
        setSelectedWorkspace(
          filteredItems.length !== 0 ? filteredItems[filteredItems.length - 1].id : items[items.length - 1].id
        );
      } else {
        setSelectedWorkspace(
          filteredItems.length !== 0 ? filteredItems[workspaceIndex - 1].id : items[workspaceIndex - 1].id
        );
        setWorkspaceIndex(workspaceIndex - 1);
      }
    }
    if (event.key === "Enter" && selectedWorkspace) {
      onSelectHandler(selectedWorkspace);
    }
  };

  useEffect(() => {
    if (isOpen) searchRef.current.focus();
  }, [isOpen]);

  useEffect(() => {
    document.addEventListener("click", onClickHandler);
    // document.addEventListener('keydown', onKeyDownHandler)
    return () => {
      document.removeEventListener("click", onClickHandler);
      // document.removeEventListener('keydown', onKeyDownHandler)
    };
  });

  return (
    <div className={`${styles.container} ${className}`} onKeyDown={onKeyDownHandler}>
      <button onClick={() => setIsOpen(!isOpen)}>
        <p>{selectedItem}</p>
        <ArrowIcon className={`${styles.arrow} ${isOpen ? styles.isOpen : ""}`} />
      </button>
      {isOpen && (
        <div className={styles.window}>
          <Search
            className={`${styles.searchBar}`}
            focusStyles={styles.focus}
            onChange={onSearchChangeHandler}
            ref={searchRef}
            // onChange={onChange}
            // value={value}
            // ref={ref}
          />
          <div className={styles.options}>
            {filteredItems.map((item) => (
              <div
                className={`${styles.item} ${item.id === selectedWorkspace ? styles.selected : ""}`}
                key={item.id}
                onClick={() => onSelectHandler(item.id)}
                title={item.label}
              >
                <p>{item.label}</p>
              </div>
            ))}
          </div>
          {filteredItems.length === 0 && <p className={styles.notFound}>Ничего не найдено</p>}
        </div>
      )}
    </div>
  );
};

export { DropDownButtonWithSearch };
