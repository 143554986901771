import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import modalWindowStyles from "../styles/ModalWindow.module.css";
import styles from "../styles/ModalWindow.module.css";
import { addResponseManually } from "../../../state/actions";
import NumberFormat from "react-number-format";
import { DropdownButton } from "../../Buttons/DropdownButton";
import { AccentButton } from "../../Buttons/AccentButton";

const SentResponseManually = ({ closeWindow }) => {
  const dispatch = useDispatch();

  const modalWindowOptionsState = useSelector((state) => state.modalWindow.options);
  const modalWindowState = useSelector((state) => state.modalWindow);
  const leadsInformation = useSelector((state) => state.leadsReducer);

  const [currency, setCurrency] = useState(3);
  const [isControl, setIsControl] = useState(false);
  const [daysValue, setDaysValue] = useState("");

  const descriptionRef = useRef(null);
  const costRef = useRef(null);
  const daysRef = useRef(null);

  const wrapperClickHandler = (event) => {
    if (event.target.className.includes(modalWindowStyles.wrapper) && modalWindowOptionsState.closeOnWrapperClick) {
      closeWindow();
    }
  };

  const currencyOptions = [];

  leadsInformation.leadCurrencies.map((currency) => currencyOptions.push({ id: currency.id, label: currency.symbol }));

  const onKeyDownHandler = (event) => {
    if (event.key === "Escape") closeWindow();
    if (event.key === "Control") setIsControl(true);
    if (event.key === "Enter" && isControl) {
      onAddClickHandler();
    }
  };

  const onKeyUpHandler = (event) => {
    if (event.key === "Control") setIsControl(false);
  };

  const onAddClickHandler = () => {
    dispatch(
      addResponseManually(
        leadsInformation.selectedLead,
        descriptionRef.current.value,
        costRef.current.state.numAsString,
        currency,
        daysValue
      )
    );
    // dispatch(addNewLead(headerRef.current.value, descriptionRef.current.value, costRef.current.state.numAsString, currency, topic))
    closeWindow();
  };

  const onPriceKeyHandler = (event) => {
    if (isNaN(event.key) || event.target.value.length > 10 || (event.target.value.length === 0 && event.key === "0"))
      event.preventDefault();
  };

  const onPasteHandler = (event) => {
    if (
      isNaN(event.clipboardData.getData("Text")) ||
      (event.target.value.length === 0 && event.clipboardData.getData("Text")[0] === "0")
    )
      event.preventDefault();
  };

  const onDaysChangeHandler = (event) => {
    setDaysValue(event.target.value);
  };

  const onKeyPressHandler = (event) => {
    if (isNaN(event.key) || event.target.value.length > 2 || (event.target.value.length === 0 && event.key === "0"))
      event.preventDefault();
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDownHandler);
    document.addEventListener("keyup", onKeyUpHandler);
    return () => {
      document.removeEventListener("keydown", onKeyDownHandler);
      document.removeEventListener("keyup", onKeyUpHandler);
    };
  });

  useEffect(() => {
    descriptionRef.current.focus();
  }, []);

  return (
    <div
      className={`${styles.wrapper} ${modalWindowState.showStatus ? "" : modalWindowStyles.hide}`}
      onClick={wrapperClickHandler}
    >
      <div className={`${styles.taskDeclineWindow} ${styles.modalWindowDefaultSize}`}>
        <div className={styles.modalDefaultHeader}>
          <h2>{modalWindowState.options.header}</h2>
          <div
            className={`${styles.closeButton} ${modalWindowState.options.closeHeaderButton ? "" : styles.hide}`}
            onClick={() => {
              closeWindow();
            }}
          >
            &times;
          </div>
        </div>
        <div className={styles.leadContainer}>
          <div className={styles.leadBody}>
            <div className={styles.row}>
              <div className={styles.row}>
                <p>Текст отклика</p>
                <textarea ref={descriptionRef} />
              </div>
            </div>
            <div className={styles.column}>
              <div className={styles.row}>
                <p>Сумма отклика</p>
                <div className={styles.cost}>
                  <NumberFormat
                    thousandSeparator={" "}
                    className={styles.optionsInput}
                    ref={costRef}
                    onKeyPress={onPriceKeyHandler}
                    onPaste={onPasteHandler}
                  />
                  <DropdownButton
                    className={`${styles.currencyButton} ${styles.currencyStyle}`}
                    customStylesToOptionsList={styles.currencyButtonOptionList}
                    customStylesToOptionsListElement={styles.currencyButtonOptionListElement}
                    onSelectOptionFunction={(symbol) =>
                      setCurrency(leadsInformation.leadCurrencies.find((currency) => currency.symbol === symbol).id)
                    }
                    editable={true}
                    dropDownMenuOptions={currencyOptions}
                    buttonLabel={leadsInformation.leadCurrencies.find((currencyL) => currencyL.id === currency).symbol}
                    defaultLabel={"₽"}
                    isFilter={true}
                  />
                </div>
              </div>
              <div className={styles.additionOptions}>
                <div className={styles.row}>
                  <p>Срок</p>
                  <input
                    className={styles.optionsInput}
                    type={"number"}
                    ref={daysRef}
                    min={1}
                    value={daysValue}
                    onChange={onDaysChangeHandler}
                    onKeyPress={onKeyPressHandler}
                    onPaste={onPasteHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.footer} ${modalWindowState.options.showOkButton ? "" : styles.hide}`}>
          <AccentButton
            label="Добавить"
            displayPlusSymbol={false}
            className={styles.okButton}
            onClick={onAddClickHandler}
          />
        </div>
      </div>
    </div>
  );
};

export { SentResponseManually };
