import styles from "./styles/Spinner.module.css";
import React from "react";

const Spinner = ({ type, className }) => {
  return (
    <div className={`${type === "global" ? styles.loader : styles.defaultLoader} ${className}`}>
      <div className={styles.spinner} />
    </div>
  );
};

export { Spinner };
