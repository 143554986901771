import {
  CHANGE_USER_FIELD_SUCCESS,
  CLEAR_SEARCH_USER_PARAMS,
  FETCH_USERS_FROM_COMPANY_FAILURE,
  FETCH_USERS_FROM_COMPANY_STARTED,
  FETCH_USERS_FROM_COMPANY_SUCCESS,
  SET_COMPANY_USERS_SEARCH_FILTER,
  SET_FILTER_TO_USERS_FROM_COMPANY,
  SET_FILTERED_USERS_FROM_COMPANY_BY_FIO,
  SET_SELECTED_USER,
  FILTER_USERS_LIST,
  CHANGE_USER_FIELD_FAILURE,
  CHANGE_USER_STATUS_SUCCESS,
  CHANGE_USER_STATUS_START,
  CHANGE_USER_STATUS_FAILURE,
  REGISTER_USER_SUCCESS,
  SORT_USERS_LIST
} from "../../actions/actionTypes";

const initialState = {
  loading: true,
  error: null,
  users: [],
  filteredUsers: [],
  filterRequest: "",
  searchFilter: "",
  selectedUser: null,
  statusChanged: true
};

function usersReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USERS_FROM_COMPANY_STARTED: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case FETCH_USERS_FROM_COMPANY_SUCCESS: {
      const sortedUsersByName = action.users.sort((a, b) => (a.user_last_name > b.user_last_name ? 1 : -1));
      const activeUsers = sortedUsersByName.filter((user) => user.user_status === "ACTIVE");
      const deletedUsers = sortedUsersByName.filter((user) => user.user_status === "DELETED");
      const sortedUsersByStatus = [...activeUsers, ...deletedUsers];
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        users: activeUsers,
        filteredUsers: activeUsers.filter((user) => {
          if (state.filteredUsers.length) {
            return state.filteredUsers.find((filteredUser) => {
              return user.user_id === filteredUser.user_id;
            });
          } else {
            return true;
          }
        }),
        allUsers: sortedUsersByStatus
      };
    }
    case FETCH_USERS_FROM_COMPANY_FAILURE: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case SET_FILTER_TO_USERS_FROM_COMPANY: {
      return {
        ...state,
        filterRequest: action.filterRequest.toLowerCase()
      };
    }
    case SET_FILTERED_USERS_FROM_COMPANY_BY_FIO: {
      return {
        ...state,
        filteredUsers: action.filteredUsers
      };
    }
    case SET_COMPANY_USERS_SEARCH_FILTER: {
      const filter = action.searchFilter.trim().toLowerCase();
      return {
        ...state,
        searchFilter: filter,
        filteredUsers: state.users
          .filter((user) =>
            `${user.user_last_name} ${user.user_last_name} ${user.user_first_name ? user.user_first_name : ""}`
              .trim()
              .toLowerCase()
              .includes(filter)
          )
          .sort((a, b) => (a.user_last_name > b.user_last_name ? 1 : -1))
      };
    }
    case CLEAR_SEARCH_USER_PARAMS: {
      return {
        ...state,
        filteredUsers: [],
        searchFilter: ""
      };
    }
    case SET_SELECTED_USER: {
      return {
        ...state,
        selectedUser: action.userId
      };
    }

    case REGISTER_USER_SUCCESS: {
      return {
        ...state,
        users: [action.data, ...state.users],
        filteredUsers: [action.data, ...state.filteredUsers]
      };
    }

    case CHANGE_USER_FIELD_SUCCESS: {
      Object.assign(
        state.filteredUsers.find((user) => user.user_id === action.userId),
        action.data
      );

      return {
        ...state
      };
    }

    case CHANGE_USER_FIELD_FAILURE: {
      return {
        ...state,
        error: action.error
      };
    }

    case CHANGE_USER_STATUS_START: {
      return {
        ...state,
        statusChanged: action.statusChanged
      };
    }

    case CHANGE_USER_STATUS_SUCCESS: {
      return {
        ...state,
        statusChanged: action.statusChanged
      };
    }

    case CHANGE_USER_STATUS_FAILURE: {
      return {
        ...state,
        statusChanged: action.statusChanged
      };
    }

    case SORT_USERS_LIST: {
      return {
        ...state,
        users: action.users,
        filteredUser: action.users
      };
    }

    case FILTER_USERS_LIST: {
      return {
        ...state,
        filteredUsers: action.users
      };
    }

    default:
      return state;
  }
}

export default usersReducer;
