import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Avatar } from "../../../../../components/Avatars/Avatar";
import styles from "./styles/PeoplesContainer.module.css";
import { useDispatch, useSelector } from "react-redux";
import { RoleUserChanger } from "../../Components/RoleUserChanger";
import {
  unsetImplementerToTask,
  unsetInitiatorToTask,
  unsetVerificatorToTask,
  clearSearchUserParams,
  unsetImplementerToTaskUserTasks,
  unsetVerificatorToTaskUserTasks
} from "../../../../../state/actions";
import { ArrowIcon } from "../../../../../components/ReactSVGIcons/ArrowIcon";
import { CloseIcon } from "../../../../../components/ReactSVGIcons/CloseIcon";
import { RoleUserChangerUserTasks } from "../../../../UserTasks/RoleUserChangerUserTasks";
import { useTranslation } from "react-multi-lang";

const PeoplesContainer = React.memo(
  ({
    peoples,
    className,
    taskId,
    disabled,
    displayState,
    taskStatus,
    permissions,
    isCalledFromUserTasks,
    newCardSetImplementer
  }) => {
    const companyUsersState = useSelector((state) => state.companyUsersReducer);

    const [showTaskRoleUserChanger, setShowTaskRoleUserChanger] = useState(false);
    const [optionsToTaskRoleUserChanger, setOptionsTaskRoleUserChanger] = useState({
      type: "changer",
      userType: "implementer"
    });

    const dispatch = useDispatch();

    const translate = useTranslation();

    const userTasksState = useSelector((state) => state.userTasksReducer);

    const initiator =
      !isCalledFromUserTasks && peoples.initiatorUserId
        ? companyUsersState.users.find((user) => user.user_id === peoples.initiatorUserId)
        : isCalledFromUserTasks
        ? userTasksState.users.find((user) => user.user_id === peoples.initiatorUserId)
        : null;

    const implementer =
      !isCalledFromUserTasks && peoples.implementerUserId
        ? companyUsersState.users.find((user) => user.user_id === peoples.implementerUserId)
        : isCalledFromUserTasks
        ? userTasksState.users.find((user) => user.user_id === peoples.implementerUserId)
        : null;

    const verificator =
      !isCalledFromUserTasks && peoples.verificatorUserId
        ? companyUsersState.users.find((user) => user.user_id === peoples.verificatorUserId)
        : isCalledFromUserTasks
        ? userTasksState.users.find((user) => user.user_id === peoples.verificatorUserId)
        : null;

    const unsetInitiatorUserButtonClickHandler = (event) => {
      isCalledFromUserTasks
        ? dispatch(unsetVerificatorToTaskUserTasks(taskId))
        : dispatch(unsetInitiatorToTask(taskId));
    };

    const unsetVerificatorUserButtonClickHandler = (event) => {
      isCalledFromUserTasks
        ? dispatch(unsetVerificatorToTaskUserTasks(taskId))
        : dispatch(unsetVerificatorToTask(taskId));
    };

    const unsetImplementerUserButtonClickHandler = (event) => {
      isCalledFromUserTasks
        ? dispatch(unsetImplementerToTaskUserTasks(taskId))
        : dispatch(unsetImplementerToTask(taskId));
    };

    const setTaskRoleUserChanger = (type, userType) => {
      setShowTaskRoleUserChanger(!disabled);
      setOptionsTaskRoleUserChanger({ type: type, userType: userType });
    };

    const onClickHandler = (event) => {
      if (!displayState)
        if (!event.target.closest(`.${styles.roleUserChanger}`) && showTaskRoleUserChanger) {
          setShowTaskRoleUserChanger(false);
          dispatch(clearSearchUserParams());
        }
      if (displayState)
        if (!event.target.closest(`.${styles.roleUserChangerGrid}`) && showTaskRoleUserChanger) {
          setShowTaskRoleUserChanger(false);
          dispatch(clearSearchUserParams());
        }
    };

    useEffect(() => {
      document.addEventListener("click", onClickHandler);
      return () => {
        document.removeEventListener("click", onClickHandler);
      };
    });

    useEffect(() => {
      if (newCardSetImplementer) {
        // eslint-disable-next-line
            {
          setTaskRoleUserChanger(implementer ? "changer" : "adder", "implementer");
        }
      }
      // eslint-disable-next-line
    }, [newCardSetImplementer])

    if (!displayState) {
      return (
        <div className={`${styles.container} ${className}`}>
          {permissions.initiator.can_read === 1 && (
            <Avatar
              type="rounded"
              className={`${permissions.initiator.can_edit === 1 ? styles.avatarEditable : styles.avatar} ${
                initiator ? "" : styles.notChanged
              } ${disabled ? styles.disabled : ""}`}
              alt={
                initiator
                  ? `${initiator.user_last_name} ${initiator.user_first_name} ${
                      initiator.user_middle_name ? initiator.user_middle_name : ""
                    }`.trim()
                  : "Добавить постановщика"
              }
              src={initiator ? initiator.user_photo : ""}
              onClick={
                permissions.initiator.can_edit === 1
                  ? () => {
                      if (!disabled) {
                        setTaskRoleUserChanger(initiator ? "changer" : "adder", "initiator");
                      }
                    }
                  : undefined
              }
            />
          )}
          {initiator && permissions.initiator.can_delete === 1 && !disabled ? (
            <button
              className={`${styles.deleteUserFromTaskButton} ${styles.initiator}`}
              onClick={unsetInitiatorUserButtonClickHandler}
            >
              <CloseIcon className={styles.closeIcon} />
            </button>
          ) : (
            ""
          )}
          <ArrowIcon className={styles.newArrow} />
          {permissions.verificator.can_read === 1 && (
            <Avatar
              type="rounded"
              className={`${permissions.verificator.can_edit === 1 ? styles.avatarEditable : styles.avatar} ${
                verificator ? "" : styles.notChanged
              } ${disabled ? styles.disabled : ""}`}
              alt={
                verificator
                  ? `${verificator.user_last_name} ${verificator.user_first_name} ${
                      verificator.user_middle_name ? verificator.user_middle_name : ""
                    }`.trim()
                  : "Добавить верификатора"
              }
              src={verificator ? verificator.user_photo : ""}
              onClick={
                permissions.verificator.can_edit === 1
                  ? () => {
                      if (!disabled) {
                        setTaskRoleUserChanger(verificator ? "changer" : "adder", "verificator");
                      }
                    }
                  : undefined
              }
            />
          )}
          {verificator && permissions.verificator.can_delete && !disabled ? (
            <button
              className={`${styles.deleteUserFromTaskButton} ${styles.verificator}`}
              onClick={unsetVerificatorUserButtonClickHandler}
            >
              <CloseIcon className={styles.closeIcon} />
            </button>
          ) : (
            ""
          )}
          <ArrowIcon className={styles.newArrow} />
          {permissions.implementer.can_read === 1 && (
            <Avatar
              type="rounded"
              className={`${permissions.implementer.can_edit === 1 ? styles.avatarEditable : styles.avatar} ${
                implementer ? "" : styles.notChanged
              } ${disabled ? styles.disabled : ""}`}
              alt={
                implementer
                  ? `${implementer.user_last_name} ${implementer.user_first_name} ${
                      implementer.user_middle_name ? implementer.user_middle_name : ""
                    }`.trim()
                  : "Добавить исполнителя"
              }
              src={implementer ? implementer.user_photo : ""}
              style={{ marginRight: 0 }}
              onClick={
                permissions.implementer.can_edit === 1
                  ? () => {
                      if (!disabled) {
                        setTaskRoleUserChanger(implementer ? "changer" : "adder", "implementer");
                      }
                    }
                  : undefined
              }
            />
          )}
          {implementer && permissions.implementer.can_delete === 1 && !disabled ? (
            <button
              className={`${styles.deleteUserFromTaskButton} ${styles.implementer}`}
              onClick={unsetImplementerUserButtonClickHandler}
            >
              <CloseIcon className={styles.closeIcon} />
            </button>
          ) : (
            ""
          )}
          {showTaskRoleUserChanger && !disabled && (
            <RoleUserChanger
              className={`${styles.roleUserChanger} ${showTaskRoleUserChanger ? "" : styles.hide} ${
                optionsToTaskRoleUserChanger.userType === "initiator"
                  ? styles.initiator
                  : optionsToTaskRoleUserChanger.userType === "verificator"
                  ? styles.verificator
                  : optionsToTaskRoleUserChanger.userType === "implementer"
                  ? styles.implementer
                  : ""
              }`}
              type={optionsToTaskRoleUserChanger.type}
              userType={optionsToTaskRoleUserChanger.userType}
              closeWindowFunction={() => {
                setShowTaskRoleUserChanger(false);
              }}
              taskId={taskId}
            />
          )}
        </div>
      );
    } else if (displayState) {
      return (
        <>
          <div
            className={styles.taskColumns}
            onClick={
              permissions.initiator.can_edit === 1
                ? () => {
                    if (!disabled) {
                      setTaskRoleUserChanger(initiator ? "changer" : "adder", "initiator");
                    }
                  }
                : undefined
            }
          >
            {permissions.initiator.can_read === 1 && (
              <Avatar
                type={initiator ? "square" : "unset"}
                className={`${initiator ? styles.avatarList : styles.emptyAvatarList} ${
                  disabled || permissions.initiator.can_edit === 0 ? styles.disabled : ""
                }`}
                alt={
                  initiator
                    ? `${initiator.user_last_name} ${initiator.user_first_name} ${
                        initiator.user_middle_name ? initiator.user_middle_name : ""
                      }`.trim()
                    : permissions.initiator.can_edit === 1
                    ? "Добавить постановщика"
                    : "Нет прав на добавление постановщика"
                }
                src={initiator ? initiator.user_photo : ""}
              />
            )}
            <p
              style={permissions.initiator.can_edit === 0 ? { cursor: "default" } : {}}
              className={!initiator ? styles.noUser : styles.name}
              title={
                initiator
                  ? `${initiator.user_last_name} ${initiator.user_first_name}`.trim()
                  : translate("taskTracker.commonWords.notAssigned")
              }
            >
              {initiator && permissions.initiator.can_read === 1
                ? `${initiator.user_last_name} ${initiator.user_first_name[0]}.`.trim()
                : permissions.initiator.can_edit === 1
                ? translate("taskTracker.commonWords.assign")
                : translate("taskTracker.commonWords.notAssigned")}
            </p>
            {initiator && permissions.initiator.can_delete === 1 && (
              <button
                className={`${styles.deleteUserFromTaskButton} ${styles.initiator}`}
                onClick={unsetInitiatorUserButtonClickHandler}
              >
                <CloseIcon className={styles.closeIcon} />
              </button>
            )}
          </div>
          <div
            className={styles.taskColumns}
            onClick={
              permissions.verificator.can_edit === 1
                ? () => {
                    if (!disabled) {
                      setTaskRoleUserChanger(verificator ? "changer" : "adder", "verificator");
                    }
                  }
                : undefined
            }
          >
            {permissions.verificator.can_read === 1 && (
              <Avatar
                type={verificator ? "square" : "unset"}
                className={`${verificator ? styles.avatarList : styles.emptyAvatarList} ${
                  disabled || permissions.verificator.can_edit === 0 ? styles.disabled : ""
                }`}
                alt={
                  verificator
                    ? `${verificator.user_last_name} ${verificator.user_first_name} ${
                        verificator.user_middle_name ? verificator.user_middle_name : ""
                      }`.trim()
                    : permissions.verificator.can_edit === 1
                    ? "Добавить верификатора"
                    : "Нет прав на добавление верификатора"
                }
                src={verificator ? verificator.user_photo : ""}
              />
            )}
            <p
              style={permissions.verificator.can_edit === 0 ? { cursor: "default" } : {}}
              className={!verificator ? styles.noUser : styles.name}
              title={
                verificator
                  ? `${verificator.user_last_name} ${verificator.user_first_name}`.trim()
                  : translate("taskTracker.commonWords.notAssigned")
              }
            >
              {verificator && permissions.verificator.can_read === 1
                ? `${verificator.user_last_name} ${verificator.user_first_name[0]}.`.trim()
                : permissions.verificator.can_edit === 1
                ? translate("taskTracker.commonWords.assign")
                : translate("taskTracker.commonWords.notAssigned")}
            </p>
            {verificator && permissions.verificator.can_delete === 1 ? (
              <button
                className={`${styles.deleteUserFromTaskButton} ${styles.grid}`}
                onClick={unsetVerificatorUserButtonClickHandler}
              >
                <CloseIcon className={styles.closeIcon} />
              </button>
            ) : (
              ""
            )}
          </div>
          <div
            className={styles.taskColumns}
            onClick={
              permissions.implementer.can_edit === 1
                ? () => {
                    if (!disabled) {
                      setTaskRoleUserChanger(implementer ? "changer" : "adder", "implementer");
                    }
                  }
                : undefined
            }
          >
            <Avatar
              type={implementer ? "square" : "unset"}
              className={`${implementer ? styles.avatarList : styles.emptyAvatarList} ${
                disabled || permissions.implementer.can_edit === 0 ? styles.disabled : ""
              }`}
              alt={
                implementer
                  ? `${implementer.user_last_name} ${implementer.user_first_name} ${
                      implementer.user_middle_name ? implementer.user_middle_name : ""
                    }`.trim()
                  : permissions.implementer.can_edit === 1
                  ? "Добавить исполнителя"
                  : "Нет прав на добавление исполнителя"
              }
              src={implementer ? implementer.user_photo : ""}
            />
            <p
              style={permissions.implementer.can_edit === 0 ? { cursor: "default" } : {}}
              className={!implementer ? styles.noUser : styles.name}
              title={
                implementer
                  ? `${implementer.user_last_name} ${implementer.user_first_name}`.trim()
                  : translate("taskTracker.commonWords.notAssigned")
              }
            >
              {implementer && permissions.implementer.can_read === 1
                ? `${implementer.user_last_name} ${implementer.user_first_name[0]}.`.trim()
                : permissions.implementer.can_edit === 1
                ? translate("taskTracker.commonWords.assign")
                : translate("taskTracker.commonWords.notAssigned")}
            </p>
            {implementer && permissions.implementer.can_delete === 1 && (
              <button
                className={`${styles.deleteUserFromTaskButton} ${styles.grid}`}
                onClick={unsetImplementerUserButtonClickHandler}
              >
                <CloseIcon className={styles.closeIcon} />
              </button>
            )}
          </div>
          {showTaskRoleUserChanger && !disabled && !isCalledFromUserTasks && (
            <RoleUserChanger
              className={`${styles.roleUserChangerGrid} ${showTaskRoleUserChanger ? "" : styles.hide} ${
                optionsToTaskRoleUserChanger.userType === "initiator"
                  ? styles.initiator
                  : optionsToTaskRoleUserChanger.userType === "verificator"
                  ? styles.verificator
                  : optionsToTaskRoleUserChanger.userType === "implementer"
                  ? styles.implementer
                  : ""
              }`}
              type={optionsToTaskRoleUserChanger.type}
              userType={optionsToTaskRoleUserChanger.userType}
              closeWindowFunction={() => {
                setShowTaskRoleUserChanger(false);
              }}
              taskId={taskId}
            />
          )}{" "}
          {showTaskRoleUserChanger && !disabled && isCalledFromUserTasks && (
            <RoleUserChangerUserTasks
              type={optionsToTaskRoleUserChanger.type}
              userType={optionsToTaskRoleUserChanger.userType}
              className={`${styles.roleUserChangerGrid} ${showTaskRoleUserChanger ? "" : styles.hide} ${
                optionsToTaskRoleUserChanger.userType === "initiator"
                  ? styles.initiator
                  : optionsToTaskRoleUserChanger.userType === "verificator"
                  ? styles.verificator
                  : optionsToTaskRoleUserChanger.userType === "implementer"
                  ? styles.implementer
                  : ""
              }`}
              closeWindowFunction={() => {
                setShowTaskRoleUserChanger(false);
              }}
              taskId={taskId}
              displayState={true}
            />
          )}
        </>
      );
    }
  }
);

PeoplesContainer.propTypes = {
  peoples: PropTypes.shape({
    initiatorUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    verificatorUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    implementerUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

PeoplesContainer.defaultProps = {
  className: "",
  disabled: false
};

export { PeoplesContainer };
