import React, { useState, useEffect } from "react";
import styles from "./styles/Calendar.module.css";
import leftArrow from "../../assets/icons/left-arrow.svg";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import { getLanguage } from "react-multi-lang";

const { datesGenerator } = require("dates-generator");

const Calendar = ({ value, pickedPreviousDate, className, setOpenCalendar, isCalledFromModal }) => {
  const months =
    getLanguage() === "ru"
      ? [
          "Январь",
          "Февраль",
          "Март",
          "Апрель",
          "Май",
          "Июнь",
          "Июль",
          "Август",
          "Сентябрь",
          "Октябрь",
          "Ноябрь",
          "Декабрь"
        ]
      : [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ];
  const days =
    getLanguage() === "ru" ? ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"] : ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];

  const [dates, setDates] = useState([]);
  const [calendar, setCalendar] = useState({
    month: pickedPreviousDate ? Number(pickedPreviousDate.split(".")[1]) - 1 : new Date().getMonth(),
    year: pickedPreviousDate ? Number(pickedPreviousDate.split(".")[2]) : new Date().getFullYear(),
    startingDay: 1
  });
  const [pickedDate, setPickedDate] = useState(
    pickedPreviousDate
      ? {
          year: Number(pickedPreviousDate.split(".")[2]),
          month: Number(pickedPreviousDate.split(".")[1]),
          date:
            pickedPreviousDate.split(".")[0][0] === "0"
              ? Number(pickedPreviousDate.split(".")[0][1])
              : Number(pickedPreviousDate.split(".")[0])
        }
      : null
  );
  const [calendarIsOpened, setCalendarIsOpened] = useState(false);

  const nowDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    date: new Date().getDate()
  };

  useEffect(() => {
    const body = {
      month: calendar.month,
      year: calendar.year,
      startingDay: 1
    };
    const { dates, nextMonth, nextYear, previousMonth, previousYear } = datesGenerator(body);

    setDates([...dates]);

    setCalendar({
      ...calendar,
      startingDay: 1,
      nextMonth,
      nextYear,
      previousMonth,
      previousYear
    });
    setCalendarIsOpened(true);
    // eslint-disable-next-line
    }, [])

  const onClickNext = () => {
    const body = { month: calendar.nextMonth, year: calendar.nextYear, startingDay: 1 };
    const { dates, nextMonth, nextYear, previousMonth, previousYear } = datesGenerator(body);

    setDates([...dates]);
    setCalendar({
      ...calendar,
      month: calendar.nextMonth,
      year: calendar.nextYear,
      startingDay: 1,
      nextMonth,
      nextYear,
      previousMonth,
      previousYear
    });
  };

  const onClickPrevious = () => {
    const body = { month: calendar.previousMonth, year: calendar.previousYear, startingDay: 1 };
    const { dates, nextMonth, nextYear, previousMonth, previousYear } = datesGenerator(body);

    setDates([...dates]);
    setCalendar({
      ...calendar,
      month: calendar.previousMonth,
      year: calendar.previousYear,
      startingDay: 1,
      nextMonth,
      nextYear,
      previousMonth,
      previousYear
    });
  };

  const onSelectDate = (date) => {
    setPickedDate(date);
    const timeZoneOffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    let preparedDate = new Date(new Date(date.year, date.month, date.date) - timeZoneOffset).toISOString().slice(0, -1);
    value((preparedDate += "Z"));
  };

  const onClickHandlerToCalendarOptions = (event) => {
    if (!event.target.closest(`.${styles.header}`)) {
      setCalendarIsOpened(false);
      setOpenCalendar(false);
    }
  };

  const calendarKeyPressHandler = (event) => {
    if (event.key === "Escape") setOpenCalendar(false);
  };

  useEffect(() => {
    document.addEventListener("keydown", calendarKeyPressHandler);
    return () => {
      document.removeEventListener("keydown", calendarKeyPressHandler);
    };
  });

  useEffect(() => {
    document.addEventListener("click", onClickHandlerToCalendarOptions);
    return () => {
      document.removeEventListener("click", onClickHandlerToCalendarOptions);
    };
  });

  return (
    <CSSTransition
      in={calendarIsOpened}
      timeout={150}
      classNames={{
        enterActive: isCalledFromModal ? styles.modalShowingModal : styles.modalShowing,
        enterDone: isCalledFromModal ? styles.modalShowModal : styles.modalShow,
        exitActive: styles.modalClosing,
        exitDone: styles.modalClose
      }}
    >
      <div className={`${isCalledFromModal ? styles.calendarModal : styles.calendar} ${className}`}>
        <div className={styles.container}>
          <div className={styles.header}>
            <div onClick={onClickPrevious} style={{ paddingRight: "20px" }} className={styles.arrow}>
              <img className={styles.leftArrow} src={leftArrow} alt={""} />
            </div>
            <div className={styles.monthText}>
              {/*<p>{calendar.year}</p>*/}
              {months[calendar.month]}
            </div>
            <div onClick={onClickNext} style={{ paddingLeft: "20px" }} className={styles.arrow}>
              <img className={styles.rightArrow} src={leftArrow} alt={""} />
            </div>
          </div>
          <div>
            <div className={styles.body}>
              <div className={styles.days}>
                {days.map((day) => (
                  <p>{day}</p>
                ))}
              </div>
              {dates.length > 0 &&
                dates.map((week) => (
                  <div className={styles.date}>
                    {week.map((each) => (
                      <p
                        onClick={() => onSelectDate(each)}
                        className={
                          each.month !== calendar.month
                            ? styles.hide
                            : pickedDate &&
                              pickedDate.date === each.date &&
                              pickedDate.year === each.year &&
                              pickedDate.month - 1 === each.month
                            ? styles.selected
                            : each.month === nowDate.month - 1 &&
                              each.year === nowDate.year &&
                              each.date === nowDate.date
                            ? styles.nowDate
                            : styles.show
                        }
                      >
                        {each.date}
                      </p>
                    ))}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

Calendar.propTypes = {
  value: PropTypes.string
};

export default Calendar;
