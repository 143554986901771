function replacer(search, replace) {
  let sel = window.getSelection();
  if (!sel.focusNode) {
    return;
  }
  let startIndex = sel.focusNode.nodeValue.indexOf(search);
  let endIndex = startIndex + search.length;
  if (startIndex === -1) {
    return;
  }
  let range = document.createRange();
  range.setStart(sel.focusNode, startIndex);
  range.setEnd(sel.focusNode, endIndex);
  range.deleteContents();
  let replacement = document.createTextNode(replace);
  range.insertNode(replacement);
  range.setStartAfter(replacement);
  sel.removeAllRanges();
  sel.addRange(range);
}

export { replacer };
