function saveToLocalStorage(dataName, data, logger = false) {
  try {
    localStorage.setItem(dataName, JSON.stringify(data));
  } catch (error) {
    if (error === "QUOTA_EXCEEDED_ERR") {
      return new Error("Error on save data into local storage. Local storage memory is overflow");
    }
  }
  return logger ? console.log(`${dataName} set to locale storage`) : "";
}

function getDataFromLocalStorage(dataName) {
  const data = localStorage.getItem(dataName);
  if (data === null) {
    return new Error("data not found in local storage");
  }
  return JSON.parse(data);
}

function removeItemFromLocalStorage(dataName) {
  localStorage.removeItem(dataName);
  return console.log(`${dataName} remove from locale storage`);
}

export { saveToLocalStorage, getDataFromLocalStorage, removeItemFromLocalStorage };
