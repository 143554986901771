import {
  ADD_ESTIMATE_LINK_TO_LEAD_SUCCESS,
  ADD_KP_LINK_TO_LEAD_SUCCESS,
  ADD_NEW_LEAD_SUCCESS,
  ADD_RESPONSE_MANUALLY_SUCCESS,
  CHANGE_LEAD_USER_START,
  CHANGE_LEADS_DISPLAY_STATE,
  CHANGE_LEADS_FILTER_OPTION,
  CHANGE_LEADS_FILTER_OPTION_FUNNEL,
  CLEAR_FETCHER_MESSAGES_IN_LEAD,
  FETCH_DIALOGS_OF_SELECTED_LEAD_START,
  FETCH_MESSAGES_IN_DIALOG_OF_SELECTED_LEAD_START,
  FETCH_MESSAGES_IN_DIALOG_OF_SELECTED_LEAD_SUCCESS,
  GET_ALL_LEADS_DIALOGS_START,
  GET_ALL_LEADS_DIALOGS_SUCCESS,
  GET_AVAILABLE_LEADS_START,
  GET_AVAILABLE_LEADS_SUCCESS,
  GET_INFO_ABOUT_LEAD_CURRENCIES,
  GET_INFO_ABOUT_LEAD_SOURCES,
  GET_INFO_ABOUT_LEAD_STATUSES,
  GET_INFO_ABOUT_LEAD_TOPICS,
  GET_INFO_ABOUT_MESSAGES_STATUSES,
  GET_INFO_ABOUT_UNREAD_DIALOGS,
  GET_LEAD_INFORMATION_SUCCESS,
  GET_LEAD_REPLY_SUCCESS,
  GET_LEAD_RESPONSE_SUCCESS,
  GET_LEADS_ROBOTS_STATUSES_SUCCESS,
  GET_MESSAGES_OF_LEAD_START,
  GET_MESSAGES_OF_LEAD_SUCCESS,
  LOAD_AVAILABLE_LEADS_STARTED,
  REMOVE_LEADS_LOADING_STATE,
  RESEND_MESSAGE_TO_DIALOG_FAILURE,
  RESEND_MESSAGE_TO_DIALOG_START,
  RESEND_RESPONSE_TO_LEAD_FAILURE,
  RESEND_RESPONSE_TO_LEAD_START,
  RESET_LEADS_FILTERS,
  RESET_LEADS_IN_CATEGORY,
  RESET_WORKSPACE_ID,
  SAVE_DATA_ABOUT_LEAD,
  SEARCH_LEADS_BY_FILTER,
  SEND_MESSAGE_TO_DIALOG_START,
  SEND_MESSAGE_TO_DIALOG_SUCCESS,
  SEND_MESSAGE_TO_LEAD_DIALOG_SUCCESS,
  SET_LEAD_SOURCE_FAILURE,
  SET_LEAD_SOURCE_START,
  SET_LEAD_STATUS_FAILURE,
  SET_LEAD_STATUS_START,
  SET_LEAD_TOPIC_FAILURE,
  SET_LEAD_TOPIC_START,
  SET_MESSAGES_TIMER,
  SET_SELECTED_DIALOG,
  SET_SELECTED_LEAD,
  SHOW_LEAD_MODAL_WINDOW,
  UPDATE_FILTERED_MESSAGES,
  UPDATE_LEADS
} from "../../actions/actionTypes";

const initialState = {
  displayState: false,
  leads: [
    { status: 1, next_page: null, isUpdating: true, leads: [] },
    { status: 4, next_page: null, isUpdating: true, leads: [] },
    { status: 7, next_page: null, isUpdating: true, leads: [] },
    { status: 8, next_page: null, isUpdating: true, leads: [] },
    { status: 9, next_page: null, isUpdating: true, leads: [] },
    { status: 10, next_page: null, isUpdating: true, leads: [] }
  ],
  filteredUsers: [],
  searchFilter: "",
  selectedLead: null,
  selectedDialog: null,
  loading: {
    LEADS_LOADING: true,
    MESSAGES_LOADING: false,
    LEADS_UPDATE: false,
    MESSAGES_UPDATING: false,
    FETCHED_MESSAGES_LOADING: false,
    DIALOGS_LOADING: false
  },
  leadSources: [],
  leadStatuses: [],
  leadTopics: [],
  leadCurrencies: [],
  leadMessagesStatuses: [],
  response: null,
  messages: [],
  dialogs: [],
  filteredDialogs: [],
  sortFilter: {
    leadStatus: 0,
    leadSource: 0,
    leadSize: 0,
    leadPeriod: 0,
    leadResponsive: null,
    leadReplier: null,
    leadTopics: []
  },
  sortFilterFunnel: {
    leadStatus: 0,
    leadSource: 0,
    leadSize: 0,
    leadPeriod: 0,
    leadResponsive: null,
    leadReplier: null,
    leadTopics: []
  },
  savedResponses: [],
  information: {
    currentPage: "",
    previousPage: "",
    nextPage: "",
    nextDialogPage: ""
  },
  fetchedSelectedLead: null,
  leadsSavedInfo: [],
  fetchedLeadMessages: {
    next_page: 1,
    messages: []
  },
  isNewMessage: false,
  unreadMessagesCount: null,
  messagesTimer: false,
  robotsStatusesInfo: []
};

function leadsReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LEADS_DISPLAY_STATE: {
      return {
        ...state,
        displayState: action.displayState
      };
    }

    case SHOW_LEAD_MODAL_WINDOW: {
      return {
        ...state,
        showModal: action.showModal
      };
    }

    case GET_AVAILABLE_LEADS_START: {
      const newLoadingState = state.loading;
      newLoadingState.LEADS_LOADING = true;

      return {
        ...state,
        loading: newLoadingState
      };
    }

    case LOAD_AVAILABLE_LEADS_STARTED: {
      state.leads.find((lead) => lead.status === action.status).isUpdating = true;
      return {
        ...state
      };
    }

    case GET_AVAILABLE_LEADS_SUCCESS: {
      const status =
        action.status > 1 && action.status <= 3 ? 1 : action.status >= 4 && action.status <= 6 ? 4 : action.status;

      let newLeadsState = state.leads.find((lead) => lead.status === status).leads;
      // eslint-disable-next-line
            action.leads.map((lead)=> {
        if (!state.leads.find((lead) => lead.status === status).leads.find((leadState) => leadState.id === lead.id))
          newLeadsState.push(lead);
        else
          Object.assign(
            state.leads.find((lead) => lead.status === status).leads.find((leadState) => leadState.id === lead.id),
            lead
          );
      });

      newLeadsState = state.leads.map((lead) => {
        if (lead.status === status) {
          lead.leads = newLeadsState;
          lead.next_page = action.next_page;
        }
        return lead;
      });

      const newLoadingState = state.loading;
      newLoadingState.LEADS_UPDATE = false;

      state.leads.find((lead) => lead.status === status).isUpdating = false;

      return {
        ...state,
        leads: newLeadsState,
        loading: newLoadingState
      };
    }

    case SET_SELECTED_LEAD: {
      return {
        ...state,
        selectedLead: action.selectedLead
      };
    }

    case GET_INFO_ABOUT_LEAD_SOURCES: {
      return {
        ...state,
        leadSources: action.leadSources
      };
    }

    case GET_INFO_ABOUT_LEAD_STATUSES: {
      return {
        ...state,
        leadStatuses: action.leadStatuses
      };
    }

    case GET_INFO_ABOUT_LEAD_TOPICS: {
      return {
        ...state,
        leadTopics: action.leadTopics
      };
    }

    case GET_INFO_ABOUT_LEAD_CURRENCIES: {
      return {
        ...state,
        leadCurrencies: action.leadCurrencies
      };
    }

    case GET_INFO_ABOUT_MESSAGES_STATUSES: {
      return {
        ...state,
        leadMessagesStatuses: action.messageStatuses
      };
    }

    case REMOVE_LEADS_LOADING_STATE: {
      const newLoadingState = state.loading;
      newLoadingState.LEADS_LOADING = false;
      return {
        ...state,
        loading: newLoadingState
      };
    }

    case SET_LEAD_SOURCE_START: {
      // const newLeadState = state.leads.find((status)=>status.status===action.status).leads
      // newLeadState.find((lead) => lead.id === action.leadId).source = action.sourceId

      state.leads
        .find((status) => status.status === action.status)
        .leads.find((lead) => lead.id === action.leadId).source = action.sourceId;

      return {
        ...state
        // leads: newLeadState,
      };
    }

    case SET_LEAD_SOURCE_FAILURE: {
      state.leads
        .find((status) => status.status === action.status)
        .leads.find((lead) => lead.id === action.leadId).source = action.previousSourceId;

      return {
        ...state
        // leads: newLeadState,
      };
    }

    case SET_LEAD_TOPIC_START: {
      // const newLeadState = state.leads.find((status)=>status.status===action.status).leads
      // newLeadState.find((lead) => lead.id === action.leadId).topic = action.topicId

      state.leads
        .find((status) => status.status === action.status)
        .leads.find((lead) => lead.id === action.leadId).topic = action.topicId;
      return {
        ...state
        // requestsLeads: newLeadState,
      };
    }

    case SET_LEAD_TOPIC_FAILURE: {
      // const newLeadState = state.leads.find((status)=>status.status===action.status).leads
      // newLeadState.find((lead) => lead.id === action.leadId).topic = action.previousTopicId

      state.leads
        .find((status) => status.status === action.status)
        .leads.find((lead) => lead.id === action.leadId).topic = action.previousTopicId;

      return {
        ...state
        // requestsLeads: newLeadState,
      };
    }

    case SET_LEAD_STATUS_START: {
      const lead = state.leads
        .find((status) => status.status === action.status)
        .leads.find((lead) => lead.id === action.leadId);

      lead.status = action.statusId;

      if (
        (action.status === 1 && action.statusId === 4) ||
        (action.status === 4 && action.statusId > 6) ||
        action.status > 6
      ) {
        if (
          state.leads.find((status) => status.status === action.status).leads.find((lead) => lead.id === action.leadId)
        )
          state.leads
            .find((status) => status.status === action.status)
            .leads.splice(
              state.leads
                .find((status) => status.status === action.status)
                .leads.findIndex((lead) => lead.id === action.leadId),
              1
            );
        state.leads.find((status) => status.status === action.statusId).leads.push(lead);
        state.leads
          .find((status) => status.status === action.statusId)
          .leads.unshift(state.leads.find((status) => status.status === action.statusId).leads.pop());
      }

      return {
        ...state
        // requestsLeads: newLeadState,
      };
    }

    case SET_LEAD_STATUS_FAILURE: {
      // const newLeadState = state.leads.find((status)=>status.status===action.status).leads
      // newLeadState.find((lead) => lead.id === action.leadId).status = action.previousStatusId
      state.leads
        .find((status) => status.status === action.status)
        .leads.find((lead) => lead.id === action.leadId).status = action.previousStatusId;
      return {
        ...state
        // requestsLeads: newLeadState,
      };
    }

    case GET_LEAD_RESPONSE_SUCCESS: {
      return {
        ...state,
        response: action.response
      };
    }

    case GET_MESSAGES_OF_LEAD_START: {
      const newLoadingState = state.loading;
      if (!action.isUpdating) newLoadingState.MESSAGES_LOADING = true;
      else newLoadingState.MESSAGES_UPDATING = true;

      return {
        ...state,
        loading: newLoadingState
      };
    }

    case GET_MESSAGES_OF_LEAD_SUCCESS: {
      const newLoadingState = state.loading;
      if (!action.isUpdating) newLoadingState.MESSAGES_LOADING = false;
      else newLoadingState.MESSAGES_UPDATING = false;

      let newMessagesState = [...state.messages];

      if (!newMessagesState.find((messageState) => messageState.dialog === action.dialogId))
        newMessagesState.push({ dialog: action.dialogId, messages: [] });

      const dialogIndex = newMessagesState.findIndex((messageState) => messageState.dialog === action.dialogId);
      // eslint-disable-next-line
            action.messages.map((message)=> {
        if (!newMessagesState[dialogIndex].messages.find((messageState) => messageState.id === message.id))
          newMessagesState[dialogIndex].messages.push(message);
        else Object.assign(newMessagesState[dialogIndex].messages, message);
      });

      newMessagesState
        .find((messageState) => messageState.dialog === action.dialogId)
        .messages.sort((a, b) => {
          if (a.id > b.id) return 1;
          else if (a.id < b.id) return -1;
          else return 0;
        });

      return {
        ...state,
        messages: newMessagesState,
        loading: newLoadingState,
        information: Object.assign(state.information, { nextMessagesPage: action.next_page })
      };
    }

    case SEND_MESSAGE_TO_DIALOG_START: {
      return {
        ...state,
        isNewMessage: false
      };
    }

    case SEND_MESSAGE_TO_DIALOG_SUCCESS: {
      state.dialogs.find((dialog) => dialog.id === action.dialogId).last_message = action.message;
      state.messages.find((dialog) => dialog.dialog === action.dialogId).messages.push(action.message);
      state.messages
        .find((dialog) => dialog.dialog === action.dialogId)
        .messages.sort((a, b) => {
          if (a.id > b.id) return 1;
          else return -1;
        });
      return {
        ...state,
        isNewMessage: true
      };
    }

    case SEND_MESSAGE_TO_LEAD_DIALOG_SUCCESS: {
      const newLeadMessagesState = state.fetchedLeadMessages;

      newLeadMessagesState.messages.push(action.message);
      return {
        ...state,
        fetchedLeadMessages: newLeadMessagesState
      };
    }

    case CHANGE_LEADS_FILTER_OPTION: {
      const newSortFilterState = Object.assign({}, state.sortFilter, {
        [action.filterOptionName]: action.filterOptionValue
      });
      const newLoadingState = state.loading;
      newLoadingState.LEADS_UPDATE = true;
      return {
        ...state,
        sortFilter: newSortFilterState,
        loading: newLoadingState
      };
    }

    case CHANGE_LEADS_FILTER_OPTION_FUNNEL: {
      const newSortFilterState = Object.assign({}, state.sortFilterFunnel, {
        [action.filterOptionName]: action.filterOptionValue
      });
      const newLoadingState = state.loading;
      newLoadingState.LEADS_UPDATE = true;
      return {
        ...state,
        sortFilterFunnel: newSortFilterState,
        loading: newLoadingState
      };
    }

    case GET_ALL_LEADS_DIALOGS_START: {
      const newLoadingState = state.loading;
      newLoadingState.DIALOGS_LOADING = true;

      let newDialogsState = [...state.dialogs];
      if (action.shouldReset) newDialogsState = [];
      return {
        ...state,
        loading: newLoadingState,
        dialogs: newDialogsState,
        filteredDialogs: newDialogsState
      };
    }

    case GET_ALL_LEADS_DIALOGS_SUCCESS: {
      let newDialogsState = [...state.dialogs];
      // eslint-disable-next-line
            action.dialogs.map((dialog)=> {
        if (!newDialogsState.find((dialogState) => dialogState.id === dialog.id)) newDialogsState.push(dialog);
        else Object.assign(newDialogsState, dialog);
      });

      const newLoadingState = state.loading;
      newLoadingState.DIALOGS_LOADING = false;

      return {
        ...state,
        dialogs: newDialogsState,
        filteredDialogs: newDialogsState,
        information: Object.assign(state.information, { nextDialogPage: action.next_page }),
        loading: newLoadingState
      };
    }

    case SET_SELECTED_DIALOG: {
      return {
        ...state,
        selectedDialog: action.dialogId
      };
    }

    case RESEND_MESSAGE_TO_DIALOG_START: {
      state.messages.find((message) => message.id === action.messageId).status = action.newStatusId;
      return {
        ...state
      };
    }

    case RESEND_MESSAGE_TO_DIALOG_FAILURE: {
      state.messages.find((message) => message.id === action.messageId).status = action.previousStatusId;
      return {
        ...state
      };
    }

    case RESEND_RESPONSE_TO_LEAD_START: {
      if (action.leadStatus >= 4 && action.leadStatus <= 6) {
        state.leads
          .find((leadStatus) => leadStatus.status === 4)
          .leads.find((lead) => lead.id === action.leadId).reply.status = action.statusId;
        state.leads
          .find((leadStatus) => leadStatus.status === 4)
          .leads.find((lead) => lead.id === action.leadId).status = 4;
      } else {
        state.leads
          .find((leadStatus) => leadStatus.status === action.leadStatus)
          .leads.find((lead) => lead.id === action.leadId).reply.status = action.statusId;
        state.leads
          .find((leadStatus) => leadStatus.status === action.leadStatus)
          .leads.find((lead) => lead.id === action.leadId).status = 4;
      }
      return {
        ...state
      };
    }

    case RESEND_RESPONSE_TO_LEAD_FAILURE: {
      state.response.status = action.previousStatus;
      return {
        ...state
      };
    }

    case RESET_LEADS_FILTERS: {
      return {
        ...state,
        sortFilter: initialState.sortFilter,
        searchFilter: ""
      };
    }

    case SEARCH_LEADS_BY_FILTER: {
      return {
        ...state,
        searchFilter: action.searchFilter
      };
    }

    case UPDATE_FILTERED_MESSAGES: {
      return {
        ...state
        // filteredDialogs: state.dialogs.filter((dialog) =>
        //     dialog.last_message ? dialog.last_message.text.toLowerCase().includes(state.searchFilter.toLowerCase()) : false)
      };
    }

    case CHANGE_LEAD_USER_START: {
      state.leads
        .find((leadState) => leadState.status === action.status)
        .leads.find((lead) => lead.id === action.leadId).user_id = action.userId;
      return {
        ...state
      };
    }

    case ADD_ESTIMATE_LINK_TO_LEAD_SUCCESS: {
      state.leads
        .find((leadState) => leadState.status === action.status)
        .leads.find((lead) => lead.id === action.leadId).estimate_url = action.smetaText;
      return {
        ...state
      };
    }

    case ADD_KP_LINK_TO_LEAD_SUCCESS: {
      state.leads
        .find((leadState) => leadState.status === action.status)
        .leads.find((lead) => lead.id === action.leadId).kp_url = action.kpText;
      return {
        ...state
      };
    }

    case GET_LEAD_INFORMATION_SUCCESS: {
      if (action.result.status >= 1 && action.result.status <= 3) {
        if (
          !state.leads.find((lead) => lead.status === 1).leads.find((leadState) => leadState.id === action.result.id)
        ) {
          state.leads.find((status) => status.status === 1).leads.push(action.result);
          state.leads
            .find((status) => status.status === 1)
            .leads.unshift(state.leads.find((status) => status.status === 1).leads.pop());
        } else
          Object.assign(
            state.leads.find((lead) => lead.status === 1).leads.find((leadState) => leadState.id === action.result.id),
            action.result
          );
      } else if (action.result.status >= 4 && action.result.status <= 6) {
        if (
          !state.leads.find((lead) => lead.status === 4).leads.find((leadState) => leadState.id === action.result.id)
        ) {
          state.leads.find((status) => status.status === 4).leads.push(action.result);
          state.leads
            .find((status) => status.status === 4)
            .leads.unshift(state.leads.find((status) => status.status === 4).leads.pop());
        } else
          Object.assign(
            state.leads.find((lead) => lead.status === 4).leads.find((leadState) => leadState.id === action.result.id),
            action.result
          );
      } else if (action.result.status > 6) {
        if (
          !state.leads
            .find((lead) => lead.status === action.result.status)
            .leads.find((leadState) => leadState.id === action.result.id)
        ) {
          state.leads.find((status) => status.status === action.result.status).leads.push(action.result);
          state.leads
            .find((status) => status.status === action.result.status)
            .leads.unshift(state.leads.find((status) => status.status === action.result.status).leads.pop());
        } else
          Object.assign(
            state.leads
              .find((lead) => lead.status === action.result.status)
              .leads.find((leadState) => leadState.id === action.result.id),
            action.result
          );
      }
      return {
        ...state,
        fetchedSelectedLead: action.result
      };
    }

    case SAVE_DATA_ABOUT_LEAD: {
      const newSavedLeadsInfoState = state.leadsSavedInfo;
      if (newSavedLeadsInfoState.find((leadInfo) => leadInfo.leadId === action.leadId))
        newSavedLeadsInfoState[
          newSavedLeadsInfoState.findIndex((leadInfo) => leadInfo.leadId === action.leadId)
        ].responseText = action.responseText;
      else newSavedLeadsInfoState.push({ leadId: action.leadId, responseText: action.responseText });
      return {
        ...state,
        leadsSavedInfo: newSavedLeadsInfoState
      };
    }

    case RESET_LEADS_IN_CATEGORY: {
      const index = state.leads.findIndex((lead) => lead.status === action.statusId);
      state.leads[index].leads = [];
      return {
        ...state
      };
    }

    case GET_LEAD_REPLY_SUCCESS: {
      state.leads.find((leadState) => leadState.status === 4).leads.find((lead) => lead.id === action.leadId).reply =
        action.reply;
      return {
        ...state
      };
    }

    case FETCH_MESSAGES_IN_DIALOG_OF_SELECTED_LEAD_START: {
      const newLoadingState = state.loading;
      newLoadingState.FETCHED_MESSAGES_LOADING = true;
      const newLeadMessagesState = state.fetchedLeadMessages;
      newLeadMessagesState.dialogId = action.dialogId;

      return {
        ...state,
        loading: newLoadingState,
        fetchedLeadMessages: newLeadMessagesState
      };
    }

    case FETCH_MESSAGES_IN_DIALOG_OF_SELECTED_LEAD_SUCCESS: {
      const newLoadingState = state.loading;
      newLoadingState.FETCHED_MESSAGES_LOADING = false;

      const newLeadMessagesState = state.fetchedLeadMessages;

      action.messages.forEach((message) => {
        newLeadMessagesState.messages.push(message);
      });

      newLeadMessagesState.messages.sort((a, b) => {
        if (a.id > b.id) return 1;
        else return -1;
      });

      newLeadMessagesState.next_page = action.next_page;

      return {
        ...state,
        fetchedLeadMessages: newLeadMessagesState,
        loading: newLoadingState
      };
    }

    case CLEAR_FETCHER_MESSAGES_IN_LEAD: {
      return {
        ...state,
        fetchedLeadMessages: {
          next_page: 1,
          messages: []
        }
      };
    }

    case FETCH_DIALOGS_OF_SELECTED_LEAD_START: {
      return {
        ...state,
        fetchedLeadMessages: {
          next_page: 1,
          messages: []
        }
      };
    }

    case ADD_NEW_LEAD_SUCCESS: {
      const newLeadsState = state.leads;
      newLeadsState.find((leads) => leads.status === 7).leads.unshift(action.lead);

      return {
        ...state,
        leads: newLeadsState
      };
    }

    case RESET_WORKSPACE_ID: {
      const newLoadingState = state.loading;
      if (action.selectedTab === "Лиды") newLoadingState.LEADS_LOADING = true;

      return {
        ...state,
        loading: newLoadingState
      };
    }

    case ADD_RESPONSE_MANUALLY_SUCCESS: {
      let lead = state.leads
        .find((status) => status.status === 1)
        .leads.find((leadState) => leadState.id === action.leadId);
      state.leads
        .find((status) => status.status === 1)
        .leads.splice(
          state.leads
            .find((status) => status.status === 1)
            .leads.findIndex((leadState) => leadState.id === action.leadId),
          1
        );
      state.leads.find((status) => status.status === 4).leads.push(lead);
      state.leads
        .find((status) => status.status === 4)
        .leads.unshift(state.leads.find((status) => status.status === 4).leads.pop());
      return {
        ...state
      };
    }

    case GET_INFO_ABOUT_UNREAD_DIALOGS: {
      return {
        ...state,
        unreadMessagesCount: action.count
      };
    }

    case SET_MESSAGES_TIMER: {
      return {
        ...state,
        messagesTimer: action.state
      };
    }

    case UPDATE_LEADS: {
      let newLeadsState = state.leads[0].leads;
      if (state.sortFilter.leadStatus !== 0) {
        newLeadsState = newLeadsState.filter((lead) => {
          if (lead.status === state.sortFilter.leadStatus || lead.id === state.selectedLead) return true;
          else {
            if (lead.id === state.selectedLead) state.selectedLead = null;
            return false;
          }
        });
      }
      // if(state.sortFilter.leadSource !== 0){
      //     newLeadsState = newLeadsState.filter((lead)=>{
      //         return lead.source === state.sortFilter.leadSource;
      //     })
      // }
      state.leads[0].leads = newLeadsState;
      return {
        ...state
      };
    }

    case GET_LEADS_ROBOTS_STATUSES_SUCCESS: {
      return {
        ...state,
        robotsStatusesInfo: action.robotsStatuses
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
}

export default leadsReducer;
