import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./styles/ProjectInformation.module.css";
import { ProjectLogo } from "./ProjectLogo.js";
import { GitHubButton } from "../../../../components/Buttons/GitHubButton";
import { DropdownButton } from "../../../../components/Buttons/DropdownButton";
import { useDispatch, useSelector } from "react-redux";
import { modifyWorkspaceName, modifyWorkspaceStatus } from "../../../../state/actions";
import { NotesIcon } from "../Components/NotesIcon";

const ProjectInformation = React.memo(
  ({ projectName, projectCurrentStatus, projectStatuses, gitHubLink, logoColor, className, workspaceId }) => {
    const [headerText, setHeaderText] = useState(projectName);
    const [headerTextBeforeEditing, setHeaderTextBeforeEditing] = useState(projectName);
    const [workspaceStatus, setWorkspaceStatus] = useState(projectCurrentStatus);
    const [editHeaderText, setEditHeaderText] = useState(false);
    const [logoLetters, setLogoLetters] = useState("");

    const workspacePermissions = useSelector((state) => state.workspaceInformationReducer.information.permissions);

    useEffect(() => {
      setHeaderText(projectName);
    }, [projectName]);

    const dispatch = useDispatch();

    const headerTextChangeHandler = (event) => {
      setHeaderText(event.target.innerText);
    };

    const headerTextBlurHandler = (event) => {
      if (event.target.innerText.length > 1) {
        setHeaderText(event.target.innerText.trim());
        dispatch(modifyWorkspaceName(workspaceId, event.target.innerText.trim(), headerTextBeforeEditing));
      } else {
        setHeaderText("Название проекта");
        dispatch(modifyWorkspaceName(workspaceId, "Название проекта", headerTextBeforeEditing));
      }
      setEditHeaderText(false);
    };

    const headerTextFocusHandler = (event) => {
      setHeaderTextBeforeEditing(event.target.innerText);
      setEditHeaderText(true);
    };

    const keyPressHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        event.target.blur();
      }
      if (event.target.innerText.length > 20) event.preventDefault();
    };

    const dropdownMenuOptions = projectStatuses.map((status) => ({
      id: status.workspace_status_id,
      label: status.workspace_status_name,
      sort: status.workspace_status_sort
    }));

    const workspaceStatusChangeHandler = (newLabel) => {
      const previousStatus = projectStatuses.find((status) => status.workspace_status_name === workspaceStatus);
      const selectedStatus = projectStatuses.find((status) => status.workspace_status_name === newLabel);

      dispatch(
        modifyWorkspaceStatus(workspaceId, selectedStatus.workspace_status_id, previousStatus.workspace_status_id)
      );
    };

    useEffect(() => {
      const projectNameArray = headerText
        ? headerText.trim().includes(" ")
          ? headerText.toUpperCase().split(" ")
          : headerText.includes("-")
          ? headerText.toUpperCase().split("-")
          : headerText.includes("_")
          ? headerText.toUpperCase().split("_")
          : headerText.toUpperCase()
        : "";
      let projectNewNameArray;
      if (typeof projectNameArray !== "string") projectNewNameArray = projectNameArray.filter((pn) => pn !== "");

      if (typeof projectNameArray === "string") setLogoLetters(projectNameArray[0] ? projectNameArray[0] : "?");
      else if (projectNewNameArray.length > 1) {
        setLogoLetters(
          (projectNewNameArray[0][0] ? projectNewNameArray[0][0] : "") +
            (projectNewNameArray[1][0] ? projectNewNameArray[1][0] : "")
        );
      } else {
        setLogoLetters("?");
      }
    }, [headerText]);

    useEffect(() => {
      setHeaderText(projectName);
      setWorkspaceStatus(projectCurrentStatus);
    }, [projectName, projectCurrentStatus]);

    return (
      <div className={`${styles.container} ${className}`}>
        <ProjectLogo letters={logoLetters} color={logoColor} className={styles.projectLogo} />
        <div className={styles.projectInformation}>
          {workspacePermissions && workspacePermissions.workspace_name.can_read === 1 && (
            <div
              className={`${styles.workspaceNameChanger} ${editHeaderText ? styles.isEdit : ""}`}
              onFocus={workspacePermissions.workspace_name.can_edit === 1 ? headerTextFocusHandler : undefined}
              onBlur={workspacePermissions.workspace_name.can_edit === 1 ? headerTextBlurHandler : undefined}
              onKeyPress={workspacePermissions.workspace_name.can_edit === 1 ? keyPressHandler : undefined}
              onChange={workspacePermissions.workspace_name.can_edit === 1 ? headerTextChangeHandler : undefined}
              suppressContentEditableWarning={true}
              contentEditable={workspacePermissions.workspace_name.can_edit === 1}
            >
              {headerText}
            </div>
          )}
          {workspacePermissions && workspacePermissions.workspace_github_url.can_read === 1 && (
            <GitHubButton
              className={`${styles.gitHubLink}`}
              link={gitHubLink}
              workspaceId={workspaceId}
              editable={workspacePermissions.workspace_github_url.can_edit === 1}
            />
          )}
          <NotesIcon workspaceId={workspaceId} />
        </div>
        {workspacePermissions && workspacePermissions.workspace_status.can_read === 1 && (
          <DropdownButton
            buttonLabel={workspaceStatus}
            dropDownMenuOptions={dropdownMenuOptions}
            className={styles.statusSelector}
            showArrowWithoutHover={false}
            customStylesToOptionsList={styles.projectStatusChangerList}
            customStylesToOptionsListElement={styles.projectStatusChangerListElement}
            customStylesToButtonOnOpenMenu={styles.openMenu}
            onSelectOptionFunction={workspaceStatusChangeHandler}
            editable={workspacePermissions.workspace_status.can_edit === 1}
            disabled={workspacePermissions.workspace_status.can_list === 0}
            shouldNotHaveShadowOnHover={true}
          />
        )}
      </div>
    );
  }
);

ProjectInformation.propTypes = {
  projectName: PropTypes.string.isRequired,
  projectCurrentStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  projectStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      workspace_status_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      workspace_status_sort: PropTypes.number,
      workspace_status_name: PropTypes.string.isRequired
    })
  ).isRequired,
  logoColor: PropTypes.string.isRequired,
  workspaceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  gitHubLink: PropTypes.string
};

ProjectInformation.defaultProps = {
  className: ""
};

export { ProjectInformation };
