import React, { useRef, useState } from "react";
import styles from "./styles/Hamburger.module.css";

const Hamburger = ({ children, className }) => {
  const checkBoxRef = useRef(null);

  const [isChecked, setIsChecked] = useState(false);

  const onClickHandler = () => {
    if (checkBoxRef.current) setIsChecked(checkBoxRef.current.checked);
  };
  children = React.cloneElement(children, { setIsChecked: () => (checkBoxRef.current.checked = false) });

  return (
    <div className={className}>
      <div
        className={`${styles.wrapper} ${isChecked ? styles.wrapperOpened : ""}`}
        onClick={() => {
          checkBoxRef.current.checked = false;
          setIsChecked(false);
        }}
      ></div>
      <div className={styles.hamburger} onClick={onClickHandler}>
        <input id={"menu__toggle_right"} className={styles.menuToggle} type="checkbox" ref={checkBoxRef} />
        <label className={styles.menuButton} htmlFor="menu__toggle_right">
          <span />
        </label>
        <div className={styles.rightContainer}>{children}</div>
      </div>
    </div>
  );
};

export { Hamburger };
