import {
  ACCEPT_ACTION_TASK_FAILURE,
  ACCEPT_ACTION_TASK_START,
  ACCEPT_ACTION_TASK_SUCCESS,
  ACCEPT_ACTION_USER_TASK_FAILURE,
  ACCEPT_ACTION_USER_TASK_START,
  ACCEPT_ACTION_USER_TASK_SUCCESS,
  ADD_ESTIMATE_LINK_TO_LEAD_SUCCESS,
  ADD_GLOBAL_WORKSPACE_LOADER_ELEMENT,
  ADD_KP_LINK_TO_LEAD_SUCCESS,
  ADD_NEW_CARD_TO_SECTION_FAILURE,
  ADD_NEW_CARD_TO_SECTION_START,
  ADD_NEW_CARD_TO_SECTION_SUCCESS,
  ADD_NEW_CARD_TO_USER_TASKS_SUCCESS,
  ADD_NEW_LEAD_SUCCESS,
  ADD_NEW_SECTION_TO_WORKSPACE_FAILURE,
  ADD_NEW_SECTION_TO_WORKSPACE_START,
  ADD_NEW_SECTION_TO_WORKSPACE_SUCCESS,
  ADD_NEW_TASK_TO_WORKSPACE_FAILURE,
  ADD_NEW_TASK_TO_WORKSPACE_START,
  ADD_NEW_TASK_TO_WORKSPACE_SUCCESS,
  ADD_RESPONSE_MANUALLY_SUCCESS,
  ADD_SELECTED_COMPANY_USER_TO_WORKSPACE_USERS_FAILURE,
  ADD_SELECTED_COMPANY_USER_TO_WORKSPACE_USERS_START,
  ADD_SELECTED_COMPANY_USER_TO_WORKSPACE_USERS_SUCCESS,
  AUTHORIZATION,
  AUTHORIZE_USER_FAILURE,
  AUTHORIZE_USER_START,
  AUTHORIZE_USER_SUCCESS,
  CHANGE_DISPLAY_STATE,
  CHANGE_EDITABLE_TEXT_STATE,
  CHANGE_FILTER_OPTION,
  CHANGE_FILTER_OPTION_USER_TASKS,
  CHANGE_LANGUAGE,
  CHANGE_LEAD_USER_START,
  CHANGE_LEADS_DISPLAY_STATE,
  CHANGE_LEADS_FILTER_OPTION,
  CHANGE_LEADS_FILTER_OPTION_FUNNEL,
  CHANGE_OPTIONS_FOR_MODAL_WINDOW,
  CHANGE_TASK_DEADLINE_DATE_FAILURE,
  CHANGE_TASK_DEADLINE_DATE_START,
  CHANGE_TASK_DEADLINE_DATE_SUCCESS,
  CHANGE_TASK_DEADLINE_DATE_USER_TASKS_FAILURE,
  CHANGE_TASK_DEADLINE_DATE_USER_TASKS_START,
  CHANGE_TASK_DEADLINE_DATE_USER_TASKS_SUCCESS,
  CHANGE_TASK_DESCRIPTION_FAILURE,
  CHANGE_TASK_DESCRIPTION_START,
  CHANGE_TASK_DESCRIPTION_SUCCESS,
  CHANGE_TASK_HEADER_FAILURE,
  CHANGE_TASK_HEADER_START,
  CHANGE_TASK_HEADER_SUCCESS,
  CHANGE_TASK_PRIORITY_FAILURE,
  CHANGE_TASK_PRIORITY_START,
  CHANGE_TASK_PRIORITY_SUCCESS,
  CHANGE_TASK_PRIORITY_USER_TASKS_FAILURE,
  CHANGE_TASK_PRIORITY_USER_TASKS_START,
  CHANGE_TASK_PRIORITY_USER_TASKS_SUCCESS,
  CHANGE_TASK_SECTION_FAILURE,
  CHANGE_TASK_SECTION_START,
  CHANGE_TASK_SECTION_SUCCESS,
  CHANGE_TASK_TOPIC_FAILURE,
  CHANGE_TASK_TOPIC_START,
  CHANGE_TASK_TOPIC_SUCCESS,
  CHANGE_TASK_TOPIC_USER_TASKS_FAILURE,
  CHANGE_TASK_TOPIC_USER_TASKS_START,
  CHANGE_TASK_TOPIC_USER_TASKS_SUCCESS,
  CHANGE_TASK_VISIBILITY_FAILURE,
  CHANGE_TASK_VISIBILITY_START,
  CHANGE_TASK_VISIBILITY_SUCCESS,
  CHANGE_USER_FIELD_SUCCESS,
  CLEAR_FETCHER_MESSAGES_IN_LEAD,
  CLEAR_SEARCH_USER_PARAMS,
  CREATE_NEW_WORKSPACE_FAILURE,
  CREATE_NEW_WORKSPACE_START,
  CREATE_NEW_WORKSPACE_SUCCESS,
  DECLINE_ACTION_TASK_FAILURE,
  DECLINE_ACTION_TASK_START,
  DECLINE_ACTION_TASK_SUCCESS,
  DECLINE_ACTION_USER_TASKS_TASK_FAILURE,
  DECLINE_ACTION_USER_TASKS_TASK_START,
  DECLINE_ACTION_USER_TASKS_TASK_SUCCESS,
  DELETE_DOCUMENT_FROM_TASK_FAILURE,
  DELETE_DOCUMENT_FROM_TASK_START,
  DELETE_DOCUMENT_FROM_TASK_SUCCESS,
  DELETE_GLOBAL_WORKSPACE_LOADER_STATUS,
  DELETE_SECTION_FROM_WORKSPACE_FAILURE,
  DELETE_SECTION_FROM_WORKSPACE_START,
  DELETE_SECTION_FROM_WORKSPACE_SUCCESS,
  DELETE_TASK_FROM_USER_TASKS_STARTED,
  DELETE_TASK_FROM_WORKSPACE_FAILURE,
  DELETE_TASK_FROM_WORKSPACE_START,
  DELETE_USER_FROM_WORKSPACE_FAILURE,
  DELETE_USER_FROM_WORKSPACE_STARTED,
  DELETE_USER_FROM_WORKSPACE_SUCCESS,
  DOWNLOAD_DOCUMENT_FAILURE,
  DOWNLOAD_DOCUMENT_START,
  DOWNLOAD_DOCUMENT_SUCCESS,
  DRAG_TASK_SECTION_FAILURE,
  DRAG_TASK_SECTION_SUCCESS,
  DRAG_TASK_SORT_FAILURE,
  DRAG_TASK_SORT_SUCCESS,
  DRAG_TASK_START,
  DRAG_WORKSPACE_FAILURE,
  FETCH_DIALOGS_OF_SELECTED_LEAD_START,
  FETCH_LIST_OF_SECTIONS_IN_WORKSPACE_FAILURE,
  FETCH_LIST_OF_SECTIONS_IN_WORKSPACE_START,
  FETCH_LIST_OF_SECTIONS_IN_WORKSPACE_SUCCESS,
  FETCH_LIST_OF_TASK_DOCUMENTS_FAILURE,
  FETCH_LIST_OF_TASK_DOCUMENTS_START,
  FETCH_LIST_OF_TASK_DOCUMENTS_SUCCESS,
  FETCH_LIST_OF_TASK_PRIORITY_STATUSES_FAILURE,
  FETCH_LIST_OF_TASK_PRIORITY_STATUSES_START,
  FETCH_LIST_OF_TASK_PRIORITY_STATUSES_SUCCESS,
  FETCH_LIST_OF_TASKS_IN_WORKSPACE_FAILURE,
  FETCH_LIST_OF_TASKS_IN_WORKSPACE_START,
  FETCH_LIST_OF_TASKS_IN_WORKSPACE_SUCCESS,
  FETCH_LIST_OF_TOPICS_FAILURE,
  FETCH_LIST_OF_TOPICS_START,
  FETCH_LIST_OF_TOPICS_SUCCESS,
  FETCH_LIST_OF_VISIBILITY_FAILURE,
  FETCH_LIST_OF_VISIBILITY_START,
  FETCH_LIST_OF_VISIBILITY_SUCCESS,
  FETCH_MESSAGES_IN_DIALOG_OF_SELECTED_LEAD_START,
  FETCH_MESSAGES_IN_DIALOG_OF_SELECTED_LEAD_SUCCESS,
  FETCH_TASK_LOGS_FAILURE,
  FETCH_TASK_LOGS_START,
  FETCH_TASK_LOGS_SUCCESS,
  FETCH_USERS_FROM_COMPANY_FAILURE,
  FETCH_USERS_FROM_COMPANY_STARTED,
  FETCH_USERS_FROM_COMPANY_SUCCESS,
  FETCH_USERS_IN_WORKSPACE_FAILURE,
  FETCH_USERS_IN_WORKSPACE_STARTED,
  FETCH_USERS_IN_WORKSPACE_SUCCESS,
  FETCH_WORKSPACE_INFORMATION_FAILURE,
  FETCH_WORKSPACE_INFORMATION_START,
  FETCH_WORKSPACE_INFORMATION_SUCCESS,
  FETCH_WORKSPACE_STATUSES_LIST_FAILURE,
  FETCH_WORKSPACE_STATUSES_LIST_START,
  FETCH_WORKSPACE_STATUSES_LIST_SUCCESS,
  FETCH_WORKSPACES_DATA_FAILURE,
  FETCH_WORKSPACES_DATA_START,
  FETCH_WORKSPACES_DATA_SUCCESS,
  FILTER_USER_TASKS,
  FILTER_USER_TASKS_BY_FILTER,
  GET_ALL_LEADS_DIALOGS_START,
  GET_ALL_LEADS_DIALOGS_SUCCESS,
  GET_ALL_TASK_PRIORITY_STATUSES_SUCCESS,
  GET_ALL_TASK_TOPICS_SUCCESS,
  GET_ALL_USERS_FROM_COMPANY_SUCCESS,
  GET_AVAILABLE_LEADS_START,
  GET_AVAILABLE_LEADS_SUCCESS,
  GET_INFO_ABOUT_LEAD_CURRENCIES,
  GET_INFO_ABOUT_LEAD_SOURCES,
  GET_INFO_ABOUT_LEAD_STATUSES,
  GET_INFO_ABOUT_LEAD_TOPICS,
  GET_INFO_ABOUT_MESSAGES_STATUSES,
  GET_INFO_ABOUT_TASK_SUCCESS,
  GET_INFO_ABOUT_TASK_USER_TASKS_SUCCESS,
  GET_INFO_ABOUT_UNREAD_DIALOGS,
  GET_INTEGRATOR_LEFT_MENU_START,
  GET_INTEGRATOR_LEFT_MENU_SUCCESS,
  GET_KNOWLEDGE_BASE_DATA_STARTED,
  GET_KNOWLEDGE_BASE_DATA_SUCCESS,
  GET_LEAD_INFORMATION_SUCCESS,
  GET_LEAD_REPLY_SUCCESS,
  GET_LEAD_RESPONSE_SUCCESS,
  GET_LIST_OF_REPORT_STATUSES_SUCCESS,
  GET_LIST_OF_REPORT_TYPES_SUCCESS,
  GET_LIST_OF_REPORTS_START,
  GET_LIST_OF_REPORTS_SUCCESS,
  GET_MESSAGES_OF_LEAD_START,
  GET_MESSAGES_OF_LEAD_SUCCESS,
  GET_REPORT_INFO_START,
  GET_REPORT_INFO_SUCCESS,
  GET_USER_INFO_SUCCESS,
  GET_USER_RANK_INFO_SUCCESS,
  GET_USER_TASKS_FAILURE,
  GET_USER_TASKS_START,
  GET_USER_TASKS_SUCCESS,
  HIDE_MODAL_WINDOW,
  HIDE_UPPER_MODAL_WINDOW,
  LOAD_AVAILABLE_LEADS_STARTED,
  MARK_TASK_AS_DONE_FAILURE,
  MARK_TASK_AS_DONE_START,
  MARK_TASK_AS_DONE_SUCCESS,
  MARK_TASK_AS_DONE_USER_TASKS_FAILURE,
  MARK_TASK_AS_DONE_USER_TASKS_START,
  MARK_TASK_AS_DONE_USER_TASKS_SUCCESS,
  MARK_TASK_AS_UNDONE_FAILURE,
  MARK_TASK_AS_UNDONE_START,
  MARK_TASK_AS_UNDONE_SUCCESS,
  MARK_TASK_AS_UNDONE_USER_TASKS_FAILURE,
  MARK_TASK_AS_UNDONE_USER_TASKS_START,
  MARK_TASK_AS_UNDONE_USER_TASKS_SUCCESS,
  MODIFY_SECTION_IN_WORKSPACE_FAILURE,
  MODIFY_SECTION_IN_WORKSPACE_START,
  MODIFY_SECTION_IN_WORKSPACE_SUCCESS,
  MODIFY_WORKSPACE_GITHUB_FAILURE,
  MODIFY_WORKSPACE_GITHUB_START,
  MODIFY_WORKSPACE_GITHUB_SUCCESS,
  MODIFY_WORKSPACE_INFORMATION_FAILURE,
  MODIFY_WORKSPACE_INFORMATION_START,
  MODIFY_WORKSPACE_INFORMATION_SUCCESS,
  MODIFY_WORKSPACE_NAME_FAILURE,
  MODIFY_WORKSPACE_NAME_START,
  MODIFY_WORKSPACE_NAME_SUCCESS,
  MODIFY_WORKSPACE_STATUS_FAILURE,
  MODIFY_WORKSPACE_STATUS_START,
  MODIFY_WORKSPACE_STATUS_SUCCESS,
  MODIFY_WORKSPACE_NOTES,
  MOVE_TASK_TO_ANOTHER_WORKSPACE_START,
  NOT_ALLOWED_TO_SEE_WORKSPACE,
  REMOVE_LEADS_LOADING_STATE,
  RESEND_MESSAGE_TO_DIALOG_FAILURE,
  RESEND_MESSAGE_TO_DIALOG_START,
  RESEND_RESPONSE_TO_LEAD_FAILURE,
  RESEND_RESPONSE_TO_LEAD_START,
  RESET_FILTER_OPTIONS,
  RESET_FILTER_OPTIONS_USER_TASKS,
  RESET_LEADS_FILTERS,
  RESET_LEADS_IN_CATEGORY,
  RESET_NEW_SECTION,
  RESET_NEW_TASK,
  RESET_NEW_TASK_USER_TASKS,
  RESET_NEW_WORKSPACE,
  RESET_SEARCH_WORKSPACES,
  RESET_USER_TASKS_ERROR,
  RESET_WORKSPACE_ID,
  SAVE_DATA_ABOUT_LEAD,
  SEARCH_LEADS_BY_FILTER,
  SEARCH_USER_TASKS,
  SEARCH_WORKSPACE,
  SEND_KP_LINK_SUCCESS,
  SEND_MESSAGE_TO_DIALOG_START,
  SEND_MESSAGE_TO_DIALOG_SUCCESS,
  SEND_MESSAGE_TO_LEAD_DIALOG_SUCCESS,
  SET_COMPANY_USERS_SEARCH_FILTER,
  SET_FILTER_TO_USERS_FROM_COMPANY,
  SET_FILTERED_USERS_FROM_COMPANY_BY_FIO,
  SET_GLOBAL_WORKSPACE_LOADER_STATUS,
  SET_IMPLEMENTER_TO_TASK_FAILURE,
  SET_IMPLEMENTER_TO_TASK_START,
  SET_IMPLEMENTER_TO_TASK_SUCCESS,
  SET_IMPLEMENTER_TO_USER_TASKS_TASK_FAILURE,
  SET_IMPLEMENTER_TO_USER_TASKS_TASK_START,
  SET_IMPLEMENTER_TO_USER_TASKS_TASK_SUCCESS,
  SET_INITIATOR_TO_TASK_FAILURE,
  SET_INITIATOR_TO_TASK_START,
  SET_INITIATOR_TO_TASK_SUCCESS,
  SET_INITIATOR_TO_TASK_USER_TASKS_FAILURE,
  SET_INITIATOR_TO_TASK_USER_TASKS_START,
  SET_INITIATOR_TO_TASK_USER_TASKS_SUCCESS,
  SET_LEAD_SOURCE_FAILURE,
  SET_LEAD_SOURCE_START,
  SET_LEAD_STATUS_FAILURE,
  SET_LEAD_STATUS_START,
  SET_LEAD_TOPIC_FAILURE,
  SET_LEAD_TOPIC_START,
  SET_MESSAGES_TIMER,
  SET_OPTIONS_FOR_MODAL_WINDOW,
  SET_SELECTED_DIALOG,
  SET_SELECTED_LEAD,
  SET_SELECTED_USER,
  SET_SHOW_STATUS,
  SET_UPLOADED_DOCUMENT_NAME,
  SET_UPLOADED_DOCUMENT_SHOW_STATUS,
  SET_UPLOADED_DOCUMENT_UPLOAD_PERCENTAGE,
  SET_USER_TASKS_LOADER,
  SET_USER_TASKS_USERS_SEARCH_FILTER,
  SET_VERIFICATOR_TO_TASK_FAILURE,
  SET_VERIFICATOR_TO_TASK_START,
  SET_VERIFICATOR_TO_TASK_SUCCESS,
  SET_VERIFICATOR_TO_TASK_USER_TASKS_FAILURE,
  SET_VERIFICATOR_TO_TASK_USER_TASKS_START,
  SET_VERIFICATOR_TO_TASK_USER_TASKS_SUCCESS,
  SET_WORKSPACE_USERS_SEARCH_FILTER,
  SHOW_LEAD_MODAL_WINDOW,
  SHOW_MODAL_WINDOW,
  SHOW_UPPER_MODAL_WINDOW,
  SORT_SECTIONS_IN_WORKSPACE,
  UNSET_IMPLEMENTER_TO_TASK_FAILURE,
  UNSET_IMPLEMENTER_TO_TASK_START,
  UNSET_IMPLEMENTER_TO_TASK_SUCCESS,
  UNSET_IMPLEMENTER_TO_TASK_USER_TASKS_FAILURE,
  UNSET_IMPLEMENTER_TO_TASK_USER_TASKS_START,
  UNSET_IMPLEMENTER_TO_TASK_USER_TASKS_SUCCESS,
  UNSET_INITIATOR_TO_TASK_FAILURE,
  UNSET_INITIATOR_TO_TASK_START,
  UNSET_INITIATOR_TO_TASK_SUCCESS,
  UNSET_INITIATOR_TO_TASK_USER_TASKS_FAILURE,
  UNSET_INITIATOR_TO_TASK_USER_TASKS_START,
  UNSET_INITIATOR_TO_TASK_USER_TASKS_SUCCESS,
  UNSET_TASK_DEADLINE_DATE_FAILURE,
  UNSET_TASK_DEADLINE_DATE_START,
  UNSET_TASK_DEADLINE_DATE_SUCCESS,
  UNSET_TASK_DEADLINE_DATE_USER_TASKS_FAILURE,
  UNSET_TASK_DEADLINE_DATE_USER_TASKS_START,
  UNSET_TASK_DEADLINE_DATE_USER_TASKS_SUCCESS,
  UNSET_VERIFICATOR_TO_TASK_FAILURE,
  UNSET_VERIFICATOR_TO_TASK_START,
  UNSET_VERIFICATOR_TO_TASK_SUCCESS,
  UNSET_VERIFICATOR_TO_TASK_USER_TASKS_FAILURE,
  UNSET_VERIFICATOR_TO_TASK_USER_TASKS_START,
  UNSET_VERIFICATOR_TO_TASK_USER_TASKS_SUCCESS,
  UPDATE_FILTERED_MESSAGES,
  UPDATE_FILTERED_TASKS,
  UPDATE_FILTERED_TASKS_SORTABLE_HEADER,
  UPDATE_LEADS,
  UPDATE_WORKSPACE_STARTED,
  UPDATE_WORKSPACE_SUCCESS,
  UPDATE_WORKSPACES,
  ADD_ADDITIONAL_WORK_TO_PROJECT_SUCCESS,
  REMOVE_REPORT_DATA,
  SET_CURRENT_REPORT_ID,
  SET_TEMPORAL_REPORT_DATE,
  UPDATE_LIST_OF_REPORTS_SUCCESS,
  REMOVE_REPORTS_DATA_ITEMS,
  SAVE_REPORT_START,
  SAVE_REPORT_SUCCESS,
  SAVE_REPORT_FAILURE,
  FILTER_USERS_LIST,
  CHANGE_USER_FIELD_FAILURE,
  CHANGE_REPORT_PROJECT_INFO_FAILURE,
  CHANGE_USER_STATUS_START,
  CHANGE_USER_STATUS_SUCCESS,
  CHANGE_USER_STATUS_FAILURE,
  REGISTER_USER_SUCCESS,
  SORT_USERS_LIST,
  GET_ADDITIONAL_WORKS_LIST_SUCCESS,
  GET_PROJECTS_LIST_SUCCESS,
  GET_PROJECTS_LIST_START,
  CHANGE_REPORT_DATA_INFO_FIELD_SUCCESS,
  GET_REPORT_INFO_FAILURE,
  GET_LEADS_ROBOTS_STATUSES_SUCCESS,
  GET_PROGRAMS_NAMES_SUCCESS,
  DELETE_ADDITIONAL_WORK_FROM_PROJECT_SUCCESS
} from "./actionTypes";
import {
  fetchDataPatcherToServer,
  fetchDataToStorage,
  fetchDeleteDataFromServer,
  fetchFileDataPatcherToServer,
  fetchPostDataToServer
} from "../../utils/DataFetcher";
import { URL_ADDRESSES, USERS_URL } from "../../config/CONSTANTS";
import { toast } from "react-toastify";
import { MODAL_WINDOW_TYPES, TASK_VIEWER_TYPES } from "../../components/Screen/ModalWindow";

/* MODAL WINDOW */

export const showModalWindow = () => ({
  type: SHOW_MODAL_WINDOW,
  showStatus: true
});

export const hideModalWindow = () => ({
  type: HIDE_MODAL_WINDOW,
  showStatus: false
});

export const showUpperModalWindow = (type, imageSrc, imageName, documentId) => ({
  type: SHOW_UPPER_MODAL_WINDOW,
  showStatus: true,
  typeModal: type,
  imageSrc: imageSrc,
  imageName: imageName,
  documentId: documentId
});

export const hideUpperModalWindow = () => ({
  type: HIDE_UPPER_MODAL_WINDOW,
  showStatus: false
});

export const setShowStatus = (status) => ({
  type: SET_SHOW_STATUS,
  showStatus: status
});

export const setOptionsToModalWindow = (options) => ({
  type: SET_OPTIONS_FOR_MODAL_WINDOW,
  options: options
});

export const changeOptionsToModalWindow = (options) => ({
  type: CHANGE_OPTIONS_FOR_MODAL_WINDOW,
  options: options
});

/* WORKSPACE USERS */

export const fetchUsersInWorkspaceStarted = () => ({
  type: FETCH_USERS_IN_WORKSPACE_STARTED,
  loading: true,
  error: null
});

export const fetchUsersInWorkspaceSuccess = (users) => ({
  type: FETCH_USERS_IN_WORKSPACE_SUCCESS,
  loading: false,
  error: null,
  users: [...users]
});

export const fetchUsersInWorkspaceFailure = (error) => ({
  type: FETCH_USERS_IN_WORKSPACE_FAILURE,
  loading: false,
  error: { ...error }
});

export const fetchUsersInWorkspace = (workspaceId) => {
  return (dispatch) => {
    dispatch(fetchUsersInWorkspaceStarted());
    fetchDataToStorage(`${URL_ADDRESSES.GET_USERS_IN_WORKSPACE(workspaceId)}`)
      .catch((error) => {
        toast.error("Ошибка получения данных о пользователях!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(fetchUsersInWorkspaceFailure(error));
      })
      .then((result) => {
        ///!!! HARDCODE !!! -->
        if (result) {
          result.result.forEach((user) => {
            if (user.user_photo !== null) {
              user.user_photo = USERS_URL + `${user.user_photo}`;
            }
          });
          ///!!! HARDCODE !!! <--
          dispatch(fetchUsersInWorkspaceSuccess(result.result));
          dispatch(updateFilteredTasks());
        }
      });
  };
};

export const deleteUserFromWorkspaceStarted = (userId) => ({
  type: DELETE_USER_FROM_WORKSPACE_STARTED,
  loading: true,
  error: null,
  deletedUser: userId
});

export const deleteUserFromWorkspaceSuccess = () => ({
  type: DELETE_USER_FROM_WORKSPACE_SUCCESS,
  loading: false,
  error: null
});

export const deleteUserFromWorkspaceFailure = (error) => ({
  type: DELETE_USER_FROM_WORKSPACE_FAILURE,
  loading: false,
  error: { ...error }
});

export const deleteUserFromWorkspace = (workspaceId, userId) => {
  return (dispatch) => {
    dispatch(deleteUserFromWorkspaceStarted(userId));
    fetchDeleteDataFromServer(`${URL_ADDRESSES.DELETE_USER_FROM_WORKSPACE(workspaceId)}`, { user_id: userId })
      .catch((error) => {
        toast.error("Ошибка удаления пользователя!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(deleteUserFromWorkspaceFailure(error));
      })
      .then((result) => {
        if (result) {
          if (result.result.deleted === true) {
            dispatch(deleteUserFromWorkspaceSuccess());
            dispatch(updateFilteredTasks());
          } else {
            dispatch(deleteUserFromWorkspaceFailure("Этого пользователя нельзя удалить"));
            toast.error("Этого пользователя нельзя удалить!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
          }
        }
      });
  };
};

export const addSelectedCompanyUserToWorkspaceUsersStart = (user) => ({
  type: ADD_SELECTED_COMPANY_USER_TO_WORKSPACE_USERS_START,
  loading: true,
  error: null,
  addedUser: user
});

export const addSelectedCompanyUserToWorkspaceUsersSuccess = () => ({
  type: ADD_SELECTED_COMPANY_USER_TO_WORKSPACE_USERS_SUCCESS,
  loading: false,
  error: null
});

export const addSelectedCompanyUserToWorkspaceUsersFailure = (error) => ({
  type: ADD_SELECTED_COMPANY_USER_TO_WORKSPACE_USERS_FAILURE,
  loading: false,
  error: error
});

export const addSelectedCompanyUserToWorkspaceUsers = (workspaceId, userId) => {
  return (dispatch, getState) => {
    const { companyUsersReducer } = getState();

    dispatch(
      addSelectedCompanyUserToWorkspaceUsersStart(companyUsersReducer.users.find((user) => user.user_id === userId))
    );
    fetchPostDataToServer(`${URL_ADDRESSES.POST_USER_TO_WORKSPACE(workspaceId)}`, { user_id: userId })
      .catch((error) => {
        toast.error("Ошибка добавления пользователя!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(addSelectedCompanyUserToWorkspaceUsersFailure(error));
      })
      .then((result) => {
        if (result) {
          dispatch(addSelectedCompanyUserToWorkspaceUsersSuccess());
          dispatch(updateFilteredTasks());
        }
      });
  };
};

export const clearSearchUserParams = () => {
  return {
    type: CLEAR_SEARCH_USER_PARAMS
  };
};

export const filterWorkspaceUsers = (query) => {
  return {
    type: SET_WORKSPACE_USERS_SEARCH_FILTER,
    searchFilter: query
  };
};

export const filterCompanyUsers = (query) => {
  return {
    type: SET_COMPANY_USERS_SEARCH_FILTER,
    searchFilter: query
  };
};

export const filterUserTasksUsers = (query) => {
  return {
    type: SET_USER_TASKS_USERS_SEARCH_FILTER,
    searchFilter: query
  };
};

/* COMPANY USERS */

export const fetchUsersFromCompanyStarted = () => ({
  type: FETCH_USERS_FROM_COMPANY_STARTED,
  loading: true,
  error: null
});

export const fetchUsersFromCompanySuccess = (users) => ({
  type: FETCH_USERS_FROM_COMPANY_SUCCESS,
  loading: false,
  error: null,
  users: [...users]
});

export const fetchUsersFromCompanyFailure = (error) => ({
  type: FETCH_USERS_FROM_COMPANY_FAILURE,
  loading: false,
  error: { ...error }
});

export const fetchUsersFromCompany = (companyId) => {
  return (dispatch) => {
    dispatch(fetchUsersFromCompanyStarted());
    fetchDataToStorage(`${URL_ADDRESSES.GET_USERS_FROM_COMPANY_BY_ID(companyId)}`)
      .catch((error) => {
        toast.error("Ошибка получения данных о пользователях!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(fetchUsersFromCompanyFailure(error));
      })
      .then((result) => {
        ///!!! HARDCODE !!! -->
        if (result) {
          result.result.forEach((user) => {
            if (user.user_photo !== null) {
              user.user_photo = USERS_URL + `${user.user_photo}`;
            }
          });
          ///!!! HARDCODE !!! <--
          dispatch(fetchUsersFromCompanySuccess(result.result));
          dispatch(updateFilteredTasks());
        }
      });
  };
};

export const fetchTaskDocumentsStarted = () => ({
  type: FETCH_LIST_OF_TASK_DOCUMENTS_START,
  loading: true,
  error: null
});

export const fetchTaskDocumentsFailure = (error) => ({
  type: FETCH_LIST_OF_TASK_DOCUMENTS_FAILURE,
  loading: false,
  error: { ...error }
});

export const fetchTaskDocumentsSuccess = (documents) => ({
  type: FETCH_LIST_OF_TASK_DOCUMENTS_SUCCESS,
  loading: false,
  error: null,
  documents: documents
});

export const fetchTaskDocuments = (taskId) => {
  return (dispatch) => {
    dispatch(fetchTaskDocumentsStarted());
    fetchDataToStorage(`${URL_ADDRESSES.GET_TASK_DOCUMENTS(taskId)}`)
      .catch((error) => {
        toast.error("Ошибка получения данных о документах!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(fetchTaskDocumentsFailure(error));
      })
      .then((result) => {
        if (result) dispatch(fetchTaskDocumentsSuccess(result.result));
      });
  };
};

export const fetchVerificationTaskDocuments = (taskId, parentTaskId) => {
  return (dispatch) => {
    dispatch(fetchTaskDocumentsStarted());
    Promise.all([
      fetchDataToStorage(`${URL_ADDRESSES.GET_TASK_DOCUMENTS(parentTaskId)}`).then((result) => {
        if (result) return result.result;
      }),
      fetchDataToStorage(`${URL_ADDRESSES.GET_TASK_DOCUMENTS(taskId)}`).then((result) => {
        if (result) return result.result;
      })
    ])
      .catch((error) => {
        toast.error("Ошибка получения данных о документах!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(fetchTaskDocumentsFailure(error));
      })
      .then((result) => {
        const allDocs = result.flat();
        dispatch(fetchTaskDocumentsSuccess(allDocs));
      });
  };
};

export const setUploadedDocumentName = (newDocumentName) => ({
  type: SET_UPLOADED_DOCUMENT_NAME,
  name: newDocumentName
});

export const setUploadedDocumentUploadPercentage = (currentPercentage) => ({
  type: SET_UPLOADED_DOCUMENT_UPLOAD_PERCENTAGE,
  percentage: currentPercentage
});

export const setUploadedDocumentShowStatus = (showStatus) => ({
  type: SET_UPLOADED_DOCUMENT_SHOW_STATUS,
  show: showStatus
});

export const deleteDocumentFromTaskStart = (documentIndex) => ({
  type: DELETE_DOCUMENT_FROM_TASK_START,
  loading: true,
  error: null,
  documentIndex: documentIndex
});

export const deleteDocumentFromTaskFailure = (error, documentObject, documentIndex) => ({
  type: DELETE_DOCUMENT_FROM_TASK_FAILURE,
  loading: false,
  error: error,
  documentObject: documentObject,
  documentIndex: documentIndex
});

export const deleteDocumentFromTaskSuccess = () => ({
  type: DELETE_DOCUMENT_FROM_TASK_SUCCESS,
  loading: false,
  error: false
});

export const deleteDocumentFromTask = (documentId) => {
  return (dispatch, getState) => {
    const { modalWindow } = getState();
    const deletedDocumentIndex = modalWindow.options.taskDocuments.findIndex(
      (document) => document.doc_id === documentId
    );
    const deletedDocumentObject = Object.assign({}, modalWindow.options.taskDocuments[deletedDocumentIndex]);
    dispatch(deleteDocumentFromTaskStart(deletedDocumentIndex));
    fetchDeleteDataFromServer(`${URL_ADDRESSES.DELETE_TASK_DOCUMENT_BY_DOCUMENT_ID(documentId)}`)
      .catch((error) => {
        toast.error("Ошибка удаления документа!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(deleteDocumentFromTaskFailure(error, deletedDocumentObject));
      })
      .then((result) => {
        if (result) dispatch(deleteDocumentFromTaskSuccess());
      });
  };
};

export const downloadDocumentStart = () => ({
  type: DOWNLOAD_DOCUMENT_START,
  loading: true,
  error: null
});

export const downloadDocumentFailure = (error) => ({
  type: DOWNLOAD_DOCUMENT_FAILURE,
  loading: false,
  error: error
});

export const downloadDocumentSuccess = () => ({
  type: DOWNLOAD_DOCUMENT_SUCCESS,
  loading: false,
  error: false
});

export const downloadDocument = (documentId) => {
  return (dispatch) => {
    dispatch(downloadDocumentStart());
    fetchDataToStorage(`${URL_ADDRESSES.DOWNLOAD_DOCUMENT_BY_DOCUMENT_ID(documentId)}`)
      .catch((error) => {
        toast.error("Ошибка скачивания документа!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(downloadDocumentFailure(error));
      })
      .then((result) => {
        //console.log(result)
        if (result) dispatch(downloadDocumentSuccess());
      });
  };
};
export const setTaskIdToModalWindow = (taskId) => {
  return (dispatch, getState) => {
    const { workspaceInformationReducer } = getState();
    const timer = setInterval(() => {
      if (!(workspaceInformationReducer.tasks.length === 0 || workspaceInformationReducer.sectionsList.length === 0)) {
        dispatch(
          changeOptionsToModalWindow({
            type: MODAL_WINDOW_TYPES.TRIPLE_WINDOW_TASK_VIEWER,
            closeHeaderButton: true,
            closeOnWrapperClick: true,
            taskId: taskId,
            taskViewerType: TASK_VIEWER_TYPES.EDITOR,
            taskDocuments: [],
            taskUploadedDocument: {
              show: false,
              name: "unknown",
              uploadPercentage: 0
            },
            logLoading: false,
            logError: null,
            logs: [
              {
                updatedAt: new Date().toISOString(),
                log_type_text: "Загрузка...",
                user_id: null,
                log_id: 0
              }
            ]
          })
        );
        dispatch(fetchTaskDocuments(taskId));
        dispatch(showModalWindow());
        clearInterval(timer);
      }
    }, 1000);
  };
};

export const setFilterToUsersFromCompany = (filter) => ({
  type: SET_FILTER_TO_USERS_FROM_COMPANY,
  filterRequest: filter
});

export const setFilteredUsers = (filteredUsers) => ({
  type: SET_FILTERED_USERS_FROM_COMPANY_BY_FIO,
  filteredUsers: filteredUsers
});

export const filterUsersFromCompanyByFIO = () => {
  return (dispatch, getState) => {
    const { workspaceUsersReducer, companyUsersReducer } = getState();
    const workspaceUsersId = workspaceUsersReducer.users.map((user) => user.user_id);
    const companyUsersId = companyUsersReducer.users.map((user) => user.user_id);
    const deltaUsersId = companyUsersId.filter((companyUsersId) => !workspaceUsersId.includes(companyUsersId));
    const filteredUsers = companyUsersReducer.users.filter(
      (user) =>
        `${user.user_last_name} ${user.user_first_name} ${user.user_middle_name !== null ? user.user_middle_name : ""}`
          .trim()
          .toLowerCase()
          .includes(companyUsersReducer.filterRequest) && deltaUsersId.includes(user.user_id)
    );
    dispatch(setFilteredUsers(filteredUsers));
  };
};

/* WORKSPACE INFORMATION */

export const getWorkspaceStatusesListStarted = () => ({
  type: FETCH_WORKSPACE_STATUSES_LIST_START,
  loading: true,
  error: null
});

export const getWorkspaceStatusesListFailure = (error) => ({
  type: FETCH_WORKSPACE_STATUSES_LIST_FAILURE,
  loading: false,
  error: { ...error }
});

export const getWorkspaceStatusesListSuccess = (statusesList) => ({
  type: FETCH_WORKSPACE_STATUSES_LIST_SUCCESS,
  loading: false,
  error: null,
  statusesList: [...statusesList]
});
///////////////////////////////
export const getWorkspaceInformationStarted = () => ({
  type: FETCH_WORKSPACE_INFORMATION_START,
  loading: true,
  error: null
});

export const getWorkspaceInformationFailure = (error) => ({
  type: FETCH_WORKSPACE_INFORMATION_FAILURE,
  loading: false,
  error: { ...error }
});

export const getWorkspaceInformationSuccess = (information) => ({
  type: FETCH_WORKSPACE_INFORMATION_SUCCESS,
  loading: false,
  error: null,
  information: { ...information }
});
///////////////////////////////
export const getWorkspaceSectionsStarted = () => ({
  type: FETCH_LIST_OF_SECTIONS_IN_WORKSPACE_START,
  loading: true,
  error: null
});

export const getWorkspaceSectionsFailure = (error) => ({
  type: FETCH_LIST_OF_SECTIONS_IN_WORKSPACE_FAILURE,
  loading: false,
  error: { ...error }
});

export const getWorkspaceSectionsSuccess = (sectionsList) => ({
  type: FETCH_LIST_OF_SECTIONS_IN_WORKSPACE_SUCCESS,
  loading: false,
  error: null,
  sectionsList: [...sectionsList]
});
///////////////////////////////
export const getWorkspaceTasksPriorityListStarted = () => ({
  type: FETCH_LIST_OF_TASK_PRIORITY_STATUSES_START,
  loading: true,
  error: null
});

export const getWorkspaceTasksPriorityListFailure = (error) => ({
  type: FETCH_LIST_OF_TASK_PRIORITY_STATUSES_FAILURE,
  loading: false,
  error: { ...error }
});

export const getWorkspaceTasksPriorityListSuccess = (taskPriorityStatusesList) => ({
  type: FETCH_LIST_OF_TASK_PRIORITY_STATUSES_SUCCESS,
  loading: false,
  error: null,
  taskPriorityStatusesList: [...taskPriorityStatusesList]
});
///////////////////////////////
export const getWorkspaceTasksTopicsListStarted = () => ({
  type: FETCH_LIST_OF_TOPICS_START,
  loading: true,
  error: null
});

export const getWorkspaceTasksTopicsListFailure = (error) => ({
  type: FETCH_LIST_OF_TOPICS_FAILURE,
  loading: false,
  error: { ...error }
});

export const getWorkspaceTasksTopicsListSuccess = (topicsList) => ({
  type: FETCH_LIST_OF_TOPICS_SUCCESS,
  loading: false,
  error: null,
  topicsList: [...topicsList]
});
///////////////////////////////
export const getWorkspaceTasksListStarted = () => ({
  type: FETCH_LIST_OF_TASKS_IN_WORKSPACE_START,
  loading: true,
  error: null
});

export const getWorkspaceTasksListFailure = (error) => ({
  type: FETCH_LIST_OF_TASKS_IN_WORKSPACE_FAILURE,
  loading: false,
  error: { ...error }
});

export const getWorkspaceTasksListSuccess = (tasks) => ({
  type: FETCH_LIST_OF_TASKS_IN_WORKSPACE_SUCCESS,
  loading: false,
  error: null,
  tasks: [...tasks]
});
///////////////////////////////
export const getVisibilityListStarted = () => ({
  type: FETCH_LIST_OF_VISIBILITY_START,
  loading: true,
  error: null
});

export const getVisibilityListFailure = (error) => ({
  type: FETCH_LIST_OF_VISIBILITY_FAILURE,
  loading: false,
  error: { ...error }
});

export const getVisibilityListSuccess = (visibilityList) => ({
  type: FETCH_LIST_OF_VISIBILITY_SUCCESS,
  loading: false,
  error: null,
  visibilityList: [...visibilityList]
});

export const setWorkspaceGlobalLoader = (loaderStatus) => ({
  type: SET_GLOBAL_WORKSPACE_LOADER_STATUS,
  loaderStatus: loaderStatus
});

export const addWorkspaceGlobalLoader = (newLoaderStatus) => ({
  type: ADD_GLOBAL_WORKSPACE_LOADER_ELEMENT,
  newLoaderStatus: newLoaderStatus
});

export const deleteWorkspaceGlobalLoader = (deleteLoaderStatus) => ({
  type: DELETE_GLOBAL_WORKSPACE_LOADER_STATUS,
  deleteLoaderStatus: deleteLoaderStatus
});

export const notAllowedToSeeWorkspace = (workspaceId) => ({
  type: NOT_ALLOWED_TO_SEE_WORKSPACE,
  workspaceId: workspaceId
});

export const resetSelectedWorkspace = (selectedTab) => ({
  type: RESET_WORKSPACE_ID,
  selectedTab: selectedTab
});

export const fetchWorkspaceSections = (workspaceId) => {
  return async (dispatch) => {
    dispatch(getWorkspaceSectionsStarted());
    await fetchDataToStorage(`${URL_ADDRESSES.GET_LIST_OF_SECTIONS_IN_WORKSPACE(workspaceId)}`)
      .catch((error) => {
        toast.error("Ошибка получения данных о секциях!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(getWorkspaceSectionsFailure(error));
      })
      .then((result) => {
        if (result) {
          dispatch(getWorkspaceSectionsSuccess(result.result));
        }
      });
  };
};

export const fetchWorkspaceInformationData = (workspaceId) => {
  return async (dispatch) => {
    const TASK_NAME = "fetchWorkspaceInformationData";
    let setNotAllowed = false;
    dispatch(addWorkspaceGlobalLoader(TASK_NAME));
    dispatch(getWorkspaceInformationStarted());
    await fetchDataToStorage(`${URL_ADDRESSES.GET_INFORMATION_ABOUT_WORKSPACE_BY_ID(workspaceId)}`)
      .catch((error) => {
        toast.error("Ошибка получения данных о Workspace!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(getWorkspaceInformationFailure(error));
      })
      .then((result) => {
        if (result.status === 0) {
          dispatch(getWorkspaceInformationSuccess(result.result));
          dispatch(updateFilteredTasks());
        } else {
          setNotAllowed = true;
          dispatch(notAllowedToSeeWorkspace(workspaceId));
          document.title = "Доступ заблокирован";
        }
      });
    if (!setNotAllowed) {
      dispatch(getWorkspaceStatusesListStarted());
      await fetchDataToStorage(`${URL_ADDRESSES.GET_WORKSPACE_STATUSES_LIST}`)
        .catch((error) => {
          toast.error("Ошибка получения данных о статусах Workspace!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          dispatch(getWorkspaceStatusesListFailure(error));
        })
        .then((result) => {
          if (result) {
            dispatch(getWorkspaceStatusesListSuccess(result.result));
            dispatch(updateFilteredTasks());
          }
        });
      dispatch(getWorkspaceSectionsStarted());
      await fetchDataToStorage(`${URL_ADDRESSES.GET_LIST_OF_SECTIONS_IN_WORKSPACE(workspaceId)}`)
        .catch((error) => {
          toast.error("Ошибка получения данных о секциях!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          dispatch(getWorkspaceSectionsFailure(error));
        })
        .then((result) => {
          if (result) {
            dispatch(getWorkspaceSectionsSuccess(result.result));
            dispatch(updateFilteredTasks());
          }
        });
      dispatch(getWorkspaceTasksPriorityListStarted());
      await fetchDataToStorage(`${URL_ADDRESSES.GET_LIST_OF_TASK_PRIORITY_STATUSES}`)
        .catch((error) => {
          toast.error("Ошибка получения данных о списке приоритетов!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          dispatch(getWorkspaceTasksPriorityListFailure(error));
        })
        .then((result) => {
          if (result) {
            dispatch(getWorkspaceTasksPriorityListSuccess(result.result));
            dispatch(updateFilteredTasks());
          }
        });
      dispatch(getWorkspaceTasksTopicsListStarted());
      await fetchDataToStorage(`${URL_ADDRESSES.GET_LIST_OF_TOPICS}`)
        .catch((error) => {
          toast.error("Ошибка получения данных о темах задач!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          dispatch(getWorkspaceTasksTopicsListFailure(error));
        })
        .then((result) => {
          if (result) {
            dispatch(getWorkspaceTasksTopicsListSuccess(result.result));
            dispatch(updateFilteredTasks());
          }
        });
      await fetchDataToStorage(`${URL_ADDRESSES.GET_LIST_OF_TASKS_IN_WORKSPACE(workspaceId)}`)
        .catch((error) => {
          toast.error("Ошибка получения данных о задачах!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          dispatch(getWorkspaceTasksListFailure(error));
        })
        .then((result) => {
          if (result) {
            dispatch(getWorkspaceTasksListSuccess(result.result));
            dispatch(updateFilteredTasks());
          }
          //dispatch(saveInitialSortedTasks());
        });
      //  let inter = setInterval(async ()=>{
      // 	dispatch(getWorkspaceTasksListStarted());
      // 	await fetchDataToStorage(
      // 		`${URL_ADDRESSES.GET_LIST_OF_TASKS_IN_WORKSPACE(workspaceId)}`
      // 	)
      // 		.catch((error) => {
      // 			toast.error('Ошибка получения данных о задачах!', {
      // 				position: 'top-right',
      // 				autoClose: 5000,
      // 				hideProgressBar: false,
      // 				closeOnClick: true,
      // 				pauseOnHover: true,
      // 				draggable: true,
      // 			});
      // 			dispatch(getWorkspaceTasksListFailure(error));
      // 		})
      // 		.then((result) => {
      // 			dispatch(getWorkspaceTasksListSuccess(result.result));
      // 			dispatch(updateFilteredTasks());
      // 			//dispatch(saveInitialSortedTasks());
      // 		});
      // }, 5000) 2*60*1000
      // dispatch(getVisibilityListStarted());
      // await fetchDataToStorage(
      //     `${URL_ADDRESSES.GET_VISIBILITY_LIST}`
      // )
      //     .catch((error) => {
      //         toast.error('Ошибка получения данных о видимости задач!', {
      //             position: 'top-right',
      //             autoClose: 5000,
      //             hideProgressBar: false,
      //             closeOnClick: true,
      //             pauseOnHover: true,
      //             draggable: true,
      //         });
      //         dispatch(getVisibilityListFailure(error));
      //     })
      //     .then((result) => {
      //         if (result) dispatch(getVisibilityListSuccess(result.result));
      //     });
    }
    dispatch(deleteWorkspaceGlobalLoader(TASK_NAME));
  };
};

export const updateWorkspaceStarted = () => ({
  type: UPDATE_WORKSPACE_STARTED,
  updateInfoLoading: true
});

export const updateWorkspaceSuccess = () => ({
  type: UPDATE_WORKSPACE_SUCCESS,
  updateInfoLoading: false
});

export const updateTasksInWorkspace = (workspaceId) => {
  return async (dispatch) => {
    dispatch(updateWorkspaceStarted());
    dispatch(getWorkspaceInformationStarted());
    let setNotAllowed = false;
    await fetchDataToStorage(`${URL_ADDRESSES.GET_INFORMATION_ABOUT_WORKSPACE_BY_ID(workspaceId)}`)
      .catch((error) => {
        toast.error("Ошибка обновления данных о Workspace!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(getWorkspaceInformationFailure(error));
      })
      .then((result) => {
        if (result) {
          dispatch(getWorkspaceInformationSuccess(result.result));
          dispatch(updateFilteredTasks());
        }
        if (result.status === 1) {
          setNotAllowed = true;
          dispatch(notAllowedToSeeWorkspace(workspaceId));
          document.title = "Доступ заблокирован";
        }
      });
    if (!setNotAllowed) {
      dispatch(getWorkspaceSectionsStarted());
      await fetchDataToStorage(`${URL_ADDRESSES.GET_LIST_OF_SECTIONS_IN_WORKSPACE(workspaceId)}`)
        .catch((error) => {
          toast.error("Ошибка обновления данных о секциях!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          dispatch(getWorkspaceSectionsFailure(error));
        })
        .then((result) => {
          if (result) {
            dispatch(getWorkspaceSectionsSuccess(result.result));
            dispatch(updateFilteredTasks());
          }
        });
      await fetchDataToStorage(`${URL_ADDRESSES.GET_LIST_OF_TASKS_IN_WORKSPACE(workspaceId)}`)
        .catch((error) => {
          toast.error("Ошибка обновления данных о задачах!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          dispatch(getWorkspaceTasksListFailure(error));
        })
        .then((result) => {
          if (result) {
            dispatch(getWorkspaceTasksListSuccess(result.result));
            dispatch(updateFilteredTasks());
          }
          //dispatch(saveInitialSortedTasks());
        });
      dispatch(updateWorkspaceSuccess());
    }
  };
};

export const fetchTaskLogsStarted = () => ({
  type: FETCH_TASK_LOGS_START,
  loading: true,
  error: null
});

export const fetchTaskLogsFailure = (error) => ({
  type: FETCH_TASK_LOGS_FAILURE,
  loading: false,
  error: error
});

export const fetchTaskLogsSuccess = (logs) => ({
  type: FETCH_TASK_LOGS_SUCCESS,
  loading: false,
  error: null,
  logs: logs
});

export const fetchTaskLogs = (taskId) => {
  return (dispatch) => {
    dispatch(fetchTaskLogsStarted());
    fetchDataToStorage(`${URL_ADDRESSES.GET_TASK_LOGS(taskId)}`)
      .catch((error) => {
        toast.error("Ошибка получения логов задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(fetchTaskLogsFailure(error));
      })
      .then((result) => {
        if (result) dispatch(fetchTaskLogsSuccess(result.result));
      });
  };
};

export const deleteTaskFromWorkspaceStart = (taskId, deletedTaskIndex) => ({
  type: DELETE_TASK_FROM_WORKSPACE_START,
  loading: true,
  error: null,
  taskId: taskId,
  deletedTaskIndex: deletedTaskIndex
});

export const deleteTaskFromWorkspaceFailure = (error, taskObject, deletedTaskIndex) => ({
  type: DELETE_USER_FROM_WORKSPACE_FAILURE,
  loading: false,
  error: error,
  taskObject: taskObject,
  deletedTaskIndex: deletedTaskIndex
});

export const deleteTaskFromWorkspaceSuccess = () => ({
  type: DELETE_USER_FROM_WORKSPACE_STARTED,
  loading: false,
  error: null
});

export const deleteTaskFromWorkspace = (taskId) => {
  return (dispatch, getState) => {
    const { workspaceInformationReducer } = getState();
    const deletedTaskIndex = workspaceInformationReducer.tasks.findIndex((task) => task.task_id === taskId);
    const deletedTask = Object.assign({}, workspaceInformationReducer.tasks[deletedTaskIndex]);
    dispatch(hideModalWindow());
    //rework after refactoring!!!!!
    dispatch(
      changeOptionsToModalWindow({
        type: MODAL_WINDOW_TYPES.NOTIFICATION,
        closeHeaderButton: true,
        closeOnWrapperClick: true,
        text: "Модальное окно",
        header: "Модальное окно",
        showOkButton: true,
        showLeftSide: false,
        taskId: workspaceInformationReducer.tasks[0]
          ? workspaceInformationReducer.tasks[0].task_id
          : workspaceInformationReducer.tasks[1].task_id
      })
    );
    dispatch(deleteTaskFromWorkspaceStart(taskId, deletedTaskIndex));
    dispatch(updateFilteredTasks());
    fetchDeleteDataFromServer(`${URL_ADDRESSES.DELETE_TASK(taskId)}`, { task_id: taskId })
      .catch((error) => {
        toast.error("Ошибка удаления задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(deleteTaskFromWorkspaceFailure(error, deletedTask, deletedTaskIndex));
        dispatch(updateFilteredTasks());
      })
      .then((result) => {
        // if(typeof result === 'undefined') {
        // 	console.log('undefffff')
        // 	toast.error('Ошибка отправки данных на сервер!', {
        // 		position: 'top-right',
        // 		autoClose: 5000,
        // 		hideProgressBar: false,
        // 		closeOnClick: true,
        // 		pauseOnHover: true,
        // 		draggable: true,
        // 	});
        // }
        // else
        if (result) dispatch(deleteTaskFromWorkspaceSuccess());
      });
  };
};

export const deleteTaskFromUserTasksStart = (taskId, deletedTaskIndex) => ({
  type: DELETE_TASK_FROM_USER_TASKS_STARTED,
  loading: true,
  error: null,
  taskId: taskId,
  deletedTaskIndex: deletedTaskIndex
});

export const deleteTaskFromUserTasksFailure = (error, taskObject, deletedTaskIndex) => ({
  type: DELETE_TASK_FROM_WORKSPACE_FAILURE,
  loading: false,
  error: error,
  taskObject: taskObject,
  deletedTaskIndex: deletedTaskIndex
});

export const deleteTaskFromUserTasks = (taskId) => {
  return (dispatch, getState) => {
    const { userTasksReducer } = getState();
    const deletedTaskIndex = userTasksReducer.user_tasks.findIndex((task) => task.task_id === taskId);
    const deletedTask = Object.assign({}, userTasksReducer.user_tasks[deletedTaskIndex]);
    dispatch(hideModalWindow());
    //rework after refactoring!!!!!
    dispatch(
      changeOptionsToModalWindow({
        type: MODAL_WINDOW_TYPES.NOTIFICATION,
        closeHeaderButton: true,
        closeOnWrapperClick: true,
        text: "Модальное окно",
        header: "Модальное окно",
        showOkButton: true,
        showLeftSide: false
      })
    );
    dispatch(deleteTaskFromUserTasksStart(taskId, deletedTaskIndex));
    dispatch(filterUserTasksByFilter());
    dispatch(filterUserTasks());
    fetchDeleteDataFromServer(`${URL_ADDRESSES.DELETE_TASK(taskId)}`, { task_id: taskId })
      .catch((error) => {
        toast.error("Ошибка удаления задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(deleteTaskFromUserTasksFailure(error, deletedTask, deletedTaskIndex));
        dispatch(filterUserTasksByFilter());
        dispatch(filterUserTasks());
      })
      .then((result) => {
        // if(typeof result === 'undefined') {
        // 	console.log('undefffff')
        // 	toast.error('Ошибка отправки данных на сервер!', {
        // 		position: 'top-right',
        // 		autoClose: 5000,
        // 		hideProgressBar: false,
        // 		closeOnClick: true,
        // 		pauseOnHover: true,
        // 		draggable: true,
        // 	});
        // }
        // else
        // if(result) dispatch(deleteTaskFromUserTasksSuccess());
      });
  };
};

export const markTaskAsDoneStarted = (taskId, task_sort) => ({
  type: MARK_TASK_AS_DONE_START,
  loading: true,
  error: null,
  taskId: taskId,
  task_sort: task_sort
});

export const markTaskAsDoneFailure = (taskId, error, previousStatus, section_id) => ({
  type: MARK_TASK_AS_DONE_FAILURE,
  loading: false,
  error: error,
  taskId: taskId,
  previousStatus: previousStatus,
  section_id: section_id
});

export const markTaskAsDoneSuccess = (taskId, taskData) => ({
  type: MARK_TASK_AS_DONE_SUCCESS,
  loading: false,
  error: null,
  taskId: taskId,
  taskData: taskData
});

export const markTaskAsUnDoneStarted = (taskId, task_sort) => ({
  type: MARK_TASK_AS_UNDONE_START,
  loading: true,
  error: null,
  taskId: taskId,
  task_sort: task_sort
});

export const markTaskAsUnDoneFailure = (taskId, error, previousStatus, section_id) => ({
  type: MARK_TASK_AS_UNDONE_FAILURE,
  loading: false,
  error: error,
  taskId: taskId,
  previousStatus: previousStatus,
  section_id: section_id
});

export const markTaskAsUnDoneSuccess = (taskId, taskData) => ({
  type: MARK_TASK_AS_UNDONE_SUCCESS,
  loading: false,
  error: null,
  taskId: taskId,
  taskData: taskData
});

export const markTaskAsDone = (taskId, initialState, task_sort) => {
  return (dispatch, getState) => {
    const { workspaceInformationReducer } = getState();
    const previousStatus = workspaceInformationReducer.sortedTasks.find((task) => task.task_id === taskId).task_status;
    const previousSection = workspaceInformationReducer.sortedTasks.find(
      (task) => task.task_id === taskId
    ).workspace_section_id;
    dispatch(markTaskAsDoneStarted(taskId));
    fetchPostDataToServer(`${URL_ADDRESSES.PATCH_TASK_STATUS_TO_DONE(taskId)}`)
      .catch((error) => {
        toast.error("Ошибка изменения статуса задачи, для её завершения обязательно должен быть Исполнитель!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(
          markTaskAsDoneFailure(taskId, error, previousStatus, initialState ? initialState.section_id : previousSection)
        );
        if (initialState) {
          //dispatch(dragTaskSectionFailure(initialState))
          // dispatch(dragTaskSortFailure(initialState))
          //dispatch(dragTask(initialState.task_id, initialState.section_id, initialState.task_sort, initialState));
        }
      })
      .then((result) => {
        if (result) {
          dispatch(markTaskAsDoneSuccess(taskId, result.result));
          if (task_sort) {
            dispatch(dragTaskSort(taskId, task_sort, initialState));
          }
          dispatch(updateFilteredTasks());
        }
      });
  };
};

export const markTaskAsUnDone = (taskId, initialState, task_workspace_section_id, task_sort) => {
  return (dispatch, getState) => {
    const { workspaceInformationReducer } = getState();
    const previousStatus = workspaceInformationReducer.sortedTasks.find((task) => task.task_id === taskId).task_status;
    dispatch(markTaskAsUnDoneStarted(taskId));
    fetchPostDataToServer(
      `${URL_ADDRESSES.PATCH_TASK_STATUS_TO_UNDO(taskId)}`,
      task_workspace_section_id && { workspace_section_id: task_workspace_section_id }
    )
      .catch((error) => {
        toast.error("Ошибка изменения статуса задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(markTaskAsUnDoneFailure(taskId, error, previousStatus, initialState.section_id));
        if (initialState) {
          //dispatch(dragTaskSectionFailure(initialState))
          dispatch(dragTaskSortFailure(initialState));
          //dispatch(dragTask(initialState.task_id, initialState.section_id, initialState.task_sort, initialState));
        }
      })
      .then((result) => {
        if (result) {
          dispatch(markTaskAsUnDoneSuccess(taskId, result.result));
          if (task_sort) {
            dispatch(dragTaskSort(taskId, task_sort, initialState));
          }
          dispatch(updateFilteredTasks());
        }
      });
  };
};

export const markTaskAsDoneUserTasksStarted = (taskId) => {
  return {
    type: MARK_TASK_AS_DONE_USER_TASKS_START,
    taskId: taskId
  };
};

export const markTaskAsDoneUserTasksFailure = (taskId) => {
  return {
    type: MARK_TASK_AS_DONE_USER_TASKS_FAILURE,
    taskId: taskId
  };
};

export const markTaskAsDoneUserTasksSuccess = (taskId, taskData) => {
  return {
    type: MARK_TASK_AS_DONE_USER_TASKS_SUCCESS,
    taskId: taskId,
    taskData: taskData
  };
};

export const markTaskAsDoneUserTasks = (taskId) => {
  return (dispatch, getState) => {
    const { userTasksReducer } = getState();
    const previousStatus = userTasksReducer.user_tasks.find((task) => task.task_id === taskId).task_status;
    dispatch(markTaskAsDoneUserTasksStarted(taskId));
    dispatch(filterUserTasksByFilter());
    dispatch(filterUserTasks());
    fetchPostDataToServer(`${URL_ADDRESSES.PATCH_TASK_STATUS_TO_DONE(taskId)}`)
      .catch((error) => {
        toast.error("Ошибка изменения статуса задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(markTaskAsDoneUserTasksFailure(taskId, error, previousStatus));
        dispatch(filterUserTasks());
      })
      .then((result) => {
        if (result) {
          dispatch(markTaskAsDoneUserTasksSuccess(taskId, result.result));
          dispatch(filterUserTasks());
        }
      });
  };
};

export const markTaskAsUnDoneUserTasksStarted = (taskId) => ({
  type: MARK_TASK_AS_UNDONE_USER_TASKS_START,
  loading: true,
  error: null,
  taskId: taskId
});

export const markTaskAsUnDoneUserTasksFailure = (taskId, error, previousStatus) => ({
  type: MARK_TASK_AS_UNDONE_USER_TASKS_FAILURE,
  loading: false,
  error: error,
  taskId: taskId,
  previousStatus: previousStatus
});

export const markTaskAsUnDoneUserTasksSuccess = (taskId, taskData) => ({
  type: MARK_TASK_AS_UNDONE_USER_TASKS_SUCCESS,
  loading: false,
  error: null,
  taskId: taskId,
  taskData: taskData
});

export const markTaskAsUnDoneUserTasks = (taskId) => {
  return (dispatch, getState) => {
    const { userTasksReducer } = getState();
    const previousStatus = userTasksReducer.user_tasks.find((task) => task.task_id === taskId).task_status;
    dispatch(markTaskAsUnDoneUserTasksStarted(taskId));
    dispatch(filterUserTasksByFilter());
    dispatch(filterUserTasks());
    fetchPostDataToServer(`${URL_ADDRESSES.PATCH_TASK_STATUS_TO_UNDO(taskId)}`)
      .catch((error) => {
        toast.error("Ошибка изменения статуса задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(markTaskAsUnDoneUserTasksFailure(taskId, error, previousStatus));
        dispatch(filterUserTasks());
      })
      .then((result) => {
        if (result) {
          dispatch(markTaskAsUnDoneUserTasksSuccess(taskId, result.result));
          dispatch(filterUserTasks());
          dispatch(updateFilteredTasks());
        }
      });
  };
};

export const changeTaskPriorityStarted = (taskId, newPriorityId) => ({
  type: CHANGE_TASK_PRIORITY_START,
  loading: true,
  error: null,
  newPriority: newPriorityId,
  taskId: taskId
});

export const changeTaskPriorityFailure = (error, taskId, previousPriorityId) => ({
  type: CHANGE_TASK_PRIORITY_FAILURE,
  loading: false,
  error: { ...error },
  previousPriority: previousPriorityId,
  taskId: taskId
});

export const changeTaskPrioritySuccess = () => ({
  type: CHANGE_TASK_PRIORITY_SUCCESS,
  loading: false,
  error: null
});

export const changeTaskPriority = (currentPriorityId, selectedPriorityId, taskId) => {
  return (dispatch) => {
    dispatch(changeTaskPriorityStarted(taskId, selectedPriorityId));
    const newState = {
      task_id: taskId,
      task_priority_id: selectedPriorityId
    };
    dispatch(updateFilteredTasks());
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_TASK_PRIORITY_BY_TASK_ID(taskId)}`, newState)
      .catch((error) => {
        toast.error("Ошибка изменения приоритета задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(changeTaskPriorityFailure(error, taskId, currentPriorityId));
        dispatch(updateFilteredTasks());
      })
      .then((result) => {
        if (result) dispatch(changeTaskPrioritySuccess());
      });
  };
};

export const changeTaskPriorityUserTasksStarted = (taskId, newPriorityId) => ({
  type: CHANGE_TASK_PRIORITY_USER_TASKS_START,
  loading: true,
  error: null,
  newPriority: newPriorityId,
  taskId: taskId
});

export const changeTaskPriorityUserTasksFailure = (error, taskId, previousPriorityId) => ({
  type: CHANGE_TASK_PRIORITY_USER_TASKS_FAILURE,
  loading: false,
  error: { ...error },
  previousPriority: previousPriorityId,
  taskId: taskId
});

export const changeTaskPriorityUserTasksSuccess = () => ({
  type: CHANGE_TASK_PRIORITY_USER_TASKS_SUCCESS,
  loading: false,
  error: null
});

export const changeTaskPriorityUserTasks = (currentPriorityId, selectedPriorityId, taskId) => {
  return (dispatch) => {
    dispatch(changeTaskPriorityUserTasksStarted(taskId, selectedPriorityId));
    const newState = {
      task_id: taskId,
      task_priority_id: selectedPriorityId
    };
    dispatch(filterUserTasks());
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_TASK_PRIORITY_BY_TASK_ID(taskId)}`, newState)
      .catch((error) => {
        toast.error("Ошибка изменения приоритета задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(changeTaskPriorityUserTasksFailure(error, taskId, currentPriorityId));
        dispatch(filterUserTasks());
      })
      .then((result) => {
        if (result) dispatch(changeTaskPriorityUserTasksSuccess());
      });
  };
};

export const changeTaskDeadlineDateStart = (taskId, newDeadlineDate) => ({
  type: CHANGE_TASK_DEADLINE_DATE_START,
  loading: true,
  error: null,
  taskId: taskId,
  newDeadlineDate: newDeadlineDate
});

export const changeTaskDeadlineDateFailure = (error, taskId, previousDeadlineDate) => ({
  type: CHANGE_TASK_DEADLINE_DATE_FAILURE,
  loading: true,
  error: { ...error },
  taskId: taskId,
  previousDeadlineDate: previousDeadlineDate
});

export const changeTaskDeadlineDateSuccess = () => ({
  type: CHANGE_TASK_DEADLINE_DATE_SUCCESS,
  loading: false,
  error: null
});

export const changeTaskDeadlineDate = (taskId, previousDeadlineDate, newDeadlineDate) => {
  return (dispatch) => {
    dispatch(changeTaskDeadlineDateStart(taskId, newDeadlineDate));
    const newState = {
      task_id: taskId,
      task_deadline_dt: newDeadlineDate
    };
    dispatch(updateFilteredTasks());
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_TASK_DEADLINE_BY_TASK_ID(taskId)}`, newState)
      .catch((error) => {
        toast.error("Ошибка изменения срока сдачи задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(changeTaskDeadlineDateFailure(error, taskId, previousDeadlineDate));
        dispatch(updateFilteredTasks());
      })
      .then((result) => {
        if (result) dispatch(changeTaskDeadlineDateSuccess());
      });
  };
};

export const changeTaskDeadlineDateUserTasksStart = (taskId, newDeadlineDate) => {
  return {
    type: CHANGE_TASK_DEADLINE_DATE_USER_TASKS_START,
    loading: true,
    error: null,
    taskId: taskId,
    newDeadlineDate: newDeadlineDate
  };
};

export const changeTaskDeadlineDateUserTasksFailure = (error, taskId, previousDeadlineDate) => ({
  type: CHANGE_TASK_DEADLINE_DATE_USER_TASKS_FAILURE,
  loading: true,
  error: { ...error },
  taskId: taskId,
  previousDeadlineDate: previousDeadlineDate
});

export const changeTaskDeadlineDateUserTasksSuccess = () => ({
  type: CHANGE_TASK_DEADLINE_DATE_USER_TASKS_SUCCESS,
  loading: false,
  error: null
});

export const changeTaskDeadlineDateUserTasks = (taskId, previousDeadlineDate, newDeadlineDate) => {
  return (dispatch) => {
    dispatch(changeTaskDeadlineDateUserTasksStart(taskId, newDeadlineDate));
    dispatch(filterUserTasks());
    const newState = {
      task_id: taskId,
      task_deadline_dt: newDeadlineDate
    };
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_TASK_DEADLINE_BY_TASK_ID(taskId)}`, newState)
      .catch((error) => {
        toast.error("Ошибка изменения срока сдачи задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(changeTaskDeadlineDateUserTasksFailure(error, taskId, previousDeadlineDate));
      })
      .then((result) => {
        //dispatch(changeTaskDeadlineUserTasksDateSuccess());
      });
  };
};

export const unsetTaskDeadlineDateStart = (taskId) => {
  return {
    type: UNSET_TASK_DEADLINE_DATE_START,
    loading: true,
    error: null,
    taskId: taskId
  };
};

export const unsetTaskDeadlineDateFailure = (error, previousDate) => {
  return {
    type: UNSET_TASK_DEADLINE_DATE_FAILURE,
    loading: false,
    error: error,
    previousDate: previousDate
  };
};

export const unsetTaskDeadlineDateSuccess = (taskId, taskChanges) => {
  return {
    type: UNSET_TASK_DEADLINE_DATE_SUCCESS,
    taskId: taskId,
    taskChanges: taskChanges,
    loading: false,
    error: null
  };
};

export const unsetTaskDeadlineDate = (taskId) => {
  return (dispatch, getState) => {
    const { workspaceInformationReducer } = getState();
    const previousTaskDeadline = workspaceInformationReducer.tasks.find(
      (task) => task.task_id === taskId
    ).task_deadline_dt;
    dispatch(unsetTaskDeadlineDateStart(taskId));
    fetchDeleteDataFromServer(`${URL_ADDRESSES.DELETE_TASK_DEADLINE_BY_TASK_ID(taskId)}`)
      .catch((error) => {
        toast.error("Ошибка изменения срока сдачи задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(unsetTaskDeadlineDateFailure(error, previousTaskDeadline));
      })
      .then((result) => {
        if (result) {
          dispatch(unsetTaskDeadlineDateSuccess(taskId, result.result));
          dispatch(updateFilteredTasks());
        }
      });
  };
};

export const unsetTaskDeadlineDateUserTasksStart = (taskId) => {
  return {
    type: UNSET_TASK_DEADLINE_DATE_USER_TASKS_START,
    loading: true,
    error: null,
    taskId: taskId
  };
};

export const unsetTaskDeadlineDateUserTasksFailure = (error, previousDate) => {
  return {
    type: UNSET_TASK_DEADLINE_DATE_USER_TASKS_FAILURE,
    loading: false,
    error: error,
    previousDate: previousDate
  };
};

export const unsetTaskDeadlineDateUserTasksSuccess = (taskId, taskChanges) => {
  return {
    type: UNSET_TASK_DEADLINE_DATE_USER_TASKS_SUCCESS,
    taskId: taskId,
    taskChanges: taskChanges,
    loading: false,
    error: null
  };
};

export const unsetTaskDeadlineDateUserTasks = (taskId) => {
  return (dispatch, getState) => {
    const { userTasksReducer } = getState();
    const previousTaskDeadline = userTasksReducer.user_tasks.find((task) => task.task_id === taskId).task_deadline_dt;
    dispatch(unsetTaskDeadlineDateUserTasksStart(taskId));
    dispatch(filterUserTasksByFilter());
    dispatch(filterUserTasks());
    fetchDeleteDataFromServer(`${URL_ADDRESSES.DELETE_TASK_DEADLINE_BY_TASK_ID(taskId)}`)
      .catch((error) => {
        toast.error("Ошибка изменения срока сдачи задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(unsetTaskDeadlineDateUserTasksFailure(error, previousTaskDeadline));
        dispatch(filterUserTasksByFilter());
        dispatch(filterUserTasks());
      })
      .then((result) => {
        if (result) dispatch(unsetTaskDeadlineDateUserTasksSuccess(taskId, result.result));
      });
  };
};

export const changeTaskHeaderStart = (taskId, newHeader) => ({
  type: CHANGE_TASK_HEADER_START,
  loading: true,
  error: null,
  taskId: taskId,
  newHeader: newHeader
});

export const changeTaskHeaderFailure = (error, taskId, previousHeader) => ({
  type: CHANGE_TASK_HEADER_FAILURE,
  loading: true,
  error: { ...error },
  taskId: taskId,
  previousHeader: previousHeader
});

export const changeTaskHeaderSuccess = () => ({
  type: CHANGE_TASK_HEADER_SUCCESS,
  loading: false,
  error: null
});

export const changeTaskHeader = (taskId, newHeader, previousHeader) => {
  return (dispatch) => {
    dispatch(changeTaskHeaderStart(taskId, newHeader));
    const newState = { task_id: taskId, task_name: newHeader };
    dispatch(updateFilteredTasks());
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_TASK_NAME_BY_TASK_ID(taskId)}`, newState)
      .catch((error) => {
        toast.error("Ошибка изменения названия задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(changeTaskHeaderFailure(error, taskId, previousHeader));
        dispatch(updateFilteredTasks());
      })
      .then((result) => {
        if (result) dispatch(changeTaskHeaderSuccess());
      });
  };
};

export const changeUserTasksTaskHeader = (taskId, newHeader, previousHeader) => {
  return (dispatch) => {
    dispatch(changeTaskHeaderStart(taskId, newHeader));
    const newState = { task_id: taskId, task_name: newHeader };
    // dispatch(filterUserTasksByFilter())
    // dispatch(filterUserTasks())
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_TASK_NAME_BY_TASK_ID(taskId)}`, newState)
      .catch((error) => {
        toast.error("Ошибка изменения названия задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(changeTaskHeaderFailure(error, taskId, previousHeader));
        // dispatch(filterUserTasksByFilter())
        // dispatch(filterUserTasks())
      })
      .then((result) => {
        if (result) dispatch(changeTaskHeaderSuccess());
      });
  };
};

export const changeEditableTextState = (state) => ({
  type: CHANGE_EDITABLE_TEXT_STATE,
  editableTextState: state
});

export const changeTaskDescriptionStart = (taskId, newDescription) => ({
  type: CHANGE_TASK_DESCRIPTION_START,
  loading: true,
  error: null,
  taskId: taskId,
  newDescription: newDescription
});

export const changeTaskDescriptionFailure = (error, taskId, previousDescription) => ({
  type: CHANGE_TASK_DESCRIPTION_FAILURE,
  loading: true,
  error: { ...error },
  taskId: taskId,
  previousDescription: previousDescription
});

export const changeTaskDescriptionSuccess = () => ({
  type: CHANGE_TASK_DESCRIPTION_SUCCESS,
  loading: false,
  error: null
});

export const changeTaskDescription = (taskId, newDescription, previousDescription) => {
  return (dispatch) => {
    dispatch(changeTaskDescriptionStart(taskId, newDescription));
    const newState = { task_id: taskId, task_descr: newDescription };
    dispatch(updateFilteredTasks());
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_TASK_DESCRIPTION_BY_TASK_ID(taskId)}`, newState)
      .catch((error) => {
        toast.error("Ошибка отправки данных на сервер!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(changeTaskDescriptionFailure(error, taskId, previousDescription));
        dispatch(updateFilteredTasks());
      })
      .then((result) => {
        if (result) dispatch(changeTaskDescriptionSuccess());
      });
  };
};

export const changeUserTasksTaskDescription = (taskId, newDescription, previousDescription) => {
  return (dispatch) => {
    dispatch(changeTaskDescriptionStart(taskId, newDescription));
    const newState = { task_id: taskId, task_descr: newDescription };
    dispatch(filterUserTasksByFilter());
    dispatch(filterUserTasks());
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_TASK_DESCRIPTION_BY_TASK_ID(taskId)}`, newState)
      .catch((error) => {
        toast.error("Ошибка отправки данных на сервер!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(changeTaskDescriptionFailure(error, taskId, previousDescription));
        dispatch(filterUserTasksByFilter());
        dispatch(filterUserTasks());
      })
      .then((result) => {
        if (result) dispatch(changeTaskDescriptionSuccess());
      });
  };
};

export const changeTaskTopicStart = (taskId, newTopicId) => ({
  type: CHANGE_TASK_TOPIC_START,
  loading: true,
  error: null,
  taskId: taskId,
  newTopicId: newTopicId
});

export const changeTaskTopicFailure = (error, taskId, previousTopicId) => ({
  type: CHANGE_TASK_TOPIC_FAILURE,
  loading: true,
  error: { ...error },
  taskId: taskId,
  previousTopicId: previousTopicId
});

export const changeTaskTopicSuccess = () => ({
  type: CHANGE_TASK_TOPIC_SUCCESS,
  loading: false,
  error: null
});

export const changeTaskTopic = (taskId, newTopicId, previousTopicId) => {
  return (dispatch) => {
    dispatch(changeTaskTopicStart(taskId, newTopicId));

    const newState = {
      task_id: taskId,
      task_topic_id: newTopicId
    };
    dispatch(updateFilteredTasks());
    if (newTopicId) {
      fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_TASK_TOPIC_BY_TASK_ID(taskId)}`, newState)
        .catch((error) => {
          toast.error("Ошибка изменения темы задачи!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          dispatch(changeTaskTopicFailure(error, taskId, previousTopicId));
          dispatch(updateFilteredTasks());
        })
        .then((result) => {
          if (result) dispatch(changeTaskTopicSuccess());
        });
    } else {
      fetchDeleteDataFromServer(`${URL_ADDRESSES.PATCH_TASK_TOPIC_BY_TASK_ID(taskId)}`)
        .catch((error) => {
          toast.error("Ошибка удаления темы задачи!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          dispatch(changeTaskTopicFailure(error, taskId, previousTopicId));
          dispatch(updateFilteredTasks());
        })
        .then((result) => {
          if (result) dispatch(changeTaskTopicSuccess());
        });
    }
  };
};

export const changeTaskTopicUserTasksStart = (taskId, newTopicId) => ({
  type: CHANGE_TASK_TOPIC_USER_TASKS_START,
  loading: true,
  error: null,
  taskId: taskId,
  newTopicId: newTopicId
});

export const changeTaskTopicUserTasksFailure = (error, taskId, previousTopicId) => ({
  type: CHANGE_TASK_TOPIC_USER_TASKS_FAILURE,
  loading: true,
  error: { ...error },
  taskId: taskId,
  previousTopicId: previousTopicId
});

export const changeTaskTopicUserTasksSuccess = () => ({
  type: CHANGE_TASK_TOPIC_USER_TASKS_SUCCESS,
  loading: false,
  error: null
});

export const changeTaskTopicUserTasks = (taskId, newTopicId, previousTopicId) => {
  return (dispatch) => {
    dispatch(changeTaskTopicUserTasksStart(taskId, newTopicId));

    const newState = {
      task_id: taskId,
      task_topic_id: newTopicId
    };
    dispatch(filterUserTasks());
    if (newTopicId) {
      fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_TASK_TOPIC_BY_TASK_ID(taskId)}`, newState)
        .catch((error) => {
          toast.error("Ошибка изменения темы задачи!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          dispatch(changeTaskTopicUserTasksFailure(error, taskId, previousTopicId));
          dispatch(filterUserTasks());
        })
        .then((result) => {
          if (result) dispatch(changeTaskTopicUserTasksSuccess());
        });
    } else {
      fetchDeleteDataFromServer(`${URL_ADDRESSES.PATCH_TASK_TOPIC_BY_TASK_ID(taskId)}`)
        .catch((error) => {
          toast.error("Ошибка удаления темы задачи!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          dispatch(changeTaskTopicFailure(error, taskId, previousTopicId));
          dispatch(updateFilteredTasks());
        })
        .then((result) => {
          if (result) dispatch(changeTaskTopicSuccess());
        });
    }
  };
};

/*export const changeTaskTypeStart = (taskId, newTaskType) => ({
	type: CHANGE_TASK_TYPE_START,
	loading: true,
	error: null,
	taskId: taskId,
	newTaskType: newTaskType,
});

export const changeTaskTypFailure = (error, taskId, previousTaskType) => ({
	type: CHANGE_TASK_TYPE_FAILURE,
	loading: true,
	error: { ...error },
	taskId: taskId,
	previousTaskType: previousTaskType,
});

export const changeTaskTypSuccess = () => ({
	type: CHANGE_TASK_TYPE_SUCCESS,
	loading: false,
	error: null,
});

export const changeTaskTyp = (taskId, newTaskType, previousTaskType) => {
	return (dispatch) => {
		dispatch(changeTaskTopicStart(taskId, newTaskType));

		const newState = {
			task_id: taskId,
			task_topic_id: newTopicId,
		};

		fetchDataPatcherToServer(
			`${URL_ADDRESSES.PATCH_TASK_TOPIC_BY_TASK_ID(
				taskId
			)}`,
			newState
		)
			.catch((error) => {
				toast.error('Ошибка отправки данных на сервер!', {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				dispatch(changeTaskTopicFailure(error, taskId, previousTopicId));
			})
			.then((result) => {
				dispatch(changeTaskTopicSuccess());
				dispatch(updateFilteredTasks());
			});
	};
};*/

export const addNewTaskToWorkspaceStart = () => ({
  type: ADD_NEW_TASK_TO_WORKSPACE_START,
  loading: true,
  error: null
});

export const addNewTaskToWorkspaceFailure = (error) => ({
  type: ADD_NEW_TASK_TO_WORKSPACE_FAILURE,
  loading: true,
  error: { ...error }
});

export const addNewTaskToWorkspaceSuccess = (task) => ({
  type: ADD_NEW_TASK_TO_WORKSPACE_SUCCESS,
  loading: false,
  error: null,
  newTask: task
});

export const addNewCardToUserTasksSuccess = (task, isCalledFromCreateModal) => ({
  type: ADD_NEW_CARD_TO_USER_TASKS_SUCCESS,
  newTask: task,
  isCalledFromCreateModal: isCalledFromCreateModal
});

export const addNewTaskToWorkspace = ({
  workspaceId,
  sectionId,
  initiator_user_id = undefined,
  verificator_user_id = undefined,
  implementer_user_id = undefined,
  task_priority_id = null,
  task_topic_id = null,
  task_deadline_dt = null,
  task_name = "",
  task_descr = "",
  task_type = "REGULAR",
  parent_task_id = undefined,
  task_sort = undefined,
  isCalledFromUserTasks,
  isCalledFromCreateModal
}) => {
  return (dispatch) => {
    dispatch(addNewCardToSectionStart());
    const sendData = {
      // implementer_user_id: implementer_user_id,
      // verificator_user_id: null,
      // initiator_user_id: 1,
      // task_topic_id: task_topic_id,
      task_deadline_dt: task_deadline_dt,
      task_name: task_name,
      task_descr: task_descr ? task_descr : "",
      task_type: task_type,
      task_visibility_id: 1,
      parent_task_id: parent_task_id
    };

    //Not the best code
    if (implementer_user_id !== null) sendData.implementer_user_id = implementer_user_id;
    if (verificator_user_id !== null) sendData.verificator_user_id = verificator_user_id;
    if (initiator_user_id !== null) sendData.initiator_user_id = initiator_user_id;
    if (task_topic_id !== null) sendData.task_topic_id = task_topic_id;
    if (task_priority_id !== null) sendData.task_priority_id = task_priority_id;
    if (task_sort !== null) sendData.task_sort = task_sort;
    if (!isCalledFromUserTasks) sendData.workspace_section_id = sectionId;

    fetchPostDataToServer(
      `${workspaceId === 0 ? URL_ADDRESSES.POST_USER_TASKS : URL_ADDRESSES.POST_TASK_TO_WORKSPACE(workspaceId)}`,
      sendData
    )
      .catch((error) => {
        toast.error("Ошибка добавления новой задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(addNewCardToSectionFailure(error));
      })
      .then((result) => {
        if (result) {
          if (isCalledFromUserTasks) {
            dispatch(addNewCardToUserTasksSuccess(result.result, isCalledFromCreateModal));
            // dispatch(filterUserTasksByFilter());
            // dispatch(filterUserTasks());
          } else {
            dispatch(addNewCardToSectionSuccess(result.result, isCalledFromCreateModal));
            dispatch(updateFilteredTasks());
          }
        }
      });
  };
  // return (dispatch) => {
  // 	dispatch(addNewTaskToWorkspaceStart());
  // 	const sendData = {
  // 		task_deadline_dt: task_deadline_dt,
  // 		task_name: task_name,
  // 		task_descr: task_descr,
  // 		task_type: task_type,
  // 		workspace_section_id: sectionId,
  // 		initiator_user_id: initiator_user_id,
  // 		verificator_user_id: verificator_user_id,
  // 		implementer_user_id: implementer_user_id,
  // 		task_priority_id: task_priority_id,
  // 		task_topic_id: task_topic_id,
  // 		parent_task_id: parent_task_id,
  // 	};
  //
  // 	fetchPostDataToServer(
  // 		`${URL_ADDRESSES.POST_TASK_TO_WORKSPACE(
  // 			workspaceId
  // 		)}`,
  // 		sendData
  // 	)
  // 		.catch((error) => {
  // 			toast.error('Ошибка отправки данных на сервер!', {
  // 				position: 'top-right',
  // 				autoClose: 5000,
  // 				hideProgressBar: false,
  // 				closeOnClick: true,
  // 				pauseOnHover: true,
  // 				draggable: true,
  // 			});
  // 			dispatch(addNewTaskToWorkspaceFailure(error));
  // 		})
  // 		.then((result) => {
  // 			console.log('RESULT:', result);
  // 			dispatch(addNewTaskToWorkspaceSuccess(result.result));
  // 			dispatch(updateFilteredTasks());
  // 		});
  // };
};

export const addNewCardToSectionStart = () => ({
  type: ADD_NEW_CARD_TO_SECTION_START,
  loading: true,
  error: null
});

export const addNewCardToSectionFailure = (error) => ({
  type: ADD_NEW_CARD_TO_SECTION_FAILURE,
  loading: true,
  error: { ...error }
});

export const addNewCardToSectionSuccess = (task, isCalledFromCreateModal) => ({
  type: ADD_NEW_CARD_TO_SECTION_SUCCESS,
  loading: false,
  error: null,
  newTask: task,
  isCalledFromCreateModal: isCalledFromCreateModal
});

export const addNewCardToSection = (workspaceId, sectionId, userId) => {
  return (dispatch) => {
    dispatch(addNewCardToSectionStart());
    const sendData = {
      task_deadline_dt: null,
      // task_name: 'Название задания',
      // task_descr: 'Описание задания',
      task_type: "REGULAR",
      workspace_section_id: sectionId,
      initiator_user_id: userId
    };
    fetchPostDataToServer(`${URL_ADDRESSES.POST_TASK_TO_WORKSPACE(workspaceId)}`, sendData)
      .catch((error) => {
        toast.error("Ошибка добавления новой задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(addNewCardToSectionFailure(error));
      })
      .then((result) => {
        if (result) {
          dispatch(addNewCardToSectionSuccess(result.result));
          dispatch(updateFilteredTasks());
        }
      });
  };
};

export const resetNewTask = () => ({
  type: RESET_NEW_TASK
});

export const resetNewTaskUserTasks = () => ({
  type: RESET_NEW_TASK_USER_TASKS
});

export const resetNewSection = () => ({
  type: RESET_NEW_SECTION
});

export const changeTaskSectionStart = (taskId, newSectionId) => ({
  type: CHANGE_TASK_SECTION_START,
  loading: true,
  error: null,
  taskId: taskId,
  newSectionId: newSectionId
});

export const changeTaskSectionFailure = (error, taskId, previousSectionId) => ({
  type: CHANGE_TASK_SECTION_FAILURE,
  loading: false,
  error: error,
  taskId: taskId,
  previousSectionId: previousSectionId
});

export const changeTaskSectionSuccess = () => ({
  type: CHANGE_TASK_SECTION_SUCCESS,
  loading: false,
  error: null
});

export const changeTaskSection = (taskId, newSectionId, previousSectionId) => {
  return (dispatch) => {
    dispatch(changeTaskSectionStart(taskId, newSectionId));
    dispatch(updateFilteredTasks());
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_TASK_SECTION(taskId)}`, { workspace_section_id: newSectionId })
      .catch((error) => {
        toast.error("Ошибка изменения секции задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(changeTaskSectionFailure(error, taskId, previousSectionId));
        dispatch(updateFilteredTasks());
      })
      .then((result) => {
        if (result) dispatch(changeTaskSectionSuccess());
      });
  };
};

export const changeTaskVisibilityStart = (taskId, newVisibilityId) => ({
  type: CHANGE_TASK_VISIBILITY_START,
  loading: true,
  error: null,
  taskId: taskId,
  newVisibilityId: newVisibilityId
});

export const changeTaskVisibilityFailure = (error, taskId, previousVisibilityId) => ({
  type: CHANGE_TASK_VISIBILITY_FAILURE,
  loading: false,
  error: error,
  taskId: taskId,
  previousVisibilityId: previousVisibilityId
});

export const changeTaskVisibilitySuccess = () => ({
  type: CHANGE_TASK_VISIBILITY_SUCCESS,
  loading: false,
  error: null
});

export const changeTaskVisibility = (taskId, newVisibilityId, previousVisibilityId) => {
  return (dispatch) => {
    dispatch(changeTaskVisibilityStart(taskId, newVisibilityId));
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_TASK_VISIBILITY(taskId)}`, { task_visibility_id: newVisibilityId })
      .catch((error) => {
        toast.error("Ошибка изменения видимости задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(changeTaskVisibilityFailure(error, taskId, previousVisibilityId));
      })
      .then((result) => {
        //console.log('result', result);
        if (result) dispatch(changeTaskVisibilitySuccess());
      });
  };
};

export const acceptTaskStart = (taskId) => ({
  type: ACCEPT_ACTION_TASK_START,
  loading: true,
  error: null,
  taskId: taskId
});

export const acceptTaskFailure = (error, previousTaskStatus, previousTaskDecision) => ({
  type: ACCEPT_ACTION_TASK_FAILURE,
  loading: false,
  error: error,
  previousTaskStatus: previousTaskStatus,
  previousTaskDecision: previousTaskDecision
});

export const acceptTaskSuccess = (taskId, changedFields, parentTaskId, parentTaskChanges) => ({
  type: ACCEPT_ACTION_TASK_SUCCESS,
  loading: false,
  error: null,
  taskId: taskId,
  changedFields: changedFields,
  parentTaskId: parentTaskId,
  parentTaskChanges: parentTaskChanges
});

export const acceptTask = (taskId) => {
  return (dispatch, getState) => {
    const { workspaceInformationReducer } = getState();
    const currentTask = workspaceInformationReducer.tasks.find((task) => task.task_id === taskId);
    const previousTaskStatus = currentTask.task_status;
    const previousTaskDecision = currentTask.task_decision;
    dispatch(acceptTaskStart(taskId));
    fetchPostDataToServer(`${URL_ADDRESSES.POST_TASK_ACTION_ACCEPT(taskId)}`)
      .catch((error) => {
        toast.error("Ошибка изменения статуса задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(acceptTaskFailure(error, previousTaskStatus, previousTaskDecision));
      })
      .then((result) => {
        if (result) {
          let changedFields = {};
          let parentTaskChanges = {};
          for (let property in result.result) {
            if (property !== "task_id" && property !== "parent_task") {
              changedFields[property] = result.result[property];
            } else if (property === "parent_task") {
              parentTaskChanges = result.result[property];
            }
          }
          dispatch(acceptTaskSuccess(taskId, changedFields, parentTaskChanges.task_id, parentTaskChanges));
          dispatch(updateFilteredTasks());
        }
      });
  };
};

export const acceptTaskUserTasksStart = (taskId) => ({
  type: ACCEPT_ACTION_USER_TASK_START,
  loading: true,
  error: null,
  taskId: taskId
});

export const acceptTaskUserTasksFailure = (error, previousTaskStatus, previousTaskDecision) => ({
  type: ACCEPT_ACTION_USER_TASK_FAILURE,
  loading: false,
  error: error,
  previousTaskStatus: previousTaskStatus,
  previousTaskDecision: previousTaskDecision
});

export const acceptTaskUserTasksSuccess = (taskId, changedFields, parentTaskId, parentTaskChanges) => ({
  type: ACCEPT_ACTION_USER_TASK_SUCCESS,
  loading: false,
  error: null,
  taskId: taskId,
  changedFields: changedFields,
  parentTaskId: parentTaskId,
  parentTaskChanges: parentTaskChanges
});

export const acceptTaskUserTasks = (taskId) => {
  return (dispatch, getState) => {
    const { userTasksReducer } = getState();
    const currentTask = userTasksReducer.user_tasks.find((task) => task.task_id === taskId);
    const previousTaskStatus = currentTask.task_status;
    const previousTaskDecision = currentTask.task_decision;
    dispatch(acceptTaskUserTasksStart(taskId));
    fetchPostDataToServer(`${URL_ADDRESSES.POST_TASK_ACTION_ACCEPT(taskId)}`)
      .catch((error) => {
        toast.error("Ошибка изменения статуса задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(acceptTaskUserTasksFailure(error, previousTaskStatus, previousTaskDecision));
      })
      .then((result) => {
        if (result) {
          let changedFields = {};
          let parentTaskChanges = {};
          for (let property in result.result) {
            if (property !== "task_id" && property !== "parent_task") {
              changedFields[property] = result.result[property];
            } else if (property === "parent_task") {
              parentTaskChanges = result.result[property];
            }
          }
          dispatch(acceptTaskUserTasksSuccess(taskId, changedFields, parentTaskChanges.task_id, parentTaskChanges));
          dispatch(filterUserTasksByFilter());
          dispatch(filterUserTasks());
        }
      });
  };
};

export const declineTaskStart = (taskId) => ({
  type: DECLINE_ACTION_TASK_START,
  loading: true,
  error: null,
  taskId: taskId
});

export const declineTaskFailure = (error, previousTaskStatus, previousTaskDecision) => ({
  type: DECLINE_ACTION_TASK_FAILURE,
  loading: false,
  error: error,
  previousTaskStatus: previousTaskStatus,
  previousTaskDecision: previousTaskDecision
});

export const declineTaskSuccess = (taskId, changedFields, parentTaskId, parentTaskChanges) => ({
  type: DECLINE_ACTION_TASK_SUCCESS,
  loading: false,
  error: null,
  taskId: taskId,
  changedFields: changedFields,
  parentTaskId: parentTaskId,
  parentTaskChanges: parentTaskChanges
});

export const declineTask = (taskId, comment) => {
  return (dispatch, getState) => {
    const { workspaceInformationReducer } = getState();
    const currentTask = workspaceInformationReducer.tasks.find((task) => task.task_id === taskId);
    const previousTaskStatus = currentTask.task_status;
    const previousTaskDecision = currentTask.task_decision;
    dispatch(declineTaskStart(taskId));
    fetchPostDataToServer(`${URL_ADDRESSES.POST_TASK_ACTION_DECLINE(taskId)}`, { task_action_comment: comment })
      .catch((error) => {
        toast.error("Ошибка изменения статуса задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(declineTaskFailure(error, previousTaskStatus, previousTaskDecision));
      })
      .then((result) => {
        if (result) {
          let changedFields = {};
          let parentTaskChanges = {};
          for (let property in result.result) {
            if (property !== "task_id" && property !== "parent_task") {
              changedFields[property] = result.result[property];
            } else if (property === "parent_task") {
              parentTaskChanges = result.result[property];
            }
          }
          dispatch(declineTaskSuccess(taskId, changedFields, parentTaskChanges.task_id, parentTaskChanges));
          dispatch(updateFilteredTasks());
        }
      });
  };
};

export const declineUserTasksTaskStart = (taskId) => ({
  type: DECLINE_ACTION_USER_TASKS_TASK_START,
  loading: true,
  error: null,
  taskId: taskId
});

export const declineUserTasksTaskFailure = (error, previousTaskStatus, previousTaskDecision) => ({
  type: DECLINE_ACTION_USER_TASKS_TASK_FAILURE,
  loading: false,
  error: error,
  previousTaskStatus: previousTaskStatus,
  previousTaskDecision: previousTaskDecision
});

export const declineUserTasksTaskSuccess = (taskId, changedFields, parentTaskId, parentTaskChanges) => ({
  type: DECLINE_ACTION_USER_TASKS_TASK_SUCCESS,
  loading: false,
  error: null,
  taskId: taskId,
  changedFields: changedFields,
  parentTaskId: parentTaskId,
  parentTaskChanges: parentTaskChanges
});

export const declineUserTasksTask = (taskId, comment) => {
  return (dispatch, getState) => {
    const { userTasksReducer } = getState();
    const currentTask = userTasksReducer.user_tasks.find((task) => task.task_id === taskId);
    const previousTaskStatus = currentTask.task_status;
    const previousTaskDecision = currentTask.task_decision;
    dispatch(declineUserTasksTaskStart(taskId));
    fetchPostDataToServer(`${URL_ADDRESSES.POST_TASK_ACTION_DECLINE(taskId)}`, { task_action_comment: comment })
      .catch((error) => {
        toast.error("Ошибка изменения статуса задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(declineUserTasksTaskFailure(error, previousTaskStatus, previousTaskDecision));
      })
      .then((result) => {
        if (result) {
          let changedFields = {};
          let parentTaskChanges = {};
          for (let property in result.result) {
            if (property !== "task_id" && property !== "parent_task") {
              changedFields[property] = result.result[property];
            } else if (property === "parent_task") {
              parentTaskChanges = result.result[property];
            }
          }
          dispatch(declineUserTasksTaskSuccess(taskId, changedFields, parentTaskChanges.task_id, parentTaskChanges));
          dispatch(filterUserTasksByFilter());
          dispatch(filterUserTasks());
        }
      });
  };
};

export const dragTaskStart = (taskId, initialState) => ({
  type: DRAG_TASK_START,
  taskId: taskId,
  initialState: initialState
});

export const dragTaskSortFailure = (initialState) => ({
  type: DRAG_TASK_SORT_FAILURE,
  initialState: initialState
});

export const dragTaskSectionFailure = (initialState) => ({
  type: DRAG_TASK_SECTION_FAILURE,
  initialState: initialState
});

export const dragTaskSectionSuccess = (taskId) => ({
  type: DRAG_TASK_SECTION_SUCCESS,
  taskId: taskId
});

export const dragTaskSortSuccess = (taskId) => ({
  type: DRAG_TASK_SORT_SUCCESS,
  taskId: taskId
});

export const dragTaskSort = (taskId, taskSort, initialState) => {
  return (dispatch) => {
    dispatch(dragTaskStart(taskId, initialState));
    dispatch(updateFilteredTasks());
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_TASK_SORT(taskId)}`, { task_sort: taskSort })
      .catch((error) => {
        toast.error("Ошибка изменения сортировки задачи при перетаскивании!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(dragTaskSortFailure(initialState));
        dispatch(updateFilteredTasks());
      })
      .then((result) => {
        if (result) dispatch(dragTaskSortSuccess(taskId));
      });
  };
};

export const dragTask = (taskId, taskSection, taskSort, initialState, isDoneOrUndone) => {
  return (dispatch) => {
    dispatch(dragTaskStart(taskId, initialState));
    dispatch(updateFilteredTasks());
    if (!isDoneOrUndone) {
      fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_TASK_SECTION(taskId)}`, { workspace_section_id: taskSection })
        .catch((error) => {
          toast.error("Ошибка изменения секции задачи при перетаскивании!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          dispatch(dragTaskSectionFailure(initialState));
          dispatch(updateFilteredTasks());
        })
        .then((result) => {
          if (result) dispatch(dragTaskSectionSuccess(taskId));
        });
    }

    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_TASK_SORT(taskId)}`, { task_sort: taskSort })
      .catch((error) => {
        toast.error("Ошибка изменения сортировки задачи при перетаскивании!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(dragTaskSortFailure(initialState));
      })
      .then((result) => {
        if (result) dispatch(dragTaskSortSuccess(taskId));
      });
  };
};

export const moveTaskToAnotherWorkspaceStart = (taskId, workspaceId) => ({
  type: MOVE_TASK_TO_ANOTHER_WORKSPACE_START,
  taskId: taskId,
  workspaceId: workspaceId
});

export const moveTaskToAnotherWorkspace = (taskId, workspaceId) => {
  return (dispatch) => {
    dispatch(moveTaskToAnotherWorkspaceStart(taskId, workspaceId));
    dispatch(filterUserTasksByFilter());
    dispatch(filterUserTasks());
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_TASK_WORKSPACE(taskId)}`, { workspace_id: workspaceId })
      .catch((error) => {
        toast.error("Ошибка изменения Workspace задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        // dispatch(dragTaskSectionFailure(initialState))
        // dispatch(updateFilteredTasks())
      })
      .then((result) => {
        // if (result) dispatch(dragTaskSectionSuccess(taskId));
      });
  };
};

export const modifyWorkspaceInformationStart = (newWorkspaceInformation) => ({
  type: MODIFY_WORKSPACE_INFORMATION_START,
  loading: true,
  error: null,
  newWorkspaceInformation: newWorkspaceInformation
});

export const modifyWorkspaceInformationFailure = (error, previousWorkspaceInformation) => ({
  type: MODIFY_WORKSPACE_INFORMATION_FAILURE,
  loading: true,
  error: error,
  previousWorkspaceInformation: previousWorkspaceInformation
});

export const modifyWorkspaceInformationSuccess = (newWorkspaceData) => ({
  type: MODIFY_WORKSPACE_INFORMATION_SUCCESS,
  loading: false,
  error: null,
  newWorkspaceData: newWorkspaceData
});

export const modifyWorkspaceInformation = (
  workspaceId,
  newWorkspaceInformation,
  previousWorkspaceInformation,
  onSuccessfulModify = () => {}
) => {
  return (dispatch) => {
    dispatch(modifyWorkspaceInformationStart(newWorkspaceInformation));

    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_WORKSPACE_INFORMATION_BY_ID(workspaceId)}`, newWorkspaceInformation)
      .catch((error) => {
        toast.error("Ошибка изменения данных о workspace!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(modifyWorkspaceInformationFailure(error, previousWorkspaceInformation));
      })
      .then((result) => {
        if (result) {
          dispatch(modifyWorkspaceInformationSuccess());
          onSuccessfulModify();
          dispatch(updateFilteredTasks());
        }
      });
  };
};

export const modifyWorkspaceNameStart = (workspaceId, newWorkspaceName) => ({
  type: MODIFY_WORKSPACE_NAME_START,
  loading: true,
  error: null,
  newWorkspaceName: newWorkspaceName,
  workspaceId: workspaceId
});

export const modifyWorkspaceNameSuccess = () => ({
  type: MODIFY_WORKSPACE_NAME_SUCCESS,
  loading: false,
  error: null
});

export const modifyWorkspaceNameFailure = (error, previousWorkspaceName) => ({
  type: MODIFY_WORKSPACE_NAME_FAILURE,
  loading: false,
  error: error,
  previousWorkspaceName: previousWorkspaceName
});

export const modifyWorkspaceName = (workspaceId, newWorkspaceName, previousWorkspaceName) => {
  return (dispatch) => {
    dispatch(modifyWorkspaceNameStart(workspaceId, newWorkspaceName));
    dispatch(updateFilteredTasks());
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_WORKSPACE_NAME_BY_ID(workspaceId)}`, {
      workspace_name: newWorkspaceName
    })
      .catch((error) => {
        toast.error("Ошибка изменения имени workspace!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(modifyWorkspaceNameFailure(error, previousWorkspaceName));
        dispatch(updateFilteredTasks());
      })
      .then((result) => {
        if (result) dispatch(modifyWorkspaceNameSuccess());
      });
  };
};

export const modifyWorkspaceStatusStart = (workspaceId, newWorkspaceStatus) => ({
  type: MODIFY_WORKSPACE_STATUS_START,
  loading: true,
  error: null,
  newWorkspaceStatus: newWorkspaceStatus,
  workspaceId: workspaceId
});

export const modifyWorkspaceStatusSuccess = (workspace) => ({
  type: MODIFY_WORKSPACE_STATUS_SUCCESS,
  loading: false,
  error: null,
  workspace: workspace
});

export const modifyWorkspaceStatusFailure = (error, previousWorkspaceStatus) => ({
  type: MODIFY_WORKSPACE_STATUS_FAILURE,
  loading: false,
  error: error,
  previousWorkspaceStatus: previousWorkspaceStatus
});

export const modifyWorkspaceStatus = (workspaceId, newWorkspaceStatus, previousWorkspaceStatus) => {
  return (dispatch) => {
    dispatch(modifyWorkspaceStatusStart(workspaceId, newWorkspaceStatus));
    dispatch(updateFilteredTasks());

    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_WORKSPACE_STATUS_BY_ID(workspaceId)}`, {
      workspace_status_id: newWorkspaceStatus
    })
      .catch((error) => {
        toast.error("Ошибка изменения статуса workspace!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(modifyWorkspaceStatusFailure(error, previousWorkspaceStatus));
        dispatch(updateFilteredTasks());
      })
      .then((result) => {
        if (result.status === 0) dispatch(modifyWorkspaceStatusSuccess(result.result));
        else {
          toast.error("Ошибка изменения статуса workspace!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          modifyWorkspaceStatusFailure("error", previousWorkspaceStatus);
        }
      });
  };
};

export const modifyWorkspaceNotes = (workspaceId, newWorkspaceNotes) => ({
  type: MODIFY_WORKSPACE_NOTES,
  newWorkspaceNotes: newWorkspaceNotes,
  workspaceId: workspaceId
});

export const modifyWorkspaceGithubLinkStart = (workspaceId, newWorkspaceGithubLink) => ({
  type: MODIFY_WORKSPACE_GITHUB_START,
  loading: true,
  error: null,
  newWorkspaceGithubLink: newWorkspaceGithubLink,
  workspaceId: workspaceId
});

export const modifyWorkspaceGithubLinkSuccess = () => ({
  type: MODIFY_WORKSPACE_GITHUB_SUCCESS,
  loading: false,
  error: null
});

export const modifyWorkspaceGithubLinkFailure = (error, previousWorkspaceGithubLink) => ({
  type: MODIFY_WORKSPACE_GITHUB_FAILURE,
  loading: false,
  error: error,
  previousWorkspaceGithubLink: previousWorkspaceGithubLink
});

export const modifyWorkspaceGithubLink = (workspaceId, newWorkspaceGithubLink, previousWorkspaceGithubLink) => {
  return (dispatch) => {
    dispatch(modifyWorkspaceGithubLinkStart(workspaceId, newWorkspaceGithubLink));
    dispatch(updateFilteredTasks());

    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_WORKSPACE_GITHUB_BY_ID(workspaceId)}`, {
      workspace_github_url: newWorkspaceGithubLink
    })
      .catch((error) => {
        toast.error("Ошибка изменения ссылки на github у workspace!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(modifyWorkspaceGithubLinkFailure(error, previousWorkspaceGithubLink));
        dispatch(updateFilteredTasks());
      })
      .then((result) => {
        if (result) dispatch(modifyWorkspaceGithubLinkSuccess());
      });
  };
};

export const modifySectionInWorkspaceStart = (sectionId, newState) => ({
  type: MODIFY_SECTION_IN_WORKSPACE_START,
  loading: true,
  error: null,
  sectionId: sectionId,
  newState: newState
});

export const modifySectionInWorkspaceFailure = (error, sectionId, previousState) => ({
  type: MODIFY_SECTION_IN_WORKSPACE_FAILURE,
  loading: true,
  error: { ...error },
  sectionId: sectionId,
  previousState: previousState
});

export const modifySectionInWorkspaceSuccess = () => ({
  type: MODIFY_SECTION_IN_WORKSPACE_SUCCESS,
  loading: false,
  error: null
});

export const modifySectionInWorkspace = (workspaceId, sectionId, newState, previousState) => {
  return (dispatch) => {
    dispatch(modifySectionInWorkspaceStart(sectionId, newState));

    fetchDataPatcherToServer(
      `${URL_ADDRESSES.PATCH_SECTION_BY_SECTION_ID_AND_WORKSPACE_ID(workspaceId, sectionId)}`,
      newState
    )
      .catch((error) => {
        toast.error("Ошибка изменения секции!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(modifySectionInWorkspaceFailure(error, workspaceId, sectionId, previousState));
      })
      .then((result) => {
        if (result) {
          dispatch(modifySectionInWorkspaceSuccess());
          dispatch(updateFilteredTasks());
        }
      });
  };
};

export const deleteSectionFromWorkspaceStart = (tasks, sectionId) => ({
  type: DELETE_SECTION_FROM_WORKSPACE_START,
  loading: true,
  error: null,
  updatedTasks: tasks,
  sectionId: sectionId
});

export const deleteSectionFromWorkspaceFailure = (error) => ({
  type: DELETE_SECTION_FROM_WORKSPACE_FAILURE,
  loading: true,
  error: { ...error }
});

export const deleteSectionFromWorkspaceSuccess = () => ({
  type: DELETE_SECTION_FROM_WORKSPACE_SUCCESS,
  loading: false,
  error: null
});

export const deleteSectionFromWorkspace = (workspaceId, sectionId) => {
  return (dispatch, getState) => {
    const { workspaceInformationReducer } = getState();
    const currentSection = workspaceInformationReducer.sectionsList.find(
      (section) => section.workspace_section_id === sectionId
    );
    if (currentSection.workspace_section_type !== "CUSTOM") {
      toast.error("Эту секцию нельзя удалить!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }
    dispatch(deleteSectionFromWorkspaceStart([], sectionId));
    dispatch(updateFilteredTasks());
    fetchDeleteDataFromServer(
      `${URL_ADDRESSES.DELETE_SECTION_BY_SECTION_ID_AND_WORKSPACE_ID(workspaceId, sectionId)}`,
      null
    )
      .catch((error) => {
        toast.error("Ошибка удаления секции!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(deleteSectionFromWorkspaceFailure(error));
        dispatch(updateFilteredTasks());
        //console.log('delete error', error);
      })
      .then((result) => {
        if (result) {
          //console.log('RESULT OF DELETE SECTION:', result);
          if (result.result.deleted === true) {
            dispatch(deleteSectionFromWorkspaceSuccess());
          } else {
            toast.error("Секция не удалена!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
            dispatch(deleteSectionFromWorkspaceFailure(result.result));
          }
        }
      });
  };
};

export const addNewSectionToWorkspaceStart = () => ({
  type: ADD_NEW_SECTION_TO_WORKSPACE_START,
  loading: true,
  error: null
});

export const addNewSectionToWorkspaceFailure = (error) => ({
  type: ADD_NEW_SECTION_TO_WORKSPACE_FAILURE,
  loading: true,
  error: { ...error }
});

export const addNewSectionToWorkspaceSuccess = (section) => ({
  type: ADD_NEW_SECTION_TO_WORKSPACE_SUCCESS,
  loading: false,
  error: null,
  newSection: section
});

export const addNewSectionToWorkspace = (workspaceId, sectionName, sectionSort) => {
  return (dispatch) => {
    dispatch(addNewSectionToWorkspaceStart());
    fetchPostDataToServer(`${URL_ADDRESSES.POST_SECTION_IN_WORKSPACE(workspaceId)}`, {
      workspace_section_name: sectionName,
      workspace_section_sort: sectionSort
    })
      .catch((error) => {
        toast.error("Ошибка добавления новой секции!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(addNewSectionToWorkspaceFailure(error));
      })
      .then((result) => {
        //console.log('ADD SECTION RESULT:', result);
        if (result) {
          dispatch(addNewSectionToWorkspaceSuccess(result.result));
          dispatch(sortSectionsInWorkpace());
          dispatch(updateFilteredTasks());
        }
      });
  };
};

export const sortSectionsInWorkpace = () => {
  return {
    type: SORT_SECTIONS_IN_WORKSPACE
  };
};

export const changeFilterOption = (filterOptionName, filterOptionValue, userId) => {
  return {
    type: CHANGE_FILTER_OPTION,
    filterOptionName: filterOptionName,
    filterOptionValue: filterOptionValue,
    userId: userId
  };
};

export const changeFilterOptionSortableHeader = (filterOptionName, filterOptionValue) => {
  return {
    type: CHANGE_FILTER_OPTION,
    filterOptionName: filterOptionName,
    filterOptionValue: filterOptionValue
  };
};

export const changeFilterOptionUserTasks = (filterOptionName, filterOptionValue) => {
  return {
    type: CHANGE_FILTER_OPTION_USER_TASKS,
    filterOptionName: filterOptionName,
    filterOptionValue: filterOptionValue
  };
};

export const filterUserTasksByFilter = () => {
  return {
    type: FILTER_USER_TASKS_BY_FILTER
  };
};

export const updateFilteredTasks = () => {
  return {
    type: UPDATE_FILTERED_TASKS
  };
};

export const resetFilterOptions = () => {
  return {
    type: RESET_FILTER_OPTIONS
  };
};

export const updateFilteredTasksSortableHeader = () => {
  return {
    type: UPDATE_FILTERED_TASKS_SORTABLE_HEADER
  };
};

export const setFilterOption = (filterOptionName, filterOptionValue, userId) => {
  return (dispatch) => {
    dispatch(changeFilterOption(filterOptionName, filterOptionValue, userId));
    dispatch(updateFilteredTasks());
  };
};

export const setFilterOptionSortableHeader = (filterOptionName, filterOptionValue) => {
  return (dispatch) => {
    dispatch(changeFilterOption(filterOptionName, filterOptionValue));
    dispatch(updateFilteredTasks());
    // dispatch(updateFilteredTasksSortableHeader());
  };
};

export const fetchWorkspacesDataStart = (addloader) => {
  return {
    type: FETCH_WORKSPACES_DATA_START,
    loading: addloader,
    error: null
  };
};

export const fetchWorkspacesDataFailure = (error) => {
  return {
    type: FETCH_WORKSPACES_DATA_FAILURE,
    loading: false,
    error: error
  };
};

export const updateWorkspacesData = () => {
  return {
    type: UPDATE_WORKSPACES
  };
};

export const fetchWorkspacesDataSuccess = (workspaces) => {
  return {
    type: FETCH_WORKSPACES_DATA_SUCCESS,
    loading: false,
    error: null,
    workspaces: workspaces
  };
};

export const dragWorkspaceFailure = (workspaceId, workspaceSort) => {
  return {
    type: DRAG_WORKSPACE_FAILURE,
    workspaceSort: workspaceSort,
    workspaceId: workspaceId
  };
};

export const dragWorkspace = (workspaceId, workspaceSort, initialWorkspaceSort) => {
  return (dispatch) => {
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_WORKSPACE_SORT(workspaceId)}`, { workspace_sort: workspaceSort })
      .catch((error) => {
        toast.error("Ошибка изменения сортировки Workspace!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(dragWorkspaceFailure(workspaceId, initialWorkspaceSort));
        dispatch(updateFilteredTasks());
      })
      .then((result) => {
        // if(result) dispatch(dragTaskSortSuccess(taskId));
      });
  };
};

export const fetchWorkspacesData = (addloader) => {
  return (dispatch) => {
    const TASK_NAME = "fetchWorkspacesData";
    if (addloader) dispatch(addWorkspaceGlobalLoader(TASK_NAME));
    dispatch(fetchWorkspacesDataStart(addloader));
    fetchDataToStorage(`${URL_ADDRESSES.GET_WORKSPACES}`)
      .catch((error) => {
        toast.error("Ошибка получения данных о workspace!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(fetchWorkspacesDataFailure(error));
      })
      .then((result) => {
        if (result) {
          dispatch(fetchWorkspacesDataSuccess(result.result));
          dispatch(deleteWorkspaceGlobalLoader(TASK_NAME));
        }
      });
  };
};

export const resetNewWorkspace = () => {
  return {
    type: RESET_NEW_WORKSPACE
  };
};

export const setImplementerToTaskStart = (implementerId, taskId) => {
  return {
    type: SET_IMPLEMENTER_TO_TASK_START,
    implementerId: implementerId,
    taskId: taskId,
    loading: true,
    error: null
  };
};

export const setImplementerToTaskFailure = (error) => {
  return {
    type: SET_IMPLEMENTER_TO_TASK_FAILURE,
    loading: false,
    error: error
  };
};

export const setImplementerToTaskSuccess = () => {
  return {
    type: SET_IMPLEMENTER_TO_TASK_SUCCESS,
    loading: false,
    error: null
  };
};

export const setImplementerToUserTasksTaskStart = (implementerId, taskId) => {
  return {
    type: SET_IMPLEMENTER_TO_USER_TASKS_TASK_START,
    implementerId: implementerId,
    taskId: taskId,
    loading: true,
    error: null
  };
};

export const setImplementerUserTasksToTaskFailure = () => {
  return {
    type: SET_IMPLEMENTER_TO_USER_TASKS_TASK_FAILURE
  };
};

export const setImplementerUserTasksToTaskSuccess = () => {
  return {
    type: SET_IMPLEMENTER_TO_USER_TASKS_TASK_SUCCESS
  };
};

export const setImplementerToTaskUserTasks = (taskId, userId) => {
  return (dispatch) => {
    dispatch(setImplementerToUserTasksTaskStart(userId, taskId));
    dispatch(filterUserTasksByFilter());
    dispatch(filterUserTasks());
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_TASK_USER_IMPLEMENTER(taskId)}`, { implementer_user_id: userId })
      .catch((error) => {
        toast.error("Ошибка изменения исполнителя задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(setImplementerUserTasksToTaskFailure(error));
        dispatch(filterUserTasksByFilter());
        dispatch(filterUserTasks());
      })
      .then((result) => {
        if (result) {
          dispatch(setImplementerUserTasksToTaskSuccess());
          dispatch(getInfoAboutTaskUserTasks(taskId));
          dispatch(filterUserTasksByFilter());
          dispatch(filterUserTasks());
        }
      });
  };
};

export const unsetImplementerToTaskUserTasksStart = (taskId) => {
  return {
    type: UNSET_IMPLEMENTER_TO_TASK_USER_TASKS_START,
    loading: true,
    error: null,
    taskId: taskId
  };
};

export const unsetImplementerToTaskUserTasksFailure = (error, implementerUserId) => {
  return {
    type: UNSET_IMPLEMENTER_TO_TASK_USER_TASKS_FAILURE,
    loading: false,
    error: error,
    implementerUserId: implementerUserId
  };
};

export const unsetImplementerToTaskUserTasksSuccess = (taskId, taskChanges) => {
  return {
    type: UNSET_IMPLEMENTER_TO_TASK_USER_TASKS_SUCCESS,
    taskId: taskId,
    taskChanges: taskChanges,
    loading: false,
    error: null
  };
};

export const unsetImplementerToTaskUserTasks = (taskId) => {
  return (dispatch, getState) => {
    const { userTasksReducer } = getState();
    const previousImplementerUserId = userTasksReducer.user_tasks.find(
      (task) => task.task_id === taskId
    ).implementer_user_id;
    dispatch(unsetImplementerToTaskUserTasksStart(taskId));
    dispatch(filterUserTasksByFilter());
    dispatch(filterUserTasks());
    fetchDeleteDataFromServer(`${URL_ADDRESSES.DELETE_TASK_USER_IMPLEMENTER(taskId)}`)
      .catch((error) => {
        toast.error("Ошибка удаления исполнителя задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(unsetImplementerToTaskUserTasksFailure(error, previousImplementerUserId));
        dispatch(filterUserTasksByFilter());
        dispatch(filterUserTasks());
      })
      .then((result) => {
        if (result) {
          dispatch(unsetImplementerToTaskUserTasksSuccess(taskId, result.result));
          dispatch(getInfoAboutTaskUserTasks(taskId));
          dispatch(filterUserTasksByFilter());
          dispatch(filterUserTasks());
        }
      });
  };
};

export const setVerificatorToTaskUserTasksStart = (verificatorId, taskId) => {
  return {
    type: SET_VERIFICATOR_TO_TASK_USER_TASKS_START,
    verificatorId: verificatorId,
    taskId: taskId
  };
};

export const setVerificatorToTaskUserTasksFailure = (error) => {
  return {
    type: SET_VERIFICATOR_TO_TASK_USER_TASKS_FAILURE,
    loading: false,
    error: error
  };
};

export const setVerificatorToTaskUserTasksSuccess = () => {
  return {
    type: SET_VERIFICATOR_TO_TASK_USER_TASKS_SUCCESS,
    loading: false,
    error: null
  };
};

export const setVerificatorToTaskUserTasks = (taskId, userId) => {
  return (dispatch) => {
    dispatch(setVerificatorToTaskUserTasksStart(userId, taskId));
    dispatch(filterUserTasksByFilter());
    dispatch(filterUserTasks());
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_TASK_USER_VERIFICATOR(taskId)}`, { verificator_user_id: userId })
      .catch((error) => {
        toast.error("Ошибка изменения верификатора задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(setVerificatorToTaskUserTasksFailure(error));
        dispatch(filterUserTasksByFilter());
        dispatch(filterUserTasks());
      })
      .then((result) => {
        if (result) {
          dispatch(setVerificatorToTaskUserTasksSuccess());
          // dispatch(getInfoAboutTaskUserTasks(taskId));
          dispatch(filterUserTasksByFilter());
          dispatch(filterUserTasks());
        }
      });
  };
};

export const unsetVerificatorToTaskUserTasksStart = (taskId) => {
  return {
    type: UNSET_VERIFICATOR_TO_TASK_USER_TASKS_START,
    loading: true,
    error: null,
    taskId: taskId
  };
};

export const unsetVerificatorToTaskUserTasksFailure = (error, verificatorUserId) => {
  return {
    type: UNSET_VERIFICATOR_TO_TASK_USER_TASKS_FAILURE,
    loading: false,
    error: error,
    verificatorUserId: verificatorUserId
  };
};

export const unsetVerificatorToTaskUserTasksSuccess = (taskId, taskChanges) => {
  return {
    type: UNSET_VERIFICATOR_TO_TASK_USER_TASKS_SUCCESS,
    taskId: taskId,
    taskChanges: taskChanges,
    loading: false,
    error: null
  };
};

export const unsetVerificatorToTaskUserTasks = (taskId) => {
  return (dispatch, getState) => {
    const { userTasksReducer } = getState();
    const previousVerificatorUserId = userTasksReducer.user_tasks.find(
      (task) => task.task_id === taskId
    ).verificator_user_id;
    dispatch(unsetVerificatorToTaskUserTasksStart(taskId));
    dispatch(filterUserTasksByFilter());
    dispatch(filterUserTasks());
    fetchDeleteDataFromServer(`${URL_ADDRESSES.DELETE_TASK_USER_VERIFICATOR(taskId)}`)
      .catch((error) => {
        toast.error("Ошибка удаления верификатора задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(unsetVerificatorToTaskUserTasksFailure(error, previousVerificatorUserId));
        dispatch(filterUserTasksByFilter());
        dispatch(filterUserTasks());
      })
      .then((result) => {
        if (result) {
          dispatch(unsetVerificatorToTaskUserTasksSuccess(taskId, result.result));
          dispatch(getInfoAboutTaskUserTasks(taskId));
          dispatch(filterUserTasksByFilter());
          dispatch(filterUserTasks());
        }
      });
  };
};

export const setInitiatorToTaskUserTasksStart = (initiatorId, taskId) => {
  return {
    type: SET_INITIATOR_TO_TASK_USER_TASKS_START,
    initiatorId: initiatorId,
    taskId: taskId,
    loading: true,
    error: null
  };
};

export const setInitiatorToTaskUserTasksFailure = (error) => {
  return {
    type: SET_INITIATOR_TO_TASK_USER_TASKS_FAILURE,
    loading: false,
    error: error
  };
};

export const setInitiatorToTaskUserTasksSuccess = () => {
  return {
    type: SET_INITIATOR_TO_TASK_USER_TASKS_SUCCESS,
    loading: false,
    error: null
  };
};

export const setInitiatorToTaskUserTasks = (taskId, userId) => {
  return (dispatch) => {
    dispatch(setInitiatorToTaskUserTasksStart(userId, taskId));
    dispatch(filterUserTasksByFilter());
    dispatch(filterUserTasks());
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_TASK_USER_INITIATOR(taskId)}`, { initiator_user_id: userId })
      .catch((error) => {
        toast.error("Ошибка изменения инициатора задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(setInitiatorToTaskUserTasksFailure(error));
        dispatch(filterUserTasksByFilter());
        dispatch(filterUserTasks());
      })
      .then((result) => {
        if (result) {
          dispatch(setInitiatorToTaskUserTasksSuccess());
          dispatch(getInfoAboutTaskUserTasks(taskId));
          dispatch(filterUserTasksByFilter());
          dispatch(filterUserTasks());
        }
      });
  };
};

export const unsetInitiatorToTaskUserTasksStart = (taskId) => {
  return {
    type: UNSET_INITIATOR_TO_TASK_USER_TASKS_START,
    loading: true,
    error: null,
    taskId: taskId
  };
};

export const unsetInitiatorToTaskUserTasksFailure = (error, initiatorUserId) => {
  return {
    type: UNSET_INITIATOR_TO_TASK_USER_TASKS_FAILURE,
    loading: false,
    error: error,
    initiatorUserId: initiatorUserId
  };
};

export const unsetInitiatorToTaskUserTasksSuccess = (taskId, taskChanges) => {
  return {
    type: UNSET_INITIATOR_TO_TASK_USER_TASKS_SUCCESS,
    taskId: taskId,
    taskChanges: taskChanges,
    loading: false,
    error: null
  };
};

export const unsetInitiatorToTaskUserTasks = (taskId) => {
  return (dispatch, getState) => {
    const { userTasksReducer } = getState();
    const previousInitiatorUserId = userTasksReducer.user_tasks.find(
      (task) => task.task_id === taskId
    ).initiator_user_id;
    dispatch(unsetInitiatorToTaskStart(taskId));
    dispatch(filterUserTasksByFilter());
    dispatch(filterUserTasks());
    fetchDeleteDataFromServer(`${URL_ADDRESSES.DELETE_TASK_USER_INITIATOR(taskId)}`)
      .catch((error) => {
        toast.error("Ошибка удаления инициатора задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(unsetInitiatorToTaskFailure(error, previousInitiatorUserId));
        dispatch(filterUserTasksByFilter());
        dispatch(filterUserTasks());
      })
      .then((result) => {
        if (result) {
          dispatch(unsetInitiatorToTaskSuccess(taskId, result.result));
          dispatch(getInfoAboutTaskUserTasks(taskId));
          dispatch(filterUserTasksByFilter());
          dispatch(filterUserTasks());
        }
      });
  };
};

export const setImplementerToTask = (taskId, userId) => {
  return (dispatch) => {
    dispatch(setImplementerToTaskStart(userId, taskId));
    dispatch(updateFilteredTasks());
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_TASK_USER_IMPLEMENTER(taskId)}`, { implementer_user_id: userId })
      .catch((error) => {
        toast.error("Ошибка изменения исполнителя задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(setImplementerToTaskFailure(error));
        dispatch(updateFilteredTasks());
      })
      .then((result) => {
        if (result) {
          dispatch(setImplementerToTaskSuccess());
          dispatch(getInfoAboutTask(taskId));
        }
      });
  };
};

export const unsetImplementerToTaskStart = (taskId) => {
  return {
    type: UNSET_IMPLEMENTER_TO_TASK_START,
    loading: true,
    error: null,
    taskId: taskId
  };
};

export const unsetImplementerToTaskFailure = (error, implementerUserId) => {
  return {
    type: UNSET_IMPLEMENTER_TO_TASK_FAILURE,
    loading: false,
    error: error,
    implementerUserId: implementerUserId
  };
};

export const unsetImplementerToTaskSuccess = (taskId, taskChanges) => {
  return {
    type: UNSET_IMPLEMENTER_TO_TASK_SUCCESS,
    taskId: taskId,
    taskChanges: taskChanges,
    loading: false,
    error: null
  };
};

export const unsetImplementerToTask = (taskId) => {
  return (dispatch, getState) => {
    const { workspaceInformationReducer } = getState();
    const previousImplementerUserId = workspaceInformationReducer.tasks.find(
      (task) => task.task_id === taskId
    ).implementer_user_id;
    dispatch(unsetImplementerToTaskStart(taskId));
    dispatch(updateFilteredTasks());
    fetchDeleteDataFromServer(`${URL_ADDRESSES.DELETE_TASK_USER_IMPLEMENTER(taskId)}`)
      .catch((error) => {
        toast.error("Ошибка удаления исполнителя задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(unsetImplementerToTaskFailure(error, previousImplementerUserId));
        dispatch(updateFilteredTasks());
      })
      .then((result) => {
        if (result) {
          dispatch(unsetImplementerToTaskSuccess(taskId, result.result));
          dispatch(getInfoAboutTask(taskId));
        }
      });
  };
};

export const setVerificatorToTaskStart = (verificatorId, taskId) => {
  return {
    type: SET_VERIFICATOR_TO_TASK_START,
    verificatorId: verificatorId,
    taskId: taskId,
    loading: true,
    error: null
  };
};

export const setVerificatorToTaskFailure = (error) => {
  return {
    type: SET_VERIFICATOR_TO_TASK_FAILURE,
    loading: false,
    error: error
  };
};

export const setVerificatorToTaskSuccess = () => {
  return {
    type: SET_VERIFICATOR_TO_TASK_SUCCESS,
    loading: false,
    error: null
  };
};

export const setVerificatorToTask = (taskId, userId) => {
  return (dispatch) => {
    dispatch(setVerificatorToTaskStart(userId, taskId));
    dispatch(updateFilteredTasks());
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_TASK_USER_VERIFICATOR(taskId)}`, { verificator_user_id: userId })
      .catch((error) => {
        toast.error("Ошибка изменения верификатора задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(setVerificatorToTaskFailure(error));
        dispatch(updateFilteredTasks());
      })
      .then((result) => {
        if (result) {
          dispatch(setVerificatorToTaskSuccess());
          dispatch(getInfoAboutTask(taskId));
        }
      });
  };
};

export const unsetVerificatorToTaskStart = (taskId) => {
  return {
    type: UNSET_VERIFICATOR_TO_TASK_START,
    loading: true,
    error: null,
    taskId: taskId
  };
};

export const unsetVerificatorToTaskFailure = (error, verificatorUserId) => {
  return {
    type: UNSET_VERIFICATOR_TO_TASK_FAILURE,
    loading: false,
    error: error,
    verificatorUserId: verificatorUserId
  };
};

export const unsetVerificatorToTaskSuccess = (taskId, taskChanges) => {
  return {
    type: UNSET_VERIFICATOR_TO_TASK_SUCCESS,
    taskId: taskId,
    taskChanges: taskChanges,
    loading: false,
    error: null
  };
};

export const unsetVerificatorToTask = (taskId) => {
  return (dispatch, getState) => {
    const { workspaceInformationReducer } = getState();
    const previousVerificatorUserId = workspaceInformationReducer.tasks.find(
      (task) => task.task_id === taskId
    ).verificator_user_id;
    dispatch(unsetVerificatorToTaskStart(taskId));
    dispatch(updateFilteredTasks());
    fetchDeleteDataFromServer(`${URL_ADDRESSES.DELETE_TASK_USER_VERIFICATOR(taskId)}`)
      .catch((error) => {
        toast.error("Ошибка удаления верификатора задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(unsetVerificatorToTaskFailure(error, previousVerificatorUserId));
        dispatch(updateFilteredTasks());
      })
      .then((result) => {
        if (result) {
          dispatch(unsetVerificatorToTaskSuccess(taskId, result.result));
          dispatch(getInfoAboutTask(taskId));
        }
      });
  };
};

export const setInitiatorToTaskStart = (initiatorId, taskId) => {
  return {
    type: SET_INITIATOR_TO_TASK_START,
    initiatorId: initiatorId,
    taskId: taskId,
    loading: true,
    error: null
  };
};

export const setInitiatorToTaskFailure = (error) => {
  return {
    type: SET_INITIATOR_TO_TASK_FAILURE,
    loading: false,
    error: error
  };
};

export const setInitiatorToTaskSuccess = () => {
  return {
    type: SET_INITIATOR_TO_TASK_SUCCESS,
    loading: false,
    error: null
  };
};

export const setInitiatorToTask = (taskId, userId) => {
  return (dispatch) => {
    dispatch(setInitiatorToTaskStart(userId, taskId));
    dispatch(updateFilteredTasks());
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_TASK_USER_INITIATOR(taskId)}`, { initiator_user_id: userId })
      .catch((error) => {
        toast.error("Ошибка изменения инициатора задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(setInitiatorToTaskFailure(error));
        dispatch(updateFilteredTasks());
      })
      .then((result) => {
        if (result) {
          dispatch(setInitiatorToTaskSuccess());
          dispatch(getInfoAboutTask(taskId));
        }
      });
  };
};

export const unsetInitiatorToTaskStart = (taskId) => {
  return {
    type: UNSET_INITIATOR_TO_TASK_START,
    loading: true,
    error: null,
    taskId: taskId
  };
};

export const unsetInitiatorToTaskFailure = (error, initiatorUserId) => {
  return {
    type: UNSET_INITIATOR_TO_TASK_FAILURE,
    loading: false,
    error: error,
    initiatorUserId: initiatorUserId
  };
};

export const unsetInitiatorToTaskSuccess = (taskId, taskChanges) => {
  return {
    type: UNSET_INITIATOR_TO_TASK_SUCCESS,
    taskId: taskId,
    taskChanges: taskChanges,
    loading: false,
    error: null
  };
};

export const unsetInitiatorToTask = (taskId) => {
  return (dispatch, getState) => {
    const { workspaceInformationReducer } = getState();
    const previousInitiatorUserId = workspaceInformationReducer.tasks.find(
      (task) => task.task_id === taskId
    ).initiator_user_id;
    dispatch(unsetInitiatorToTaskStart(taskId));
    fetchDeleteDataFromServer(`${URL_ADDRESSES.DELETE_TASK_USER_INITIATOR(taskId)}`)
      .catch((error) => {
        toast.error("Ошибка удаления инициатора задачи!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(unsetInitiatorToTaskFailure(error, previousInitiatorUserId));
      })
      .then((result) => {
        if (result) {
          dispatch(unsetInitiatorToTaskSuccess(taskId, result.result));
          dispatch(getInfoAboutTask(taskId));
        }
      });
  };
};

/* WORKSPACES */

export const createNewWorkspaceStart = () => {
  return {
    type: CREATE_NEW_WORKSPACE_START,
    loading: true,
    error: null
  };
};

export const createNewWorkspaceFailure = (error) => {
  return {
    type: CREATE_NEW_WORKSPACE_FAILURE,
    loading: false,
    error: error
  };
};

export const createNewWorkspaceSuccess = (newWorkspace) => {
  return {
    type: CREATE_NEW_WORKSPACE_SUCCESS,
    newWorkspace: newWorkspace,
    loading: false,
    error: null
  };
};

export const createNewWorkspace = (workspaceName, githubLink, projectId = null) => {
  return (dispatch) => {
    dispatch(createNewWorkspaceStart());
    const data = {
      workspace_name: workspaceName.trim()
    };
    if (githubLink !== "") data.workspace_github_url = githubLink;
    fetchPostDataToServer(`${URL_ADDRESSES.POST_CREATE_NEW_WORKSPACE}`, data)
      .catch((error) => {
        toast.error("Ошибка добавления нового workspace, скорее всего workspace с таким названием уже существует!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(createNewWorkspaceFailure(error));
      })
      .then((result) => {
        if (result) {
          dispatch(createNewWorkspaceSuccess(result.result));
          dispatch(hideModalWindow());
          dispatch(fetchWorkspacesData());
        }
        //window.history.replaceState(window.location.hostname,'title','/workspace/'+result.result.workspace_id)
        //window.location.href = result.result.workspace_id
      });
  };
};

export const searchWorkspace = (query) => {
  return {
    type: SEARCH_WORKSPACE,
    searchQuery: query
  };
};

export const resetSearchWorkspaces = () => {
  return {
    type: RESET_SEARCH_WORKSPACES
  };
};

export const changeDisplayState = (state) => {
  return {
    type: CHANGE_DISPLAY_STATE,
    displayState: state
  };
};

export const changeAuthorizationState = (state, token, data, url) => {
  return {
    type: AUTHORIZATION,
    authorized: state,
    token: token,
    user_id: data.user_id,
    company_id: data.company_id,
    role_id: data.role_id,
    url: url
  };
};

export const authorizeUserStart = () => {
  return {
    type: AUTHORIZE_USER_START,
    loading: true,
    error: null
  };
};

export const authorizeUserSuccess = () => {
  return {
    type: AUTHORIZE_USER_SUCCESS,
    loading: false,
    error: null
  };
};

export const authorizeUserFailure = (error) => {
  return {
    type: AUTHORIZE_USER_FAILURE,
    loading: false,
    error: error
  };
};

export const authorizeUser = (userEmail, userPassword) => {
  return (dispatch) => {
    dispatch(authorizeUserStart());
    const data = {
      user_email: userEmail,
      user_password: userPassword
    };
    fetchPostDataToServer(`${URL_ADDRESSES.POST_AUTHORIZE_USER}`, data)
      .catch((error) => {
        // toast.error('Ошибка отправки данных на сервер!', {
        // 	position: 'top-right',
        // 	autoClose: 5000,
        // 	hideProgressBar: false,
        // 	closeOnClick: true,
        // 	pauseOnHover: true,
        // 	draggable: true,
        // });
        dispatch(authorizeUserFailure(error));
      })
      .then((result) => {
        if (result && result.status === 0) {
          dispatch(authorizeUserSuccess());
          dispatch(checkToken(result.result.token));
        }
      });
  };
};

export const checkToken = (token, url) => {
  return (dispatch) => {
    fetchDataToStorage(`${URL_ADDRESSES.CHECK_TOKEN}`, "", token)
      .catch((error) => {
        toast.error("Ошибка проверки токена пользователя!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(authorizeUserFailure());
      })
      .then((result) => {
        if (result && result.status === 0) {
          if (result.result.user_instance) {
            dispatch(changeAuthorizationState(true, token, result.result.user_instance, url));
            dispatch(getUserRankInfo(result.result.user_instance.user_id));
            // setInterval(()=>dispatch(getUserRankInfo(result.result.user_instance.user_id)), 10*60*1000)
          }
          // if(result.result === 'Authorization error. Token not valid') {
          //     document.cookie = "token=;path=/"
          //     // window.location.href = '/auth'
          // }

          // dispatch(checkTokenSuccess());
          // dispatch(changeAuthorizationState(true, result.result.token));
        }
      });
  };
};

export const logoutUser = () => {
  return {
    type: AUTHORIZATION,
    authorized: false
  };
};

export const getUserInfoSuccess = (data) => {
  return {
    type: GET_USER_INFO_SUCCESS,
    user_info: data
  };
};

export const getUserTasksSuccess = (data) => {
  return {
    type: GET_USER_TASKS_SUCCESS,
    loading: false,
    user_tasks: data
  };
};

export const getUserTasksFailure = () => {
  return {
    type: GET_USER_TASKS_FAILURE,
    loading: false,
    error: "getUserTasksError"
  };
};

export const getAllTaskPriorityStatusesSuccess = (data) => {
  return {
    type: GET_ALL_TASK_PRIORITY_STATUSES_SUCCESS,
    data: data
  };
};

export const getAllTaskTopicsSuccess = (data) => {
  return {
    type: GET_ALL_TASK_TOPICS_SUCCESS,
    data: data
  };
};

export const getAllUsersFromCompanySuccess = (data) => {
  return {
    type: GET_ALL_USERS_FROM_COMPANY_SUCCESS,
    data: data.filter((user) => user.user_status === "ACTIVE")
  };
};

export const getUserTasksStarted = () => {
  return {
    type: GET_USER_TASKS_START
  };
};

export const updateUserTasks = (user_id) => {
  return async (dispatch) => {
    await fetchDataToStorage(`${URL_ADDRESSES.GET_TASKS_BY_USER_ID(user_id)}`)
      .catch((error) => {
        toast.error("Ошибка получения задач пользователя!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(getUserTasksFailure());
      })
      .then((result) => {
        if (result) {
          if (result.status === 0) {
            dispatch(getUserTasksSuccess(result.result));
            dispatch(filterUserTasksByFilter());
            dispatch(filterUserTasks());
          } else dispatch(getUserTasksFailure());
        }
      });
  };
};

export const getInfoAboutTaskSuccess = (task_id, data) => {
  return {
    type: GET_INFO_ABOUT_TASK_SUCCESS,
    taskId: task_id,
    data: data
  };
};

export const getInfoAboutTask = (task_id) => {
  return (dispatch) => {
    fetchDataToStorage(`${URL_ADDRESSES.GET_INFORMATION_ABOUT_TASK_BY_ID(task_id)}`)
      .catch((error) => {
        toast.error("Ошибка обновления данных о задаче!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        if (result) {
          dispatch(getInfoAboutTaskSuccess(task_id, result.result));
          dispatch(updateFilteredTasks());
        }
      });
  };
};

export const getInfoAboutTaskUserTasksSuccess = (task_id, data) => {
  return {
    type: GET_INFO_ABOUT_TASK_USER_TASKS_SUCCESS,
    taskId: task_id,
    data: data
  };
};

export const getInfoAboutTaskUserTasks = (task_id) => {
  return (dispatch) => {
    fetchDataToStorage(`${URL_ADDRESSES.GET_INFORMATION_ABOUT_TASK_BY_ID(task_id)}`)
      .catch((error) => {
        toast.error("Ошибка обновления данных о задаче!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        if (result) {
          dispatch(getInfoAboutTaskUserTasksSuccess(task_id, result.result));
          dispatch(updateFilteredTasks());
        }
      });
  };
};

export const setUserTasksLoader = (state) => {
  return {
    type: SET_USER_TASKS_LOADER,
    loader: state
  };
};

export const getUserTasks = (user_id) => {
  return async (dispatch) => {
    dispatch(getUserTasksStarted());
    await fetchDataToStorage(`${URL_ADDRESSES.GET_TASKS_BY_USER_ID(user_id)}`)
      .catch((error) => {
        toast.error("Ошибка получения задач пользователя!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        if (result) {
          if (result.status === 0) {
            dispatch(getUserTasksSuccess(result.result));
            dispatch(filterUserTasksByFilter());
            dispatch(filterUserTasks());
          } else dispatch(getUserTasksFailure());
        }
      });
  };
};

export const getInfoWithUserTasks = () => {
  return async (dispatch) => {
    dispatch(setUserTasksLoader(true));
    await fetchDataToStorage(`${URL_ADDRESSES.GET_LIST_OF_TASK_PRIORITY_STATUSES}`)
      .catch((error) => {
        toast.error("Ошибка получения данных о списке приоритетов!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        if (result) dispatch(getAllTaskPriorityStatusesSuccess(result.result));
      });
    await fetchDataToStorage(`${URL_ADDRESSES.GET_LIST_OF_TOPICS}`)
      .catch((error) => {
        toast.error("Ошибка получения данных о темах задач!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        if (result) dispatch(getAllTaskTopicsSuccess(result.result));
      });
    await fetchDataToStorage(
      `${URL_ADDRESSES.GET_USERS_FROM_COMPANY_BY_ID(1)}` ///HARDCODED
    )
      .catch((error) => {
        toast.error("Ошибка получения данных о пользователях компании!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        if (result) {
          result.result.forEach((user) => {
            if (user.user_photo !== null) {
              user.user_photo = USERS_URL + `${user.user_photo}`;
            }
          });
          dispatch(getAllUsersFromCompanySuccess(result.result));
        }
      });
  };
};

export const fetchUserTasks = (user_id) => {
  return async (dispatch) => {
    await dispatch(setUserTasksLoader(true));
    await fetchDataToStorage(`${URL_ADDRESSES.GET_LIST_OF_TASK_PRIORITY_STATUSES}`)
      .catch((error) => {
        toast.error("Ошибка получения данных о списке приоритетов!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        if (result) dispatch(getAllTaskPriorityStatusesSuccess(result.result));
      });
    await fetchDataToStorage(`${URL_ADDRESSES.GET_LIST_OF_TOPICS}`)
      .catch((error) => {
        toast.error("Ошибка получения данных о темах задач!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        if (result) dispatch(getAllTaskTopicsSuccess(result.result));
      });
    await fetchDataToStorage(
      `${URL_ADDRESSES.GET_USERS_FROM_COMPANY_BY_ID(1)}` ///HARDCODED
    )
      .catch((error) => {
        toast.error("Ошибка получения данных о пользователях компании!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        if (result) {
          result.result.forEach((user) => {
            if (user.user_photo !== null) {
              user.user_photo = USERS_URL + `${user.user_photo}`;
            }
          });
          dispatch(getAllUsersFromCompanySuccess(result.result));
        }
      });
    await dispatch(getUserTasks(user_id));
    await dispatch(setUserTasksLoader(false));
  };
};

export const searchUserTasks = (query) => {
  return {
    type: SEARCH_USER_TASKS,
    query: query
  };
};

export const resetFilterOptionsUserTasks = (all_users) => {
  return {
    type: RESET_FILTER_OPTIONS_USER_TASKS,
    all_users: all_users
  };
};

export const getInfoAboutUser = (user_id) => {
  return (dispatch) => {
    fetchDataToStorage(`${URL_ADDRESSES.GET_INFO_ABOUT_USER_BY_USER_ID(user_id)}`)
      .catch((error) => {
        toast.error("Ошибка получения информации о пользователе!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        if (result) dispatch(getUserInfoSuccess(result.result));
      });
  };
};

export const resetUserTasksError = () => ({
  type: RESET_USER_TASKS_ERROR
});

export const getTasksOfAllCompanyUsersSuccess = (tasks) => {};

export const getTasksOfAllCompanyUsers = () => {
  return async (dispatch) => {
    await fetchDataToStorage(`${URL_ADDRESSES.GET_TASKS_OF_ALL_USERS}`)
      .catch((error) => {
        toast.error("Ошибка получения списка задач по всем пользователям!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        if (result.status === 0) {
          dispatch(getUserTasksSuccess(result.result));
          dispatch(setUserTasksLoader(false));
          // dispatch(resetFilterOptionsUserTasks(true))
          dispatch(filterUserTasksByFilter());
          dispatch(filterUserTasks());
        } else dispatch(getUserTasksFailure());
      });
  };
};

export const filterUserTasks = () => {
  return {
    type: FILTER_USER_TASKS
  };
};

export const getAvailableLeadsStart = () => {
  return {
    type: GET_AVAILABLE_LEADS_START
  };
};

export const loadAvailableLeadsStarted = (status) => ({
  type: LOAD_AVAILABLE_LEADS_STARTED,
  status: status
});

export const getAvailableLeadsSuccess = (data, status_gte) => {
  return {
    type: GET_AVAILABLE_LEADS_SUCCESS,
    leads: data.results,
    next_page: data.next,
    status: status_gte
  };
};

export const getInfoAboutLeadSourcesSuccess = (sources) => {
  return {
    type: GET_INFO_ABOUT_LEAD_SOURCES,
    leadSources: sources
  };
};

export const getInfoAboutLeadStatusesSuccess = (statuses) => {
  return {
    type: GET_INFO_ABOUT_LEAD_STATUSES,
    leadStatuses: statuses
  };
};

export const getInfoAboutLeadTopicsSuccess = (topics) => {
  return {
    type: GET_INFO_ABOUT_LEAD_TOPICS,
    leadTopics: topics
  };
};

export const getInfoAboutLeadCurrenciesSuccess = (currencies) => {
  return {
    type: GET_INFO_ABOUT_LEAD_CURRENCIES,
    leadCurrencies: currencies
  };
};

export const getInfoAboutMessagesStatuses = (messageStatuses) => {
  return {
    type: GET_INFO_ABOUT_MESSAGES_STATUSES,
    messageStatuses: messageStatuses
  };
};

export const removeLeadsLoading = () => {
  return {
    type: REMOVE_LEADS_LOADING_STATE
  };
};

export const loadLeads = ({
  shouldShowLoader,
  status_lte,
  status_gte,
  page,
  displayState,
  days = null,
  user_id = null,
  reply_user_id = null,
  sourceId = null,
  statusId = null,
  price_lte = null,
  price_gte = null,
  text = null,
  topicsList = []
}) => {
  return (dispatch) => {
    shouldShowLoader && dispatch(loadAvailableLeadsStarted(status_gte));
    fetchDataToStorage(
      `${URL_ADDRESSES.GET_LIST_OF_FILTERED_LEADS({
        status_lte: status_lte,
        status_gte: status_gte,
        page: page,
        days: days,
        user_id: user_id,
        reply_user_id: reply_user_id,
        sourceId: sourceId,
        status: statusId,
        price_lte: price_lte,
        price_gte: price_gte,
        text: text,
        topicsList: topicsList
      })}`
    )
      .catch((error) => {
        toast.error("Ошибка получения информации о лидах!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        if (!displayState) dispatch(getAvailableLeadsSuccess(result, status_gte));
        else return result;
        // dispatch(setSelectedLead(result.filter((lead)=>lead.status <= 3)[0].id))
        // if (result) dispatch(getUserInfoSuccess(result.result))
      });
  };
};

export const loadAvailableLeadsInformation = () => {
  return async (dispatch) => {
    dispatch(getAvailableLeadsStart());

    await fetchDataToStorage(`${URL_ADDRESSES.GET_LIST_OF_LEAD_SOURCES}`)
      .catch((error) => {
        toast.error("Ошибка получения информации о списке источников!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        dispatch(getInfoAboutLeadSourcesSuccess(result));
        // if (result) dispatch(getUserInfoSuccess(result.result))
      });
    await fetchDataToStorage(`${URL_ADDRESSES.GET_LIST_OF_LEAD_STATUSES}`)
      .catch((error) => {
        toast.error("Ошибка получения информации о списке статусов!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        dispatch(getInfoAboutLeadStatusesSuccess(result));
        // if (result) dispatch(getUserInfoSuccess(result.result))
      });
    await fetchDataToStorage(`${URL_ADDRESSES.GET_LIST_OF_LEAD_TOPICS}`)
      .catch((error) => {
        toast.error("Ошибка получения информации о списке тем!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        dispatch(getInfoAboutLeadTopicsSuccess(result));
        // if (result) dispatch(getUserInfoSuccess(result.result))
      });
    await fetchDataToStorage(`${URL_ADDRESSES.GET_LIST_OF_LEAD_CURRENCIES}`)
      .catch((error) => {
        toast.error("Ошибка получения информации о списке валют!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        dispatch(getInfoAboutLeadCurrenciesSuccess(result));
        // if (result) dispatch(getUserInfoSuccess(result.result))
      });
    await fetchDataToStorage(`${URL_ADDRESSES.GET_LIST_OF_MESSAGES_STATUSED}`)
      .catch((error) => {
        toast.error("Ошибка получения списка статусов сообщений!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        dispatch(getInfoAboutMessagesStatuses(result));
        // if (result) dispatch(getUserInfoSuccess(result.result))
      });
    await dispatch(getInfoAboutUnreadDialogs());
    await dispatch(removeLeadsLoading());
  };
};

export const getInfoAboutUnreadDialogs = () => {
  return (dispatch) => {
    fetchDataToStorage(`${URL_ADDRESSES.GET_COUNT_OF_UNREAD_DIALOGS}`)
      .catch((error) => {
        toast.error("Ошибка получения количества непрочитанных диалогов!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        dispatch(getInfoAboutUnreadDialogsSuccess(result.count));
        // if (result) dispatch(getUserInfoSuccess(result.result))
      });
  };
};

export const getInfoAboutUnreadDialogsSuccess = (count) => {
  return {
    type: GET_INFO_ABOUT_UNREAD_DIALOGS,
    count: count
  };
};

export const setLeadsDisplayState = (displayState) => {
  return {
    type: CHANGE_LEADS_DISPLAY_STATE,
    displayState: displayState
  };
};

export const showLeadModalWindow = (showModal) => {
  return {
    type: SHOW_LEAD_MODAL_WINDOW,
    showModal: showModal
  };
};

export const setSelectedLead = (leadId) => {
  return {
    type: SET_SELECTED_LEAD,
    selectedLead: leadId
  };
};

export const setLeadSourceStart = (leadId, sourceId, status) => {
  return {
    type: SET_LEAD_SOURCE_START,
    leadId: leadId,
    sourceId: sourceId,
    status: status
  };
};

export const setLeadSourceFailure = (leadId, previousSourceId, status) => {
  return {
    type: SET_LEAD_SOURCE_FAILURE,
    leadId: leadId,
    previousSourceId: previousSourceId,
    status: status
  };
};

export const setLeadSource = (leadId, sourceId, previousSourceId, status) => {
  return (dispatch) => {
    dispatch(setLeadSourceStart(leadId, sourceId, status));
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_LEAD_SOURCE(leadId)}`, { source_id: sourceId })
      .catch((error) => {
        toast.error("Ошибка изменения источника лида!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(setLeadSourceFailure(leadId, previousSourceId, status));
      })
      .then((result) => {
        // if(result) dispatch(changeTaskPrioritySuccess());
      });
  };
};

export const setLeadTopicStart = (leadId, topicId, status) => {
  return {
    type: SET_LEAD_TOPIC_START,
    leadId: leadId,
    topicId: topicId,
    status: status
  };
};

export const setLeadTopicFailure = (leadId, previousTopicId, status) => {
  return {
    type: SET_LEAD_TOPIC_FAILURE,
    leadId: leadId,
    previousTopicId: previousTopicId,
    status: status
  };
};

export const setLeadTopic = (leadId, topicId, previousTopicId, status) => {
  return (dispatch) => {
    dispatch(setLeadTopicStart(leadId, topicId, status));
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_LEAD_TOPIC(leadId)}`, { topic_id: topicId })
      .catch((error) => {
        toast.error("Ошибка изменения темы лида!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(setLeadTopicFailure(leadId, previousTopicId, status));
      })
      .then((result) => {
        // if(result) dispatch(changeTaskPrioritySuccess());
      });
  };
};

export const setLeadStatusStart = (leadId, statusId, status) => {
  return {
    type: SET_LEAD_STATUS_START,
    leadId: leadId,
    statusId: statusId,
    status: status
  };
};

export const setLeadStatusFailure = (leadId, previousStatusId, status) => {
  return {
    type: SET_LEAD_STATUS_FAILURE,
    leadId: leadId,
    previousTopicId: previousStatusId,
    status: status
  };
};

export const updateLeads = () => {
  return {
    type: UPDATE_LEADS
  };
};

const getLeadStatusMethod = (statusId) => {
  if (statusId === 2) return URL_ADDRESSES.PATCH_LEAD_LOCK;
  else if (statusId === 1) return URL_ADDRESSES.PATCH_LEAD_UNLOCK;
  else return URL_ADDRESSES.PATCH_LEAD_STATUS;
};

export const setLeadStatus = (leadId, statusId, previousStatusId, status) => {
  return (dispatch) => {
    dispatch(setLeadStatusStart(leadId, statusId, status));
    //TODO Method lock/unlock doesn't work
    fetchDataPatcherToServer(`${getLeadStatusMethod(statusId)(leadId)}`, { status_id: statusId })
      .catch((error) => {
        toast.error("Ошибка изменения статуса лида!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(setLeadStatusFailure(leadId, previousStatusId, status));
      })
      .then((result) => {
        status === 1 && dispatch(updateLeads());
        // if(result) dispatch(changeTaskPrioritySuccess());
      });
  };
};

export const getLeadReplySuccess = (reply, leadId) => {
  return {
    type: GET_LEAD_REPLY_SUCCESS,
    reply: reply,
    leadId: leadId
  };
};

export const getLeadReply = (leadId, statusId) => {
  return (dispatch) => {
    fetchDataToStorage(`${URL_ADDRESSES.POST_LEAD_RESPONSE(leadId)}`)
      .catch((error) => {
        toast.error("Ошибка отправки отклика!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        // dispatch(setLeadStatusFailure(leadId, previousStatusId))
      })
      .then((result) => {
        if (result) {
          dispatch(getLeadReplySuccess(result, leadId));
        }
        // if(result) dispatch(changeTaskPrioritySuccess());
      });
  };
};

export const sendResponseToLead = (leadId, text, cost, currency, days, user_id, statusId) => {
  return (dispatch) => {
    // dispatch(setLeadStatusStart(leadId,statusId))
    fetchPostDataToServer(`${URL_ADDRESSES.POST_LEAD_RESPONSE(leadId)}`, {
      text: text,
      price: cost,
      currency: currency,
      duration: days
    })
      .catch((error) => {
        toast.error("Ошибка отправки отклика!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        // dispatch(setLeadStatusFailure(leadId, previousStatusId))
      })
      .then((result) => {
        if (result) {
          toast.success("Отклик успешно отправлен, лид перешел в Воронку!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          dispatch(setLeadStatus(leadId, 4, statusId, 1));
          dispatch(setSelectedLead(null));
          dispatch(getLeadReply(leadId));
        }
        // if(result) dispatch(changeTaskPrioritySuccess());
      });
  };
};

export const getLeadResponseSuccess = (response) => {
  return {
    type: GET_LEAD_RESPONSE_SUCCESS,
    response: response
  };
};

export const getLeadResponse = (leadId) => {
  return (dispatch) => {
    // dispatch(setLeadStatusStart(leadId,statusId))
    fetchDataToStorage(`${URL_ADDRESSES.GET_LEAD_RESPONSE(leadId)}`)
      .catch((error) => {
        toast.error("Ошибка получения отклика выбранного лида!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        // dispatch(setLeadStatusFailure(leadId, previousStatusId))
      })
      .then((result) => {
        if (result) dispatch(getLeadResponseSuccess(result.reply));
      });
  };
};

export const editResponseText = (leadId, text) => {
  return (dispatch) => {
    // dispatch(setLeadStatusStart(leadId,statusId))
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_LEAD_RESPONSE_TEXT(leadId)}`, { text: text })
      .catch((error) => {
        toast.error("Ошибка изменения текста отклика!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        // dispatch(setLeadStatusFailure(leadId, previousStatusId))
      })
      .then((result) => {
        // if(result) dispatch(editResponseTextSuccess(result.reply));
      });
  };
};

export const editResponseDuration = (leadId, duration) => {
  return (dispatch) => {
    // dispatch(setLeadStatusStart(leadId,statusId))
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_LEAD_RESPONSE_DURATION(leadId)}`, { duration: duration })
      .catch((error) => {
        toast.error("Ошибка изменения срока реализации!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        // dispatch(setLeadStatusFailure(leadId, previousStatusId))
      })
      .then((result) => {
        // if(result) dispatch(editResponseTextSuccess(result.reply));
      });
  };
};

export const editResponsePrice = (leadId, price) => {
  return (dispatch) => {
    // dispatch(setLeadStatusStart(leadId,statusId))
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_LEAD_RESPONSE_PRICE(leadId)}`, { price: price })
      .catch((error) => {
        toast.error("Ошибка изменения стоимости реализации!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        // dispatch(setLeadStatusFailure(leadId, previousStatusId))
      })
      .then((result) => {
        // if(result) dispatch(editResponseTextSuccess(result.reply));
      });
  };
};

export const editResponseCurrency = (leadId, currency) => {
  return (dispatch) => {
    // dispatch(setLeadStatusStart(leadId,statusId))
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_LEAD_RESPONSE_CURRENCY(leadId)}`, { currency_id: currency })
      .catch((error) => {
        toast.error("Ошибка изменения валюты отклика!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        // dispatch(setLeadStatusFailure(leadId, previousStatusId))
      })
      .then((result) => {
        // if(result) dispatch(editResponseTextSuccess(result.reply));
      });
  };
};

export const getMessagesLeadSuccess = (messages) => {
  return {
    type: GET_MESSAGES_OF_LEAD_SUCCESS,
    messages: messages
  };
};

export const getLeadDialogMessages = (leadId) => {
  return (dispatch) => {
    dispatch(getMessagesInDialogStart());
    fetchDataToStorage(`${URL_ADDRESSES.GET_LEAD_DIALOGS(leadId)}`)
      .catch((error) => {
        toast.error("Ошибка получения диалогов лида!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        // dispatch(setLeadStatusFailure(leadId, previousStatusId))
      })
      .then((result) => {
        if (result[result.length - 1]) {
          fetchDataToStorage(`${URL_ADDRESSES.GET_LEAD_MESSAGES_IN_DIALOG(leadId, result[result.length - 1].id)}`)
            .catch((error) => {
              toast.error("Ошибка получения сообщений диалога лида!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              });
              // dispatch(setLeadStatusFailure(leadId, previousStatusId))
            })
            .then((result) => {
              dispatch(getMessagesLeadSuccess(result));
              // if(result) dispatch(editResponseTextSuccess(result.reply));
            });
        } else dispatch(getMessagesLeadSuccess(result));
        // if(result) dispatch(editResponseTextSuccess(result.reply));
      });
  };
};

export const sendMessageToDialogSuccess = (dialogId, message) => {
  return {
    type: SEND_MESSAGE_TO_DIALOG_SUCCESS,
    message: message,
    dialogId: dialogId
  };
};

export const sendMessageToLeadDialogSuccess = (message) => ({
  type: SEND_MESSAGE_TO_LEAD_DIALOG_SUCCESS,
  message: message
});

export const sendMessageToDialogStart = () => ({
  type: SEND_MESSAGE_TO_DIALOG_START
});

export const sendMessageToDialog = (leadId, dialogId, text) => {
  return (dispatch) => {
    // dispatch(setLeadStatusStart(leadId,statusId))
    fetchPostDataToServer(`${URL_ADDRESSES.POST_MESSAGE_TO_DIALOG(leadId, dialogId)}`, { text: text })
      .catch((error) => {
        toast.error("Ошибка отправки сообщения в диалог!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        // dispatch(setLeadStatusFailure(leadId, previousStatusId))
      })
      .then((result) => {
        if (result) dispatch(sendMessageToLeadDialogSuccess(result));
      });
  };
};

export const sendMessageToDialogById = (dialogId, text) => {
  return (dispatch) => {
    dispatch(sendMessageToDialogStart());
    // dispatch(setLeadStatusStart(leadId,statusId))
    fetchPostDataToServer(`${URL_ADDRESSES.POST_MESSAGE_TO_DIALOG_BY_ID(dialogId)}`, { text: text })
      .catch((error) => {
        toast.error("Ошибка отправки сообщения в диалог!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        // dispatch(setLeadStatusFailure(leadId, previousStatusId))
      })
      .then((result) => {
        if (result) dispatch(sendMessageToDialogSuccess(dialogId, result));
      });
  };
};

export const resetLeadsFilters = () => ({
  type: RESET_LEADS_FILTERS
});

export const changeLeadsFilterOption = (filterOptionName, filterOptionValue) => {
  return {
    type: CHANGE_LEADS_FILTER_OPTION,
    filterOptionName: filterOptionName,
    filterOptionValue: filterOptionValue
  };
};

export const changeLeadsFilterOptionFunnel = (filterOptionName, filterOptionValue) => {
  return {
    type: CHANGE_LEADS_FILTER_OPTION_FUNNEL,
    filterOptionName: filterOptionName,
    filterOptionValue: filterOptionValue
  };
};

export const fetchAllLeadsDialogsSuccess = (result) => {
  return {
    type: GET_ALL_LEADS_DIALOGS_SUCCESS,
    dialogs: result.results,
    next_page: result.next
  };
};

export const fetchAllLeadsDialogsStarted = (shouldReset) => ({
  type: GET_ALL_LEADS_DIALOGS_START,
  shouldReset: shouldReset
});

export const fetchAllLeadsDialogs = (page = 1, shouldReset, text = null) => {
  return (dispatch) => {
    dispatch(fetchAllLeadsDialogsStarted(shouldReset));
    fetchDataToStorage(`${URL_ADDRESSES.GET_LIST_OF_DIALOGS(page, text)}`)
      .catch((error) => {
        toast.error("Ошибка получения списка диалогов!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        // dispatch(setLeadStatusFailure(leadId, previousStatusId))
      })
      .then((result) => {
        if (result) dispatch(fetchAllLeadsDialogsSuccess(result));
      });
  };
};

export const setSelectedDialog = (dialogId) => {
  return {
    type: SET_SELECTED_DIALOG,
    dialogId: dialogId
  };
};

export const getMessagesInDialogStart = (isUpdating) => {
  return {
    type: GET_MESSAGES_OF_LEAD_START,
    isUpdating: isUpdating
  };
};

export const getMessagesInDialogSuccess = (result, isUpdating, dialogId) => {
  return {
    type: GET_MESSAGES_OF_LEAD_SUCCESS,
    messages: result.results,
    next_page: result.next,
    dialogId: dialogId,
    isUpdating: isUpdating
  };
};

export const getMessagesInDialog = (dialogId, page = 1, isUpdating) => {
  return (dispatch) => {
    dispatch(getMessagesInDialogStart(isUpdating));
    // dispatch(setLeadStatusStart(leadId,statusId))
    fetchDataToStorage(`${URL_ADDRESSES.GET_MESSAGES_IN_DIALOG(dialogId, page)}`)
      .catch((error) => {
        toast.error("Ошибка получения списка сообщений в диалоге!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        // dispatch(setLeadStatusFailure(leadId, previousStatusId))
      })
      .then((result) => {
        if (result) dispatch(getMessagesInDialogSuccess(result, isUpdating, dialogId));
      });
  };
};

export const resendResponseToLeadStart = (statusId, leadId, leadStatus) => {
  return {
    type: RESEND_RESPONSE_TO_LEAD_START,
    leadId: leadId,
    leadStatus: leadStatus,
    statusId: statusId
  };
};

export const resendResponseToLeadFailure = (previousStatusId) => {
  return {
    type: RESEND_RESPONSE_TO_LEAD_FAILURE,
    previousStatusId: previousStatusId
  };
};

export const resendResponseToLead = (leadId, previousStatus, leadStatus) => {
  return (dispatch) => {
    dispatch(resendResponseToLeadStart(1, leadId, leadStatus));
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_RESEND_RESPONSE_TO_LEAD(leadId)}`)
      .catch((error) => {
        toast.error("Ошибка повторной отправки отклика!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(resendResponseToLeadFailure(previousStatus));
        // dispatch(setLeadStatusFailure(leadId, previousStatusId))
      })
      .then((result) => {
        // if(result) dispatch(getMessagesInDialogSuccess(result));
      });
  };
};

export const resendMessageToDialogStart = (messageId, newStatusId) => {
  return {
    type: RESEND_MESSAGE_TO_DIALOG_START,
    messageId: messageId,
    newStatusId: newStatusId
  };
};

export const resendMessageToDialogFailure = (messageId, previousStatusId) => {
  return {
    type: RESEND_MESSAGE_TO_DIALOG_FAILURE,
    messageId: messageId,
    previousStatusId: previousStatusId
  };
};

export const resendMessageToDialog = (dialogId, messageId, newStatusId, previousStatusId) => {
  return (dispatch) => {
    dispatch(resendMessageToDialogStart(messageId, newStatusId));
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_RESEND_MESSAGE_TO_DIALOG(dialogId, messageId)}`)
      .catch((error) => {
        toast.error("Ошибка повторной отправки сообщения!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(resendMessageToDialogFailure(messageId, previousStatusId));
        // dispatch(setLeadStatusFailure(leadId, previousStatusId))
      })
      .then((result) => {
        // if(result) dispatch(getMessagesInDialogSuccess(result));
      });
  };
};

export const resendMessageToLeadDialog = (leadId, dialogId, messageId, newStatusId, previousStatusId) => {
  return (dispatch) => {
    dispatch(resendMessageToDialogStart(messageId, newStatusId));
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_RESEND_MESSAGE_TO_LEAD_DIALOG(leadId, dialogId, messageId)}`)
      .catch((error) => {
        toast.error("Ошибка повторной отправки сообщения!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(resendMessageToDialogFailure(messageId, previousStatusId));
        // dispatch(setLeadStatusFailure(leadId, previousStatusId))
      })
      .then((result) => {
        // if(result) dispatch(getMessagesInDialogSuccess(result));
      });
  };
};

export const editMessageInLeadDialog = (leadId, messageDialog, messageId, text) => {
  return (dispatch) => {
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_CHANGE_MESSAGE_IN_LEAD_DIALOG(leadId, messageDialog, messageId)}`, {
      text: text
    })
      .catch((error) => {
        toast.error("Ошибка изменения текста сообщения!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        // dispatch(setLeadStatusFailure(leadId, previousStatusId))
      })
      .then((result) => {
        // if(result) dispatch(getMessagesInDialogSuccess(result));
      });
  };
};

export const editMessageInDialog = (messageDialog, messageId, text) => {
  return (dispatch) => {
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_CHANGE_MESSAGE_IN_DIALOG(messageDialog, messageId)}`, {
      text: text
    })
      .catch((error) => {
        toast.error("Ошибка изменения текста сообщения!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        // dispatch(setLeadStatusFailure(leadId, previousStatusId))
      })
      .then((result) => {
        // if(result) dispatch(getMessagesInDialogSuccess(result));
      });
  };
};

export const searchLeadsByFilter = (query) => {
  return {
    type: SEARCH_LEADS_BY_FILTER,
    searchFilter: query
  };
};

export const updateFilteredMessages = () => {
  return {
    type: UPDATE_FILTERED_MESSAGES
  };
};

export const changeLeadUserStart = (leadId, userId, status) => {
  return {
    type: CHANGE_LEAD_USER_START,
    leadId: leadId,
    userId: userId,
    status: status
  };
};

export const changeLeadUser = (leadId, userId, status) => {
  return (dispatch) => {
    dispatch(changeLeadUserStart(leadId, userId, status));
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_CHANGE_LEAD_USER(leadId)}`, { user_id: userId })
      .catch((error) => {
        toast.error("Ошибка изменения ответственного за лид!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        // dispatch(setLeadStatusFailure(leadId, previousStatusId))
      })
      .then((result) => {
        // if(result) dispatch(getMessagesInDialogSuccess(result));
      });
  };
};

export const addEstimateLinkToLeadSuccess = (leadId, smetaText, status) => {
  return {
    type: ADD_ESTIMATE_LINK_TO_LEAD_SUCCESS,
    leadId: leadId,
    smetaText: smetaText,
    status: status
  };
};

export const addEstimateLinkToLead = (leadId, smetaText, status) => {
  return (dispatch) => {
    dispatch(addEstimateLinkToLeadSuccess(leadId, smetaText, status));
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_LEAD_ESTIMATE_LINK(leadId)}`, { estimate_url: smetaText })
      .catch((error) => {
        toast.error("Ошибка изменения ссылки на смету!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        // dispatch(setLeadStatusFailure(leadId, previousStatusId))
      })
      .then((result) => {
        // if(result) dispatch(addEstimateLinkToLeadSuccess(leadId, smetaText));
      });
  };
};

export const addKpLinkToLeadSuccess = (leadId, kpText, status) => {
  return {
    type: ADD_KP_LINK_TO_LEAD_SUCCESS,
    leadId: leadId,
    kpText: kpText,
    status: status
  };
};

export const addKpLinkToLead = (leadId, kpText, status) => {
  return (dispatch) => {
    dispatch(addKpLinkToLeadSuccess(leadId, kpText, status));
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_LEAD_KP_LINK(leadId)}`, { kp_url: kpText })
      .catch((error) => {
        toast.error("Ошибка изменения ссылки на КП!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        // dispatch(setLeadStatusFailure(leadId, previousStatusId))
      })
      .then((result) => {
        // if(result) dispatch(addKpLinkToLeadSuccess(leadId, kpText));
      });
  };
};

export const addLinksToLead = (smetaText, kpText, leadId, statusId, previousStatusId) => {
  return async (dispatch) => {
    await dispatch(setLeadStatus(leadId, statusId, previousStatusId, previousStatusId));
    await dispatch(addEstimateLinkToLead(leadId, smetaText, statusId));
    await dispatch(addKpLinkToLead(leadId, kpText, statusId));
  };
};

export const sendKpLinkSuccess = (data, error = false) => ({
  type: SEND_KP_LINK_SUCCESS,
  data: data,
  error: error
});

export const sendKpLink = (link) => {
  return async (dispatch) => {
    await fetchPostDataToServer(`${URL_ADDRESSES.SEND_KP_LINK}`, { google_sheet_url: link })
      .catch((error) => {
        toast.error("Ошибка отправки ссылки на КП!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(sendKpLinkSuccess(error, true));
        // dispatch(setLeadStatusFailure(leadId, previousStatusId))
      })
      .then((result) => {
        if (result) dispatch(sendKpLinkSuccess(result));
      });
  };
};

export const getLeadInformationSuccess = (result) => ({
  type: GET_LEAD_INFORMATION_SUCCESS,
  result: result
});

export const getLeadInformation = (leadId) => {
  return (dispatch) => {
    fetchDataToStorage(`${URL_ADDRESSES.GET_INFO_ABOUT_LEAD(leadId)}`)
      .catch((error) => {
        toast.error("Ошибка получения данных о лиде!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        if (result.detail)
          toast.error("Ошибка получения данных о лиде!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        if (result) dispatch(getLeadInformationSuccess(result));
      });
  };
};

export const getUserRankInfoSuccess = (result) => ({
  type: GET_USER_RANK_INFO_SUCCESS,
  rank_info: result
});

export const getUserRankInfo = (user_id) => {
  return (dispatch) => {
    fetchDataToStorage(`${URL_ADDRESSES.GET_USER_RANK_INFO(user_id)}`)
      .catch((error) => {
        toast.error("Ошибка получения данных о ранге пользователя!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        if (result) dispatch(getUserRankInfoSuccess(result.result));
      });
  };
};

export const saveDataAboutLead = ({ leadId, responseText }) => ({
  type: SAVE_DATA_ABOUT_LEAD,
  leadId: leadId,
  responseText: responseText
});

export const resetLeadsInCategory = (statusId) => ({
  type: RESET_LEADS_IN_CATEGORY,
  statusId: statusId
});

export const fetchMessagesInDialogSuccess = (result) => ({
  type: FETCH_MESSAGES_IN_DIALOG_OF_SELECTED_LEAD_SUCCESS,
  messages: result.results,
  next_page: result.next
});

export const fetchMessagesInDialogStart = (dialogId) => ({
  type: FETCH_MESSAGES_IN_DIALOG_OF_SELECTED_LEAD_START,
  dialogId: dialogId
});

export const fetchMessagesInDialog = (leadId, dialogId, page = 1) => {
  return (dispatch) => {
    dispatch(fetchMessagesInDialogStart(dialogId));
    fetchDataToStorage(`${URL_ADDRESSES.GET_LEAD_MESSAGES_IN_DIALOG(leadId, dialogId, page)}`)
      .catch((error) => {
        toast.error("Ошибка получения списка сообщений диалога лида!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        if (result) dispatch(fetchMessagesInDialogSuccess(result));
      });
  };
};

export const clearFetchedMessagesInLead = () => ({
  type: CLEAR_FETCHER_MESSAGES_IN_LEAD
});

export const fetchDialogsOfSelectedLeadStart = () => ({
  type: FETCH_DIALOGS_OF_SELECTED_LEAD_START
});

export const fetchDialogsOfSelectedLead = (leadId) => {
  return (dispatch) => {
    dispatch(fetchDialogsOfSelectedLeadStart());
    fetchDataToStorage(`${URL_ADDRESSES.GET_LEAD_DIALOGS(leadId)}`)
      .catch((error) => {
        toast.error("Ошибка получения списка диалогов лида!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        if (result) dispatch(fetchMessagesInDialog(result));
      });
  };
};

export const addNewLeadSuccess = (lead) => ({
  type: ADD_NEW_LEAD_SUCCESS,
  lead: lead
});

export const addNewLead = (title, text, price, currency, topic) => {
  return (dispatch) => {
    // dispatch(fetchDialogsOfSelectedLeadStart())
    fetchPostDataToServer(`${URL_ADDRESSES.ADD_NEW_LEAD}`, {
      title: title,
      text: text,
      price: price,
      currency: currency,
      topic: topic,
      status: 7,
      source: null
    })
      .catch((error) => {
        toast.error("Ошибка добавления нового лида!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        if (result) dispatch(addNewLeadSuccess(result));
      });
  };
};

export const getKnowledgeBaseDataStarted = () => ({
  type: GET_KNOWLEDGE_BASE_DATA_STARTED
});

export const getKnowledgeBaseDataSuccess = (knowledgeData) => ({
  type: GET_KNOWLEDGE_BASE_DATA_SUCCESS,
  knowledgeData: knowledgeData
});

export const getKnowledgeBaseData = () => {
  return (dispatch) => {
    dispatch(getKnowledgeBaseDataStarted());
    fetchDataToStorage(`${URL_ADDRESSES.GET_KNOWLEDGE_BASE_DATA}`)
      .catch((error) => {
        toast.error("Ошибка получения данных базы знаний!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        if (result) dispatch(getKnowledgeBaseDataSuccess(result.result));
      });
  };
};

export const addResponseManuallySuccess = (leadId) => ({
  type: ADD_RESPONSE_MANUALLY_SUCCESS,
  leadId: leadId
});

export const addResponseManually = (leadId, description, price, currency, days) => {
  return (dispatch) => {
    fetchPostDataToServer(`${URL_ADDRESSES.POST_LEAD_RESPONSE(leadId)}`, {
      text: description ? description : "Отклик был добавлен и опубликован на бирже вручную",
      price: price,
      currency: currency,
      duration: days,
      status: 2
    })
      .catch((error) => {
        toast.error("Ошибка отправки отклика!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        // dispatch(setLeadStatusFailure(leadId, previousStatusId))
      })
      .then(async (result) => {
        if (result) {
          toast.success("Отклик успешно добавлен, лид перешел в Воронку!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          await dispatch(addResponseManuallySuccess(leadId));
          // dispatch(setLeadStatus(leadId, 4, statusId, 1))
          dispatch(setSelectedLead(null));
          dispatch(getLeadReply(leadId));
        }
      });
  };
};

export const setMessagesTimer = (state) => ({
  type: SET_MESSAGES_TIMER,
  state: state
});

export const getIntegratorLeftMenuStart = () => ({
  type: GET_INTEGRATOR_LEFT_MENU_START
});

export const getIntegratorLeftMenuSuccess = (menuData) => ({
  type: GET_INTEGRATOR_LEFT_MENU_SUCCESS,
  menuData: menuData
});

export const getIntegratorLeftMenu = (companyId) => {
  return (dispatch) => {
    dispatch(getIntegratorLeftMenuStart());
    fetchDataToStorage(`${URL_ADDRESSES.GET_INTEGRATOR_LEFT_MENU(companyId)}`)
      .catch((error) => {
        toast.error("Ошибка получения данных о разделах панели!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        if (!result.status) dispatch(getIntegratorLeftMenuSuccess(result));
      });
  };
};

export const changeLanguage = (language) => ({
  type: CHANGE_LANGUAGE,
  language: language
});

export const setSelectedUser = (userId) => ({
  type: SET_SELECTED_USER,
  userId: userId
});

export const changeUserFieldStart = (userId, data) => ({
  type: CHANGE_USER_FIELD_SUCCESS,
  userId: userId,
  data: data
});

export const changeUserFieldFailure = (error) => ({
  type: CHANGE_USER_FIELD_FAILURE,
  error: error
});

export const changeUserField = (userId, data, type, option, company_id) => {
  const currentChangeUserInfoRequest = type === "name" ? "PATCH_USER_NAME" : "PATCH_USER_INFO";
  const requestType = option ? option : type;
  return (dispatch) => {
    dispatch(changeUserFieldStart(userId, data));
    fetchDataPatcherToServer(`${URL_ADDRESSES[currentChangeUserInfoRequest](userId, requestType)}`, data)
      .catch((error) => {
        dispatch(fetchUsersFromCompany(company_id));
        dispatch(changeUserFieldFailure(error));
        toast.error("Ошибка изменения данных о пользователе!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        // if (!result.status) dispatch(getIntegratorLeftMenuSuccess(result))
      });
  };
};

export const registerUserSuccess = (data) => ({
  type: REGISTER_USER_SUCCESS,
  data
});

export const registerUser = (data, company_id) => {
  return (dispatch) => {
    fetchPostDataToServer(URL_ADDRESSES.POST_REGISTER_USER, data)
      .catch((error) => {
        dispatch(fetchUsersFromCompany(company_id));
        dispatch(changeUserFieldFailure(error));
        toast.error("Ошибка регистрации пользователя!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((response) => {
        // console.log(response);
        dispatch(registerUserSuccess(response.result));
      });
  };
};

export const setImageToStore = (userId) => {
  return (dispatch) => {
    fetchDataToStorage(`${URL_ADDRESSES.GET_USER_INFO(userId)}`)
      .catch((error) => {
        toast.error("Ошибка изменения аватара пользователя!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((response) => {
        dispatch(
          changeUserFieldStart(userId, {
            user_photo: `https://test.erp.sixhands.co/api/users${response.result.user_photo}`
          })
        );
      });
  };
};

export const changeUserImage = (userId, type, data, company_id) => {
  return (dispatch) => {
    dispatch(changeUserFieldStart(userId, data));
    fetchFileDataPatcherToServer(`${URL_ADDRESSES.PATCH_USER_IMAGE(userId, type)}`, data)
      .catch((error) => {
        dispatch(fetchUsersFromCompany(company_id));
        toast.error("Ошибка изменения аватара пользователя!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        dispatch(setImageToStore(userId));
        // dispatch(fetchUsersFromCompany(company_id))
      });
  };
};

export const changeUserStatusStart = () => ({
  type: CHANGE_USER_STATUS_START,
  statusChanged: false
});

export const changeUserStatusSusccess = () => ({
  type: CHANGE_USER_STATUS_SUCCESS,
  statusChanged: true
});

export const changeUserStatusFailure = () => ({
  type: CHANGE_USER_STATUS_FAILURE,
  statusChanged: false
});

export const deleteUser = (user_id, company_id, data) => {
  return (dispatch) => {
    dispatch(changeUserFieldStart(user_id, data));
    dispatch(changeUserStatusStart());
    fetchDeleteDataFromServer(`${URL_ADDRESSES.DELETE_USER(user_id)}`)
      .catch((error) => {
        dispatch(changeUserStatusFailure());
        dispatch(fetchUsersFromCompany(company_id));
        toast.error("Ошибка изменения статуса пользователя!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        dispatch(changeUserStatusSusccess());
        // dispatch(fetchUsersFromCompany(company_id))
      });
  };
};

export const restoreUser = (user_id, company_id, data) => {
  return (dispatch) => {
    dispatch(changeUserStatusStart());
    dispatch(changeUserFieldStart(user_id, data));
    fetchDataPatcherToServer(`${URL_ADDRESSES.RESTORE_USER(user_id)}`)
      .catch((error) => {
        dispatch(changeUserStatusFailure());
        dispatch(fetchUsersFromCompany(company_id));
        toast.error("Ошибка изменения статуса пользователя!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        dispatch(changeUserStatusSusccess());

        // dispatch(fetchUsersFromCompany(company_id))
      });
  };
};

export const deleteUserImage = (userId, type, data, company_id) => {
  return (dispatch) => {
    dispatch(changeUserFieldStart(userId, data));
    fetchDeleteDataFromServer(`${URL_ADDRESSES.DELETE_USER_IMAGE(userId, type)}`)
      .catch((error) => {
        dispatch(fetchUsersFromCompany(company_id));
        toast.error("Ошибка изменения аватара пользователя!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        dispatch(setImageToStore(userId));
        // dispatch(fetchUsersFromCompany(company_id))
      });
  };
};

export const sortUsersList = (data) => ({
  type: SORT_USERS_LIST,
  users: data
});

export const filterUsersList = (data) => ({
  type: FILTER_USERS_LIST,
  users: data
});

export const getListOfReportStatusesSuccess = (result) => ({
  type: GET_LIST_OF_REPORT_STATUSES_SUCCESS,
  statusesList: result
});

export const getListOfReportTypesSuccess = (result) => ({
  type: GET_LIST_OF_REPORT_TYPES_SUCCESS,
  typesList: result
});

export const getReportsInfo = () => {
  return async (dispatch) => {
    await fetchDataToStorage(`${URL_ADDRESSES.GET_LIST_OF_REPORTS_STATUSES}`)
      .catch((error) => {
        toast.error("Ошибка получения данных о списках статусов отчетов!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        dispatch(getListOfReportStatusesSuccess(result));
      });
    await fetchDataToStorage(`${URL_ADDRESSES.GET_LIST_OF_REPORTS_TYPES}`)
      .catch((error) => {
        toast.error("Ошибка получения данных о списках типов отчетов!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        dispatch(getListOfReportTypesSuccess(result));
      });
  };
};

export const getListOfReportsStart = () => ({
  type: GET_LIST_OF_REPORTS_START
});

export const getListOfReportsSuccess = (result) => ({
  type: GET_LIST_OF_REPORTS_SUCCESS,
  reportsData: result
});

export const updateListOfReportsSuccess = (result) => ({
  type: UPDATE_LIST_OF_REPORTS_SUCCESS,
  reportsData: result
});

export const getListOfReports = (userId) => {
  const currentRequest = userId && +userId !== 0 ? "GET_LIST_OF_USER_REPORTS" : "GET_LIST_OF_ALL_REPORTS";
  return (dispatch) => {
    dispatch(getListOfReportsStart());
    fetchDataToStorage(`${URL_ADDRESSES[currentRequest](1, userId)}`)
      .catch((error) => {
        toast.error("Ошибка получения данных об отчетах!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        dispatch(getListOfReportsSuccess(result));
      });
  };
};

export const updateListOfReports = (pageNumber, userId) => {
  const currentRequest = userId && +userId !== 0 ? "GET_LIST_OF_USER_REPORTS" : "GET_LIST_OF_ALL_REPORTS";
  return (dispatch) => {
    dispatch(getListOfReportsStart());
    fetchDataToStorage(`${URL_ADDRESSES[currentRequest](pageNumber, userId)}`)
      .catch((error) => {
        toast.error("Ошибка получения данных об отчетах!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        dispatch(updateListOfReportsSuccess(result));
      });
  };
};

export const setCurrentReportID = (reportID) => ({
  type: SET_CURRENT_REPORT_ID,
  reportID: reportID
});

export const createNewReport = (report_type) => {
  return (dispatch) => {
    fetchPostDataToServer(`${URL_ADDRESSES.POST_REPORT_TO_REPORTS_LIST(report_type)}`)
      .catch((error) => {
        toast.error("Ошибка создания нового отчета", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        dispatch(setCurrentReportID(result.id));
        dispatch(getReportsInfo());
      });
  };
};

export const deleteReport = (report_type, report_id, user_id) => {
  return (dispatch) => {
    fetchDeleteDataFromServer(`${URL_ADDRESSES.DELETE_REPORT(report_type, report_id)}`)
      .catch((error) => {
        console.log(error);
        dispatch(getReportInfo(report_id));
        toast.error("Ошибка удаления отчета!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        dispatch(getListOfReports(user_id));
      });
  };
};

export const getProgramsNamesSuccess = (programsNames) => ({
  type: GET_PROGRAMS_NAMES_SUCCESS,
  programsNames: programsNames
});

export const getProgramsNames = () => {
  return (dispatch) => {
    fetchDataToStorage(URL_ADDRESSES.GET_PROGRAMS_NAMES)
      .catch((error) => {
        toast.error("Ошибка получения информации о программах!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((data) => {
        if (!data.length) {
          toast.error("Ни один связанный проект не найден. Просьба сообщить о данной проблеме менеджеру проекта", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        }
        const programsNames = data.map((project) => {
          return {
            id: project.id,
            name: project.name
          };
        });
        dispatch(getProgramsNamesSuccess(programsNames));
      });
  };
};

export const getReportInfoStart = () => ({
  type: GET_REPORT_INFO_START,
  reportsLoading: true
});

export const getReportInfoSuccess = (reportData) => ({
  type: GET_REPORT_INFO_SUCCESS,
  reportData: reportData,
  reportDataLoading: false,
  reportsLoading: false
});

export const getReportInfoFailure = () => ({
  type: GET_REPORT_INFO_FAILURE,
  reportDataLoading: false,
  reportsLoading: false
});

export const getReportInfo = (reportId) => {
  return (dispatch) => {
    dispatch(getReportInfoStart());
    fetchDataToStorage(`${URL_ADDRESSES.GET_REPORT_INFO_BY_ID(reportId)}`)
      .catch((error) => {
        toast.error("Ошибка получения информации об отчете!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        dispatch(getReportInfoFailure());
      })
      .then((result) => {
        if (result) dispatch(getReportInfoSuccess(result));
      });
  };
};

export const getProjectsListStart = () => ({
  type: GET_PROJECTS_LIST_START,
  reportDataLoading: true
});

export const getProjectsListSuccess = () => ({
  type: GET_PROJECTS_LIST_SUCCESS,
  reportDataLoading: false
});

export const getProjectsList = (reportType, reportId, date) => {
  return (dispatch) => {
    dispatch(getProjectsListStart());
    fetchDataToStorage(`${URL_ADDRESSES.GET_PROJECTS_LIST(reportType, reportId, date)}`)
      .catch((error) => {
        console.log("errpr");
        dispatch(getReportInfo(reportId));
        toast.error("Ошибка получения информации о проектах!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        if (result?.detail?.includes("username is not found"))
          toast.error("Пользователь с таким именем в GitLab - не найден!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        dispatch(getReportInfo(reportId));
      });
  };
};

export const getAdditionalWorksListSuccess = (addition_works) => ({
  type: GET_ADDITIONAL_WORKS_LIST_SUCCESS,
  addition_works
});

export const getAdditionalWorksList = (reportType, reportId) => {
  return (dispatch) => {
    fetchDataToStorage(`${URL_ADDRESSES.GET_ADDITIONAL_WORKS_LIST(reportType, reportId)}`)
      .catch((error) => {
        toast.error("Ошибка получения информации о дополнительных работах!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        dispatch(getAdditionalWorksListSuccess(result));
      });
  };
};

export const saveReportStart = () => ({
  type: SAVE_REPORT_START,
  reportDataLoading: true
});

export const saveReportSuccess = (report_id, data) => ({
  type: SAVE_REPORT_SUCCESS,
  reportDataLoading: false,
  saveData: {
    report_id,
    saved: true,
    data
  }
});

export const saveReportFailure = (report_id) => ({
  type: SAVE_REPORT_FAILURE,
  reportDataLoading: false,
  saveData: {
    report_id,
    saved: false,
    data: null
  }
});

export const saveReport = (report_type, report_id, reportDate) => {
  return (dispatch) => {
    dispatch(saveReportStart());
    fetchDataPatcherToServer(`${URL_ADDRESSES.PATCH_REPORT_TO_SAVE(report_type, report_id)}`, reportDate)
      .catch((error) => {
        dispatch(saveReportFailure(report_id));
        // toast.error('Ошибка сохранения отчета!', {
        //     position: 'top-right',
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        // });
      })
      .then((result) => {
        dispatch(saveReportSuccess(report_id, result));
      });
  };
};

export const changeReportProjectInfoFailure = (error) => ({
  type: CHANGE_REPORT_PROJECT_INFO_FAILURE,
  error: error
});

export const addAdditionalWorkToProjectSuccess = (workData) => ({
  type: ADD_ADDITIONAL_WORK_TO_PROJECT_SUCCESS,
  workData: workData
});

export const addAdditionalWorkToProject = (report_type, report_id) => {
  return (dispatch) => {
    fetchPostDataToServer(`${URL_ADDRESSES.POST_ADDITIONAL_WORK_TO_PROJECT(report_type, report_id)}`)
      .catch((error) => {
        dispatch(changeReportProjectInfoFailure(error));
        dispatch(getReportInfo(report_id));
        toast.error("Ошибка изменения данных проекта!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        dispatch(getReportInfo(report_id));
      });
  };
};

const deleteAdditionalWorkFromProjectSuccess = (report_id, work_id) => ({
  type: DELETE_ADDITIONAL_WORK_FROM_PROJECT_SUCCESS,
  report_id,
  work_id
});

export const deleteAdditionalWorkFromProject = (report_type, report_id, work_id) => {
  return (dispatch) => {
    dispatch(deleteAdditionalWorkFromProjectSuccess(report_id, work_id));
    fetchDeleteDataFromServer(`${URL_ADDRESSES.DELETE_ADDITIONAL_WORK_FROM_PROJECT(report_type, report_id, work_id)}`)
      .catch((error) => {
        dispatch(changeReportProjectInfoFailure(error));
        dispatch(getReportInfo(report_id));
        toast.error("Ошибка удаления дополнительной работы!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then(() => {
        dispatch(getReportInfo(report_id));
      });
  };
};

export const changeReportDataInfoFieldSuccess = (projectType, id, data) => ({
  type: CHANGE_REPORT_DATA_INFO_FIELD_SUCCESS,
  projectType,
  id,
  data
});

export const changeReportDataInfoField = (report_type_id, report_id, project_id, option, type, data) => {
  return (dispatch) => {
    fetchDataPatcherToServer(
      `${URL_ADDRESSES.PATCH_REPORT_DATA_INFO(report_type_id, report_id, project_id, type, option)}`,
      data
    )
      .catch((error) => {
        dispatch(changeReportProjectInfoFailure(error));
        dispatch(getReportInfo(report_id));
        toast.error("Ошибка изменения данных проекта!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        dispatch(getReportInfo(report_id));
      });
  };
};

export const removeReportData = () => ({
  type: REMOVE_REPORT_DATA,
  reportData: []
});

export const removeReportsDataItems = () => ({
  type: REMOVE_REPORTS_DATA_ITEMS,
  reportsDataItems: []
});

export const setTemporalReportDate = (reportDate, reportID) => ({
  type: SET_TEMPORAL_REPORT_DATE,
  reportDate: reportDate,
  reportID: reportID
});

export const getLeadsRobotsStatusesSuccess = (robotsStatuses) => ({
  type: GET_LEADS_ROBOTS_STATUSES_SUCCESS,
  robotsStatuses: robotsStatuses
});

export const getLeadsRobotsStatuses = () => {
  return (dispatch) => {
    fetchDataToStorage(`${URL_ADDRESSES.GET_ACTIVE_ROBOTS_INFO}`)
      .catch((error) => {
        toast.error("Ошибка получения информации о статусе роботов!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .then((result) => {
        dispatch(getLeadsRobotsStatusesSuccess(result));
      });
  };
};
