import React from "react";
import PropTypes from "prop-types";
import styles from "./styles/BoxWithShadow.module.css";

const BoxWithShadow = ({ children, className }) => {
  return <div className={`${styles.container} ${className}`}>{children}</div>;
};

BoxWithShadow.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

BoxWithShadow.defaultProps = {
  className: ""
};

export { BoxWithShadow };
