import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createNewWorkspace, hideModalWindow } from "../../../state/actions";
import { AccentButton } from "../../Buttons/AccentButton";
import { DefaultInput } from "../../Inputs/DefaultInput";
import modalWindowStyles from "../styles/ModalWindow.module.css";
import styles from "./styles/WorkspaceCreator.module.css";
import { useTranslation } from "react-multi-lang";

const WorkspaceCreator = () => {
  const workspaceNameInputRef = useRef(null);
  const githubLinkInputRef = useRef(null);
  const modalWindowState = useSelector((state) => state.modalWindow);

  const dispatch = useDispatch();
  const translate = useTranslation();

  const closeWindow = () => {
    dispatch(hideModalWindow());
  };

  const [longNameError, setLongNameError] = useState(false);

  const [workspaceNameIsEdit, setWorkspaceNameIsEdit] = useState(false);
  const [gitHubLinkIsEdit, setGitHubLinkIsEdit] = useState(false);

  const createNewWorkspaceHandler = () => {
    const newWorkspaceName = workspaceNameInputRef.current.querySelector("input").value;
    if (newWorkspaceName.trim().length > 20 || newWorkspaceName.trim().length === 0) {
      setLongNameError(true);
    } else {
      const newWorkspaceGithubLink = githubLinkInputRef.current.querySelector("input").value;
      dispatch(createNewWorkspace(newWorkspaceName, newWorkspaceGithubLink));
    }
  };

  let textareaControlPressState = false;

  const modalWindowKeyPressHandler = (event) => {
    if (event.key === "Escape") {
      closeWindow();
    }
    if (event.key === "Control") {
      textareaControlPressState = true;
    }
    if (event.key === "Enter" && textareaControlPressState) {
      createNewWorkspaceHandler();
    }
    if (!event.target.closest(`.${styles.workspaceCreatorWindow}`)) closeWindow();
  };

  const onWorkspaceNameBlurHandler = () => {
    setWorkspaceNameIsEdit(false);
  };

  useEffect(() => {
    document.addEventListener("click", modalWindowKeyPressHandler);
    document.addEventListener("keydown", modalWindowKeyPressHandler);
    return () => {
      document.removeEventListener("click", modalWindowKeyPressHandler);
      document.removeEventListener("keydown", modalWindowKeyPressHandler);
    };
  });

  useEffect(() => {
    if (workspaceNameIsEdit || gitHubLinkIsEdit) document.removeEventListener("click", modalWindowKeyPressHandler);
    else document.addEventListener("click", modalWindowKeyPressHandler);
  });

  useEffect(() => {
    workspaceNameInputRef.current.querySelector("input").value = "";
    githubLinkInputRef.current.querySelector("input").value = "";
    setLongNameError(false);
    workspaceNameInputRef.current.querySelector("input").focus();
  }, [modalWindowState.showStatus]);

  const onWorkspaceNameChangeHandler = () => {
    setWorkspaceNameIsEdit(true);
    workspaceNameInputRef.current.querySelector("input").value.trim().length > 20
      ? setLongNameError(true)
      : setLongNameError(false);
  };

  const onChangeGitHubLinkHandler = () => setGitHubLinkIsEdit(true);
  const onGitHubLinkBlurHandler = () => setGitHubLinkIsEdit(false);

  return (
    <div className={`${modalWindowStyles.wrapper}`}>
      <div className={`${styles.workspaceCreatorWindow} ${modalWindowStyles.modalWindowDefaultSize}`}>
        <div className={modalWindowStyles.modalDefaultHeader}>
          <h2>{modalWindowState.options.header}</h2>
          <div
            className={`${modalWindowStyles.closeButton} ${
              modalWindowState.options.closeHeaderButton ? "" : modalWindowStyles.hide
            }`}
            onClick={() => {
              closeWindow();
            }}
          >
            &times;
          </div>
        </div>
        <div className={styles.body}>
          <DefaultInput
            label={`${translate("taskTracker.commonWords.title")} Workspace`}
            className={styles.input}
            placeholder=""
            ref={workspaceNameInputRef}
            error={longNameError}
            onChange={onWorkspaceNameChangeHandler}
            onFocus={() => setWorkspaceNameIsEdit(true)}
            onBlur={onWorkspaceNameBlurHandler}
          />
          <DefaultInput
            label={`${translate("taskTracker.commonWords.link")} GitHub`}
            className={styles.input}
            placeholder=""
            ref={githubLinkInputRef}
            onChange={onChangeGitHubLinkHandler}
            onFocus={() => setGitHubLinkIsEdit(true)}
            onBlur={onGitHubLinkBlurHandler}
          />
        </div>
        <div className={`${styles.footer} ${modalWindowState.options.showOkButton ? "" : styles.hide}`}>
          <button
            className={styles.declineButton}
            onClick={() => {
              closeWindow();
            }}
          >
            Отменить
          </button>
          <AccentButton
            label="Добавить"
            displayPlusSymbol={false}
            className={styles.okButton}
            onClick={createNewWorkspaceHandler}
            disabled={longNameError}
          />
        </div>
      </div>
    </div>
  );
};

export { WorkspaceCreator };
