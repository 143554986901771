import React, { useEffect, useRef, useState } from "react";
import styles from "../styles/ModalWindow.module.css";
import modalWindowStyles from "../styles/ModalWindow.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AccentButton } from "../../Buttons/AccentButton";
import { LeadSource } from "../../../pages/Leads/Components/LeadSource";
import { LeadTopic } from "../../../pages/Leads/Components/LeadTopic";
import { DropdownButton } from "../../Buttons/DropdownButton";
import NumberFormat from "react-number-format";
import { addNewLead } from "../../../state/actions";

const AddLeadWindow = ({ closeWindow }) => {
  const dispatch = useDispatch();

  const modalWindowOptionsState = useSelector((state) => state.modalWindow.options);
  const modalWindowState = useSelector((state) => state.modalWindow);
  const leadsInformation = useSelector((state) => state.leadsReducer);

  const [topic, setTopic] = useState(1);
  const [source, setSource] = useState(1);
  const [currency, setCurrency] = useState(3);
  const [isControl, setIsControl] = useState(false);

  const headerRef = useRef(null);
  const descriptionRef = useRef(null);
  const costRef = useRef(null);

  const wrapperClickHandler = (event) => {
    if (event.target.className.includes(modalWindowStyles.wrapper) && modalWindowOptionsState.closeOnWrapperClick) {
      closeWindow();
    }
  };

  const currencyOptions = [];

  leadsInformation.leadCurrencies.map((currency) => currencyOptions.push({ id: currency.id, label: currency.symbol }));

  const onKeyDownHandler = (event) => {
    if (event.key === "Escape") closeWindow();
    if (event.key === "Control") setIsControl(true);
    if (event.key === "Enter" && isControl) {
      onAddClickHandler();
    }
  };

  const onKeyUpHandler = (event) => {
    if (event.key === "Control") setIsControl(false);
  };

  const onAddClickHandler = () => {
    dispatch(
      addNewLead(
        headerRef.current.value,
        descriptionRef.current.value,
        costRef.current.state.numAsString,
        currency,
        topic
      )
    );
    closeWindow();
  };

  const onPriceKeyHandler = (event) => {
    if (isNaN(event.key) || event.target.value.length > 10 || (event.target.value.length === 0 && event.key === "0"))
      event.preventDefault();
  };

  const onPasteHandler = (event) => {
    if (
      isNaN(event.clipboardData.getData("Text")) ||
      (event.target.value.length === 0 && event.clipboardData.getData("Text")[0] === "0")
    )
      event.preventDefault();
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDownHandler);
    document.addEventListener("keyup", onKeyUpHandler);
    return () => {
      document.removeEventListener("keydown", onKeyDownHandler);
      document.removeEventListener("keyup", onKeyUpHandler);
    };
  });

  useEffect(() => {
    headerRef.current.focus();
  }, []);

  return (
    <div
      className={`${styles.wrapper} ${modalWindowState.showStatus ? "" : modalWindowStyles.hide}`}
      onClick={wrapperClickHandler}
    >
      <div className={`${styles.taskDeclineWindow} ${styles.modalWindowDefaultSize}`}>
        <div className={styles.modalDefaultHeader}>
          <h2>{modalWindowState.options.header}</h2>
          <div
            className={`${styles.closeButton} ${modalWindowState.options.closeHeaderButton ? "" : styles.hide}`}
            onClick={() => {
              closeWindow();
            }}
          >
            &times;
          </div>
        </div>
        <div className={styles.leadContainer}>
          <div className={styles.leadBody}>
            <div className={styles.row}>
              <div className={styles.row}>
                <p>Заголовок</p>
                <input ref={headerRef} />
              </div>
              <div className={styles.row}>
                <p>Описание</p>
                <textarea ref={descriptionRef} />
              </div>
            </div>
            <div className={styles.column}>
              <div className={styles.row}>
                <p>Цена</p>
                <div className={styles.cost}>
                  <NumberFormat
                    thousandSeparator={" "}
                    className={styles.optionsInput}
                    ref={costRef}
                    onKeyPress={onPriceKeyHandler}
                    onPaste={onPasteHandler}
                  />
                  <DropdownButton
                    className={`${styles.currencyButton} ${styles.currencyStyle}`}
                    customStylesToOptionsList={styles.currencyButtonOptionList}
                    customStylesToOptionsListElement={styles.currencyButtonOptionListElement}
                    onSelectOptionFunction={(symbol) =>
                      setCurrency(leadsInformation.leadCurrencies.find((currency) => currency.symbol === symbol).id)
                    }
                    editable={true}
                    dropDownMenuOptions={currencyOptions}
                    buttonLabel={leadsInformation.leadCurrencies.find((currencyL) => currencyL.id === currency).symbol}
                    defaultLabel={"₽"}
                    isFilter={true}
                  />
                </div>
              </div>
              <div className={styles.additionOptions}>
                <div className={styles.row}>
                  <p>Источник</p>
                  <div className={styles.topic}>
                    <LeadSource
                      sourceId={source}
                      sourcesList={leadsInformation.leadSources}
                      editable={true}
                      shouldReturnValue={true}
                      returnValueFunction={setSource}
                    />
                  </div>
                </div>
                <div className={styles.row}>
                  <p>Технологии</p>
                  <div className={styles.topic}>
                    <LeadTopic
                      topicId={topic}
                      topicsList={leadsInformation.leadTopics}
                      editable={true}
                      shouldReturnValue={true}
                      returnValueFunction={setTopic}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.footer} ${modalWindowState.options.showOkButton ? "" : styles.hide}`}>
          <AccentButton
            label="Добавить"
            displayPlusSymbol={false}
            className={styles.okButton}
            onClick={onAddClickHandler}
          />
        </div>
      </div>
    </div>
  );
};

export { AddLeadWindow };
