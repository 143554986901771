import React from "react";
import styles from "./styles/updateYourBrowser.module.css";

const UpdateYourBrowser = ({ browserName }) => {
  let site;

  switch (browserName.toLowerCase()) {
    case "firefox":
      site = "https://www.mozilla.org/ru/firefox/new/";
      break;
    case "chrome":
      site = "https://www.google.com/intl/ru_ru/chrome/";
      break;
    default:
      site = null;
  }

  return (
    <div className={styles.container}>
      <h1>Вы используете устаревшую версию браузера {browserName ? browserName : ""}</h1>
      <p>
        Для полноценной работы в ERP системе компании Sixhands, рекомендуем обновить ваш браузер до современной версии
      </p>
      {site && (
        <p>
          Официальный сайт для обновления вашего браузера <a href={site}>{site}</a>
        </p>
      )}
    </div>
  );
};

export { UpdateYourBrowser };
