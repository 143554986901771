import { saveToLocalStorage } from "./LocalStorageWorker";

let globaltoken;

async function fetchDataToStorage(url, storageDataName = null, token) {
  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: new Headers({
      Authorization: token ? "Bearer " + token : "Bearer " + globaltoken
    })
  });
  if (token) {
    globaltoken = token;
  }
  const parsedResponse = await response.json();

  if (response.status === 304) {
    return;
  }
  if (storageDataName !== null) {
    saveToLocalStorage(storageDataName, parsedResponse.result);
  }
  return parsedResponse;
}

async function fetchDataPatcherToServer(url, data, showResponse) {
  try {
    let response;
    if (!data) {
      response = await fetch(url, {
        method: "PATCH",
        mode: "cors",
        cache: "no-cache",
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + globaltoken }
      });
    } else {
      response = await fetch(url, {
        method: "PATCH",
        mode: "cors",
        cache: "no-cache",
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + globaltoken },
        body: JSON.stringify(data)
      });
    }
    if (response.ok === false) {
      throw new Error(`error on PATCH data(to ${url})!`);
    }
    return await response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function fetchPostDataToServer(url, data, catchError = true) {
  try {
    let response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + globaltoken },
      body: JSON.stringify(data)
    });
    if (response.ok === false && catchError) {
      throw new Error(`error on POST data(to ${url})!`);
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
}

async function fetchDeleteDataFromServer(url, data = "") {
  try {
    let response;
    if (data === null || data === "") {
      response = await fetch(url, {
        method: "DELETE",
        mode: "cors",
        cache: "no-cache",
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + globaltoken }
      });
    } else {
      response = await fetch(url, {
        method: "DELETE",
        mode: "cors",
        cache: "no-cache",
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + globaltoken },
        body: JSON.stringify(data)
      });
    }
    if (response.ok === false) {
      throw new Error(`error on DELETE data(to ${url})!`);
    }
    return await response;
  } catch (error) {
    throw error;
  }
}

async function fetchFileDataPatcherToServer(url, data) {
  try {
    const response = await fetch(url, {
      method: "PATCH",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + globaltoken
      },
      body: data
    });

    if (response.ok === false) {
      throw new Error(`error on PATCH data(to ${url})!`);
    }
    return await response;
  } catch (error) {
    throw error;
  }
}

export {
  fetchDataToStorage,
  fetchDataPatcherToServer,
  fetchPostDataToServer,
  fetchDeleteDataFromServer,
  fetchFileDataPatcherToServer
};
