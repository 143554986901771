import React from "react";
import PropTypes from "prop-types";

const TripleDotMenuIcon = ({ className, color, width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 21.002 4.998"
    >
      <path
        fill={color}
        d="M16.002 2.498a2.5 2.5 0 112.5 2.5 2.495 2.495 0 01-2.5-2.5zm-8 0a2.5 2.5 0 112.5 2.5 2.5 2.5 0 01-2.5-2.5zm-8 0a2.5 2.5 0 112.5 2.5 2.5 2.5 0 01-2.5-2.5z"
      />
    </svg>
  );
};

TripleDotMenuIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

export { TripleDotMenuIcon };
