import styles from "../styles/ModalWindow.module.css";
import notesStyles from "./styles/WorkspaceNotesEditor.module.css";
import { UploadFile } from "./components/UploadFile";
import { modifyWorkspaceNotes } from "../../../state/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const WorkspaceNotesEditor = React.memo(({ closeWindow, workspaceId }) => {
  const dispatch = useDispatch();

  const modalWindowOptionsState = useSelector((state) => state.modalWindow.options);

  const workspacesState = useSelector((state) => state.workspacesReducer.workspaces);

  const currentWorkspace = workspacesState.filter((workspace) => workspace.workspace_id === workspaceId)[0];

  const [notesText, setNotesText] = useState(currentWorkspace.workspace_notes ? currentWorkspace.workspace_notes : "");
  const [isChangable, setIsChangable] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [documentsNames, setDocumentsNames] = useState([]);

  useEffect(() => {
    for (let i = 0; i < workspacesState.length; i++) {
      if (workspacesState[i].id === workspaceId) {
        if (workspacesState[i].workspace_notes) setNotesText(workspacesState[i].workspace_notes);
      }
    }
  }, []);

  const onDragEnterHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const onDropHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    // make it possible to test what uploaded documents may look like
    const newFileNames = [];
    for (let i = 0; i < event.dataTransfer.files.length; i++) {
      const fileName = event.dataTransfer.files[i].name;
      newFileNames.push(fileName);
    }
    setDocumentsNames([...documentsNames, ...newFileNames]);
  };

  const onChangeFileInputHandler = (event) => {
    const fileName = event.target.files[0].name;
    setDocumentsNames([...documentsNames, fileName]);
  };

  const modalWrapperClickHandler = (event) => {
    if (event.target.className.includes(styles.wrapper) && modalWindowOptionsState.closeOnWrapperClick) {
      closeWindow();
    }
  };

  const textareaChangeHander = (event) => {
    setNotesText(event.target.value);
  };

  const textFocusHandler = () => {
    setIsChangable(true);
  };

  const textBlurHandler = (event) => {
    if (event.target.value.trim().length > 0) {
      setNotesText(event.target.value.trim());
    } else {
      setNotesText("");
    }
    setIsChangable(false);
    // didn't use modifyWorkspaceInformation function cause of backend errors, this way works at least until you refresh the page
    dispatch(modifyWorkspaceNotes(workspaceId, notesText));
  };

  return (
    <div
      className={`${styles.wrapper}`}
      onClick={modalWrapperClickHandler}
      onDragEnter={onDragEnterHandler}
      onDragOver={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
      onDrop={onDropHandler}
    >
      <div
        className={`${notesStyles.workspaceNotesModalWindowSize} 
        ${notesStyles.workSpaceNotesWindowContainer}`}
      >
        <div className={styles.modalDefaultHeader}>
          <h2>{`${modalWindowOptionsState.header} ${currentWorkspace.workspace_name}`}</h2>
          <div
            className={`${notesStyles.closeButton} ${styles.closeButton} ${
              modalWindowOptionsState.closeHeaderButton ? "" : styles.hide
            }`}
            onClick={() => {
              closeWindow();
            }}
          >
            &times;
          </div>
        </div>
        <div className={notesStyles.workspaceNotesBodyContainer}>
          <textarea
            className={`${notesStyles.workspaceNotes} 
            ${isChangable ? notesStyles.workspaceNotesChangable : ""}`}
            value={notesText}
            onChange={textareaChangeHander}
            onFocus={textFocusHandler}
            onBlur={textBlurHandler}
            placeholder={"Введите заметки..."}
          />
          <div className={`${notesStyles.workspaceFileUploadArea} ${styles.documentsList}`}>
            <input multiple="" type="file" autoComplete="off" tabIndex="-1" className={styles.dropInput} />
            <div className={styles.dropZone} style={isDragging ? {} : { display: "none" }}>
              <p>Переместите файлы в эту зону для их загрузки</p>
            </div>
            <div className={styles.documentsList}>
              {documentsNames
                ? documentsNames.map((name, ind) => (
                    <UploadFile
                      key={name + ind}
                      fileName={name}
                      documentId={name + ind}
                      progressMax="100"
                      currentProgress="100"
                      permissions={{ can_read: 1, can_delete: 1 }}
                    />
                  ))
                : ""}
              <label>
                <div className={styles.addDocumentButton}>+</div>
                <input type="file" onChange={onChangeFileInputHandler} />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export { WorkspaceNotesEditor };
