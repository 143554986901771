import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { replacer } from "../../../utils/Replacer";
import { PrioritySelector } from "../../../pages/Workspace/components/Components/PrioritySelector";
import { RoleUserChanger } from "../../../pages/Workspace/components/Components/RoleUserChanger";
import { ThemeSelector } from "../../../pages/Workspace/components/Components/ThemeSelector";
import { PeoplesContainer } from "../../../pages/Workspace/components/kanban/components/PeoplesContainer";
import {
  acceptTask,
  acceptTaskUserTasks,
  addNewTaskToWorkspace,
  changeOptionsToModalWindow,
  changeTaskDescription,
  changeTaskHeader,
  changeTaskSection,
  changeTaskTopic,
  changeTaskTopicUserTasks,
  changeUserTasksTaskDescription,
  changeUserTasksTaskHeader,
  deleteTaskFromUserTasks,
  deleteTaskFromWorkspace,
  fetchTaskDocuments,
  fetchVerificationTaskDocuments,
  fetchTaskLogs,
  hideModalWindow,
  markTaskAsDone,
  markTaskAsDoneUserTasks,
  markTaskAsUnDone,
  moveTaskToAnotherWorkspace,
  setUploadedDocumentName,
  setUploadedDocumentShowStatus,
  setUploadedDocumentUploadPercentage,
  unsetImplementerToTask,
  unsetImplementerToTaskUserTasks,
  unsetTaskDeadlineDate,
  unsetTaskDeadlineDateUserTasks,
  unsetVerificatorToTask,
  unsetVerificatorToTaskUserTasks
} from "../../../state/actions";
import doneIconInCircle from "../../../assets/icons/doneIconInCircle.svg";
import doneIcon from "../../../assets/icons/doneIcon.svg";
import clockIcon from "../../../assets/icons/clock.svg";
import closeIcon from "../../../assets/icons/close.svg";
import tripleDotIcon from "../../../assets/icons/menu.svg";
import hierarchyIcon from "../../../assets/icons/hierarchy.svg";
import { Avatar } from "../../Avatars/Avatar";
import { DropdownButton } from "../../Buttons/DropdownButton";
import { InputDate } from "../../Inputs/InputDate";
import styles from "../styles/ModalWindow.module.css";
import { UploadFile } from "./components/UploadFile";
import { MODAL_WINDOW_TYPES } from "../ModalWindow";
import { toast } from "react-toastify";
import { URL_ADDRESSES } from "../../../config/CONSTANTS";
import leftArrow from "../../../assets/icons/left-arrow.svg";
import { RoleUserChangerUserTasks } from "../../../pages/UserTasks/RoleUserChangerUserTasks";
import { EditableTaskNameModal } from "./components/EditableTaskNameModal";
import { useTranslation } from "react-multi-lang";
import { DropDownButtonWithSearch } from "./components/DropDownButtonWithSearch";
import CommentModalWindow from "./CommentModalWindow";

const EditTaskModalWindow = ({ isModalClosing }) => {
  
  const [modalWindowOptionsBeforeSwitch, setModalWindowOptionsBeforeSwitch] = useState({});
  const modalWindowState = useSelector((state) => state.modalWindow);

  const authorizationState = useSelector((state) => state.authorizationReducer);

  const workspacesState = useSelector((state) => state.workspacesReducer);
  const translate = useTranslation();
  const workspaceInformationState = useSelector((state) => state.workspaceInformationReducer);
  const userTasksState = useSelector((state) => state.userTasksReducer);
  const usersState = useSelector((state) =>
    modalWindowState.options.isCalledFromUserTasks ? state.userTasksReducer : state.companyUsersReducer
  );
  const dispatch = useDispatch();
  let task;

  if (modalWindowState.options.isCalledFromUserTasks)
    if (userTasksState.user_tasks.find((task) => task.task_id === modalWindowState.options.taskId))
      task = userTasksState.user_tasks.find((task) => task.task_id === modalWindowState.options.taskId);
    else {
      userTasksState.user_tasks.push(
        userTasksState.initial_user_tasks.find((task) => task.task_id === modalWindowState.options.taskId)
      );
      task = userTasksState.user_tasks.find((task) => task.task_id === modalWindowState.options.taskId);
    }
  else task = workspaceInformationState.tasks.find((task) => task.task_id === modalWindowState.options.taskId);

  const [caretPosition, setCaretPosition] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const cardRef = useRef(null);
  const taskDescriptionRef = useRef(null);
  const hiddenDescriptionRef = useRef(null);
  const [taskNameText, setTaskNameText] = useState(task.task_name ? task.task_name : "");
  const [headerIsEdit, setHeaderIsEdit] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [showOptionsMenu, setShowOptionsMenu] = useState(false);
  const [showTaskVerificatorUserChanger, setShowTaskVerificatorUserChanger] = useState(false);

  const [showTaskImplementerUserChanger, setShowTaskImplementerUserChanger] = useState(false);

  const [showStatus, setShowStatus] = useState(false);

  const [taskDescriptionText, setTaskDescriptionText] = useState(task ? task.task_descr : "");
  const [descriptionIsEdit, setDescriptionIsEdit] = useState(false);

  const deleteSectionButtonRef = useRef(null);

  if (!task && !modalWindowState.options.isCalledFromUserTasks) {
    setIsClosing(true);
    dispatch(hideModalWindow());
    history.push(`${match.url}/${workspaceInformationState.information.workspace_id}`);
    toast.error("Данная задача не найдена, скорее всего она была удалена!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
  }
  let parentTask;
  if (modalWindowState.options.isCalledFromUserTasks)
    parentTask = userTasksState.initial_user_tasks.find((parent_task) => parent_task.task_id === task.parent_task_id);
  else parentTask = workspaceInformationState.tasks.find((parent_task) => parent_task.task_id === task.parent_task_id);
  let taskPriority;
  if (modalWindowState.options.isCalledFromUserTasks)
    taskPriority = task
      ? userTasksState.priorityList.find((priority) => priority.task_priority_id === task.task_priority_id)
      : "";
  else
    taskPriority = task
      ? workspaceInformationState.taskPriorityStatusesList.find(
          (priority) => priority.task_priority_id === task.task_priority_id
        )
      : "";

  const closeWindow = () => {
    setIsClosing(true);
    dispatch(hideModalWindow());
  };

  const tripleCardModalWindowClose = () => {
    closeWindow();
    if (modalWindowState.options.isCalledFromUserTasks)
      history.push(`/${authorizationState.company_id}/tasks/user/${userTasksState.user_info.user_id}/tasks`);
    if (/\/workspace\/[0-9]+\/task\/[0-9]+/.test(location.pathname)) {
      const currentLocationStartIndex = location.pathname.search(/\/task\/[0-9]+/);
      const currentLocation = location.pathname.substring(0, currentLocationStartIndex);
      history.push(currentLocation);
    }
  };

  const onDragEnterHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const onDropHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    for (let i = 0; i < event.dataTransfer.files.length; i++) {
      let blob = event.dataTransfer.files[i];
      let xhr = new XMLHttpRequest();

      const data = {
        task_id: task.task_id,
        workspace_id: task.workspace_id
      };
      xhr.open("POST", `${URL_ADDRESSES.POST_DOCUMENT_TO_TASK(task.task_id)}`);
      xhr.setRequestHeader("Authorization", "Baerer " + authorizationState.token);
      let formData = new FormData();
      const fileName = blob.name;

      xhr.upload.onprogress = function (event) {
        if (event.lengthComputable) {
          dispatch(setUploadedDocumentName(fileName));
          dispatch(setUploadedDocumentShowStatus(true));
          const percentage = Math.round((event.loaded * 100) / event.total);
          dispatch(setUploadedDocumentUploadPercentage(percentage));
        }
      };
      xhr.upload.onerror = function () {
        toast.error("Ошибка отправки файла на сервер!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      };
      xhr.onloadend = () => {
        if (xhr.status > 199 && xhr.status < 300) {
          if (task.task_type === "VERIFICATION") {
            dispatch(fetchVerificationTaskDocuments(task.task_id, task.parent_task_id));
          } else {
            dispatch(fetchTaskDocuments(task.task_id));
          }
          dispatch(setUploadedDocumentShowStatus(false));
        } else {
          //console.log(`File send error: ${xhr.status}`);
          toast.error("Ошибка отправки файла на сервер!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        }
      };
      formData.append("file", blob);
      formData.append("task_id", data.task_id);
      xhr.send(formData);
    }
  };

  const onKeyPressHandler = (event) => {
    if (event.key === "Tab") event.preventDefault();
  };

  const onClickHandler = (event) => {
    if (!event.target.closest(`.${styles.roleUserChanger}`) && showTaskImplementerUserChanger) {
      setShowTaskImplementerUserChanger(false);
    }
    if (!event.target.closest(`.${styles.roleUserChanger}`) && showTaskVerificatorUserChanger) {
      setShowTaskVerificatorUserChanger(false);
    }
  };

  let sectionsList;
  let currentTaskSection;

  if (!modalWindowState.options.isCalledFromUserTasks) {
    sectionsList = workspaceInformationState.sectionsList.map((section) => ({
      id: section.workspace_section_id,
      label: section.workspace_section_name,
      order: 0
    }));
    currentTaskSection =
      task && task.workspace_section_id
        ? workspaceInformationState.sectionsList.find(
            (section) => section.workspace_section_id === task.workspace_section_id
          ).workspace_section_name
        : sectionsList[0].workspace_section_name;
  } else {
    sectionsList = workspacesState.workspaces
      .filter((workspace) => workspace.permissions.can_read === 1)
      .map((workspace) => ({
        id: workspace.workspace_id,
        label: workspace.workspace_name,
        order: 0
      }));
    sectionsList.unshift({ id: 0, label: translate("taskTracker.commonWords.withoutWorkspace"), order: 0 });
    if (task.workspace_id) sectionsList.unshift({ id: task.workspace_id, label: task.workspace_name, order: 0 });
  }
  let topicsList;
  if (modalWindowState.options.isCalledFromUserTasks)
    topicsList = userTasksState.topicsList.map((topic) => ({
      id: topic.task_topic_id,
      label: topic.task_topic_name,
      order: topic.task_topic_sort
    }));
  else
    topicsList = workspaceInformationState.topicsList.map((topic) => ({
      id: topic.task_topic_id,
      label: topic.task_topic_name,
      order: topic.task_topic_sort
    }));
  let currentTaskTopic;
  if (modalWindowState.options.isCalledFromUserTasks)
    currentTaskTopic =
      task && task.task_topic_id
        ? userTasksState.topicsList.find((topic) => topic.task_topic_id === task.task_topic_id).task_topic_name
        : translate("taskTracker.commonWords.notSelected");
  else
    currentTaskTopic =
      task && task.task_topic_id
        ? workspaceInformationState.topicsList.find((topic) => topic.task_topic_id === task.task_topic_id)
            .task_topic_name
        : translate("taskTracker.commonWords.notSelected");

  const changeTaskSectionHandler = (newSectionName) => {
    const previousSection = workspaceInformationState.sectionsList.find(
      (section) => section.workspace_section_name === currentTaskSection
    );
    const newSection = workspaceInformationState.sectionsList.find(
      (section) => section.workspace_section_name === newSectionName
    );
    const initialState = {
      section_id: task.workspace_section_id,
      task_id: task.task_id,
      task_sort: task.task_sort
    };
    if (newSection.workspace_section_type === "DONE") dispatch(markTaskAsDone(task.task_id, initialState));
    else if (previousSection.workspace_section_type === "DONE")
      dispatch(markTaskAsUnDone(task.task_id, initialState, newSection.workspace_section_id));
    else
      dispatch(changeTaskSection(task.task_id, newSection.workspace_section_id, previousSection.workspace_section_id));
  };

  const closeUserSelectorFunction = () => {
    if (showTaskImplementerUserChanger) {
      setShowTaskImplementerUserChanger(false);
    }
    if (showTaskVerificatorUserChanger) {
      setShowTaskVerificatorUserChanger(false);
    }
  };

  let parentTaskPriority;
  if (modalWindowState.options.isCalledFromUserTasks)
    parentTaskPriority = parentTask
      ? userTasksState.priorityList.find((priority) => priority.task_priority_id === parentTask.task_priority_id)
      : "";
  else
    parentTaskPriority = parentTask
      ? workspaceInformationState.taskPriorityStatusesList.find(
          (priority) => priority.task_priority_id === parentTask.task_priority_id
        )
      : "";

  let parentTaskTopic;

  if (modalWindowState.options.isCalledFromUserTasks)
    parentTaskTopic =
      parentTask && parentTask.task_topic_id
        ? userTasksState.topicsList.find((topic) => topic.task_topic_id === parentTask.task_topic_id).task_topic_name
        : translate("taskTracker.commonWords.notSelected");
  else
    parentTaskTopic =
      parentTask && parentTask.task_topic_id
        ? workspaceInformationState.topicsList.find((topic) => topic.task_topic_id === parentTask.task_topic_id)
            .task_topic_name
        : translate("taskTracker.commonWords.notSelected");

  const implementer = task ? usersState.users.find((user) => user.user_id === task.implementer_user_id) : task;
  const verificator = task ? usersState.users.find((user) => user.user_id === task.verificator_user_id) : task;
  const authorizationUserData = usersState.users.find((user) => user.user_id === authorizationState.user_id);

  const onClickHandlerToTaskOptions = (event) => {
    if (!event.target.closest(`.${styles.optionsButton}`) && showOptionsMenu) {
      setShowOptionsMenu(false);
    }
  };

  const onPasteHandler = (pasteEvent, callback, imageFormat) => {
    if (pasteEvent.clipboardData === false) {
      if (typeof callback == "function") {
        callback(undefined);
      }
    }
    let items = pasteEvent.clipboardData.items;

    if (items === undefined) {
      if (typeof callback == "function") {
        callback(undefined);
      }
    }
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") === -1) continue;
      let blob = items[i].getAsFile();
      let xhr = new XMLHttpRequest();

      const data = {
        task_id: task.task_id,
        workspace_id: task.workspace_id
      };
      xhr.open("POST", `${URL_ADDRESSES.POST_DOCUMENT_TO_TASK(task.task_id)}`);
      xhr.setRequestHeader("Authorization", "Baerer " + authorizationState.token);
      let formData = new FormData();
      const fileName = blob.name;

      xhr.upload.onprogress = function (event) {
        if (event.lengthComputable) {
          dispatch(setUploadedDocumentName(fileName));
          dispatch(setUploadedDocumentShowStatus(true));
          const percentage = Math.round((event.loaded * 100) / event.total);
          dispatch(setUploadedDocumentUploadPercentage(percentage));
        }
      };
      xhr.upload.onerror = function () {
        toast.error("Ошибка отправки файла на сервер!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      };
      xhr.onloadend = () => {
        if (xhr.status > 199 && xhr.status < 300) {
          if (task.task_type === "VERIFICATION") {
            dispatch(fetchVerificationTaskDocuments(task.task_id, task.parent_task_id));
          } else {
            dispatch(fetchTaskDocuments(task.task_id));
          }
          dispatch(setUploadedDocumentShowStatus(false));
        } else {
          toast.error("Ошибка отправки файла на сервер!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        }
      };
      formData.append("file", blob);
      formData.append("task_id", data.task_id);
      xhr.send(formData);
    }
  };

  const wrapperClickHandler = (event) => {
    if (event.target.className.includes(styles.wrapper) && modalWindowState.options.closeOnWrapperClick) {
      tripleCardModalWindowClose();
    }
  };

  const acceptTaskButtonClickHandler = (event) => {
    if (task.task_status !== "DONE" && task.task_decision !== "ACCEPTED") {
      if (modalWindowState.options.isCalledFromUserTasks) dispatch(acceptTaskUserTasks(task.task_id));
      else dispatch(acceptTask(task.task_id));
      tripleCardModalWindowClose();
    }
  };

  const declineTaskButtonClickHandler = (event) => {
    if (task.task_status !== "DONE" && task.task_decision !== "DECLINED") {
      setModalWindowOptionsBeforeSwitch(modalWindowState.options);
      if (modalWindowState.options.isCalledFromUserTasks)
        dispatch(
          changeOptionsToModalWindow({
            type: MODAL_WINDOW_TYPES.DECLINE_TASK_COMMENT,
            closeHeaderButton: true,
            header: "Укажите причину отказа",
            showOkButton: true,
            previousOptions: modalWindowOptionsBeforeSwitch,
            back_to_task: null,
            isCalledFromUserTasks: true
          })
        );
      else
        dispatch(
          changeOptionsToModalWindow({
            type: MODAL_WINDOW_TYPES.DECLINE_TASK_COMMENT,
            closeHeaderButton: true,
            header: "Укажите причину отказа",
            showOkButton: true,
            previousOptions: modalWindowOptionsBeforeSwitch,
            back_to_task: null,
            isCalledFromUserTasks: false
          })
        );
    }
  };

  const unsetImplementerUserButtonClickHandler = () => {
    modalWindowState.options.isCalledFromUserTasks
      ? dispatch(unsetImplementerToTaskUserTasks(task.task_id))
      : dispatch(unsetImplementerToTask(task.task_id));
  };

  const unsetVerificatorUserButtonClickHandler = () => {
    modalWindowState.options.isCalledFromUserTasks
      ? dispatch(unsetVerificatorToTaskUserTasks(task.task_id))
      : dispatch(unsetVerificatorToTask(task.task_id));
  };

  const unsetDeadlineTaskButtonClickHandler = () => {
    modalWindowState.options.isCalledFromUserTasks
      ? dispatch(unsetTaskDeadlineDateUserTasks(task.task_id))
      : dispatch(unsetTaskDeadlineDate(task.task_id));
  };

  const doneButtonClickHandler = () => {
    if (modalWindowState.options.isCalledFromUserTasks) dispatch(markTaskAsDoneUserTasks(task.task_id));
    else dispatch(markTaskAsDone(task.task_id));
    tripleCardModalWindowClose();
  };

  const showLeftSideShowTogglerButtonHandler = () => {
    dispatch(
      changeOptionsToModalWindow({
        showLeftSide: !modalWindowState.options.showLeftSide
      })
    );
  };

  const showRightSideShowTogglerButtonHandler = () => {
    if (!modalWindowState.options.showRightSide) {
      dispatch(fetchTaskLogs(task.task_id));
    }
    dispatch(
      changeOptionsToModalWindow({
        showRightSide: !modalWindowState.options.showRightSide
      })
    );
  };

  const closeModalWindowClickHandler = () => {
    tripleCardModalWindowClose();
  };

  const OptionsButtonClickHandler = () => {
    setShowOptionsMenu((showOptionsMenu) => !showOptionsMenu);
  };

  const deleteTaskHandler = (event) => {
    if (event.target === deleteSectionButtonRef.current && task.permissions.task.can_delete === 1) {
      if (!modalWindowState.options.isCalledFromUserTasks) {
        history.push(`${match.url}/${workspaceInformationState.information.workspace_id}`);
        dispatch(deleteTaskFromWorkspace(task.task_id));
      } else {
        history.push(`/${authorizationState.company_id}/tasks/user/tasks`);
        dispatch(deleteTaskFromUserTasks(task.task_id));
      }
    }
  };

  const onCopyButtonClickHandler = () => {
    if (modalWindowState.options.isCalledFromUserTasks)
      dispatch(
        addNewTaskToWorkspace({
          workspaceId: task.workspace_id ? task.workspace_id : 0,
          sectionId: task.workspace_section_id,
          verificator_user_id: verificator?.user_id,
          implementer_user_id: implementer?.user_id,
          task_priority_id: task.task_priority_id,
          task_topic_id: task.task_topic_id,
          task_deadline_dt: task.task_deadline_dt,
          task_name: taskNameText,
          task_descr: taskDescriptionText,
          task_type: task.task_type,
          isCalledFromUserTasks: true,
          isCalledFromCreateModal: true
        })
      );
    else {
      if (
        workspaceInformationState.sortedTasks
          .filter((WorkspaceTask) => WorkspaceTask.workspace_section_id === task.workspace_section_id)
          .findIndex((WorkspaceTask) => WorkspaceTask.task_id === task.task_id) === 0
      )
        dispatch(
          addNewTaskToWorkspace({
            workspaceId: task.workspace_id,
            sectionId: task.workspace_section_id,
            verificator_user_id: verificator?.user_id,
            implementer_user_id: implementer?.user_id,
            task_priority_id: task.task_priority_id,
            task_topic_id: task.task_topic_id,
            task_deadline_dt: task.task_deadline_dt,
            task_name: taskNameText,
            task_descr: taskDescriptionText,
            task_type: task.task_type,
            isCalledFromCreateModal: true
          })
        );
      else {
        dispatch(
          addNewTaskToWorkspace({
            workspaceId: task.workspace_id,
            sectionId: task.workspace_section_id,
            verificator_user_id: verificator?.user_id,
            implementer_user_id: implementer?.user_id,
            task_priority_id: task.task_priority_id,
            task_topic_id: task.task_topic_id,
            task_deadline_dt: task.task_deadline_dt,
            task_name: taskNameText,
            task_descr: taskDescriptionText,
            task_type: task.task_type,
            task_sort:
              (workspaceInformationState.sortedTasks.filter(
                (WorkspaceTask) => WorkspaceTask.workspace_section_id === task.workspace_section_id
              )[
                workspaceInformationState.sortedTasks
                  .filter((WorkspaceTask) => WorkspaceTask.workspace_section_id === task.workspace_section_id)
                  .findIndex((WorkspaceTask) => WorkspaceTask.task_id === task.task_id) - 1
              ].task_sort +
                task.task_sort) /
              2,
            isCalledFromCreateModal: true
          })
        );
      }
      // console.log({'task_name': taskNameText, 'task_descr': taskDescriptionText,
      //     'task_priority': task.task_priority_id, 'deadline': task.task_deadline_dt,
      //     'section': task.workspace_section_id, 'topic': task.task_topic_id,
      //     'implementor': implementer?.user_id, 'verificator': verificator?.user_id })
    }
    tripleCardModalWindowClose();
  };

  let textareaControlPressState = false;

  const textareaKeyDownHandler = (event) => {
    if (event.key === "Control") {
      textareaControlPressState = true;
    }
    if (event.key === "Enter" && textareaControlPressState) {
      event.preventDefault();
      event.target.blur();
    }
    if (event.target.innerHTML.includes("--")) {
      setTaskDescriptionText(event.target.innerHTML.replace("--", "—"));
      updateTaskDescription(event.target.innerHTML.replace("--", "—"));
      replacer("--", "—");
    }
  };

  const taskNameFocusHandler = () => {
    setTaskNameText(task ? task.task_name : "");
    setHeaderIsEdit(true);
  };

  const updateTaskName = (newTaskName) => {
    const previousTaskName = task.task_name;
    if (modalWindowState.options.isCalledFromUserTasks)
      dispatch(changeUserTasksTaskHeader(task.task_id, newTaskName, previousTaskName));
    else dispatch(changeTaskHeader(task.task_id, newTaskName, previousTaskName));
  };

  const taskNameBlurHandler = (event) => {
    if (event.target.innerText !== task.task_name) {
      if (event.target.innerText.length > 0) {
        setTaskNameText(event.target.innerText);
        updateTaskName(event.target.innerText);
      } else {
        setTaskNameText("");
        updateTaskName("");
      }
    }
    setHeaderIsEdit(false);
  };

  const taskDescriptionFocusHandler = (event) => {
    if (caretPosition) {
      let setpos = document.createRange();
      let set = window.getSelection();
      setpos.setStart(event.target.childNodes[0], event.target.childNodes[0].length);
      setpos.collapse(true);
      set.removeAllRanges();
      set.addRange(setpos);
      event.target.focus();
    }
    setDescriptionIsEdit(true);
  };

  const updateTaskDescription = (newTaskDescription) => {
    const previousTaskDescription = task.task_descr;
    if (modalWindowState.options.isCalledFromUserTasks)
      dispatch(changeUserTasksTaskDescription(task.task_id, newTaskDescription, previousTaskDescription));
    else dispatch(changeTaskDescription(task.task_id, newTaskDescription, previousTaskDescription));
  };

  const taskDescriptionBlurHandler = (event) => {
    let position = 0;
    const isSupported = typeof window.getSelection !== "undefined";
    if (isSupported) {
      const selection = window.getSelection();
      if (selection.rangeCount !== 0) {
        const range = window.getSelection().getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(event.target);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        position = preCaretRange.toString().length;
      }
    }
    setCaretPosition(position);

    if (event.target.innerHTML !== task.task_descr) {
      if (event.target.innerHTML.length > 0) {
        setTaskDescriptionText(event.target.innerHTML);
        updateTaskDescription(event.target.innerHTML);
        taskDescriptionRef.current.innerHTML = event.target.innerHTML;
      } else {
        setTaskDescriptionText("");
        updateTaskDescription("");
      }
    }

    setDescriptionIsEdit(false);
  };

  // eslint-disable-next-line
  useEffect(() => {
    taskDescriptionRef.current.innerHTML = taskDescriptionText;
  }, []);

  const changeTaskTopicHandler = (newTopicName) => {
    if (modalWindowState.options.isCalledFromUserTasks) {
      const previousTheme = userTasksState.topicsList.find((theme) => theme.task_topic_name === currentTaskTopic);
      const selectedTheme = userTasksState.topicsList.find((theme) => theme.task_topic_name === newTopicName);

      dispatch(
        changeTaskTopicUserTasks(
          task.task_id,
          selectedTheme.task_topic_id,
          previousTheme ? previousTheme.task_topic_id : null
        )
      );
    } else {
      const previousTheme = workspaceInformationState.topicsList.find(
        (theme) => theme.task_topic_name === currentTaskTopic
      );
      const selectedTheme = workspaceInformationState.topicsList.find(
        (theme) => theme.task_topic_name === newTopicName
      );

      dispatch(
        changeTaskTopic(task.task_id, selectedTheme.task_topic_id, previousTheme ? previousTheme.task_topic_id : null)
      );
    }
  };

  const changeFIleInputHandler = (event) => {
    const xhr = new XMLHttpRequest();
    const data = {
      task_id: task.task_id,
      workspace_id: task.workspace_id
    };
    xhr.open("POST", `${URL_ADDRESSES.POST_DOCUMENT_TO_TASK(task.task_id)}`);
    xhr.setRequestHeader("Authorization", "Baerer " + authorizationState.token);
    let formData = new FormData();
    const fileName = event.target.files[0].name;

    xhr.upload.onprogress = function (event) {
      if (event.lengthComputable) {
        dispatch(setUploadedDocumentName(fileName));
        dispatch(setUploadedDocumentShowStatus(true));
        const percentage = Math.round((event.loaded * 100) / event.total);
        dispatch(setUploadedDocumentUploadPercentage(percentage));
      }
    };
    xhr.upload.onerror = function () {
      toast.error("Ошибка отправки файла на сервер!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    };
    xhr.onloadend = () => {
      if (xhr.status > 199 && xhr.status < 300) {
        if (task.task_type === "VERIFICATION") {
          dispatch(fetchVerificationTaskDocuments(task.task_id, task.parent_task_id));
        } else {
          dispatch(fetchTaskDocuments(task.task_id));
        }
        dispatch(setUploadedDocumentShowStatus(false));
      } else {
        toast.error("Ошибка отправки файла на сервер!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    };
    formData.append("file", event.target.files[0]);
    formData.append("task_id", data.task_id);
    // formData.append('workspace_id', data.workspace_id);
    xhr.send(formData);
  };

  const logTextHandler = (log) => {
    // eslint-disable-next-line
        let userInstance = usersState.users.find((user) => {
      if (user.user_id === log.new_object_id) return user;
    });

    switch (log.log_type_id) {
      case 1:
        let date = new Date();
        date.setTime(Date.parse(log.new_object_text));
        date = date.toLocaleString().split(",")[0];
        return (
          <>
            {" "}
            {log.log_type_text} на {date}{" "}
          </>
        );
      case 5:
        return (
          <>
            {" "}
            {log.log_type_text} на «{log.new_object_text}»{" "}
          </>
        );
      case 6:
        return (
          <>
            {" "}
            {log.log_type_text} на {log.new_object_text}{" "}
          </>
        );
      case 13:
      case 15:
      case 16:
      case 17:
        return (
          <>
            {" "}
            {log.log_type_text} на {userInstance.user_first_name} {userInstance.user_last_name}
          </>
        ); //Fetch User
      default:
        return (
          <>
            {" "}
            {log.log_type_text} {log.new_object_text}{" "}
          </>
        );
    }
  };

  const onOpenTaskClickHandler = () => {
    if (modalWindowState.options.isCalledFromUserTasks) {
      history.push(
        `/${authorizationState.company_id}/tasks/workspace/${parentTask.workspace_id}/task/${parentTask.task_id}`
      );
    } else dispatch(changeOptionsToModalWindow({ back_to_task: task.task_id }));
  };

  const textareaKeyUpHandler = (event) => {
    if (event.key === "Control") {
      textareaControlPressState = false;
    }
    if (event.target.innerHTML.includes("--")) {
      setTaskDescriptionText(event.target.innerHTML.replace("--", "—"));
      updateTaskDescription(event.target.innerHTML.replace("--", "—"));
      replacer("--", "—");
    }
  };

  const onDescriptionPasteHandler = (event) => {
    if (!event.clipboardData.getData("Text")) event.preventDefault();
    else {
      event.preventDefault();
      let text = event.clipboardData.getData("text/plain");
      document.execCommand("insertHTML", false, text);
    }
  };

  const newTaskSectionUpdate = (workspaceName) => {
    dispatch(
      moveTaskToAnotherWorkspace(
        task.task_id,
        workspaceName !== translate("taskTracker.commonWords.withoutWorkspace")
          ? workspacesState.workspaces.find((workspace) => workspace.workspace_name === workspaceName).workspace_id
          : 0
      )
    );
  };
  // eslint-disable-next-line
    useEffect(() => {
    setTimeout(() => setShowStatus(modalWindowState.showStatus), 1000);
    // eslint-disable-next-line
    }, [modalWindowState.showStatus])
  // eslint-disable-next-line
    useEffect(() => {
    setTaskDescriptionText(task ? task.task_descr : "");
    // eslint-disable-next-line
    }, [task.task_id]);
  // eslint-disable-next-line
    useEffect(() => {
    document.addEventListener("click", onClickHandlerToTaskOptions);
    task.permissions.docs.can_edit === 1 && window.addEventListener("paste", onPasteHandler, false);
    document.addEventListener("keydown", onKeyPressHandler);
    return () => {
      document.removeEventListener("click", onClickHandlerToTaskOptions);
      task.permissions.docs.can_edit === 1 && window.removeEventListener("paste", onPasteHandler, false);
      document.removeEventListener("keydown", onKeyPressHandler);
    };
  });
  // eslint-disable-next-line
    useEffect(() => setTaskDescriptionText(task ? task.task_descr : ''),
    // eslint-disable-next-line
        [task.task_id]);
  // eslint-disable-next-line
    useEffect(() => {
    document.addEventListener("click", onClickHandler);
    window.onpopstate = function (event) {
      if (modalWindowState.options.isCalledFromUserTasks) {
        closeWindow();
      }
      if (/\/workspace\/[0-9]+\/task\/[0-9]+/.test(location.pathname)) {
        closeWindow();
      }
    };
    return () => {
      document.removeEventListener("click", onClickHandler);
    };
  });
  // eslint-disable-next-line
    useEffect(() => {
    if (descriptionIsEdit || headerIsEdit || modalWindowState.showUpperModalStatus === true)
      document.removeEventListener("click", onClickHandler);
    else if (modalWindowState.showUpperModalStatus === false) document.addEventListener("click", onClickHandler);
  });

  return (
    <div
      className={`${styles.wrapper} ${styles.tripleWindowTask} ${
        isClosing || isModalClosing ? styles.isClosing : styles.opened
      }`}
      onClick={
        descriptionIsEdit || headerIsEdit || modalWindowState.showUpperModalStatus === true
          ? undefined
          : wrapperClickHandler
      }
      onDragEnter={onDragEnterHandler}
      onDragOver={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
      onDrop={onDropHandler}
    >
      <div className={`${styles.leftSideWindow} ${modalWindowState.options.showLeftSide ? styles.show : ""}`}>
        <div className={`${styles.windowLabel} ${modalWindowState.options.showLeftSide ? styles.show : ""}`}>
          {translate("taskTracker.commonWords.parentTask")}
        </div>
        {parentTask && showStatus && (
          <div className={styles.card}>
            <h3>{parentTask.task_name}</h3>
            <div className={styles.parentTaskInformationBar}>
              <div className={styles.leftSide}>
                <PrioritySelector
                  taskId={parentTask.task_id}
                  currentLabel={parentTaskPriority.task_priority_name}
                  currentColor={parentTaskPriority.task_priority_color}
                  prioritiesList={
                    modalWindowState.options.isCalledFromUserTasks
                      ? userTasksState.priorityList
                      : workspaceInformationState.taskPriorityStatusesList
                  }
                  className={styles.prioritySelector}
                  disabled={true}
                  editable={false}
                  isCalledFromUserTasks={modalWindowState.options.isCalledFromUserTasks}
                />
                <ThemeSelector
                  taskId={parentTask.task_id}
                  currentLabel={parentTaskTopic}
                  themesList={workspaceInformationState.topicsList}
                  className={styles.themeSelector}
                  disabled={true}
                  editable={false}
                  isCalledFromUserTasks={modalWindowState.options.isCalledFromUserTasks}
                />
              </div>
              <div className={styles.rightSide}>
                <InputDate
                  className={styles.calendar}
                  value={parentTask.task_deadline_dt ? parentTask.task_deadline_dt : ""}
                  showImmutableDate={true}
                  taskId={parentTask.task_id}
                  disabled={true}
                  isCalledFromUserTasks={modalWindowState.options.isCalledFromUserTasks}
                />
              </div>
            </div>
            <div className={styles.parentTaskDescription}>
              <p>{parentTask.task_descr}</p>
              <div className={styles.documentsList} />
            </div>
            <div className={styles.footer}>
              <PeoplesContainer
                peoples={{
                  initiatorUserId: parentTask.initiator_user_id,
                  verificatorUserId: parentTask.verificator_user_id,
                  implementerUserId: parentTask.implementer_user_id
                }}
                className={styles.peoplesContainer}
                taskId={parentTask.task_id}
                disabled={true}
                permissions={task.permissions.user}
                isCalledFromUserTasks={modalWindowState.options.isCalledFromUserTasks}
              />
              <Link to={`${parentTask.task_id}`} onClick={onOpenTaskClickHandler}>
                <div className={styles.openParentTaskButton}>{translate("taskTracker.commonWords.openTask")}</div>
              </Link>
            </div>
          </div>
        )}
      </div>
      <div className={styles.centralWindow}>
        {modalWindowState.options.back_to_task && (
          <Link
            to={`${modalWindowState.options.back_to_task}`}
            onClick={() => dispatch(changeOptionsToModalWindow({ back_to_task: null }))}
          >
            <img
              src={leftArrow}
              className={styles.backArrow}
              alt={"Назад"}
              title={translate("taskTracker.commonWords.backToTask")}
            />
          </Link>
        )}
        <div
          className={`${styles.windowLabel} ${
            modalWindowState.options.showLeftSide || modalWindowState.options.showRightSide ? styles.show : ""
          }`}
        >
          {translate("taskTracker.commonWords.responseTask")}
        </div>
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <div className={styles.leftSideButtonsGroup}>
              {task && task.task_type === "REGULAR" ? (
                <div
                  className={`${styles.doneButton} ${task.permissions.actions.done.can_edit === 0 && styles.disabled}`}
                  onClick={task.permissions.actions.done.can_edit === 1 ? doneButtonClickHandler : undefined}
                >
                  {task.permissions.actions.done.can_read === 1 && (
                    <>
                      <div
                        className={`${styles.objectContainer} ${
                          task.task_status === "DONE"
                            ? styles.done
                            : task.task_status === "ON_VERIFICATION"
                            ? styles.verification
                            : ""
                        }`}
                      >
                        <object type="image/svg+xml" data={doneIconInCircle}>
                          done icon
                        </object>
                      </div>

                      <span>{translate("taskTracker.commonWords.done")}</span>
                    </>
                  )}
                </div>
              ) : task.task_type === "VERIFICATION" ? (
                <div className={styles.verificationButtonBar}>
                  {task.permissions.actions.accept.can_read === 1 && (
                    <button
                      className={`${styles.acceptButton} ${
                        task && task.task_status === "DONE" && task.task_decision === "ACCEPTED" ? styles.active : ""
                      }`}
                      style={task.permissions.actions.accept.can_edit !== 1 ? { cursor: "default" } : {}}
                      onClick={
                        task.permissions.actions.accept.can_edit === 1 ? acceptTaskButtonClickHandler : undefined
                      }
                    >
                      <object type="image/svg+xml" data={doneIcon}>
                        done icon
                      </object>
                    </button>
                  )}

                  {task.permissions.actions.decline.can_read === 1 && (
                    <button
                      className={`${styles.declineButton} ${
                        task && task.task_status === "DONE" && task.task_decision === "DECLINED" ? styles.active : ""
                      }`}
                      style={task.permissions.actions.decline.can_edit !== 1 ? { cursor: "default" } : {}}
                      onClick={
                        task.permissions.actions.decline.can_edit === 1 ? declineTaskButtonClickHandler : undefined
                      }
                    >
                      <object type="image/svg+xml" data={closeIcon}>
                        decline icon
                      </object>
                    </button>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className={styles.rightSideButtonsGroup}>
              {parentTask && (
                <button
                  className={`${styles.headerButton} ${modalWindowState.options.showLeftSide ? styles.show : ""}`}
                  onClick={showLeftSideShowTogglerButtonHandler}
                >
                  <img src={hierarchyIcon} alt={""} />
                </button>
              )}
              {task.permissions.logs.can_read === 1 && (
                <button
                  className={`${styles.headerButton} ${modalWindowState.options.showRightSide ? styles.show : ""}`}
                  onClick={showRightSideShowTogglerButtonHandler}
                >
                  <img src={clockIcon} alt={""} />
                </button>
              )}
              {task.permissions.task.can_delete === 1 && (
                <div className={styles.optionsButton} onClick={OptionsButtonClickHandler}>
                  <object type="image/svg+xml" data={tripleDotIcon}>
                    tripleDot icon
                  </object>
                  {showOptionsMenu && (
                    <div className={styles.optionsList}>
                      <div
                        className={styles.sectionOptionsMenu}
                        style={{ color: "black" }}
                        onClick={onCopyButtonClickHandler}
                      >
                        {translate("taskTracker.commonWords.copy")}
                      </div>
                      <div
                        className={styles.sectionOptionsMenu}
                        ref={deleteSectionButtonRef}
                        onClick={deleteTaskHandler}
                      >
                        {translate("taskTracker.commonWords.delete")}
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div
                className={`${styles.optionsButton} ${styles.closeButton} ${
                  modalWindowState.options.closeHeaderButton ? "" : styles.hide
                }`}
                onClick={closeModalWindowClickHandler}
              >
                <object type="image/svg+xml" data={closeIcon}>
                  close icon
                </object>
              </div>
            </div>
          </div>
          <div
            className={styles.cardBody}
            ref={cardRef}
            // onDragLeave={onDragLeaveHandler}
          >
            {task.permissions.name.can_read === 1 && (
              <EditableTaskNameModal
                text={taskNameText}
                permissions={task.permissions.name}
                headerIsEdit={headerIsEdit}
                onFocus={task.permissions.name.can_edit === 1 ? taskNameFocusHandler : undefined}
                onBlur={task.permissions.name.can_edit === 1 ? taskNameBlurHandler : undefined}
              />
            )}
            <div className={styles.taskForm}>
              {task.permissions.user.implementer.can_read === 1 && (
                <div className={styles.formElement}>
                  <div className={styles.label}>{translate("taskTracker.commonWords.implementer")}</div>
                  <div
                    className={`${styles.userInput} ${task && implementer ? "" : styles.notChanged} ${
                      styles.inputSize
                    } ${task.permissions.user.implementer.can_edit === 1 ? "" : styles.notEditable}`}
                    onClick={() => {
                      if (!showTaskImplementerUserChanger) setShowTaskImplementerUserChanger(true);
                    }}
                    style={task.permissions.user.implementer.can_edit === 1 ? {} : { cursor: "default" }}
                  >
                    <Avatar
                      src={task && implementer ? implementer.user_photo : ""}
                      className={styles.avatar}
                      alt={
                        task && implementer
                          ? `${implementer.user_last_name} ${implementer.user_first_name} ${
                              implementer.user_middle_name ? implementer.user_middle_name : ""
                            }`.trim()
                          : ""
                      }
                      type="square"
                    />
                    {task && implementer && (
                      <h1>{`${implementer.user_last_name} ${implementer.user_first_name[0]}.`}</h1>
                    )}
                    {task && !implementer && task.permissions.user.implementer.can_edit === 1 ? (
                      <p>{translate("taskTracker.commonWords.assign")}</p>
                    ) : (
                      !implementer && <p>{translate("taskTracker.commonWords.notAssigned")}</p>
                    )}
                    {showTaskImplementerUserChanger &&
                      task.permissions.user.implementer.can_edit === 1 &&
                      (modalWindowState.options.isCalledFromUserTasks ? (
                        <RoleUserChangerUserTasks
                          className={`${styles.roleUserChanger} ${showTaskImplementerUserChanger ? "" : styles.hide}`}
                          type={task && implementer ? "changer" : "adder"}
                          userType="implementer"
                          closeWindowFunction={() => {
                            closeUserSelectorFunction();
                          }}
                          taskId={task.task_id}
                        />
                      ) : (
                        <RoleUserChanger
                          className={`${styles.roleUserChanger} ${showTaskImplementerUserChanger ? "" : styles.hide}`}
                          type={task && implementer ? "changer" : "adder"}
                          userType="implementer"
                          closeWindowFunction={() => {
                            closeUserSelectorFunction();
                          }}
                          taskId={task.task_id}
                        />
                      ))}
                    {task && implementer && task.permissions.user.implementer.can_delete === 1 && (
                      <button
                        className={styles.deleteButtonOnTaskForm}
                        onClick={unsetImplementerUserButtonClickHandler}
                      >
                        <object type="image/svg+xml" data={closeIcon}>
                          delete user
                        </object>
                      </button>
                    )}
                  </div>
                </div>
              )}
              {task.permissions.section.can_read === 1 && (
                <div className={styles.formElement}>
                  <div className={styles.label}>
                    {modalWindowState.options.isCalledFromUserTasks
                      ? "Workspace"
                      : translate("taskTracker.commonWords.section")}
                  </div>
                  {modalWindowState.options.isCalledFromUserTasks && (
                    <DropDownButtonWithSearch items={sectionsList} onSelectOptionFunction={newTaskSectionUpdate} />
                  )}
                  {!modalWindowState.options.isCalledFromUserTasks && (
                    <DropdownButton
                      buttonLabel={currentTaskSection}
                      className={`${styles.inputSize}`}
                      customStylesToButton={`${styles.dropdownButton} ${
                        task.permissions.section.can_edit === 0 ? styles.dropdownDisabled : ""
                      }`}
                      customStylesToOptionsList={styles.dropdownButtonOptionList}
                      customStylesToOptionsListElement={styles.dropdownButtonOptionListElement}
                      customStylesToButtonOnOpenMenu={styles.dropdownButtonIsOpen}
                      dropDownMenuOptions={sectionsList}
                      onSelectOptionFunction={changeTaskSectionHandler}
                      editable={task.permissions.section.can_edit === 1}
                      isCalledFromUserTasks={modalWindowState.options.isCalledFromUserTasks}
                    />
                  )}
                </div>
              )}
              {task.permissions.user.verificator.can_read === 1 && (
                <div className={styles.formElement}>
                  <div className={styles.label}>{translate("taskTracker.commonWords.verifier")}</div>
                  <div
                    className={`${styles.userInput} ${task && verificator ? "" : styles.notChanged} ${
                      styles.inputSize
                    } ${task.permissions.user.verificator.can_edit === 1 ? "" : styles.notEditable}`}
                    onClick={() => {
                      if (!showTaskVerificatorUserChanger) setShowTaskVerificatorUserChanger(true);
                    }}
                    style={task.permissions.user.verificator.can_edit === 1 ? {} : { cursor: "default" }}
                  >
                    <Avatar
                      src={task && verificator ? verificator.user_photo : ""}
                      className={styles.avatar}
                      alt={
                        task && verificator
                          ? `${verificator.user_last_name} ${verificator.user_first_name} ${
                              verificator.user_middle_name ? verificator.user_middle_name : ""
                            }`.trim()
                          : ""
                      }
                      type="square"
                    />
                    {task && verificator && (
                      <h1>{`${verificator.user_last_name} ${verificator.user_first_name[0]}.`}</h1>
                    )}
                    {!verificator &&
                      (task && task.permissions.user.verificator.can_edit === 1 ? (
                        <p>{translate("taskTracker.commonWords.assign")}</p>
                      ) : (
                        !verificator && <p>{translate("taskTracker.commonWords.notAssigned")}</p>
                      ))}
                    {showTaskVerificatorUserChanger &&
                      task.permissions.user.verificator.can_edit === 1 &&
                      (modalWindowState.options.isCalledFromUserTasks ? (
                        <RoleUserChangerUserTasks
                          className={`${styles.roleUserChanger} ${showTaskVerificatorUserChanger ? "" : styles.hide}`}
                          type={task && verificator ? "changer" : "adder"}
                          userType="verificator"
                          closeWindowFunction={() => {
                            closeUserSelectorFunction();
                          }}
                          taskId={task.task_id}
                        />
                      ) : (
                        <RoleUserChanger
                          className={`${styles.roleUserChanger} ${showTaskVerificatorUserChanger ? "" : styles.hide}`}
                          type={task && verificator ? "changer" : "adder"}
                          userType="verificator"
                          closeWindowFunction={() => {
                            closeUserSelectorFunction();
                          }}
                          taskId={task.task_id}
                        />
                      ))}
                    {task && verificator && task.permissions.user.verificator.can_delete === 1 && (
                      <button
                        className={styles.deleteButtonOnTaskForm}
                        onClick={unsetVerificatorUserButtonClickHandler}
                      >
                        <object type="image/svg+xml" data={closeIcon}>
                          delete user
                        </object>
                      </button>
                    )}
                  </div>
                </div>
              )}
              {task.permissions.topic.can_read === 1 && (
                <div className={styles.formElement}>
                  <div className={styles.label}>{translate("taskTracker.commonWords.topic")}</div>
                  <DropDownButtonWithSearch
                    buttonLabel={currentTaskTopic}
                    className={`${styles.inputSize}`}
                    items={topicsList}
                    onSelectOptionFunction={changeTaskTopicHandler}
                    defaultLabel={currentTaskTopic}
                    editable={task.permissions.topic.can_edit}
                    // isCalledFromUserTasks={modalWindowState.options.isCalledFromUserTasks}
                  />
                </div>
              )}
              {task.permissions.deadline.can_read === 1 ? (
                <div className={styles.formElement}>
                  <div className={styles.label}>{translate("taskTracker.commonWords.deadline")}</div>
                  <div
                    className={`${styles.dateInput} ${styles.inputSize} 
										${
                      task.task_deadline_dt &&
                      new Date(new Date(task.task_deadline_dt).toDateString()) < new Date(new Date().toDateString())
                        ? styles.deadline
                        : ""
                    }`}
                    style={task.permissions.deadline.can_edit === 1 ? {} : { cursor: "default" }}
                  >
                    <InputDate
                      className={styles.calendar}
                      containerClassName={styles.calendarContainer}
                      value={task ? task.task_deadline_dt : ""}
                      taskId={task ? task.task_id : ""}
                      showImmutableDate={true}
                      editable={task.permissions.deadline.can_edit === 1}
                      displayState={true}
                      isCalledFromModal={true}
                      isCalledFromUserTasks={modalWindowState.options.isCalledFromUserTasks}
                    />
                    {task && task.task_deadline_dt && task.permissions.deadline.can_delete === 1 && (
                      <button className={styles.deleteButtonOnTaskForm} onClick={unsetDeadlineTaskButtonClickHandler}>
                        <object type="image/svg+xml" data={closeIcon}>
                          delete deadline
                        </object>
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className={styles.formElement}></div>
              {task.permissions.priority.can_read === 1 && (
                <div className={styles.formElement}>
                  <div className={styles.label}>{translate("taskTracker.commonWords.priority")}</div>
                  <div className={`${styles.priorityInput} ${styles.inputSize}`}>
                    <PrioritySelector
                      taskId={task ? task.task_id : ""}
                      currentLabel={taskPriority.task_priority_name}
                      currentColor={taskPriority.task_priority_color}
                      prioritiesList={
                        modalWindowState.options.isCalledFromUserTasks
                          ? userTasksState.priorityList
                          : workspaceInformationState.taskPriorityStatusesList
                      }
                      className={styles.prioritySelector}
                      customStylesToDropdownList={styles.priorityList}
                      customStylesToSelectorButton={styles.priorityButton}
                      showArrow={true}
                      editable={task.permissions.priority.can_edit}
                      isCalledFromUserTasks={modalWindowState.options.isCalledFromUserTasks}
                    />
                  </div>
                </div>
              )}
            </div>
            {task.permissions.description.can_read === 1 && (
              <>
                <div
                  className={`${styles.taskDescriptionHidden} ${descriptionIsEdit ? styles.isEdit : ""}`}
                  ref={hiddenDescriptionRef}
                >
                  {taskDescriptionText}
                </div>
                <div
                  className={`${styles.taskDescription} ${descriptionIsEdit ? styles.isEdit : ""}`}
                  onFocus={task.permissions.description.can_edit === 1 ? taskDescriptionFocusHandler : undefined}
                  onBlur={task.permissions.description.can_edit === 1 ? taskDescriptionBlurHandler : undefined}
                  onKeyDown={task.permissions.description.can_edit === 1 ? textareaKeyDownHandler : undefined}
                  onKeyUp={task.permissions.description.can_edit === 1 ? textareaKeyUpHandler : undefined}
                  onPaste={onDescriptionPasteHandler}
                  contentEditable={task.permissions.description.can_edit === 1}
                  suppressContentEditableWarning={true}
                  placeholder={
                    task.permissions.description.can_edit === 1
                      ? `${translate("taskTracker.commonWords.enterTaskDescription")}...`
                      : translate("taskTracker.commonWords.noTaskDescription")
                  }
                  ref={taskDescriptionRef}
                  style={task.permissions.description.can_edit === 1 ? {} : { cursor: "default" }}
                ></div>
              </>
            )}
            {/*{task.task_type === 'VERIFICATION' &&*/}
            {/*    <div className={styles.actionComment}>*/}
            {/*        <h4>Результат работ:</h4>*/}
            {/*        <div contentEditable={task.permissions.description.can_edit === 1}*/}
            {/*             placeholder={'Укажите результат выполненных работ:...'}*/}
            {/*             style={task.permissions.description.can_edit === 1 ? {} : {cursor: 'default'}}*/}
            {/*        >*/}
            {/*            /!*{task.task_action_comment}*!/*/}
            {/*        </div>*/}
            {/*    </div>}*/}
            {task.permissions.docs.can_list === 1 && (
              <div className={styles.taskDocs}>
                <h4>{translate("taskTracker.commonWords.documentsAndFiles")}:</h4>
                {task.permissions.docs.can_edit === 1 && (
                  <>
                    <input multiple="" type="file" autoComplete="off" tabIndex="-1" className={styles.dropInput} />
                    <div className={styles.dropZone} style={isDragging ? {} : { display: "none" }}>
                      <p>Переместите файлы в эту зону для их загрузки</p>
                    </div>
                  </>
                )}
                <div className={styles.documentsList}>
                  {modalWindowState.options.taskDocuments
                    ? modalWindowState.options.taskDocuments.map((document) => (
                        <UploadFile
                          key={document.doc_id}
                          fileName={document.doc_original_name}
                          documentId={document.doc_id}
                          progressMax="100"
                          currentProgress="100"
                          permissions={task.permissions.docs}
                        />
                      ))
                    : ""}
                  {modalWindowState.options.taskUploadedDocument.show && (
                    <UploadFile
                      fileName={modalWindowState.options.taskUploadedDocument.name}
                      progressMax="100"
                      currentProgress={modalWindowState.options.taskUploadedDocument.uploadPercentage}
                      permissions={task.permissions.docs}
                    />
                  )}
                  {task.permissions.docs.can_edit === 1 && (
                    <label>
                      <div className={styles.addDocumentButton}>+</div>
                      <input type="file" onChange={changeFIleInputHandler} />
                    </label>
                  )}
                </div>
              </div>
            )}
            {/* <CommentModalWindow users={authorizationUserData} /> */}
          </div>
        </div>
      </div>
      <div className={`${styles.rightSideWindow} ${modalWindowState.options.showRightSide ? styles.show : ""}`}>
        {showStatus && (
          <>
            <div className={`${styles.windowLabel} ${modalWindowState.options.showRightSide ? styles.show : ""}`}>
              {translate("taskTracker.commonWords.log")}
            </div>
            {task.permissions.logs.can_list === 1 && (
              <div className={styles.card}>
                {modalWindowState.options.logs.map((log) => (
                  <div className={styles.logListElement} key={log.log_id}>
                    <p className={styles.dateTime}>
                      {`${
                        new Date(log.updatedAt).getDate() < 10
                          ? "0" + new Date(log.updatedAt).getDate()
                          : new Date(log.updatedAt).getDate()
                      }.${
                        new Date(log.updatedAt).getMonth() < 9
                          ? "0" + (+new Date(log.updatedAt).getMonth() + 1)
                          : +new Date(log.updatedAt).getMonth() + 1
                      }.${new Date(log.updatedAt).getFullYear()}`}{" "}
                      {`${new Date(log.updatedAt).getHours()}:${
                        (new Date(log.updatedAt).getMinutes() < 10 ? "0" : "") + new Date(log.updatedAt).getMinutes()
                      }`}
                    </p>
                    <p className={styles.logText}>{logTextHandler(log)}</p>
                  </div>
                ))}
                {modalWindowState.options.logs.length === 0 && (
                  <div className={styles.logListElement}>
                    <p className={styles.logText}>Отсутствуют логи для этой задачи</p>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export { EditTaskModalWindow };
