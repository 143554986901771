import React from "react";
import PropTypes from "prop-types";

const SearchIllustrationIcon = ({ className, color, width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      viewBox="0 0 51.047 41.915"
    >
      <path
        fill={color ? color : "#c4c6c9"}
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit="10"
        d="M46.795 41.305a2.912 2.912 0 01-1.768-1.41l-4.82-8.635a.853.853 0 111.49-.83l4.814 8.635a1.256 1.256 0 00.75.6 1.1 1.1 0 00.854-.094 1.2 1.2 0 00.406-1.632l-4.717-8.45a.853.853 0 111.49-.83l4.721 8.46a2.91 2.91 0 01-1.07 3.942 2.843 2.843 0 01-2.15.245zm-29.379-1.042a2.243 2.243 0 01-1.863-2.08v-3.824c0-.74.576-1.48 1.863-1.48h8.2c.863 0 1.3.5 1.3 1.48v3.824c0 1.807-.82 2.08-1.3 2.08zm-.156-5.673v3.593a.9.9 0 00.27.373h7.662a3.4 3.4 0 00.02-.373v-3.6h-7.795a1.36 1.36 0 00-.157.009zM2.104 40.263c-.99 0-1.6-1.212-1.6-2.08v-3.824a1.446 1.446 0 011.6-1.48h8.2c1.273 0 1.273 1.112 1.273 1.48v3.824c0 1.382-.43 2.08-1.273 2.08zm.1-2.08a.861.861 0 00.113.373h7.545a3.694 3.694 0 00.014-.373v-3.6H2.208zm15.34-7.578v-.854H9.589v.854a.852.852 0 11-1.7 0v-1.7a.854.854 0 01.854-.854h3.977v-2.273a.854.854 0 011.707 0v2.273h3.975a.856.856 0 01.854.854v1.7a.854.854 0 01-1.707 0zm16.209-1.334a11.313 11.313 0 113.125.438 11.391 11.391 0 01-3.126-.436zm-6.2-13.587a9.671 9.671 0 109.309-7.026 9.68 9.68 0 00-9.307 7.028zm-17.8 8.106a2.031 2.031 0 01-1.873-1.872v-3.824c0-.425.186-1.405 1.873-1.405h8.205a1.194 1.194 0 011.287 1.405v3.824c0 1.547-.7 1.872-1.287 1.872zm-.17-5.395v3.494a.6.6 0 00.221.193h7.734V18.39H9.753c-.062.003-.118.003-.165.008zm3.127-3.7v-1.391a1.186 1.186 0 01.367-.878 1.765 1.765 0 011.43-.306c.107.009.207.019.307.019h9.539V9.87a.854.854 0 011.707 0v3.122a.853.853 0 01-.854.854H14.813a3.638 3.638 0 01-.4-.023v.872a.854.854 0 01-1.707 0zm8.525-6.814c-1.061 0-1.707-1.442-1.707-2.23V1.827a1.268 1.268 0 01.367-.943 1.656 1.656 0 011.26-.387h8.361a1.31 1.31 0 011.014.325 1.388 1.388 0 01.357 1v3.824c0 .9-.385 2.23-1.451 2.23zm0-2.23a1.279 1.279 0 00.211.523h7.658a1.943 1.943 0 00.08-.523V2.2h-7.949z"
      />
    </svg>
  );
};

SearchIllustrationIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

export { SearchIllustrationIcon };
