import React, { useEffect, useRef } from "react";
import styles from "./styles/WorkspaceHeader.module.css";
import { ProjectInformation } from "./ProjectInformation";
import { HeaderSearch } from "../../../../components/Search/HeaderSearch";
import { PeoplesStacker } from "../../../../components/PeoplesStacker/PeoplesStacker";
import { COLORS } from "../../../../config/CONSTANTS";
import { useSelector, useDispatch } from "react-redux";
import { setOptionsToModalWindow, showModalWindow, setFilterOption } from "../../../../state/actions";
import { MODAL_WINDOW_TYPES } from "../../../../components/Screen/ModalWindow";
import { AccentButton } from "../../../../components/Buttons/AccentButton";
import { SmallSizeRightPanel } from "./SmallSizeRightPanel";
import { Hamburger } from "../../../../components/Screen/Hamburger";
import { UserRank } from "../../../../components/Views/UserRank";
import { useLogOut } from "../../../../hooks/Common/useLogOut";
import { useTranslation } from "react-multi-lang";
import { PeoplesStackerSkeleton, ProjectInfromationSkeleton } from "./WorkspaceHeaderSkeletons";

export const getWorkspaceColor = (workspaceId) => {
  if (workspaceId > -1) {
    return COLORS[workspaceId % COLORS.length];
  }
  return COLORS[0];
};

const WorkspaceHeader = () => {
  const checkBoxRef = useRef(null);
  const usersState = useSelector((state) => state.workspaceUsersReducer);
  const workspaceInformation = useSelector((state) => state.workspaceInformationReducer.information);
  const globalWorkspaceLoader = useSelector((state) => state.workspaceInformationReducer.globalWorkspaceLoader);

  const commonState = useSelector((state) => state.commonReducer);

  const translate = useTranslation();

  const dispatch = useDispatch();

  const logout = useLogOut();

  const searchInputHandler = (event) => {
    dispatch(setFilterOption("taskName", event.target.value));
  };

  const onKeyDownHandler = (event) => {
    if (event.key === "Escape") if (checkBoxRef.current) checkBoxRef.current.checked = false;
  };

  const onWrapperClickHandler = (event) => {
    if (!event.target.closest(`.${styles.hamburger}`)) {
      setTimeout(() => {
        if (!event.target.closest(`.${styles.rightContainer}`))
          if (checkBoxRef.current) checkBoxRef.current.checked = false;
      }, 100);
      if (checkBoxRef.current) checkBoxRef.current.checked = false;
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDownHandler);
    document.addEventListener("click", onWrapperClickHandler);
    return () => {
      document.removeEventListener("keydown", onKeyDownHandler);
      document.removeEventListener("click", onWrapperClickHandler);
    };
  });

  return (
    <div className={styles.container}>
      {globalWorkspaceLoader.length > 0 ? (
        <ProjectInfromationSkeleton className={styles.projectInformation} />
      ) : (
        <ProjectInformation
          workspaceId={workspaceInformation.workspace_id}
          projectName={workspaceInformation.workspace_name}
          projectCurrentStatus={workspaceInformation.workspace_status_name}
          projectStatuses={workspaceInformation.statusesList}
          logoColor={getWorkspaceColor(workspaceInformation.workspace_id)}
          className={styles.projectInformation}
          gitHubLink={workspaceInformation.workspace_github_url}
        />
      )}
      <div className={styles.searchBarAndPeoples}>
        {/*<LanguageSwitcher />*/}
        <div className={styles.userRank}>
          {commonState.userRank && (
            <UserRank
              grade={commonState.userRank.user_grade}
              sixhandsAmount={commonState.userRank.user_shestiruk_amount}
              percentage={commonState.userRank.percentage}
            />
          )}
        </div>
        <HeaderSearch className={styles.searchBar} onChange={searchInputHandler} />
        <div className={styles.peoplesBlock}>
          {globalWorkspaceLoader.length > 0 ? (
            <PeoplesStackerSkeleton />
          ) : (
            workspaceInformation.permissions &&
            workspaceInformation.permissions.user.can_read === 1 && (
              <PeoplesStacker
                numOfPeoples={3}
                setLastBlock={true}
                avatarType="rounded"
                arrayOfPeoples={usersState.users}
                onClick={() => {
                  if (workspaceInformation.permissions && workspaceInformation.permissions.user.can_list === 1) {
                    dispatch(
                      setOptionsToModalWindow({
                        type: MODAL_WINDOW_TYPES.USERS_EDITOR,
                        closeHeaderButton: true,
                        closeOnWrapperClick: true,
                        text: "-",
                        header:
                          workspaceInformation.permissions && workspaceInformation.permissions.user.can_edit
                            ? translate("taskTracker.commonWords.usersEdit")
                            : "Просмотр пользователей",
                        showOkButton: false,
                        isTyping: true
                      })
                    );
                    dispatch(showModalWindow());
                  }
                }}
              />
            )
          )}
          <div
            className={styles.addPeopleButton}
            onClick={() => {
              if (workspaceInformation.permissions && workspaceInformation.permissions.user.can_list === 1) {
                dispatch(
                  setOptionsToModalWindow({
                    type: MODAL_WINDOW_TYPES.USERS_EDITOR,
                    closeHeaderButton: true,
                    closeOnWrapperClick: true,
                    text: "-",
                    header:
                      workspaceInformation.permissions && workspaceInformation.permissions.user.can_edit
                        ? translate("taskTracker.commonWords.usersEdit")
                        : "Просмотр пользователей",
                    showOkButton: false,
                    isTyping: true
                  })
                );
                dispatch(showModalWindow());
              }
            }}
          >
            &times;
          </div>
          <AccentButton
            displayPlusSymbol={false}
            label={translate("commonWords.exit")}
            className={styles.exitButton}
            onClick={logout}
          />
        </div>
        <Hamburger
          className={styles.hamburger}
          children={<SmallSizeRightPanel searchInputHandler={searchInputHandler} logout={logout} />}
        />
      </div>
    </div>
  );
};

export { WorkspaceHeader };
