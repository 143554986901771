import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { BoxWithShadow } from "../BoxWithShadow/BoxWithShadow";
import styles from "./styles/GitHubButton.module.css";
import gitHubLogo from "../../assets/icons/GitHub-Mark.svg";
import { useDispatch } from "react-redux";
import { modifyWorkspaceGithubLink } from "../../state/actions";

const GitHubButton = ({ link, className, workspaceId, editable }) => {
  const containerRef = useRef(null);

  const [gitHubLink, setGitHubLink] = useState(link);
  const [open, setOpen] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    setGitHubLink(link);
  }, [link]);

  const dispatch = useDispatch();
  const inpuRef = useRef();
  const updateLink = (newLink) => {
    dispatch(modifyWorkspaceGithubLink(workspaceId, newLink, gitHubLink));
  };

  const textChangeHandler = (event) => {
    if (event.target.innerText.trim() !== gitHubLink) updateLink(event.target.innerText.trim());
    setOpen(false);
  };

  const onTextClickHandler = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const keyPressHandler = (event) => {
    if (event.key === "Enter") {
      event.target.blur();
    }
  };

  const inputPressHandler = (event) => {
    if (event.key === "Enter") {
      event.target.blur();
      updateLink(inpuRef.current.value);
    }
  };

  useEffect(() => {
    setGitHubLink(link);
  }, [link]);

  const onMouseEnterHandler = () => {
    setShowInput(true);
  };

  const inputChangerHandler = (event) => {
    if (event.target.value !== gitHubLink) updateLink(event.target.value);
    setIsFocus(false);
    setShowInput(false);
  };

  const onClickHandler = (event) => {
    if (!event.target.closest(`.${styles.container}`)) {
      setShowInput(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", onClickHandler);
    return () => {
      document.removeEventListener("click", onClickHandler);
    };
  });

  if (link) {
    return (
      <a
        href={gitHubLink}
        target="_blank"
        rel="noopener noreferrer"
        className={`${styles.container} ${className} ${open ? styles.isOpen : ""}`}
        ref={containerRef}
      >
        <BoxWithShadow className={`${styles.boxShadowContainer}`}>
          <object type="image/svg+xml" data={gitHubLogo} className={styles.githubSVG}>
            gitHub link
          </object>
          <p
            contentEditable={editable ? true : undefined}
            suppressContentEditableWarning={editable ? true : undefined}
            onClick={editable ? onTextClickHandler : undefined}
            onBlur={editable ? textChangeHandler : undefined}
            onChange={editable ? textChangeHandler : undefined}
            onKeyPress={editable ? keyPressHandler : undefined}
          >
            {gitHubLink}
          </p>
        </BoxWithShadow>
      </a>
    );
  } else {
    return (
      <div
        rel="noopener noreferrer"
        className={`${styles.container} ${className} ${open ? styles.isOpen : ""}`}
        ref={containerRef}
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={() => !isFocus && setShowInput(false)}
      >
        <BoxWithShadow className={styles.boxShadowContainerInput}>
          <object type="image/svg+xml" data={gitHubLogo} className={styles.gitHubRed}>
            gitHub link
          </object>
          <input
            onFocus={() => setIsFocus(true)}
            className={showInput ? styles.showInput : styles.hideInput}
            onBlur={editable && inputChangerHandler}
            onKeyPress={editable && inputPressHandler}
            placeholder={"Введите ссылку..."}
            ref={inpuRef}
          />
        </BoxWithShadow>
      </div>
    );
  }
};

GitHubButton.propTypes = {
  link: PropTypes.string,
  className: PropTypes.string
};

GitHubButton.defaultProps = {
  className: ""
};

export { GitHubButton };
