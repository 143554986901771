import React, { useEffect, useRef, useState } from "react";
import styles from "./styles/ModalWindow.module.css";
import { useSelector, useDispatch } from "react-redux";
import { changeOptionsToModalWindow, deleteSectionFromWorkspace, hideModalWindow } from "../../state/actions";
import { AccentButton } from "../Buttons/AccentButton";
import { WorkspaceUsersEditor } from "./modalWindows/WorkspaceUsersEditor";
import { WorkspaceNotesEditor } from "./modalWindows/WorkspaceNotesEditor";
import { WorkspaceCreator } from "./modalWindows/WorkspaceCreator";
import { useHistory, useLocation } from "react-router-dom";
import { EditTaskModalWindow } from "./modalWindows/EditTaskModalWindow";
import { CreateTaskModalWindow } from "./modalWindows/CreateTaskModalWindow";
import { DeclineTaskWindow } from "./modalWindows/DeclineTaskWindow";
import lockIcon from "../../assets/icons/lockIcon.svg";
import { AddLeadWindow } from "./modalWindows/AddLeadWindow";
import { AddLinksToLeadWindow } from "./modalWindows/AddLinksToLeadWindow";
import { SentResponseManually } from "./modalWindows/SentResponseManually";
import { ConfirmReportConfirmation } from "./modalWindows/ConfirmReportConfirmation";

const MODAL_WINDOW_TYPES = {
  NOTIFICATION: "notification",
  USERS_EDITOR: "usersEditor",
  WORKSPACE_CREATOR: "workspaceCreator",
  TRIPLE_WINDOW_TASK_VIEWER: "tripleWindowTaskViewer",
  DECLINE_TASK_COMMENT: "declineTaskComment",
  CONFIRM_DELETE_SECTION: "confirmDeleteSection",
  LOCKED_WORKSPACE: "lockedWorkspace",
  ADD_LEAD: "addLead",
  ADD_LINKS_TO_LEAD: "addLinksToLead",
  RESPONSE_SENT_MANUALLY: "responseSentManually",
  CONFIRM_REPORT_CONFIRMATION: "confirmReportConfirmation",
  WORKSPACE_NOTES_EDITOR: "workspaceNotesEditor"
};

export const TASK_VIEWER_TYPES = {
  CREATOR: "creator",
  EDITOR: "editor"
};

const ModalWindow = () => {
  const modalWindowState = useSelector((state) => state.modalWindow);

  const authorizationState = useSelector((state) => state.authorizationReducer);

  const dispatch = useDispatch();
  const [isClosing, setIsClosing] = useState(false);

  const closeWindow = () => {
    setIsClosing(true);
    if (modalWindowState.options.isCalledFromUserTasks)
      history.push(`/${authorizationState.company_id}/tasks/user/tasks`);
    dispatch(hideModalWindow());
  };

  const wrapperClickHandler = (event) => {
    if (event.target.className.includes(styles.wrapper) && modalWindowState.options.closeOnWrapperClick) {
      closeWindow();
    }
  };

  const workspaceInformationState = useSelector((state) => state.workspaceInformationReducer);

  const userTasksState = useSelector((state) => state.userTasksReducer);
  let task;
  if (modalWindowState.options.isCalledFromUserTasks)
    task = userTasksState.user_tasks.find((task) => task.task_id === modalWindowState.options.taskId);
  else task = workspaceInformationState.tasks.find((task) => task.task_id === modalWindowState.options.taskId);
  let isHaveParenTask = useRef(false);
  let parentTask = useRef({});
  let workspaceIdLink = useRef("workspace/");

  useEffect(() => {
    setIsClosing(false);
    isHaveParenTask.current = task && task.parent_task_id !== null;
    if (!isHaveParenTask.current) {
      dispatch(
        changeOptionsToModalWindow({
          showLeftSide: false
        })
      );
    } else {
      if (modalWindowState.options.isCalledFromUserTasks) {
        parentTask.current = userTasksState.user_tasks.find((parentTask) => parentTask.task_id === task.parent_task_id);
      } else {
        parentTask.current = workspaceInformationState.tasks.find(
          (parentTask) => parentTask.task_id === task.parent_task_id
        );
        workspaceIdLink.current = location.pathname.match(/\/workspace\/[0-9]+/)[0];
      }
    }
    // eslint-disable-next-line
    }, [modalWindowState.options.taskId, dispatch]);

  const location = useLocation();

  const [showTaskImplementerUserChanger, setShowTaskImplementerUserChanger] = useState(false);

  const [showTaskVerificatorUserChanger, setShowTaskVerificatorUserChanger] = useState(false);

  const onClickHandler = (event) => {
    if (!event.target.closest(`.${styles.roleUserChanger}`) && showTaskImplementerUserChanger) {
      setShowTaskImplementerUserChanger(false);
    }
    if (!event.target.closest(`.${styles.roleUserChanger}`) && showTaskVerificatorUserChanger) {
      setShowTaskVerificatorUserChanger(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", onClickHandler);
    return () => {
      document.removeEventListener("click", onClickHandler);
    };
  });

  const onClickHandlerToTaskOptions = (event) => {
    if (!event.target.closest(`.${styles.optionsButton}`) && showOptionsMenu) {
      setShowOptionsMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", onClickHandlerToTaskOptions);
    return () => {
      document.removeEventListener("click", onClickHandlerToTaskOptions);
    };
  });

  const [showOptionsMenu, setShowOptionsMenu] = useState(false);

  const history = useHistory();

  const modalWindowKeyPressHandler = (event) => {
    if (
      event.key === "Escape" &&
      (modalWindowState.options.type === MODAL_WINDOW_TYPES.TRIPLE_WINDOW_TASK_VIEWER ||
        modalWindowState.options.type === MODAL_WINDOW_TYPES.CONFIRM_DELETE_SECTION)
    ) {
      if (modalWindowState.options.taskViewerType === "creator") {
        closeWindow();
      }
      if (modalWindowState.options.taskViewerType !== "creator" && !modalWindowState.showUpperModalStatus) {
        closeWindow();
        if (/\/workspace\/[0-9]+\/task\/[0-9]+/.test(location.pathname)) {
          const currentLocationStartIndex = location.pathname.search(/\/task\/[0-9]+/);
          const currentLocation = location.pathname.substring(0, currentLocationStartIndex);
          history.push(currentLocation);
        }
      }
    }
    if (event.key === "Enter") {
      if (modalWindowState.options.type === MODAL_WINDOW_TYPES.CONFIRM_DELETE_SECTION) {
        dispatch(
          deleteSectionFromWorkspace(
            workspaceInformationState.information.workspace_id,
            modalWindowState.options.sectionId
          )
        );
        dispatch(hideModalWindow());
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", modalWindowKeyPressHandler);
    return () => {
      document.removeEventListener("keydown", modalWindowKeyPressHandler);
    };
  });

  switch (modalWindowState.options.type) {
    case MODAL_WINDOW_TYPES.NOTIFICATION: {
      return (
        <div
          className={`${styles.wrapper} ${modalWindowState.showStatus ? "" : styles.hide}`}
          onClick={wrapperClickHandler}
        >
          <div className={`${styles.notificationWindow} ${styles.modalWindowDefaultSize}`}>
            <div className={styles.modalDefaultHeader}>
              <h2>{modalWindowState.options.header}</h2>
              <div
                className={`${styles.closeButton} ${modalWindowState.options.closeHeaderButton ? "" : styles.hide}`}
                onClick={() => {
                  closeWindow();
                }}
              >
                &times;
              </div>
            </div>
            <div className={styles.body}>{modalWindowState.options.text}</div>
            <div className={`${styles.footer} ${modalWindowState.options.showOkButton ? "" : styles.hide}`}>
              <AccentButton
                label="OK"
                displayPlusSymbol={false}
                className={styles.okButton}
                onClick={() => {
                  closeWindow();
                }}
              />
            </div>
          </div>
        </div>
      );
    }

    case MODAL_WINDOW_TYPES.CONFIRM_DELETE_SECTION: {
      return (
        <div
          className={`${styles.wrapper} ${modalWindowState.showStatus ? "" : styles.hide}`}
          onClick={wrapperClickHandler}
        >
          <div className={`${styles.notificationWindow} ${styles.modalWindowDefaultSize}`}>
            <div className={styles.modalDefaultHeader}>
              <h2>{modalWindowState.options.header}</h2>
              <div
                className={`${styles.closeButton} ${modalWindowState.options.closeHeaderButton ? "" : styles.hide}`}
                onClick={() => {
                  closeWindow();
                }}
              >
                &times;
              </div>
            </div>
            <div className={styles.body}>{modalWindowState.options.text}</div>
            <div className={`${styles.footer} ${modalWindowState.options.showOkButton ? "" : styles.hide}`}>
              <AccentButton
                label="Удалить"
                displayPlusSymbol={false}
                className={styles.okButton}
                onClick={() => {
                  dispatch(
                    deleteSectionFromWorkspace(
                      workspaceInformationState.information.workspace_id,
                      modalWindowState.options.sectionId
                    )
                  );
                  closeWindow();
                }}
              />
              <AccentButton
                label="Отмена"
                displayPlusSymbol={false}
                className={styles.cancelButton}
                onClick={() => {
                  closeWindow();
                }}
              />
            </div>
          </div>
        </div>
      );
    }

    case MODAL_WINDOW_TYPES.LOCKED_WORKSPACE: {
      return (
        <div
          className={`${styles.locked} ${modalWindowState.showStatus ? "" : styles.hide}`}
          onClick={wrapperClickHandler}
        >
          <div className={`${styles.lockedWindow}`}>
            <div className={styles.lockedBody}>
              <img src={lockIcon} className={styles.lockIcon} alt={""} />
              <h1>Доступ заблокирован</h1>
            </div>
          </div>
        </div>
      );
    }

    case MODAL_WINDOW_TYPES.USERS_EDITOR: {
      return <WorkspaceUsersEditor modalWindowState={modalWindowState} closeWindow={closeWindow} />;
    }
    case MODAL_WINDOW_TYPES.WORKSPACE_CREATOR: {
      return <WorkspaceCreator modalWindowState={modalWindowState} closeWindow={closeWindow} />;
    }
    case MODAL_WINDOW_TYPES.TRIPLE_WINDOW_TASK_VIEWER: {
      if (modalWindowState.options.taskViewerType === TASK_VIEWER_TYPES.EDITOR) {
        return <EditTaskModalWindow isModalClosing={isClosing} />;
      } else {
        return <CreateTaskModalWindow isModalClosing={isClosing} />;
      }
    }
    case MODAL_WINDOW_TYPES.DECLINE_TASK_COMMENT: {
      return <DeclineTaskWindow modalWindowState={modalWindowState} closeWindow={closeWindow} task={task} />;
    }
    case MODAL_WINDOW_TYPES.ADD_LEAD: {
      return <AddLeadWindow closeWindow={closeWindow} />;
    }
    case MODAL_WINDOW_TYPES.ADD_LINKS_TO_LEAD: {
      return <AddLinksToLeadWindow closeWindow={closeWindow} />;
    }
    case MODAL_WINDOW_TYPES.RESPONSE_SENT_MANUALLY: {
      return <SentResponseManually closeWindow={closeWindow} />;
    }
    case MODAL_WINDOW_TYPES.CONFIRM_REPORT_CONFIRMATION: {
      return <ConfirmReportConfirmation closeWindow={closeWindow} />;
    }
    case MODAL_WINDOW_TYPES.WORKSPACE_NOTES_EDITOR: {
      return <WorkspaceNotesEditor closeWindow={closeWindow} workspaceId={modalWindowState.options.workspaceId} />;
    }
    default: {
      return (
        <div className={`${styles.wrapper}`}>
          <div className={styles.notificationWindow}>
            <div className={styles.header}>{modalWindowState.options.header}</div>
            <div className={styles.body}>{modalWindowState.options.text}</div>
            <div className={styles.footer} />
          </div>
        </div>
      );
    }
  }
};

export { ModalWindow, MODAL_WINDOW_TYPES };
