import React, { useEffect, useRef, useState } from "react";
import styles from "./styles/SmallSizeRightPanel.module.css";
import { HeaderSearch } from "../../../../components/Search/HeaderSearch";
import { AccentButton } from "../../../../components/Buttons/AccentButton";
import { setFilterOption, setOptionsToModalWindow, showModalWindow } from "../../../../state/actions";
import { MODAL_WINDOW_TYPES, TASK_VIEWER_TYPES } from "../../../../components/Screen/ModalWindow";
import { useDispatch, useSelector } from "react-redux";
import { DropdownButton } from "../../../../components/Buttons/DropdownButton";
import { getDataFromLocalStorage, saveToLocalStorage } from "../../../../utils/LocalStorageWorker";
import { UserRank } from "../../../../components/Views/UserRank";
import { useTranslation } from "react-multi-lang";

const SmallSizeRightPanel = ({ searchInputHandler, logout, setIsChecked }) => {
  const dispatch = useDispatch();

  const workspaceState = useSelector((state) => state.workspaceInformationReducer);
  const authorizationState = useSelector((state) => state.authorizationReducer);

  const commonState = useSelector((state) => state.commonReducer);

  const translate = useTranslation();

  const [customStatusLabel, setCustomStatusLabel] = useState(translate("taskTracker.filters.taskStatus.any"));
  const [customTaskTypeLabel, setCustomTaskTypeLabel] = useState(translate("taskTracker.filters.taskType.any"));
  const [customTaskDeadlineLabel, setCustomTaskDeadlineLabel] = useState(
    translate("taskTracker.filters.taskDeadline.any")
  );
  const [customTaskTopicLabel, setCustomTaskTopicLabel] = useState("Любая тема");
  const [customDispayTasksLabel, setCustomDispayTasksLabel] = useState(
    translate("taskTracker.filters.taskUserType.any")
  );

  const headerSearchRef = useRef(null);

  const saveToStorage = (object) => {
    saveToLocalStorage(
      `Workspace${workspaceState.information.workspace_id}Filters`,
      Object.assign(getDataFromLocalStorage(`Workspace${workspaceState.information.workspace_id}Filters`), object)
    );
  };

  const statusFilterDropdownMenuOptions = workspaceState.taskPriorityStatusesList.map((priority) => ({
    id: priority.task_priority_id,
    label: priority.task_priority_name,
    sort: priority.task_priority_sort
  }));
  statusFilterDropdownMenuOptions.push({
    id: 0,
    label: translate("taskTracker.filters.taskStatus.any"),
    sort: 0
  });

  const changeTaskStatusFilter = (label) => {
    const currentFilterOption = statusFilterDropdownMenuOptions.find((priority) => priority.label === label);
    saveToStorage({ priorityId: currentFilterOption.id });
    dispatch(setFilterOption("priorityId", currentFilterOption.id));
  };

  const taskTypeFilterMenuOptions = [
    { id: 0, label: translate("taskTracker.filters.taskType.any") },
    { id: 1, label: translate("taskTracker.filters.taskType.default") },
    { id: 2, label: translate("taskTracker.filters.taskType.verification") }
  ];

  const changeTaskTypeFilter = (label) => {
    const currentFilterOption = taskTypeFilterMenuOptions.find((priority) => priority.label === label);
    saveToStorage({ typeId: currentFilterOption.id });
    dispatch(setFilterOption("typeId", currentFilterOption.id));
  };

  const taskDateFilterDropdownMenuOptions = [
    { id: 0, label: translate("taskTracker.filters.taskDeadline.any") },
    { id: 1, label: translate("taskTracker.filters.taskDeadline.delay") },
    { id: 2, label: translate("taskTracker.filters.taskDeadline.onToday") },
    { id: 3, label: translate("taskTracker.filters.taskDeadline.withoutDelay") },
    { id: 4, label: translate("taskTracker.filters.taskDeadline.withDeadline") },
    { id: 5, label: translate("taskTracker.filters.taskDeadline.withoutDeadline") },
    { id: 6, label: translate("taskTracker.filters.taskDeadline.onTomorrow") },
    { id: 7, label: translate("taskTracker.filters.taskDeadline.onTodayOrTomorrow") }
  ];

  const changeTaskDateFilter = (label) => {
    const currentFilterOption = taskDateFilterDropdownMenuOptions.find((priority) => priority.label === label);
    saveToStorage({ dateId: currentFilterOption.id });
    dispatch(setFilterOption("dateId", currentFilterOption.id));
  };

  const taskTopicFilterDropdownMenuOptions = workspaceState.topicsList.map((topic) => ({
    id: topic.task_topic_id,
    label: topic.task_topic_name,
    order: topic.task_topic_sort
  }));

  const userTasksOptions = [
    { id: 0, label: translate("taskTracker.filters.taskUserType.any") },
    { id: 1, label: "Мои задачи" },
    { id: 2, label: translate("taskTracker.filters.taskUserType.implementer") },
    { id: 3, label: translate("taskTracker.filters.taskUserType.verifier") },
    { id: 4, label: translate("taskTracker.filters.taskUserType.initiator") }
  ];

  taskTopicFilterDropdownMenuOptions.push({
    id: 0,
    label: "Любая тема",
    order: 0
  });

  const changeTaskTopicFilter = (label) => {
    const currentFilterOption = taskTopicFilterDropdownMenuOptions.find((priority) => priority.label === label);
    saveToStorage({ topicId: currentFilterOption.id });
    dispatch(setFilterOption("topicId", currentFilterOption.id));
  };

  const changeUserTasksFilter = (label) => {
    const currentFilterOption = userTasksOptions.find((task) => task.label === label);
    saveToStorage({ displayTasks: currentFilterOption.id });
    dispatch(setFilterOption("displayTasks", currentFilterOption.id, authorizationState.user_id));
  };

  const createTaskClickHandler = (event) => {
    setIsChecked();
    dispatch(
      setOptionsToModalWindow({
        type: MODAL_WINDOW_TYPES.TRIPLE_WINDOW_TASK_VIEWER,
        closeHeaderButton: true,
        closeOnWrapperClick: true,
        taskViewerType: TASK_VIEWER_TYPES.CREATOR
      })
    );
    dispatch(showModalWindow());
  };

  const eraserClickHandler = () => {
    headerSearchRef.current.value = "";
    headerSearchRef.current.blur();
    dispatch(setFilterOption("taskName", headerSearchRef.current.value));
  };

  useEffect(() => {
    const data = getDataFromLocalStorage(`Workspace${workspaceState.information.workspace_id}Filters`);

    if (data.priorityId) dispatch(setFilterOption("priorityId", data.priorityId));
    if (data.typeId) dispatch(setFilterOption("typeId", data.typeId));
    if (data.dateId) dispatch(setFilterOption("dateId", data.dateId));
    if (data.topicId) dispatch(setFilterOption("topicId", data.topicId));
    if (data.displayTasks) dispatch(setFilterOption("displayTasks", data.displayTasks));
    // eslint-disable-next-line
    }, [workspaceState.information.workspace_id])

  useEffect(() => {
    if (!workspaceState.globalWorkspaceLoader.length > 0) {
      setCustomTaskTypeLabel(
        taskTypeFilterMenuOptions.find((option) => option.id === workspaceState.sortFilter.typeId).label
      );
      setCustomStatusLabel(
        statusFilterDropdownMenuOptions.find((option) => option.id === workspaceState.sortFilter.priorityId).label
      );
      setCustomTaskTopicLabel(
        taskTopicFilterDropdownMenuOptions.find((option) => option.id === workspaceState.sortFilter.topicId).label
      );
      setCustomTaskDeadlineLabel(
        taskDateFilterDropdownMenuOptions.find((option) => option.id === workspaceState.sortFilter.dateId).label
      );
      setCustomDispayTasksLabel(
        userTasksOptions.find((option) => option.id === workspaceState.sortFilter.displayTasks).label
      );
    }
    // eslint-disable-next-line
    }, [workspaceState.sortFilter, workspaceState.globalWorkspaceLoader, commonState.language])

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <div className={styles.content}>
          {commonState.userRank && (
            <UserRank
              grade={commonState.userRank.user_grade}
              sixhandsAmount={commonState.userRank.user_shestiruk_amount}
              percentage={commonState.userRank.percentage}
            />
          )}
        </div>
      </div>
      {/*<div className={styles.languageSwitcher}>*/}
      {/*    <LanguageSwitcher />*/}
      {/*</div>*/}
      <div className={styles.item} style={{ height: "80px" }}>
        <div className={styles.content}>
          <HeaderSearch
            className={styles.searchBar}
            onChange={searchInputHandler}
            haveErase={true}
            eraserClickHandler={eraserClickHandler}
            ref={headerSearchRef}
          />
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.content}>
          <AccentButton
            label={translate("taskTracker.commonWords.addTask")}
            className={styles.accentButton}
            onClick={createTaskClickHandler}
          />
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.content}>
          <DropdownButton
            defaultLabel={translate("taskTracker.filters.taskStatus.any")}
            buttonLabel={customStatusLabel}
            dropDownMenuOptions={statusFilterDropdownMenuOptions}
            className={`${styles.dropdownButton} ${styles.taskStatusFilter}`}
            customStylesToOptionsList={styles.dropdownButtonOptionList}
            customStylesToOptionsListElement={styles.dropdownButtonOptionListElement}
            customStylesToButtonOnOpenMenu={styles.dropdownButtonIsOpen}
            onSelectOptionFunction={changeTaskStatusFilter}
            editable={true}
            isFilter={true}
          />
          <DropdownButton
            defaultLabel={translate("taskTracker.filters.taskType.any")}
            buttonLabel={customTaskTypeLabel}
            dropDownMenuOptions={taskTypeFilterMenuOptions}
            className={`${styles.dropdownButton} ${styles.taskTypeFilter}`}
            customStylesToOptionsList={styles.dropdownButtonOptionList}
            customStylesToOptionsListElement={styles.dropdownButtonOptionListElement}
            customStylesToButtonOnOpenMenu={styles.dropdownButtonIsOpen}
            onSelectOptionFunction={changeTaskTypeFilter}
            editable={true}
            isFilter={true}
          />
          <DropdownButton
            defaultLabel={translate("taskTracker.filters.taskDeadline.any")}
            buttonLabel={customTaskDeadlineLabel}
            dropDownMenuOptions={taskDateFilterDropdownMenuOptions}
            className={`${styles.dropdownButton} ${styles.taskDateFilter}`}
            customStylesToOptionsList={styles.dropdownButtonOptionList}
            customStylesToOptionsListElement={styles.dropdownButtonOptionListElement}
            customStylesToButtonOnOpenMenu={styles.dropdownButtonIsOpen}
            onSelectOptionFunction={changeTaskDateFilter}
            editable={true}
            isFilter={true}
          />
          <DropdownButton
            defaultLabel={"Любая тема"}
            buttonLabel={customTaskTopicLabel}
            dropDownMenuOptions={taskTopicFilterDropdownMenuOptions}
            className={`${styles.dropdownButton} ${styles.taskThemeFilter}`}
            customStylesToOptionsList={styles.dropdownButtonOptionList}
            customStylesToOptionsListElement={styles.dropdownButtonOptionListElement}
            customStylesToButtonOnOpenMenu={styles.dropdownButtonIsOpen}
            onSelectOptionFunction={changeTaskTopicFilter}
            editable={true}
            isFilter={true}
            isMultiSelector={true}
          />
          <DropdownButton
            defaultLabel={translate("taskTracker.filters.taskUserType.any")}
            buttonLabel={customDispayTasksLabel}
            dropDownMenuOptions={userTasksOptions}
            className={`${styles.dropdownButton} ${styles.userTasksFilter}`}
            customStylesToOptionsList={styles.dropdownButtonOptionList}
            customStylesToOptionsListElement={styles.dropdownButtonOptionListElement}
            customStylesToButtonOnOpenMenu={styles.dropdownButtonIsOpen}
            onSelectOptionFunction={changeUserTasksFilter}
            editable={true}
            isFilter={true}
          />
        </div>
      </div>
      <AccentButton
        displayPlusSymbol={false}
        label={translate("commonWords.exit")}
        className={styles.exitButton}
        onClick={logout}
      />
    </div>
  );
};

export { SmallSizeRightPanel };
