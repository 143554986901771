import React, { useEffect, useRef } from "react";
import styles from "./styles/KProposition.module.css";
import { useDispatch, useSelector } from "react-redux";
import { resetSelectedWorkspace, sendKpLink } from "../../state/actions";
import copyIcon from "../../assets/icons/copy.svg";
import { CommonHeader } from "../../components/Screen/CommonHeader";

const KProposition = () => {
  const dispatch = useDispatch();

  const workspaceState = useSelector((state) => state.workspaceInformationReducer);

  const inputRef = useRef(null);
  const linkRef = useRef(null);

  const onClickHandler = (event) => {
    dispatch(sendKpLink(inputRef.current.value));
  };

  const onKeyDownHandler = (event) => {
    if (event.key === "Enter") dispatch(sendKpLink(inputRef.current.value));
  };

  const onCopyClickHandler = (event) => {
    linkRef.current.select();
    document.execCommand("copy");
  };

  useEffect(() => {
    dispatch(resetSelectedWorkspace("КП"));
    document.title = "КП";
    // eslint-disable-next-line
    }, [])

  return (
    <div className={styles.container}>
      {/*<div className={styles.header}>*/}

      {/*</div>*/}
      <CommonHeader leftSideChildren={<h1 className={styles.name}>КП</h1>} isUserRankReversed={true} />
      <div className={styles.body}>
        <h2>Поле для ввода URL сметы</h2>
        <input
          className={styles.input}
          onKeyDown={onKeyDownHandler}
          placeholder={"URL-адрес Google Sheet таблицы"}
          ref={inputRef}
        />
        <button className={styles.button} onClick={onClickHandler}>
          Создать
        </button>
        {workspaceState.kpError && <p style={{ marginTop: "20px" }}>Необработанная ошибка</p>}
        {workspaceState.kpInformation && JSON.stringify(workspaceState.kpInformation.result) && (
          <p style={{ marginTop: "20px" }}>{JSON.stringify(workspaceState.kpInformation.result)}</p>
        )}
        {workspaceState.kpInformation && workspaceState.kpInformation.kp_id && (
          <div style={{ marginTop: "20px" }}>
            <a
              href={
                window.location.origin === "https://erp.sixhands.co"
                  ? `https://erp.sixhands.co/kp/${workspaceState.kpInformation.kp_id}`
                  : `https://test.erp.sixhands.co/kp/${workspaceState.kpInformation.kp_id}`
              }
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              <input
                readOnly={true}
                className={styles.roboto}
                ref={linkRef}
                value={
                  window.location.origin === "https://erp.sixhands.co"
                    ? `https://erp.sixhands.co/kp/${workspaceState.kpInformation.kp_id}`
                    : `https://test.erp.sixhands.co/kp/${workspaceState.kpInformation.kp_id}`
                }
              />
            </a>
            <span className={styles.copyContainer}>
              <img src={copyIcon} className={styles.copyIcon} onClick={onCopyClickHandler} alt={""} />
              <p className={styles.info}>Копировать</p>
            </span>
          </div>
        )}
        {/*<AccentButton displayPlusSymbol={false} label={'Выход'} className={styles.exitButton} onClick={logout}/>*/}
      </div>
    </div>
  );
};

export { KProposition };
