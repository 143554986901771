import {
  ACCEPT_ACTION_TASK_FAILURE,
  ACCEPT_ACTION_TASK_START,
  ACCEPT_ACTION_TASK_SUCCESS,
  ADD_GLOBAL_WORKSPACE_LOADER_ELEMENT,
  ADD_NEW_CARD_TO_SECTION_FAILURE,
  ADD_NEW_CARD_TO_SECTION_START,
  ADD_NEW_CARD_TO_SECTION_SUCCESS,
  ADD_NEW_SECTION_TO_WORKSPACE_FAILURE,
  ADD_NEW_SECTION_TO_WORKSPACE_START,
  ADD_NEW_SECTION_TO_WORKSPACE_SUCCESS,
  ADD_NEW_TASK_TO_WORKSPACE_FAILURE,
  ADD_NEW_TASK_TO_WORKSPACE_START,
  ADD_NEW_TASK_TO_WORKSPACE_SUCCESS,
  AUTHORIZATION,
  CHANGE_DISPLAY_STATE,
  CHANGE_EDITABLE_TEXT_STATE,
  CHANGE_FILTER_OPTION,
  CHANGE_TASK_DEADLINE_DATE_FAILURE,
  CHANGE_TASK_DEADLINE_DATE_START,
  CHANGE_TASK_DEADLINE_DATE_SUCCESS,
  CHANGE_TASK_DESCRIPTION_FAILURE,
  CHANGE_TASK_DESCRIPTION_START,
  CHANGE_TASK_DESCRIPTION_SUCCESS,
  CHANGE_TASK_HEADER_FAILURE,
  CHANGE_TASK_HEADER_START,
  CHANGE_TASK_HEADER_SUCCESS,
  CHANGE_TASK_PRIORITY_FAILURE,
  CHANGE_TASK_PRIORITY_START,
  CHANGE_TASK_PRIORITY_SUCCESS,
  CHANGE_TASK_SECTION_FAILURE,
  CHANGE_TASK_SECTION_START,
  CHANGE_TASK_SECTION_SUCCESS,
  CHANGE_TASK_TOPIC_FAILURE,
  CHANGE_TASK_TOPIC_START,
  CHANGE_TASK_TOPIC_SUCCESS,
  DECLINE_ACTION_TASK_FAILURE,
  DECLINE_ACTION_TASK_START,
  DECLINE_ACTION_TASK_SUCCESS,
  DELETE_GLOBAL_WORKSPACE_LOADER_STATUS,
  DELETE_SECTION_FROM_WORKSPACE_FAILURE,
  DELETE_SECTION_FROM_WORKSPACE_START,
  DELETE_SECTION_FROM_WORKSPACE_SUCCESS,
  DELETE_TASK_FROM_WORKSPACE_FAILURE,
  DELETE_TASK_FROM_WORKSPACE_START,
  DELETE_TASK_FROM_WORKSPACE_SUCCESS,
  DRAG_TASK,
  DRAG_TASK_SECTION_FAILURE,
  DRAG_TASK_SORT_FAILURE,
  DRAG_TASK_START,
  FETCH_LIST_OF_SECTIONS_IN_WORKSPACE_FAILURE,
  FETCH_LIST_OF_SECTIONS_IN_WORKSPACE_START,
  FETCH_LIST_OF_SECTIONS_IN_WORKSPACE_SUCCESS,
  FETCH_LIST_OF_TASK_PRIORITY_STATUSES_FAILURE,
  FETCH_LIST_OF_TASK_PRIORITY_STATUSES_START,
  FETCH_LIST_OF_TASK_PRIORITY_STATUSES_SUCCESS,
  FETCH_LIST_OF_TASKS_IN_WORKSPACE_FAILURE,
  FETCH_LIST_OF_TASKS_IN_WORKSPACE_START,
  FETCH_LIST_OF_TASKS_IN_WORKSPACE_SUCCESS,
  FETCH_LIST_OF_TOPICS_FAILURE,
  FETCH_LIST_OF_TOPICS_START,
  FETCH_LIST_OF_TOPICS_SUCCESS,
  FETCH_LIST_OF_VISIBILITY_FAILURE,
  FETCH_LIST_OF_VISIBILITY_START,
  FETCH_LIST_OF_VISIBILITY_SUCCESS,
  FETCH_WORKSPACE_INFORMATION_FAILURE,
  FETCH_WORKSPACE_INFORMATION_START,
  FETCH_WORKSPACE_INFORMATION_SUCCESS,
  FETCH_WORKSPACE_STATUSES_LIST_FAILURE,
  FETCH_WORKSPACE_STATUSES_LIST_START,
  FETCH_WORKSPACE_STATUSES_LIST_SUCCESS,
  GET_INFO_ABOUT_TASK_SUCCESS,
  MARK_TASK_AS_DONE_FAILURE,
  MARK_TASK_AS_DONE_START,
  MARK_TASK_AS_DONE_SUCCESS,
  MARK_TASK_AS_UNDONE_FAILURE,
  MARK_TASK_AS_UNDONE_START,
  MARK_TASK_AS_UNDONE_SUCCESS,
  MODIFY_SECTION_IN_WORKSPACE_FAILURE,
  MODIFY_SECTION_IN_WORKSPACE_START,
  MODIFY_SECTION_IN_WORKSPACE_SUCCESS,
  MODIFY_WORKSPACE_GITHUB_FAILURE,
  MODIFY_WORKSPACE_GITHUB_START,
  MODIFY_WORKSPACE_GITHUB_SUCCESS,
  MODIFY_WORKSPACE_INFORMATION_FAILURE,
  MODIFY_WORKSPACE_INFORMATION_START,
  MODIFY_WORKSPACE_INFORMATION_SUCCESS,
  MODIFY_WORKSPACE_NAME_FAILURE,
  MODIFY_WORKSPACE_NAME_START,
  MODIFY_WORKSPACE_NAME_SUCCESS,
  MODIFY_WORKSPACE_STATUS_FAILURE,
  MODIFY_WORKSPACE_STATUS_START,
  MODIFY_WORKSPACE_STATUS_SUCCESS,
  NOT_ALLOWED_TO_SEE_WORKSPACE,
  RESET_FILTER_OPTIONS,
  RESET_NEW_SECTION,
  RESET_NEW_TASK,
  RESET_WORKSPACE_ID,
  SEND_KP_LINK_SUCCESS,
  SET_GLOBAL_WORKSPACE_LOADER_STATUS,
  SET_IMPLEMENTER_TO_TASK_FAILURE,
  SET_IMPLEMENTER_TO_TASK_START,
  SET_IMPLEMENTER_TO_TASK_SUCCESS,
  SET_INITIATOR_TO_TASK_FAILURE,
  SET_INITIATOR_TO_TASK_START,
  SET_INITIATOR_TO_TASK_SUCCESS,
  SET_VERIFICATOR_TO_TASK_FAILURE,
  SET_VERIFICATOR_TO_TASK_START,
  SET_VERIFICATOR_TO_TASK_SUCCESS,
  SORT_SECTIONS_IN_WORKSPACE,
  UNSET_IMPLEMENTER_TO_TASK_FAILURE,
  UNSET_IMPLEMENTER_TO_TASK_START,
  UNSET_IMPLEMENTER_TO_TASK_SUCCESS,
  UNSET_INITIATOR_TO_TASK_FAILURE,
  UNSET_INITIATOR_TO_TASK_START,
  UNSET_INITIATOR_TO_TASK_SUCCESS,
  UNSET_TASK_DEADLINE_DATE_FAILURE,
  UNSET_TASK_DEADLINE_DATE_START,
  UNSET_TASK_DEADLINE_DATE_SUCCESS,
  UNSET_VERIFICATOR_TO_TASK_FAILURE,
  UNSET_VERIFICATOR_TO_TASK_START,
  UNSET_VERIFICATOR_TO_TASK_SUCCESS,
  UPDATE_FILTERED_TASKS,
  UPDATE_FILTERED_TASKS_SORTABLE_HEADER,
  UPDATE_WORKSPACE_STARTED,
  UPDATE_WORKSPACE_SUCCESS
} from "../../actions/actionTypes";

const initialState = {
  loading: false,
  error: null,
  globalWorkspaceLoader: [],
  information: {
    workspace_id: -1,
    workspace_github_url: "",
    workspace_name: "loading...",
    statusesList: [],
    workspace_status_name: "loading..."
  },
  tasks: [],
  sortFilter: {
    priorityId: 0,
    typeId: 0,
    dateId: 0,
    topicId: 0,
    taskName: "",
    initiator: null,
    verificator: null,
    implementer: null,
    displayTasks: 0
  },
  sortedTasks: [],
  initialSortedTasks: [],
  sectionsList: [],
  taskPriorityStatusesList: [],
  topicsList: [],
  visibilityList: [],
  displayState: false,
  editableTextState: false,
  notAllowed: false,
  selectedTab: ""
};

function workspaceInformationReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_WORKSPACE_INFORMATION_START: {
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        selectedTab: ""
      };
    }
    case FETCH_WORKSPACE_INFORMATION_FAILURE: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }

    case NOT_ALLOWED_TO_SEE_WORKSPACE: {
      const newInformationState = { ...state.information };
      Object.assign(newInformationState, { workspace_id: action.workspaceId });
      return {
        ...state,
        notAllowed: true,
        information: newInformationState
      };
    }

    case RESET_WORKSPACE_ID: {
      const newInformationState = { ...state.information };
      Object.assign(newInformationState, { workspace_id: null });
      return {
        ...state,
        information: newInformationState,
        selectedTab: action.selectedTab
      };
    }

    case FETCH_WORKSPACE_INFORMATION_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        notAllowed: false,
        information: Object.assign({}, state.information, action.information)
      };
    }
    case FETCH_LIST_OF_SECTIONS_IN_WORKSPACE_START: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case FETCH_LIST_OF_SECTIONS_IN_WORKSPACE_FAILURE: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case FETCH_LIST_OF_SECTIONS_IN_WORKSPACE_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        sectionsList: action.sectionsList
      };
    }
    case FETCH_LIST_OF_TASKS_IN_WORKSPACE_START: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case FETCH_LIST_OF_TASKS_IN_WORKSPACE_FAILURE: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case FETCH_LIST_OF_TASKS_IN_WORKSPACE_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        tasks: action.tasks
      };
    }
    case FETCH_LIST_OF_TASK_PRIORITY_STATUSES_START: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case FETCH_LIST_OF_TASK_PRIORITY_STATUSES_FAILURE: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case FETCH_LIST_OF_TASK_PRIORITY_STATUSES_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        taskPriorityStatusesList: action.taskPriorityStatusesList
      };
    }
    case FETCH_LIST_OF_TOPICS_START: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case FETCH_LIST_OF_TOPICS_FAILURE: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case FETCH_LIST_OF_TOPICS_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        topicsList: action.topicsList
      };
    }
    case SET_GLOBAL_WORKSPACE_LOADER_STATUS: {
      return {
        ...state,
        globalWorkspaceLoader: action.loaderStatus
      };
    }
    case ADD_GLOBAL_WORKSPACE_LOADER_ELEMENT: {
      const newGlobalWorkspaceLoader = [...state.globalWorkspaceLoader];
      newGlobalWorkspaceLoader.push(action.newLoaderStatus);
      return {
        ...state,
        globalWorkspaceLoader: newGlobalWorkspaceLoader
      };
    }
    case DELETE_GLOBAL_WORKSPACE_LOADER_STATUS: {
      const newGlobalWorkspaceLoader = [...state.globalWorkspaceLoader];
      const deleteLoaderElementIndex = newGlobalWorkspaceLoader.findIndex(
        (loaderElement) => loaderElement === action.deleteLoaderStatus
      );
      newGlobalWorkspaceLoader.splice(deleteLoaderElementIndex, 1);
      return {
        ...state,
        globalWorkspaceLoader: newGlobalWorkspaceLoader
      };
    }
    case MODIFY_WORKSPACE_INFORMATION_START: {
      const newInformationState = { ...state.information };
      Object.assign(newInformationState, action.newWorkspaceInformation);
      return {
        ...state,
        information: newInformationState
      };
    }
    case MODIFY_WORKSPACE_INFORMATION_FAILURE: {
      const previousInformationState = { ...state.information };
      Object.assign(previousInformationState, action.previousWorkspaceInformation);
      return {
        ...state,
        information: previousInformationState
      };
    }
    case MODIFY_WORKSPACE_INFORMATION_SUCCESS: {
      const previousInformationState = { ...state.information };
      Object.assign(previousInformationState, action.newWorkspaceData);
      return {
        ...state,
        information: previousInformationState
      };
    }

    case MODIFY_WORKSPACE_NAME_START: {
      const newInformationState = { ...state.information };
      Object.assign(newInformationState, { workspace_name: action.newWorkspaceName });
      return {
        ...state,
        information: newInformationState,
        loading: action.loading,
        error: action.error
      };
    }

    case MODIFY_WORKSPACE_NAME_FAILURE: {
      const newInformationState = { ...state.information };
      Object.assign(newInformationState, { workspace_name: action.previousWorkspaceName });
      return {
        ...state,
        information: newInformationState,
        loading: action.loading,
        error: action.error
      };
    }

    case MODIFY_WORKSPACE_NAME_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }

    case MODIFY_WORKSPACE_STATUS_START: {
      const newInformationState = { ...state.information };
      Object.assign(newInformationState, {
        workspace_status: action.newWorkspaceStatus,
        workspace_status_name: state.information.statusesList.find(
          (status) => status.workspace_status_id === action.newWorkspaceStatus
        ).workspace_status_name
      });
      return {
        ...state,
        information: newInformationState,
        loading: action.loading,
        error: action.error
      };
    }

    case MODIFY_WORKSPACE_STATUS_FAILURE: {
      const newInformationState = { ...state.information };
      Object.assign(newInformationState, { workspace_status: action.previousWorkspaceStatus });
      return {
        ...state,
        information: newInformationState,
        loading: action.loading,
        error: action.error
      };
    }

    case MODIFY_WORKSPACE_GITHUB_START: {
      const newInformationState = { ...state.information };
      Object.assign(newInformationState, { workspace_github_url: action.newWorkspaceGithubLink });
      return {
        ...state,
        information: newInformationState,
        loading: action.loading,
        error: action.error
      };
    }

    case MODIFY_WORKSPACE_GITHUB_FAILURE: {
      const newInformationState = { ...state.information };
      Object.assign(newInformationState, { workspace_github_url: action.previousWorkspaceGithubLink });
      return {
        ...state,
        information: newInformationState,
        loading: action.loading,
        error: action.error
      };
    }

    case MODIFY_WORKSPACE_GITHUB_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }

    case MODIFY_WORKSPACE_STATUS_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        information: Object.assign({}, state.information, action.info)
      };
    }

    case FETCH_WORKSPACE_STATUSES_LIST_START: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case FETCH_WORKSPACE_STATUSES_LIST_FAILURE: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case FETCH_WORKSPACE_STATUSES_LIST_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        information: Object.assign({}, state.information, {
          statusesList: action.statusesList
        })
      };
    }
    case FETCH_LIST_OF_VISIBILITY_START: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case FETCH_LIST_OF_VISIBILITY_FAILURE: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case FETCH_LIST_OF_VISIBILITY_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        visibilityList: action.visibilityList
      };
    }
    case ADD_NEW_TASK_TO_WORKSPACE_START: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case ADD_NEW_TASK_TO_WORKSPACE_FAILURE: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case ADD_NEW_TASK_TO_WORKSPACE_SUCCESS: {
      const newTasksState = [...state.tasks];
      newTasksState.push(action.newTask);
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        tasks: newTasksState
      };
    }
    case ADD_NEW_CARD_TO_SECTION_START: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case ADD_NEW_CARD_TO_SECTION_FAILURE: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case ADD_NEW_CARD_TO_SECTION_SUCCESS: {
      const newTasksState = [...state.tasks];
      newTasksState.push(action.newTask);
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        tasks: newTasksState,
        new_task: !action.isCalledFromCreateModal ? action.newTask.task_id : null
      };
    }
    case CHANGE_TASK_SECTION_START: {
      const newTasksState = [...state.tasks];
      const changedTask = newTasksState.find((task) => task.task_id === action.taskId);
      changedTask.workspace_section_id = action.newSectionId;
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        tasks: newTasksState
      };
    }
    case CHANGE_TASK_SECTION_FAILURE: {
      const newTasksState = [...state.tasks];
      const changedTask = newTasksState.find((task) => task.task_id === action.taskId);
      changedTask.workspace_section_id = action.previousSectionId;
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        tasks: newTasksState
      };
    }
    case CHANGE_TASK_SECTION_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case ADD_NEW_SECTION_TO_WORKSPACE_START: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case ADD_NEW_SECTION_TO_WORKSPACE_FAILURE: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case ADD_NEW_SECTION_TO_WORKSPACE_SUCCESS: {
      const newSectionsListState = [...state.sectionsList];
      newSectionsListState.push(action.newSection);
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        sectionsList: newSectionsListState,
        newSection: action.newSection.workspace_section_id
      };
    }

    case SORT_SECTIONS_IN_WORKSPACE: {
      const newSectionsListState = [...state.sectionsList];
      const newSectionList = newSectionsListState.sort((a, b) => {
        if (a.workspace_section_sort > b.workspace_section_sort) {
          return 1;
        } else return -1;
      });
      return {
        ...state,
        sectionsList: newSectionList
      };
    }

    case DELETE_SECTION_FROM_WORKSPACE_START: {
      const newSectionsListState = [...state.sectionsList];
      const deletedSectionIndex = newSectionsListState.findIndex(
        (section) => section.workspace_section_id === action.sectionId
      );
      newSectionsListState.splice(deletedSectionIndex, 1);
      const updatedTasksId = action.updatedTasks.map((task) => task.task_id);
      const newTasksListState = [...state.tasks].map((task) =>
        updatedTasksId.includes(task.task_id)
          ? Object.assign({}, task, {
              workspace_section_id: (task.workspace_section_id = action.updatedTasks.find(
                (updatedTask) => updatedTask.task_id === task.task_id
              ).workspace_section_id)
            })
          : task
      );
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        sectionsList: newSectionsListState,
        tasks: newTasksListState
      };
    }
    case DELETE_SECTION_FROM_WORKSPACE_FAILURE: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case DELETE_SECTION_FROM_WORKSPACE_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case ACCEPT_ACTION_TASK_START: {
      const newTaskState = [...state.tasks];
      const changedTask = newTaskState.find((task) => task.task_id === action.taskId);
      changedTask.task_status = "DONE";
      changedTask.task_decision = "ACCEPTED";
      return {
        ...state,
        tasks: newTaskState,
        loading: action.loading,
        error: action.error
      };
    }
    case ACCEPT_ACTION_TASK_FAILURE: {
      const newTaskState = [...state.tasks];
      const changedTask = newTaskState.find((task) => task.task_id === action.taskId);
      changedTask.task_status = action.previousTaskStatus;
      changedTask.task_decision = action.previousTaskDecision;
      return {
        ...state,
        tasks: newTaskState,
        loading: action.loading,
        error: action.error
      };
    }
    case ACCEPT_ACTION_TASK_SUCCESS: {
      const newTaskState = [...state.tasks];
      const changedTask = newTaskState.find((task) => task.task_id === action.taskId);
      Object.assign(changedTask, action.changedFields);
      const ChangedParentTask = newTaskState.find((task) => task.task_id === action.parentTaskId);
      Object.assign(ChangedParentTask, action.parentTaskChanges);
      return {
        ...state,
        tasks: newTaskState,
        loading: action.loading,
        error: action.error
      };
    }
    case DECLINE_ACTION_TASK_START: {
      const newTaskState = [...state.tasks];
      const changedTask = newTaskState.find((task) => task.task_id === action.taskId);
      changedTask.task_status = "DONE";
      changedTask.task_decision = "DECLINED";
      return {
        ...state,
        tasks: newTaskState,
        loading: action.loading,
        error: action.error
      };
    }
    case DECLINE_ACTION_TASK_FAILURE: {
      const newTaskState = [...state.tasks];
      const changedTask = newTaskState.find((task) => task.task_id === action.taskId);
      changedTask.task_status = action.previousTaskStatus;
      changedTask.task_decision = action.previousTaskDecision;
      return {
        ...state,
        tasks: newTaskState,
        loading: action.loading,
        error: action.error
      };
    }
    case DECLINE_ACTION_TASK_SUCCESS: {
      const newTaskState = [...state.tasks];
      const changedTask = newTaskState.find((task) => task.task_id === action.taskId);
      Object.assign(changedTask, action.changedFields);
      const ChangedParentTask = newTaskState.find((task) => task.task_id === action.parentTaskId);
      Object.assign(ChangedParentTask, action.parentTaskChanges);
      return {
        ...state,
        tasks: newTaskState,
        loading: action.loading,
        error: action.error
      };
    }
    case MODIFY_SECTION_IN_WORKSPACE_START: {
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        sectionsList: state.sectionsList.map((section) =>
          section.workspace_section_id === action.sectionId ? Object.assign({}, section, action.newState) : section
        )
      };
    }
    case MODIFY_SECTION_IN_WORKSPACE_FAILURE: {
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        sectionsList: state.sectionsList.map((section) =>
          section.workspace_section_id === action.sectionId ? Object.assign({}, section, action.previousState) : section
        )
      };
    }
    case MODIFY_SECTION_IN_WORKSPACE_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case CHANGE_TASK_PRIORITY_START: {
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        tasks: state.tasks.map((task) =>
          task.task_id === action.taskId ? Object.assign({}, task, { task_priority_id: action.newPriority }) : task
        )
      };
    }
    case CHANGE_TASK_PRIORITY_FAILURE: {
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        tasks: state.tasks.map((task) =>
          task.task_id === action.task_id
            ? Object.assign({}, task, {
                task_priority_id: action.previousPriority
              })
            : task
        )
      };
    }
    case CHANGE_TASK_PRIORITY_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case CHANGE_TASK_DEADLINE_DATE_START: {
      //taskId, newDeadlineDate
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        tasks: state.tasks.map((task) =>
          task.task_id === action.taskId
            ? Object.assign({}, task, {
                task_deadline_dt: action.newDeadlineDate
              })
            : task
        )
      };
    }
    case CHANGE_TASK_DEADLINE_DATE_FAILURE: {
      //taskId, previousDeadlineDate
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        tasks: state.tasks.map((task) =>
          task.task_id === action.taskId
            ? Object.assign({}, task, {
                task_deadline_dt: action.previousDeadlineDate
              })
            : task
        )
      };
    }
    case CHANGE_TASK_DEADLINE_DATE_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case UNSET_TASK_DEADLINE_DATE_START: {
      const newTaskState = [...state.tasks];
      newTaskState.find((task) => task.task_id === action.taskId).task_deadline_dt = null;
      return {
        ...state,
        tasks: newTaskState,
        loading: action.loading,
        error: action.error
      };
    }
    case UNSET_TASK_DEADLINE_DATE_FAILURE: {
      const newTaskState = [...state.tasks];
      newTaskState.find((task) => task.task_id === action.taskId).task_deadline_dt = action.previousDate;
      return {
        ...state,
        tasks: newTaskState,
        loading: action.loading,
        error: action.error
      };
    }
    case UNSET_TASK_DEADLINE_DATE_SUCCESS: {
      const newTasksState = [...state.tasks];
      const currentTask = newTasksState.find((task) => task.task_id === action.taskId);
      Object.assign(currentTask, action.taskChanges);
      return {
        ...state,
        tasks: newTasksState,
        loading: action.loading,
        error: action.error
      };
    }
    case CHANGE_TASK_HEADER_START: {
      //taskId, newHeader
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        tasks: state.tasks.map((task) =>
          task.task_id === action.taskId
            ? Object.assign({}, task, {
                task_name: action.newHeader
              })
            : task
        )
      };
    }
    case CHANGE_TASK_HEADER_FAILURE: {
      //taskId, previousHeader
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        tasks: state.tasks.map((task) =>
          task.task_id === action.taskId
            ? Object.assign({}, task, {
                task_name: action.previousHeader
              })
            : task
        )
      };
    }
    case CHANGE_TASK_HEADER_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case CHANGE_TASK_DESCRIPTION_START: {
      //taskId, newDescription
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        tasks: state.tasks.map((task) =>
          task.task_id === action.taskId
            ? Object.assign({}, task, {
                task_descr: action.newDescription
              })
            : task
        )
      };
    }
    case CHANGE_TASK_DESCRIPTION_FAILURE: {
      //taskId, previousDescription
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        tasks: state.tasks.map((task) =>
          task.task_id === action.taskId
            ? Object.assign({}, task, {
                task_descr: action.previousDescription
              })
            : task
        )
      };
    }
    case CHANGE_TASK_DESCRIPTION_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case DELETE_TASK_FROM_WORKSPACE_START: {
      const newTasksState = [...state.tasks];
      newTasksState.splice(action.deletedTaskIndex, 1);
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        tasks: newTasksState
      };
    }
    case DELETE_TASK_FROM_WORKSPACE_FAILURE: {
      const newTasksState = [...state.tasks];
      newTasksState.splice(action.deletedTaskIndex, 0, action.taskObject);
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        tasks: newTasksState
      };
    }
    case DELETE_TASK_FROM_WORKSPACE_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case MARK_TASK_AS_DONE_START: {
      const newTaskState = [...state.tasks];
      const currentTaskIndex = newTaskState.findIndex((task) => task.task_id === action.taskId);
      const taskHaveVerificator = newTaskState[currentTaskIndex].verificator_user_id !== null;
      newTaskState[currentTaskIndex].task_status = taskHaveVerificator ? "ON_VERIFICATION" : "DONE";
      return {
        ...state,
        tasks: newTaskState,
        loading: action.loading,
        error: action.error
      };
    }
    case MARK_TASK_AS_DONE_FAILURE: {
      const newTaskState = [...state.sortedTasks];
      newTaskState.find((task) => task.task_id === action.taskId).task_status = action.previousStatus;
      newTaskState.find((task) => task.task_id === action.taskId).workspace_section_id = action.section_id;
      return {
        ...state,
        tasks: newTaskState,
        loading: action.loading,
        error: action.error
      };
    }
    case MARK_TASK_AS_DONE_SUCCESS: {
      const newTaskState = [...state.tasks];
      const updatedTask = newTaskState.find((task) => task.task_id === action.taskId);
      if (action.taskData.new_task) newTaskState.push(action.taskData.new_task);
      delete action.taskData.task_sort;
      Object.assign(updatedTask, action.taskData);
      return {
        ...state,
        tasks: [...newTaskState],
        loading: action.loading,
        error: action.error
      };
    }

    case MARK_TASK_AS_UNDONE_START: {
      const newTaskState = [...state.tasks];
      const currentTaskIndex = newTaskState.findIndex((task) => task.task_id === action.taskId);
      const taskHaveVerificator = newTaskState[currentTaskIndex].verificator_user_id !== null;
      newTaskState[currentTaskIndex].task_status = taskHaveVerificator ? "ON_VERIFICATION" : "NOT_PROCESSED";
      return {
        ...state,
        tasks: newTaskState,
        loading: action.loading,
        error: action.error
      };
    }
    case MARK_TASK_AS_UNDONE_FAILURE: {
      const newTaskState = [...state.sortedTasks];
      newTaskState.find((task) => task.task_id === action.taskId).task_status = action.previousStatus;
      newTaskState.find((task) => task.task_id === action.taskId).workspace_section_id = action.section_id;
      return {
        ...state,
        sortedTasks: newTaskState,
        loading: action.loading,
        error: action.error
      };
    }
    case MARK_TASK_AS_UNDONE_SUCCESS: {
      const newTaskState = [...state.tasks];
      const updatedTask = newTaskState.find((task) => task.task_id === action.taskId);
      if (action.taskData.deleted_tasks) {
        for (let i = 0; i < newTaskState.length; i++) {
          for (let j = 0; j < action.taskData.deleted_tasks.length; j++) {
            if (newTaskState[i].task_id === action.taskData.deleted_tasks[j].task_id) {
              newTaskState.splice(i, 1);
              break;
            }
          }
        }
      }
      delete action.taskData.current_task.task_sort;
      Object.assign(updatedTask, action.taskData.current_task);
      return {
        ...state,
        tasks: [...newTaskState],
        loading: action.loading,
        error: action.error
      };
    }

    case CHANGE_TASK_TOPIC_START: {
      //taskId, newTopicId
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        tasks: state.tasks.map((task) =>
          task.task_id === action.taskId
            ? Object.assign({}, task, {
                task_topic_id: action.newTopicId
              })
            : task
        )
      };
    }
    case CHANGE_TASK_TOPIC_FAILURE: {
      //taskId, previousTopicId
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        tasks: state.tasks.map((task) =>
          task.task_id === action.taskId
            ? Object.assign({}, task, {
                task_topic_id: action.previousTopicId
              })
            : task
        )
      };
    }
    case CHANGE_TASK_TOPIC_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case CHANGE_FILTER_OPTION: {
      const newSortFilterState = Object.assign({}, state.sortFilter, {
        [action.filterOptionName]: action.filterOptionValue
      });
      return {
        ...state,
        sortFilter: newSortFilterState
        // userId: action.userId,
      };
    }

    case RESET_FILTER_OPTIONS: {
      return {
        ...state,
        sortFilter: initialState.sortFilter
      };
    }

    case DRAG_TASK: {
      return {
        ...state,
        move_from: action.move_from,
        move_to: action.move_to
      };
    }

    case UPDATE_FILTERED_TASKS_SORTABLE_HEADER: {
      let newStateTasks = state.tasks;
      // if(!state.sortFilter.initiator && !state.sortFilter.verificator && !state.sortFilter.implementer)
      // 	return{
      // 		...state,
      // 		sortedTasks: state.tasks,
      // 	}
      if (state.sortFilter.initiator || state.sortFilter.verificator || state.sortFilter.implementer) {
        if (state.sortFilter.initiator)
          newStateTasks = newStateTasks.filter((task) => task.initiator_user_id === state.sortFilter.initiator);
        if (state.sortFilter.verificator)
          newStateTasks = newStateTasks.filter((task) => task.verificator_user_id === state.sortFilter.verificator);
        if (state.sortFilter.implementer)
          newStateTasks = newStateTasks.filter((task) => task.implementer_user_id === state.sortFilter.implementer);
      }

      if (state.sortFilter.dateId === 1) {
        newStateTasks.sort(function (a, b) {
          if (a.task_deadline_dt > b.task_deadline_dt) {
            return 1;
          }
          if (a.task_deadline_dt < b.task_deadline_dt) {
            return -1;
          }
          if (a.task_deadline_dt === b.task_deadline_dt) {
            if (a.task_id > b.task_id) {
              return 1;
            }
            if (a.task_id < b.task_id) {
              return -1;
            }
          }
          return 0;
        });
      } else {
        newStateTasks.sort(function (a, b) {
          if (a.task_deadline_dt < b.task_deadline_dt) {
            return 1;
          }
          if (a.task_deadline_dt > b.task_deadline_dt) {
            return -1;
          }
          if (a.task_deadline_dt === b.task_deadline_dt) {
            if (a.task_id < b.task_id) {
              return 1;
            }
            if (a.task_id > b.task_id) {
              return -1;
            }
          }
          return 0;
        });
      }
      return {
        ...state,
        sortedTasks: newStateTasks
      };
    }

    case UPDATE_FILTERED_TASKS: {
      return {
        ...state,
        sortedTasks: state.tasks
          .filter(
            (task) =>
              (state.sortFilter.priorityId !== 0 ? task.task_priority_id === state.sortFilter.priorityId : true) &&
              (state.sortFilter.typeId !== 0
                ? task.task_type === "REGULAR" && state.sortFilter.typeId === 1
                  ? true
                  : task.task_type === "VERIFICATION" && state.sortFilter.typeId === 2
                  ? true
                  : false
                : true) &&
              (state.sortFilter.dateId !== 0
                ? state.sortFilter.dateId === 1 && task.task_deadline_dt !== null
                  ? new Date(task.task_deadline_dt).toISOString().substring(0, 10) <
                    new Date().toISOString().substring(0, 10)
                    ? true
                    : false
                  : state.sortFilter.dateId === 1 && task.task_deadline_dt === null
                  ? false
                  : state.sortFilter.dateId === 2 && task.task_deadline_dt !== null
                  ? new Date(task.task_deadline_dt).toISOString().substring(0, 10) ===
                    new Date().toISOString().substring(0, 10)
                    ? true
                    : false
                  : state.sortFilter.dateId === 2 && task.task_deadline_dt === null
                  ? false
                  : state.sortFilter.dateId === 3 && task.task_deadline_dt !== null
                  ? new Date(task.task_deadline_dt).toISOString().substring(0, 10) >=
                    new Date().toISOString().substring(0, 10)
                    ? true
                    : false
                  : state.sortFilter.dateId === 3 && task.task_deadline_dt === null
                  ? true
                  : state.sortFilter.dateId === 4 && task.task_deadline_dt !== null
                  ? true
                  : state.sortFilter.dateId === 5 && task.task_deadline_dt === null
                  ? true
                  : state.sortFilter.dateId === 6 &&
                    task.task_deadline_dt !== null &&
                    new Date(new Date(task.task_deadline_dt).setHours(0, 0, 0, 0)).toString() ===
                      new Date(
                        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1).setHours(
                          0,
                          0,
                          0,
                          0
                        )
                      ).toString()
                  ? true
                  : state.sortFilter.dateId === 7 &&
                    task.task_deadline_dt !== null &&
                    (new Date(new Date(task.task_deadline_dt).setHours(0, 0, 0, 0)).toString() ===
                      new Date(
                        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1).setHours(
                          0,
                          0,
                          0,
                          0
                        )
                      ).toString() ||
                      (task.task_deadline_dt !== null &&
                        new Date(task.task_deadline_dt).toISOString().substring(0, 10) ===
                          new Date().toISOString().substring(0, 10)))
                : true) &&
              (state.sortFilter.topicId !== 0 ? task.task_topic_id === state.sortFilter.topicId : true) &&
              (state.sortFilter.taskName !== ""
                ? (task.task_name !== null &&
                    task.task_name.trim().toLowerCase().includes(state.sortFilter.taskName.trim().toLowerCase())) ||
                  (task.task_descr !== null &&
                    task.task_descr.trim().toLowerCase().includes(state.sortFilter.taskName.trim().toLowerCase()))
                : true) &&
              (state.sortFilter.displayTasks !== 0
                ? state.sortFilter.displayTasks === 1
                  ? task.verificator_user_id === state.userId ||
                    task.implementer_user_id === state.userId ||
                    task.initiator_user_id === state.userId
                  : state.sortFilter.displayTasks === 2
                  ? task.implementer_user_id === state.userId
                  : state.sortFilter.displayTasks === 3
                  ? task.verificator_user_id === state.userId
                  : state.sortFilter.displayTasks === 4
                  ? task.initiator_user_id === state.userId
                  : true
                : true) &&
              (state.sortFilter.initiator ? task.initiator_user_id === state.sortFilter.initiator : true) &&
              (state.sortFilter.verificator ? task.verificator_user_id === state.sortFilter.verificator : true) &&
              (state.sortFilter.implementer ? task.implementer_user_id === state.sortFilter.implementer : true)
          )
          .sort(function (a, b) {
            if (a.task_sort > b.task_sort) {
              return 1;
            }
            if (a.task_sort < b.task_sort) {
              return -1;
            }
            if (a.task_sort === b.task_sort) {
              if (a.task_id > b.task_id) {
                return 1;
              }
              if (a.task_id < b.task_id) {
                return -1;
              }
            }
            return 0;
          })
      };
    }
    case AUTHORIZATION: {
      return {
        ...state,
        userId: action.user_id
      };
    }

    case CHANGE_EDITABLE_TEXT_STATE: {
      return {
        ...state,
        editableTextState: action.editableTextState
      };
    }

    case SET_IMPLEMENTER_TO_TASK_START: {
      //implementorId, taskId
      const newTasksState = [...state.tasks];
      const taskIndex = newTasksState.findIndex((task) => task.task_id === action.taskId);
      newTasksState[taskIndex].implementer_user_id = action.implementerId;
      return {
        ...state,
        tasks: newTasksState,
        loading: action.loading,
        error: action.error
      };
    }
    case SET_IMPLEMENTER_TO_TASK_FAILURE: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case SET_IMPLEMENTER_TO_TASK_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case UNSET_IMPLEMENTER_TO_TASK_START: {
      const newTaskState = [...state.tasks];
      newTaskState.find((task) => task.task_id === action.taskId).implementer_user_id = null;
      return {
        ...state,
        tasks: newTaskState,
        loading: action.loading,
        error: action.error
      };
    }
    case UNSET_IMPLEMENTER_TO_TASK_FAILURE: {
      const newTaskState = [...state.tasks];
      newTaskState.find((task) => task.task_id === action.taskId).implementer_user_id = action.implementerUserId;
      return {
        ...state,
        tasks: newTaskState,
        loading: action.loading,
        error: action.error
      };
    }
    case UNSET_IMPLEMENTER_TO_TASK_SUCCESS: {
      const newTasksState = [...state.tasks];
      const currentTask = newTasksState.find((task) => task.task_id === action.taskId);
      Object.assign(currentTask, action.taskChanges);
      return {
        ...state,
        tasks: newTasksState,
        loading: action.loading,
        error: action.error
      };
    }
    case SET_VERIFICATOR_TO_TASK_START: {
      const newTasksState = [...state.tasks];
      const taskIndex = newTasksState.findIndex((task) => task.task_id === action.taskId);
      newTasksState[taskIndex].verificator_user_id = action.verificatorId;
      return {
        ...state,
        tasks: newTasksState,
        loading: action.loading,
        error: action.error
      };
    }
    case SET_VERIFICATOR_TO_TASK_FAILURE: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case SET_VERIFICATOR_TO_TASK_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case UNSET_VERIFICATOR_TO_TASK_START: {
      const newTaskState = [...state.tasks];
      newTaskState.find((task) => task.task_id === action.taskId).verificator_user_id = null;
      return {
        ...state,
        tasks: newTaskState,
        loading: action.loading,
        error: action.error
      };
    }
    case UNSET_VERIFICATOR_TO_TASK_FAILURE: {
      const newTaskState = [...state.tasks];
      newTaskState.find((task) => task.task_id === action.taskId).verificator_user_id = action.verificatorUserId;
      return {
        ...state,
        tasks: newTaskState,
        loading: action.loading,
        error: action.error
      };
    }
    case UNSET_VERIFICATOR_TO_TASK_SUCCESS: {
      const newTasksState = [...state.tasks];
      const currentTask = newTasksState.find((task) => task.task_id === action.taskId);
      Object.assign(currentTask, action.taskChanges);
      return {
        ...state,
        tasks: newTasksState,
        loading: action.loading,
        error: action.error
      };
    }
    case SET_INITIATOR_TO_TASK_START: {
      //initiatorId, taskId
      const newTasksState = [...state.tasks];
      const taskIndex = newTasksState.findIndex((task) => task.task_id === action.taskId);
      newTasksState[taskIndex].initiator_user_id = action.initiatorId;
      return {
        ...state,
        tasks: newTasksState,
        loading: action.loading,
        error: action.error
      };
    }
    case SET_INITIATOR_TO_TASK_FAILURE: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case SET_INITIATOR_TO_TASK_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case UNSET_INITIATOR_TO_TASK_START: {
      const newTaskState = [...state.tasks];
      newTaskState.find((task) => task.task_id === action.taskId).initiator_user_id = null;
      return {
        ...state,
        tasks: newTaskState,
        loading: action.loading,
        error: action.error
      };
    }
    case UNSET_INITIATOR_TO_TASK_FAILURE: {
      const newTaskState = [...state.tasks];
      newTaskState.find((task) => task.task_id === action.taskId).initiator_user_id = action.initiatorUserId;
      return {
        ...state,
        tasks: newTaskState,
        loading: action.loading,
        error: action.error
      };
    }
    case UNSET_INITIATOR_TO_TASK_SUCCESS: {
      const newTasksState = [...state.tasks];
      const currentTask = newTasksState.find((task) => task.task_id === action.taskId);
      Object.assign(currentTask, action.taskChanges);
      return {
        ...state,
        tasks: newTasksState,
        loading: action.loading,
        error: action.error
      };
    }
    case CHANGE_DISPLAY_STATE: {
      return {
        ...state,
        displayState: action.displayState
      };
    }

    case DRAG_TASK_START: {
      return {
        ...state,
        draggableTaskId: action.taskId,
        initialState: action.initialState
      };
    }

    case DRAG_TASK_SORT_FAILURE: {
      return {
        ...state,
        sortedTasks: state.sortedTasks.filter((task) => {
          if (task.task_id === action.initialState.task_id) task.task_sort = action.initialState.task_sort;
          return true;
        })
      };
    }

    case DRAG_TASK_SECTION_FAILURE: {
      return {
        ...state,
        sortedTasks: state.sortedTasks.filter((task) => {
          if (task.task_id === action.initialState.task_id) task.workspace_section_id = action.initialState.section_id;
          return true;
        })
      };
    }

    case RESET_NEW_TASK: {
      return {
        ...state,
        new_task: null
      };
    }

    case RESET_NEW_SECTION: {
      return {
        ...state,
        newSection: null
      };
    }

    case GET_INFO_ABOUT_TASK_SUCCESS: {
      const newTasksState = [...state.tasks];
      const currentTask = newTasksState.find((task) => task.task_id === action.taskId);
      Object.assign(currentTask, action.data);
      return {
        ...state,
        tasks: newTasksState
      };
    }

    case UPDATE_WORKSPACE_STARTED: {
      return {
        ...state,
        updateInfoLoading: action.updateInfoLoading
      };
    }

    case UPDATE_WORKSPACE_SUCCESS: {
      return {
        ...state,
        updateInfoLoading: action.updateInfoLoading
      };
    }

    case SEND_KP_LINK_SUCCESS: {
      return {
        ...state,
        kpInformation: action.data,
        kpError: action.error
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
}

export default workspaceInformationReducer;
