import { declineTask, declineUserTasksTask, hideModalWindow } from "../../../state/actions";
import styles from "../styles/ModalWindow.module.css";
import { AccentButton } from "../../Buttons/AccentButton";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import modalWindowStyles from "../styles/ModalWindow.module.css";

const DeclineTaskWindow = ({ modalWindowState, closeWindow, task }) => {
  const dispatch = useDispatch();
  const [declineTaskComment, setDeclineTaskComment] = useState("");
  const [controlIsPressed, setControlIsPressed] = useState(false);
  const textareaRef = useRef(null);

  const modalWindowOptionsState = useSelector((state) => state.modalWindow.options);

  const commentChangeHandler = (event) => {
    setDeclineTaskComment(event.target.innerText);
  };

  const sendDeclineTaskCommentClickHandler = (event) => {
    if (declineTaskComment.length > 0) {
      setDeclineTaskComment("");
      if (modalWindowOptionsState.isCalledFromUserTasks)
        dispatch(declineUserTasksTask(task.task_id, declineTaskComment));
      else dispatch(declineTask(task.task_id, declineTaskComment));
      dispatch(hideModalWindow());
    }
  };

  const wrapperClickHandler = (event) => {
    if (event.target.className.includes(modalWindowStyles.wrapper) && modalWindowOptionsState.closeOnWrapperClick) {
      setDeclineTaskComment("");
      closeWindow();
    }
  };

  const modalWindowKeyPressHandler = (event) => {
    if (event.key === "Escape") {
      setDeclineTaskComment("");
      closeWindow();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", modalWindowKeyPressHandler);
    return () => {
      document.removeEventListener("keydown", modalWindowKeyPressHandler);
    };
  });

  const textareaKeyDownHandler = (event) => {
    if (event.key === "Control") {
      setControlIsPressed(true);
    }
    if (event.key === "Enter" && controlIsPressed) {
      sendDeclineTaskCommentClickHandler();
    }
    // if (event.key === 'Enter' && !controlIsPressed) {
    //     const selectorPosition = event.target.selectionStart;
    //     event.target.value =
    //         event.target.value.slice(0, event.target.selectionStart) +
    //         event.target.value.slice(
    //             event.target.selectionEnd,
    //             event.target.value.length
    //         );
    //     event.target.selectionStart = selectorPosition + 1;
    //     event.target.selectionEnd = selectorPosition;
    // }
  };

  const textareaKeyPressHandler = (event) => {};

  const textareaKeyUpHandler = (event) => {
    if (event.key === "Control") {
      setControlIsPressed(false);
    }
  };

  const onPasteHandler = (event) => {
    if (!event.clipboardData.getData("Text")) event.preventDefault();
    else {
      event.preventDefault();
      let text = event.clipboardData.getData("text/plain");
      document.execCommand("insertHTML", false, text);
    }
  };

  const commentTextareaBlurHandler = (event) => {
    // let position = 0;
    // const isSupported = typeof window.getSelection !== "undefined";
    // if (isSupported) {
    //     const selection = window.getSelection();
    //     if (selection.rangeCount !== 0) {
    //         const range = window.getSelection().getRangeAt(0);
    //         const preCaretRange = range.cloneRange();
    //         preCaretRange.selectNodeContents(event.target);
    //         preCaretRange.setEnd(range.endContainer, range.endOffset);
    //         position = preCaretRange.toString().length;
    //     }
    // }
    // setCaretPosition(position)
  };

  const onFocusHandler = (event) => {
    // if (caretPosition) {
    //     let setpos = document.createRange();
    //     let set = window.getSelection();
    //     setpos.setStart(event.target.childNodes[0], caretPosition);
    //     setpos.collapse(true);
    //     set.removeAllRanges();
    //     set.addRange(setpos);
    //     event.target.focus();
    // }
  };

  useEffect(() => {
    textareaRef.current.focus();
  }, [task]);

  return (
    <div
      className={`${styles.wrapper} ${modalWindowState.showStatus ? "" : modalWindowStyles.hide}`}
      onClick={wrapperClickHandler}
    >
      <div className={`${styles.taskDeclineWindow} ${styles.modalWindowDefaultSize}`}>
        <div className={styles.modalDefaultHeader}>
          <h2>{modalWindowState.options.header}</h2>
          <div
            className={`${styles.closeButton} ${modalWindowState.options.closeHeaderButton ? "" : styles.hide}`}
            onClick={() => {
              setDeclineTaskComment("");
              closeWindow();
            }}
          >
            &times;
          </div>
        </div>
        <div className={styles.body}>
          <div
            contentEditable={true}
            onInput={commentChangeHandler}
            onBlur={commentTextareaBlurHandler}
            onKeyPress={textareaKeyPressHandler}
            onKeyDown={textareaKeyDownHandler}
            onKeyUp={textareaKeyUpHandler}
            onPaste={onPasteHandler}
            onFocus={onFocusHandler}
            className={styles.declineComment}
            ref={textareaRef}
            placeholder="Описание причины отказа..."
          />
        </div>
        <div className={`${styles.footer} ${modalWindowState.options.showOkButton ? "" : styles.hide}`}>
          <button
            className={styles.declineButton}
            onClick={() => {
              setDeclineTaskComment("");
              closeWindow();
            }}
          >
            Отменить
          </button>
          <AccentButton
            label="Отправить"
            displayPlusSymbol={false}
            className={declineTaskComment.length === 0 ? styles.disabledButton : styles.okButton}
            onClick={declineTaskComment.length > 0 && sendDeclineTaskCommentClickHandler}
          />
        </div>
      </div>
    </div>
  );
};

export { DeclineTaskWindow };
