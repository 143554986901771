import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { Redirect, Route, Switch } from "react-router-dom";
import { LoginPage } from "../components/Authorization/LoginPage";
import { Navigation } from "../components/Navigation/NavigationBar";
import { useSelector } from "react-redux";
import { Screen } from "../components/Screen/Screen";
import { GlobalErrorCatcher } from "../components/ErrorCatchers/GlobalErrorCatcher";
import { KProposition } from "./KP/KProposition";
import { KnowledgeBase } from "./KnowledgeBase/KnowledgeBase";
import { Spinner } from "../components/Screen/Spinner";
import { WorkspaceLockedScreen } from "../components/Navigation/Components/WorkspaceLockedScreen";

const Leads = lazy(() => import("./Leads/Leads"));

const UserTasks = lazy(() => import("./UserTasks/UserTasks"));

const UserDashboard = lazy(() => import("./UserDashboard/UserDashboard"));

const WorkspaceSelector = lazy(() => import("./Workspace/WorkspaceSelector.js"));

const Users = lazy(() => import("./Users/Users"));

const ReportsPage = lazy(() => import("./Reports/ReportsPage"));

const Routes = () => {
  const authorizationState = useSelector((state) => state.authorizationReducer);

  const commonState = useSelector((state) => state.commonReducer);

  if (authorizationState.authorized) {
    return (
      <GlobalErrorCatcher>
        <Navigation />
        {!commonState.integratorLoading && (
          <Switch>
            {commonState.integratorLeftMenuData.find((tab) => tab.name === "Отчеты") && (
              <Route
                path={[
                  `/${authorizationState.company_id}/reports/:userId/:reportId`,
                  `/${authorizationState.company_id}/reports/:userId`,
                  `/${authorizationState.company_id}/reports`
                ]}
                render={({ match }) => {
                  return (
                    <>
                      {authorizationState.role_id < 3 && !match.params.reportId && (
                        <Redirect to={`/${authorizationState.company_id}/reports/${authorizationState.user_id}`} />
                      )}
                      {!match.params.userId && (
                        <Redirect to={`/${authorizationState.company_id}/reports/${authorizationState.user_id}`} />
                      )}
                      <Screen>
                        <Suspense fallback={<Spinner type={"global"} />}>
                          <ReportsPage />
                        </Suspense>
                      </Screen>
                    </>
                  );
                }}
              />
            )}
            {commonState.integratorLeftMenuData.find((tab) => tab.name === "Пользователи") && (
              <Route path={`/${authorizationState.company_id}/users`}>
                <Screen>
                  <Suspense fallback={<Spinner type={"global"} />}>
                    <Users />
                  </Suspense>
                </Screen>
              </Route>
            )}
            <Route path={"/locked"}>
              <Screen>
                <WorkspaceLockedScreen />
              </Screen>
            </Route>
            {commonState.integratorLeftMenuData.find((tab) => tab.name === "База знаний") && (
              <Route path={`/${authorizationState.company_id}/tasks/knowledge`}>
                <Screen>
                  <KnowledgeBase />
                </Screen>
              </Route>
            )}
            {commonState.integratorLeftMenuData.find((tab) => tab.name === "Задачи") && (
              <Route path={`/${authorizationState.company_id}/tasks/workspace`}>
                <Suspense fallback={<Spinner type={"global"} />}>
                  <WorkspaceSelector />
                </Suspense>
              </Route>
            )}
            {commonState.integratorLeftMenuData.find((tab) => tab.name === "КП") && (
              <Route path={`/kp`}>
                <KProposition />
              </Route>
            )}
            {commonState.integratorLeftMenuData.find((tab) => tab.name === "Задачи") && (
              <Route
                path={[
                  `/${authorizationState.company_id}/tasks/user/:userId/tasks`,
                  `/${authorizationState.company_id}/tasks/user/tasks`,
                  `/${authorizationState.company_id}/tasks`
                ]}
              >
                {authorizationState.role_id < 3 && (
                  <Redirect to={`/${authorizationState.company_id}/tasks/user/${authorizationState.user_id}/tasks`} />
                )}
                <Screen>
                  <Suspense fallback={<Spinner type={"global"} />}>
                    <UserTasks />
                  </Suspense>
                </Screen>
              </Route>
            )}

            <Route path={[`/${authorizationState.company_id}/dashboard/user/:userId/`]}>
              {authorizationState.role_id < 3 && (
                <Redirect to={`/${authorizationState.company_id}/dashboard/user/${authorizationState.user_id}/`} />
              )}
              <Screen>
                <Suspense fallback={<Spinner type={"global"} />}>
                  <UserDashboard />
                </Suspense>
              </Screen>
            </Route>

            {commonState.integratorLeftMenuData.find((tab) => tab.name === "Лиды") && (
              <Route path={`/${authorizationState.company_id}/leads`}>
                <Screen>
                  <Suspense fallback={<Spinner type={"global"} />}>
                    <Leads />
                  </Suspense>
                </Screen>
              </Route>
            )}
            {window.location.pathname.includes("guest") ? (
              <Redirect to={`/${authorizationState.company_id}/tasks/workspace/54`} />
            ) : (
              <Redirect to={`/${authorizationState.company_id}/tasks/user/${authorizationState.user_id}/tasks`} />
            )}
          </Switch>
        )}
      </GlobalErrorCatcher>
    );
  } else {
    return (
      <Switch>
        <Route exact path="/auth">
          <LoginPage />
        </Route>
        {/*<Redirect to={'/auth'}/> */}
      </Switch>
    );
  }
};

Routes.propTypes = {
  isAuth: PropTypes.bool
};

export { Routes };
