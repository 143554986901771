import React, { useEffect } from "react";
import styles from "./styles/knowledgeBase.module.css";
import { getKnowledgeBaseData, resetSelectedWorkspace } from "../../state/actions";
import { useDispatch, useSelector } from "react-redux";
import { KnowledgeItem } from "./KnowledgeItem";
import { Spinner } from "../../components/Screen/Spinner";
import { CommonHeader } from "../../components/Screen/CommonHeader";

const KnowledgeBase = () => {
  const dispatch = useDispatch();

  const commonState = useSelector((state) => state.commonReducer);

  useEffect(() => {
    dispatch(resetSelectedWorkspace("База знаний"));
    dispatch(getKnowledgeBaseData());
    document.title = "База знаний";
    // eslint-disable-next-line
    }, [])

  return (
    <div className={styles.container}>
      <CommonHeader
        leftSideChildren={
          <div className={styles.headerText}>
            <h1>База знаний</h1>
          </div>
        }
        isUserRankReversed={true}
      />
      <div className={styles.body}>
        {commonState.knowledgeDataLoading ? (
          <Spinner type={"global"} />
        ) : (
          commonState.knowledgeData.map((child) => (
            <div className={styles.item}>
              <KnowledgeItem
                name={child.name}
                description={child.description}
                children={child.objects}
                url={child.url}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export { KnowledgeBase };
