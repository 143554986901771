import React from "react";
import PropTypes from "prop-types";

const DocumentIcon = ({ className, documentBodyClassName, color, width, height, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      height={height ? height : "24"}
      width={width ? width : "24"}
      viewBox="0 0 24 24"
      onClick={onClick}
    >
      <path d="M0 0h24v24H0z" fill="#ffffff00" />
      <path
        d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"
        fill={color}
        className={documentBodyClassName}
      />
    </svg>
  );
};

DocumentIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  documentBodyClassName: PropTypes.string,
  onClick: PropTypes.func
};

DocumentIcon.defaultProps = {
  className: "",
  documentBodyClassName: ""
};

export { DocumentIcon };
