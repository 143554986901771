require("dotenv").config();

let TASKS_URL, USERS_URL, DOCS_URL, LEADS_URL, KP_URL, INTEGRATOR_URL, REPORTS_URL, ROBOTS_URL;

if (window.location.origin === "https://erp.sixhands.co") {
  TASKS_URL = process.env.REACT_APP_MICRO_TASKS_URL;
  USERS_URL = process.env.REACT_APP_MICRO_USERS_URL;
  DOCS_URL = process.env.REACT_APP_MICRO_DOCS_URL;
  LEADS_URL = process.env.REACT_APP_MICRO_LEADS_URL;
  KP_URL = process.env.REACT_APP_MICRO_KP_URL;
  INTEGRATOR_URL = process.env.REACT_APP_MICRO_INTEGRATOR_URL;
  REPORTS_URL = process.env.REACT_APP_MICRO_REPORTS_URL;
  ROBOTS_URL = process.env.REACT_APP_MICRO_ROBOTS_URL;
} else {
  TASKS_URL = process.env.REACT_APP_TEST_MICRO_TASKS_URL;
  USERS_URL = process.env.REACT_APP_TEST_MICRO_USERS_URL;
  DOCS_URL = process.env.REACT_APP_TEST_MICRO_DOCS_URL;
  LEADS_URL = process.env.REACT_APP_TEST_MICRO_LEADS_URL;
  KP_URL = process.env.REACT_APP_TEST_MICRO_KP_URL;
  INTEGRATOR_URL = process.env.REACT_APP_TEST_MICRO_INTEGRATOR_URL;
  REPORTS_URL = process.env.REACT_APP_TEST_MICRO_REPORTS_URL;
  ROBOTS_URL = process.env.REACT_APP_TEST_MICRO_ROBOTS_URL;
}

export const guestId = window.location.origin === "https://erp.sixhands.co" ? 55 : 36;

export const telegramChatIdForErrorMessages = 275919742;

const URL_ADDRESSES = {
  GET_LIST_OF_TASK_PRIORITY_STATUSES: TASKS_URL + "/task/priority",
  GET_LIST_OF_TOPICS: TASKS_URL + "/task/topic",
  GET_LIST_OF_WORKSPACES: TASKS_URL + "/workspace/list?show_deleted=0",
  GET_WORKSPACE_STATUSES_LIST: TASKS_URL + "/workspace/status",
  GET_WORKSPACES: TASKS_URL + "/workspace/",
  GET_VISIBILITY_LIST: TASKS_URL + `/task/visibility/`,
  GET_TASKS_OF_ALL_USERS: TASKS_URL + "/task/user",
  POST_CREATE_NEW_WORKSPACE: TASKS_URL + "/workspace/",
  POST_USER_TASKS: TASKS_URL + "/task",

  GET_LIST_OF_LEAD_SOURCES: LEADS_URL + "/source/",
  GET_LIST_OF_LEAD_STATUSES: LEADS_URL + "/status/",
  GET_LIST_OF_LEAD_TOPICS: LEADS_URL + "/topic/",
  GET_LIST_OF_LEAD_CURRENCIES: LEADS_URL + "/currency/",
  GET_LIST_OF_MESSAGES_STATUSED: LEADS_URL + "/message_status/",
  GET_COUNT_OF_UNREAD_DIALOGS: LEADS_URL + "/dialog/unread/",
  SEND_KP_LINK: `${KP_URL}/generate/`,
  ADD_NEW_LEAD: LEADS_URL + "/",
  GET_KNOWLEDGE_BASE_DATA: TASKS_URL + "/knowledge",

  GET_LIST_OF_REPORTS_STATUSES: REPORTS_URL + "/status/",
  GET_LIST_OF_REPORTS_TYPES: REPORTS_URL + "/type/",
  GET_PROGRAMS_NAMES: REPORTS_URL + "/get_programs/",

  GET_ACTIVE_ROBOTS_INFO: ROBOTS_URL,

  GET_LIST_OF_ALL_REPORTS: (reportsPageNumber) => {
    return REPORTS_URL + `/type/1/reports/?page=${reportsPageNumber}&page_size=20`;
  },

  GET_LIST_OF_USER_REPORTS: (reportsPageNumber, userId) => {
    return REPORTS_URL + `/type/1/reports/?page=${reportsPageNumber}&user_id=${userId}&page_size=20`;
  },

  GET_REPORT_INFO_BY_ID: (reportId) => {
    return REPORTS_URL + `/type/1/reports/${reportId}/`;
  },

  GET_INTEGRATOR_LEFT_MENU: (companyId) => {
    return INTEGRATOR_URL + `/${companyId}/menu`;
  },

  GET_LIST_OF_DIALOGS: (page, text) => {
    return LEADS_URL + `/dialog/?page=${page}${text ? `&text=${text}` : ""}`;
  },

  GET_INFO_ABOUT_LEAD: (leadId) => {
    return LEADS_URL + `/${leadId}/`;
  },

  GET_LIST_OF_LEADS: (page) => {
    return LEADS_URL + `/?page=${page}`;
  },

  GET_LIST_OF_FILTERED_LEADS: ({
    status_lte = 1,
    status_gte = 1,
    page_size = 10,
    page = 1,
    sourceId,
    user_id,
    reply_user_id,
    days,
    status,
    price,
    text,
    topicsList
  }) => {
    let queryParams = `/?status__lte=${status_lte}&status__gte=${status_gte}&page_size=${page_size}&page=${page}
		${sourceId ? `&source_id=${sourceId}` : ""}
		${user_id ? `&user_id=${user_id}` : ""}
		${reply_user_id ? `&reply__user_id=${reply_user_id}` : ""}
		${days ? `&days=${days}` : ""}
		${status ? `&status=${status}` : ""}
		${price ? `&price=${price}` : ""}
		${text ? `&text=${text}` : ""}
		${topicsList.length > 0 ? topicsList.map((topic) => `&topic=${topic}`).join("") : ""}
		`;

    return LEADS_URL + queryParams;
  },

  GET_INFORMATION_ABOUT_WORKSPACE_BY_ID: (id) => {
    return TASKS_URL + `/workspace/${id}`;
  },
  GET_LIST_OF_SECTIONS_IN_WORKSPACE: (workspaceId) => {
    return TASKS_URL + `/workspace/${workspaceId}/section/`;
  },
  GET_LIST_OF_TASKS_IN_WORKSPACE: (workspaceId) => {
    return TASKS_URL + `/task/workspace/${workspaceId}`;
  },
  GET_INFORMATION_ABOUT_TASK_BY_ID: (taskId) => {
    return TASKS_URL + `/task/${taskId}`;
  },
  GET_TASK_PRIORITY_BY_TASK_ID: (taskId) => {
    return TASKS_URL + `/task/priority/?task_id=${taskId}`;
  },
  GET_USERS_IN_WORKSPACE: (workspaceId) => {
    return TASKS_URL + `/workspace/${workspaceId}/user/`;
  },
  GET_TASK_LOGS: (taskId) => {
    return TASKS_URL + `/task/${taskId}/logs/`;
  },
  PATCH_WORKSPACE_INFORMATION_BY_ID: (id) => {
    return TASKS_URL + `/workspace/${id}`;
  },
  PATCH_WORKSPACE_NAME_BY_ID: (id) => {
    return TASKS_URL + `/workspace/${id}/name`;
  },
  PATCH_WORKSPACE_STATUS_BY_ID: (id) => {
    return TASKS_URL + `/workspace/${id}/status`;
  },
  PATCH_WORKSPACE_GITHUB_BY_ID: (id) => {
    return TASKS_URL + `/workspace/${id}/github`;
  },
  PATCH_WORKSPACE_SORT_BY_ID: (id) => {
    return TASKS_URL + `/workspace/${id}/sort`;
  },
  PATCH_TASK_NAME_BY_TASK_ID: (id) => {
    return TASKS_URL + `/task/${id}/name/`;
  },
  PATCH_TASK_DESCRIPTION_BY_TASK_ID: (id) => {
    return TASKS_URL + `/task/${id}/description/`;
  },
  PATCH_TASK_STATUS_TO_DONE: (taskId) => {
    return TASKS_URL + `/task/${taskId}/action/done`;
  },
  PATCH_TASK_STATUS_TO_UNDO: (taskId) => {
    return TASKS_URL + `/task/${taskId}/action/undo`;
  },
  PATCH_TASK_PRIORITY_BY_TASK_ID: (id) => {
    return TASKS_URL + `/task/${id}/priority/`;
  },
  PATCH_TASK_DEADLINE_BY_TASK_ID: (id) => {
    return TASKS_URL + `/task/${id}/deadline/`;
  },
  PATCH_TASK_TOPIC_BY_TASK_ID: (id) => {
    return TASKS_URL + `/task/${id}/topic/`;
  },
  PATCH_SECTION_BY_SECTION_ID_AND_WORKSPACE_ID: (workspaceId, sectionId) => {
    return TASKS_URL + `/workspace/${workspaceId}/section/${sectionId}`;
  },
  PATCH_TASK_USER_IMPLEMENTER: (taskId) => {
    return TASKS_URL + `/task/${taskId}/user/implementer/`;
  },
  PATCH_TASK_USER_VERIFICATOR: (taskId) => {
    return TASKS_URL + `/task/${taskId}/user/verificator/`;
  },
  PATCH_TASK_USER_INITIATOR: (taskId) => {
    return TASKS_URL + `/task/${taskId}/user/initiator/`;
  },
  PATCH_TASK_WORKSPACE: (taskId) => {
    return TASKS_URL + `/task/${taskId}/workspace`;
  },
  PATCH_TASK_SECTION: (taskId) => {
    return TASKS_URL + `/task/${taskId}/section/`;
  },
  PATCH_TASK_VISIBILITY: (taskId) => {
    return TASKS_URL + `/task/${taskId}/visibility`;
  },
  POST_TASK_TO_WORKSPACE: (id) => {
    return TASKS_URL + `/task/workspace/${id}`;
  },
  POST_TASK_ACTION_ACCEPT: (taskId) => {
    return TASKS_URL + `/task/${taskId}/action/accept`;
  },
  POST_TASK_ACTION_DECLINE: (taskId) => {
    return TASKS_URL + `/task/${taskId}/action/decline`;
  },
  PATCH_TASK_SORT: (taskId) => {
    return TASKS_URL + `/task/${taskId}/sort/`;
  },
  PATCH_WORKSPACE_SORT: (workspaceId) => {
    return TASKS_URL + `/workspace/${workspaceId}/sort`;
  },
  POST_SECTION_IN_WORKSPACE: (id) => {
    return TASKS_URL + `/workspace/${id}/section/`;
  },
  POST_USER_TO_WORKSPACE: (workspaceId) => {
    return TASKS_URL + `/workspace/${workspaceId}/user`;
  },
  DELETE_SECTION_BY_SECTION_ID_AND_WORKSPACE_ID: (workspaceId, sectionId) => {
    return TASKS_URL + `/workspace/${workspaceId}/section/${sectionId}`;
  },
  DELETE_USER_FROM_WORKSPACE: (workspaceId) => {
    return TASKS_URL + `/workspace/${workspaceId}/user/`;
  },
  DELETE_TASK: (taskId) => {
    return TASKS_URL + `/task/${taskId}`;
  },
  DELETE_TASK_USER_IMPLEMENTER: (taskId) => {
    return TASKS_URL + `/task/${taskId}/user/implementer/`;
  },
  DELETE_TASK_USER_VERIFICATOR: (taskId) => {
    return TASKS_URL + `/task/${taskId}/user/verificator/`;
  },
  DELETE_TASK_USER_INITIATOR: (taskId) => {
    return TASKS_URL + `/task/${taskId}/user/initiator/`;
  },
  DELETE_TASK_DEADLINE_BY_TASK_ID: (taskId) => {
    return TASKS_URL + `/task/${taskId}/deadline/`;
  },
  GET_TASKS_BY_USER_ID: (user_id) => {
    return TASKS_URL + `/task/user/${user_id}`;
  },
  POST_AUTHORIZE_USER: USERS_URL + "/auth/login/",
  CHECK_TOKEN: USERS_URL + "/auth/check/",
  GET_USERS_FROM_COMPANY_BY_ID: (companyId) => {
    return USERS_URL + `/company/${companyId}/user?show_deleted=1`;
  },
  GET_USER_INFO_BY_ID: (id) => {
    return USERS_URL + `/user/${id}`;
  },
  GET_INFO_ABOUT_USER_BY_USER_ID: (user_id) => {
    return USERS_URL + `/user/${user_id}`;
  },
  GET_TASK_DOCUMENTS: (taskId) => {
    return DOCS_URL + `/task/${taskId}`;
  },
  DELETE_TASK_DOCUMENT_BY_DOCUMENT_ID: (documentId) => {
    return DOCS_URL + `/${documentId}`;
  },
  DOWNLOAD_DOCUMENT_BY_DOCUMENT_ID: (documentId) => {
    return DOCS_URL + `/${documentId}/download`;
  },
  POST_DOCUMENT_TO_TASK: (taskId) => {
    return DOCS_URL + `/task/${taskId}`;
  },
  PATCH_LEAD_SOURCE: (leadId) => {
    return LEADS_URL + `/${leadId}/source/`;
  },
  PATCH_LEAD_TOPIC: (leadId) => {
    return LEADS_URL + `/${leadId}/topic/`;
  },
  PATCH_LEAD_STATUS: (leadId) => {
    return LEADS_URL + `/${leadId}/status/`;
  },
  PATCH_LEAD_UNLOCK: (leadId) => {
    return LEADS_URL + `/${leadId}/unlock/`;
  },
  PATCH_LEAD_LOCK: (leadId) => {
    return LEADS_URL + `/${leadId}/lock/`;
  },
  POST_LEAD_RESPONSE: (leadId) => {
    return LEADS_URL + `/${leadId}/response/`;
  },
  GET_LEAD_RESPONSE: (leadId) => {
    return LEADS_URL + `/${leadId}/`;
  },
  PATCH_LEAD_RESPONSE_TEXT: (leadId) => {
    return LEADS_URL + `/${leadId}/response/text/`;
  },
  PATCH_LEAD_RESPONSE_DURATION: (leadId) => {
    return LEADS_URL + `/${leadId}/response/duration/`;
  },
  PATCH_LEAD_RESPONSE_PRICE: (leadId) => {
    return LEADS_URL + `/${leadId}/response/price/`;
  },
  PATCH_LEAD_RESPONSE_CURRENCY: (leadId) => {
    return LEADS_URL + `/${leadId}/response/currency/`;
  },
  GET_LEAD_DIALOGS: (leadId) => {
    return LEADS_URL + `/${leadId}/dialog/`;
  },
  GET_LEAD_MESSAGES_IN_DIALOG: (leadId, dialogId, page) => {
    return LEADS_URL + `/${leadId}/dialog/${dialogId}/?page=${page}`;
  },
  POST_MESSAGE_TO_DIALOG: (leadId, dialogId) => {
    return LEADS_URL + `/${leadId}/dialog/${dialogId}/message/`;
  },
  POST_MESSAGE_TO_DIALOG_BY_ID: (dialogId) => {
    return LEADS_URL + `/dialog/${dialogId}/message/`;
  },
  GET_MESSAGES_IN_DIALOG: (dialogId, page) => {
    return LEADS_URL + `/dialog/${dialogId}/?page=${page}&page_size=15`;
  },
  PATCH_RESEND_RESPONSE_TO_LEAD: (leadId) => {
    return LEADS_URL + `/${leadId}/response/send-again/`;
  },
  PATCH_RESEND_MESSAGE_TO_DIALOG: (dialogId, messageId) => {
    return LEADS_URL + `/dialog/${dialogId}/message/${messageId}/send-again/`;
  },
  PATCH_RESEND_MESSAGE_TO_LEAD_DIALOG: (leadId, dialogId, messageId) => {
    return LEADS_URL + `/${leadId}/dialog/${dialogId}/message/${messageId}/send-again/`;
  },
  PATCH_CHANGE_LEAD_USER: (leadId) => {
    return LEADS_URL + `/${leadId}/user/`;
  },
  PATCH_LEAD_ESTIMATE_LINK: (leadId) => {
    return LEADS_URL + `/${leadId}/estimate-url/`;
  },
  PATCH_LEAD_KP_LINK: (leadId) => {
    return LEADS_URL + `/${leadId}/kp-url/`;
  },
  PATCH_CHANGE_MESSAGE_IN_DIALOG: (dialogId, messageId) => {
    return LEADS_URL + `/dialog/${dialogId}/message/${messageId}/text/`;
  },
  PATCH_CHANGE_MESSAGE_IN_LEAD_DIALOG: (leadId, dialogId, messageId) => {
    return LEADS_URL + `/${leadId}/dialog/${dialogId}/message/${messageId}/text/`;
  },

  GET_USER_RANK_INFO: (user_id) => {
    return USERS_URL + `/user/${user_id}/rank`;
  },

  GET_USER_INFO: (user_id) => {
    return USERS_URL + `/user/${user_id}`;
  },
  PATCH_USER_NAME: (user_id, type) => {
    return USERS_URL + `/user/${user_id}/name/${type}`;
  },
  PATCH_USER_INFO: (user_id, type) => {
    return USERS_URL + `/user/${user_id}/${type}`;
  },
  PATCH_USER_IMAGE: (user_id, type) => {
    return USERS_URL + `/user/${user_id}/${type}`;
  },

  POST_REGISTER_USER: USERS_URL + "/auth/register",

  DELETE_USER_IMAGE: (user_id, type) => {
    return USERS_URL + `/user/${user_id}/${type}`;
  },

  DELETE_USER: (user_id) => {
    return USERS_URL + `/user/${user_id}`;
  },

  RESTORE_USER: (user_id) => {
    return USERS_URL + `/user/${user_id}/restore`;
  },

  PATCH_REPORT_DATA_INFO: (report_type, report_id, project_id, type, option) => {
    return REPORTS_URL + `/type/${report_type}/reports/${report_id}/${type}/${project_id}/${option}/`;
  },
  GET_ADDITIONAL_WORKS_LIST: (report_type, report_id) => {
    return REPORTS_URL + `/type/${report_type}/reports/${report_id}/works/`;
  },
  GET_PROJECTS_LIST: (report_type, report_id, date) => {
    return REPORTS_URL + `/type/${report_type}/reports/${report_id}/projects/?date=${date}`;
  },
  POST_ADDITIONAL_WORK_TO_PROJECT: (report_type, report_id) => {
    return REPORTS_URL + `/type/${report_type}/reports/${report_id}/works/`;
  },
  DELETE_ADDITIONAL_WORK_FROM_PROJECT: (report_type, report_id, work_id) => {
    return REPORTS_URL + `/type/${report_type}/reports/${report_id}/works/${work_id}/`;
  },
  POST_REPORT_TO_REPORTS_LIST: (report_type) => {
    return REPORTS_URL + `/type/${report_type}/reports/`;
  },
  PATCH_REPORT_TO_SAVE: (report_type, report_id) => {
    return REPORTS_URL + `/type/${report_type}/reports/${report_id}/save/`;
  },
  DELETE_REPORT: (report_type, report_id) => {
    return REPORTS_URL + `/type/${report_type}/reports/${report_id}/`;
  }
};

const LOCAL_STORAGE_DATA = {
  SECTIONS: "sections",
  TASKS: "tasks",
  TASK_PRIORITY_STATUSES: "tasksPriorityStatuses",
  WORKSPACE_USERS: "users",
  TOPICS: "topics",
  WORKSPACE_INFORMATION: "workspaceInfo",
  WORKSPACE_STATUSES: "workspaceStatuses",
  WORKSPACE_DATA_CONTAINER: "workspaceData"
};

// prettier-ignore
const COLORS = [
    '#63b598', '#ce7d78', '#ea9e70', '#a48a9e', '#c6e1e8',
    '#648177', '#0d5ac1', '#f205e6', '#1c0365', '#14a9ad',
    '#4ca2f9', '#a4e43f', '#d298e2', '#6119d0', '#d2737d',
    '#c0a43c', '#f2510e', '#651be6', '#79806e', '#61da5e',
    '#cd2f00', '#9348af', '#01ac53', '#c5a4fb', '#996635',
    '#b11573', '#4bb473', '#75d89e', '#2f3f94', '#2f7b99',
    '#da967d', '#34891f', '#b0d87b', '#ca4751', '#7e50a8',
    '#c4d647', '#e0eeb8', '#11dec1', '#289812', '#566ca0',
    '#ffdbe1', '#2f1179', '#935b6d', '#916988', '#513d98',
    '#aead3a', '#9e6d71', '#4b5bdc', '#0cd36d', '#250662',
    '#cb5bea', '#228916', '#ac3e1b', '#df514a', '#539397',
    '#880977', '#f697c1', '#ba96ce', '#679c9d', '#c6c42c',
    '#5d2c52', '#48b41b', '#e1cf3b', '#5be4f0', '#57c4d8',
    '#a4d17a', '#225b88', '#be608b', '#96b00c', '#088baf',
    '#f158bf', '#e145ba', '#ee91e3', '#05d371', '#5426e0',
    '#4834d0', '#802234', '#6749e8', '#0971f0', '#8fb413',
    '#b2b4f0', '#c3c89d', '#c9a941', '#41d158', '#fb21a3',
    '#51aed9', '#5bb32d', '#807fb8', '#21538e', '#89d534',
    '#d36647', '#7fb411', '#0023b8', '#3b8c2a', '#986b53',
    '#f50422', '#983f7a', '#ea24a3', '#79352c', '#521250',
    '#c79ed2', '#d6dd92', '#e33e52', '#b2be57', '#fa06ec',
    '#1bb699', '#6b2e5f', '#64820f', '#1c2718', '#21538e',
    '#89d534', '#d36647', '#7fb411', '#0023b8', '#3b8c2a',
    '#986b53', '#f50422', '#983f7a', '#ea24a3', '#79352c',
    '#521250', '#c79ed2', '#d6dd92', '#e33e52', '#b2be57',
    '#fa06ec', '#1bb699', '#6b2e5f', '#64820f', '#1c2718',
    '#9cb64a', '#996c48', '#9ab9b7', '#06e052', '#e3a481',
    '#0eb621', '#fc458e', '#b2db15', '#aa226d', '#792ed8',
    '#73872a', '#520d3a', '#cefcb8', '#a5b3d9', '#7d1d85',
    '#c4fd57', '#f1ae16', '#8fe22a', '#ef6e3c', '#243eeb',
    '#1dc188', '#dd93fd', '#3f8473', '#e7dbce', '#421f79',
    '#7a3d93', '#635f6d', '#93f2d7', '#9b5c2a', '#15b9ee',
    '#0f5997', '#409188', '#911e20', '#1350ce', '#10e5b1',
    '#fff4d7', '#cb2582', '#ce00be', '#32d5d6', '#172328',
    '#608572', '#c79bc2', '#00f87c', '#77772a', '#6995ba',
    '#fc6b57', '#f07815', '#8fd883', '#060e27', '#96e591',
    '#21d52e', '#d00043', '#b47162', '#1ec227', '#4f0f6f',
    '#1d1d58', '#947002', '#bde052', '#e08c56', '#28fcfd',
    '#bb09b8', '#36486a', '#d02e29', '#1ae6db', '#3e464c',
    '#a84a8f', '#911e7e', '#3f16d9', '#0f525f', '#ac7c0a',
    '#b4c086', '#c9d730', '#30cc49', '#3d6751', '#fb4c03',
    '#640fc1', '#62c03e', '#d3493a', '#88aa0b', '#406df9',
    '#615af0', '#4be478', '#2a3434', '#4a543f', '#79bca0',
    '#a8b8d4', '#00efd4', '#7ad236', '#7260d8', '#1deaa7',
    '#06f43a', '#823c59', '#e3d94c', '#dc1c06', '#f53b2a',
    '#b46238', '#2dfff6', '#a82b89', '#1a8011', '#436a9f',
    '#1a806a', '#4cf09d', '#c188a2', '#67eb4b', '#b308d3',
    '#fc7e41', '#af3101', '#ff0658', '#71b1f4', '#a2f8a5',
    '#e23dd0', '#d3486d', '#00f7f9', '#474893', '#3cec35',
    '#1c65cb', '#5d1d0c', '#2d7d2a', '#ff3420', '#5cdd87',
    '#a259a4', '#e4ac44', '#1bede6', '#8798a4', '#d7790f',
    '#b2c24f', '#de73c2', '#d70a9c', '#25b678', '#88e9b8',
    '#c2b0e2', '#86e98f', '#ae90e2', '#1a806b', '#436a9e',
    '#0ec0ff', '#f812b3', '#b17fc9', '#8d6c2f', '#d3277a',
    '#2ca1ae', '#9685eb', '#8a96c6', '#dba2e6', '#76fc1b',
    '#608fa4', '#20f6ba', '#07d7f6', '#dce77a', '#77ecca',
];

export { URL_ADDRESSES, LOCAL_STORAGE_DATA, COLORS, USERS_URL };
