import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./styles/PrioritySelector.module.css";
import arrowSVG from "../../../../assets/icons/arrow.svg";
import { useDispatch } from "react-redux";
import { changeTaskPriority, changeTaskPriorityUserTasks } from "../../../../state/actions";

const PrioritySelector = React.memo(
  ({
    taskId,
    currentLabel,
    currentColor,
    prioritiesList,
    className,
    customStylesToSelectorButton,
    customStylesToDropdownList,
    disabled,
    localUpdateFunction,
    showArrow,
    editable,
    isCalledFromUserTasks
  }) => {
    const containerRef = useRef(null);

    const [open, setOpen] = useState(false);
    const [buttonSelectOptionLabel, setButtonSelectOptionLabel] = useState(currentLabel);
    const [buttonSelectOptionColor, setButtonSelectOptionColor] = useState(currentColor);
    const [firstListItem, setFirstListItem] = useState(
      prioritiesList.find((priority) => priority.task_priority_name === buttonSelectOptionLabel)
    );

    useEffect(() => {
      setButtonSelectOptionLabel(currentLabel);
      setButtonSelectOptionColor(currentColor);
      setFirstListItem(prioritiesList.find((priority) => priority.task_priority_name === currentLabel));
    }, [currentLabel, currentColor, prioritiesList]);

    const onClickButtonHandler = () => {
      setOpen((open) => (!disabled ? !open : ""));
    };

    const dispatcher = useDispatch();

    const onOptionClickHandler = (event) => {
      const previousPriority = prioritiesList.filter(
        (priority) => priority.task_priority_name === buttonSelectOptionLabel
      )[0];
      const selectedPriority = prioritiesList.filter(
        (priority) => priority.task_priority_name === event.target.querySelector("p").innerText.trim().substring(1)
      )[0];

      if (taskId) {
        !isCalledFromUserTasks
          ? dispatcher(changeTaskPriority(previousPriority.task_priority_id, selectedPriority.task_priority_id, taskId))
          : dispatcher(
              changeTaskPriorityUserTasks(previousPriority.task_priority_id, selectedPriority.task_priority_id, taskId)
            );
      } else {
        localUpdateFunction(selectedPriority);
      }

      setOpen((open) => (!disabled ? !open : ""));
    };

    const onClickHandler = (event) => {
      if (!event.target.closest(`.${containerRef.current.className}`) && open) {
        setOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener("click", onClickHandler);
      return () => {
        document.removeEventListener("click", onClickHandler);
      };
    });

    const PriorityListElement = ({ color, label }) => {
      return (
        <div className={`${styles.priority} ${styles.priorityListElement} `} onClick={onOptionClickHandler}>
          <div className={styles.primaryBackground} />
          <div className={styles.background} style={{ backgroundColor: color }} />
          <p>
            <b className={styles.maker} style={{ color: color }}>
              &bull;
            </b>
            {label}
          </p>
        </div>
      );
    };

    return (
      <div className={`${styles.container} ${className}`} ref={containerRef}>
        <div
          className={`${editable ? styles.priority : styles.priorityDisabled} ${customStylesToSelectorButton} ${
            disabled ? styles.disabled : ""
          }`}
          onClick={editable ? onClickButtonHandler : undefined}
        >
          <div className={styles.background} style={{ backgroundColor: buttonSelectOptionColor }} />
          <p>
            <b className={styles.maker} style={{ color: buttonSelectOptionColor }}>
              &bull;
            </b>
            {buttonSelectOptionLabel}
          </p>
        </div>
        {open && (
          <div className={`${styles.prioritiesList} ${customStylesToDropdownList}`}>
            <PriorityListElement color={firstListItem.task_priority_color} label={firstListItem.task_priority_name} />
            {
              // eslint-disable-next-line
                        prioritiesList.map((priority) => {
                if (priority.task_priority_name !== buttonSelectOptionLabel) {
                  return (
                    <PriorityListElement
                      key={priority.task_priority_id}
                      color={priority.task_priority_color}
                      label={priority.task_priority_name}
                    />
                  );
                }
              })
            }
          </div>
        )}
        {!disabled && showArrow && (
          <img src={arrowSVG} className={`${styles.arrow} ${open ? styles.isOpen : ""}`} alt={""} />
        )}
      </div>
    );
  }
);

PrioritySelector.propTypes = {
  className: PropTypes.string,
  currentLabel: PropTypes.string,
  currentColor: PropTypes.string,
  prioritiesList: PropTypes.arrayOf(
    PropTypes.shape({
      task_priority_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      task_priority_name: PropTypes.string.isRequired,
      task_priority_color: PropTypes.string.isRequired,
      task_priority_sort: PropTypes.number.isRequired
    })
  ),
  taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customStylesToSelectorButton: PropTypes.string,
  customStylesToDropdownList: PropTypes.string,
  disabled: PropTypes.bool,
  localUpdateFunction: PropTypes.func,
  showArrow: PropTypes.bool
};

PrioritySelector.defaultProps = {
  customStylesToSelectorButton: "",
  customStylesToDropdownList: "",
  disabled: false,
  localUpdateFunction: () => {},
  showArrow: false
};

export { PrioritySelector };
