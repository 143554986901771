import React, { useEffect, useRef } from "react";
import styles from "./styles/navigationBar.module.css";
import sixhandsLogo from "../../assets/icons/sixhands_logo.svg";
import { NavigationTab } from "./Components/NavigationTab";
import { DropdownSearch } from "../Search/DropdownSearch";
import { useDispatch, useSelector } from "react-redux";
import {
  dragWorkspace,
  fetchUsersFromCompany,
  fetchWorkspacesData,
  getIntegratorLeftMenu,
  getUserRankInfo,
  resetNewWorkspace,
  setOptionsToModalWindow,
  showModalWindow,
  updateWorkspacesData
} from "../../state/actions";
import { MODAL_WINDOW_TYPES } from "../Screen/ModalWindow";
import { useHistory } from "react-router-dom";
import addIcon from "../../assets/icons/add.svg";
import useCookies from "react-cookie/cjs/useCookies";
import { Spinner } from "../Screen/Spinner";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { WorkspaceTab } from "./WorkspaceTab";
import { guestId } from "../../config/CONSTANTS";
import { useTranslation } from "react-multi-lang";

const Navigation = React.memo(() => {
  const workspacesState = useSelector((state) => state.workspacesReducer);
  const history = useHistory();
  const dispatch = useDispatch();
  // eslint-disable-next-line
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const translate = useTranslation();
  const authorizationState = useSelector((state) => state.authorizationReducer);

  const commonState = useSelector((state) => state.commonReducer);

  const checkBoxRef = useRef(null);

  useEffect(() => {
    setCookie("token", authorizationState.token, {
      path: "/"
      // sameSite: "lax", secure: true
    });
  });

  useEffect(() => {
    dispatch(fetchWorkspacesData(true));
    let interval = setInterval(() => dispatch(fetchWorkspacesData(false)), 6 * 60 * 1000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
    }, []);

  useEffect(() => {
    dispatch(getIntegratorLeftMenu(authorizationState.company_id));
    dispatch(getUserRankInfo(authorizationState.user_id));
    dispatch(fetchUsersFromCompany(authorizationState.company_id));
    // eslint-disable-next-line
    }, [])

  useEffect(() => {
    if (workspacesState.newWorkspaceId) {
      history.push(`/${authorizationState.company_id}/tasks/workspace/${workspacesState.newWorkspaceId}`);
      dispatch(resetNewWorkspace());
    }
    // eslint-disable-next-line
    })

  const clickOnNewWorkspaceCreateButtonHandler = (event) => {
    dispatch(
      setOptionsToModalWindow({
        type: MODAL_WINDOW_TYPES.WORKSPACE_CREATOR,
        closeHeaderButton: true,
        closeOnWrapperClick: true,
        header: `${translate("taskTracker.commonWords.addNew")} Workspace`,
        showOkButton: true
      })
    );
    dispatch(showModalWindow());
  };

  const onDragEnd = (result) => {
    if (result.destination && result.destination.index !== result.source.index) {
      let initialWorkspaceSort = workspacesState.workspaces[result.source.index].workspace_sort;
      if (
        !workspacesState.workspaces[result.destination.index - 1] &&
        workspacesState.workspaces[result.destination.index + 1]
      ) {
        workspacesState.workspaces[result.source.index].workspace_sort =
          workspacesState.workspaces[result.destination.index].workspace_sort - 100;
      } else if (
        !workspacesState.workspaces[result.destination.index + 1] &&
        workspacesState.workspaces[result.destination.index - 1]
      ) {
        workspacesState.workspaces[result.source.index].workspace_sort =
          workspacesState.workspaces[result.destination.index].workspace_sort + 100;
      } else if (result.destination.index > result.source.index) {
        const nextNeighbour = workspacesState.workspaces[result.destination.index + 1].workspace_sort;
        const selectedWorkspace = workspacesState.workspaces[result.destination.index].workspace_sort;
        workspacesState.workspaces[result.source.index].workspace_sort = (nextNeighbour + selectedWorkspace) / 2;
      } else {
        const prevNeighbour = workspacesState.workspaces[result.destination.index - 1].workspace_sort;
        const selectedWorkspace = workspacesState.workspaces[result.destination.index].workspace_sort;
        workspacesState.workspaces[result.source.index].workspace_sort = (prevNeighbour + selectedWorkspace) / 2;
      }
      dispatch(
        dragWorkspace(
          workspacesState.workspaces[result.source.index].workspace_id,
          workspacesState.workspaces[result.source.index].workspace_sort,
          initialWorkspaceSort
        )
      );
      dispatch(updateWorkspacesData());
    }
  };

  const onKeyDownHandler = (event) => {
    // if(!window.getComputedStyle(ReactDOM.findDOMNode(checkBoxRef.current)).getPropertyValue("display") === 'none')
    if (event.key === "Escape") if (checkBoxRef.current) checkBoxRef.current.checked = false;
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDownHandler);
    // document.addEventListener('click', onWrapperClickHandler)
    return () => {
      document.removeEventListener("keydown", onKeyDownHandler);
      // document.removeEventListener('click', onWrapperClickHandler)
    };
  });

  return (
    <div className={styles.hamburger}>
      <input id={"menu__toggle"} className={styles.menuToggle} type="checkbox" ref={checkBoxRef} />
      <label className={styles.menuButton} htmlFor="menu__toggle">
        <span />
      </label>
      <div className={styles.wrapper} onClick={() => (checkBoxRef.current.checked = false)}></div>
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <object type="image/svg+xml" data={sixhandsLogo} className={styles.LogoSvg}>
            sixhands logo
          </object>
        </div>
        <div className={styles.searchBarContainer}>
          <DropdownSearch className={styles.searchBar} />
        </div>
        <div className={styles.navigationTabsContainer} onClick={() => (checkBoxRef.current.checked = false)}>
          {/*<TabLink tabName={'Пользователи'} isLocked={false} linkTo={`/${authorizationState.company_id}/users`}/>*/}
          {!commonState.integratorLoading &&
            commonState.integratorLeftMenuData.map(
              (tab, index) => tab && <NavigationTab key={index} tabName={tab.name} isLocked={tab.locked} />
            )}
          {commonState.integratorLeftMenuData.find(
            (tab) => tab.name && tab.name.trim() === "Задачи" && tab.locked !== 1
          ) &&
            authorizationState.role_id > 1 && (
              <div className={styles.addNewWorkspaceButton}>
                <img
                  src={addIcon}
                  style={{ width: "19px" }}
                  onClick={clickOnNewWorkspaceCreateButtonHandler}
                  alt={`${translate("taskTracker.commonWords.addNew")} Workspace`}
                />
              </div>
            )}
        </div>
        {commonState.integratorLoading && <Spinner className={styles.spinner} />}
        {commonState.integratorLeftMenuData.find((tab) => tab.name && tab.name.trim() === "Задачи") &&
        !commonState.integratorLoading &&
        !workspacesState.loading ? (
          <>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={"1"}>
                {(provided) => (
                  <div
                    className={`${styles.workspacesListContainer} ${styles.show}`}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    onClick={() => (checkBoxRef.current.checked = false)}
                  >
                    {workspacesState.workspaces.map((workspace, index) =>
                      workspace.permissions.can_list === 1 &&
                      workspace.workspace_status_id === 1 &&
                      (workspace.permissions.can_read === 1 ||
                        authorizationState.user_id === guestId ||
                        authorizationState.role_id === 1) ? (
                        <Draggable
                          key={workspace.workspace_id}
                          draggableId={workspace.workspace_id.toString()}
                          isDragDisabled={authorizationState.role_id !== 4 && authorizationState.role_id !== 5}
                          index={index}
                        >
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <WorkspaceTab workspace={workspace} />
                            </div>
                          )}
                        </Draggable>
                      ) : (
                        ""
                      )
                    )}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </>
        ) : (
          !commonState.integratorLoading && <Spinner className={styles.spinner} />
        )}
      </div>
    </div>
  );
});

export { Navigation };
