import React, { useEffect, useState } from "react";
import styles from "./styles/leadTopic.module.css";
import { setLeadTopic } from "../../../state/actions";
import { useDispatch } from "react-redux";

const LeadTopic = React.memo(
  ({ topicId, topicsList, leadId, editable, status, shouldReturnValue, returnValueFunction }) => {
    const [showSources, setShowSources] = useState(false);
    const [topic, setTopic] = useState(
      topicsList.find((topic) => topic.id === topicId) ? topicsList.find((topic) => topic.id === topicId).name : null
    );

    const dispatch = useDispatch();

    const onClickHandler = () => {
      setShowSources(!showSources);
    };

    const onTopicClickHandler = (id, name) => {
      setTopic(name);
      shouldReturnValue ? returnValueFunction(id) : dispatch(setLeadTopic(leadId, id, topicId, status));
      setShowSources(false);
    };

    const onWrapperClickHandler = (event) => {
      if (!event.target.closest(`.${styles.body}`)) {
        setShowSources(false);
      }
    };

    useEffect(() => {
      setTopic(
        topicsList.find((topic) => topic.id === topicId) ? topicsList.find((topic) => topic.id === topicId).name : null
      );
      // eslint-disable-next-line
    }, [leadId])

    useEffect(() => {
      setTopic(
        topicsList.find((topic) => topic.id === topicId) ? topicsList.find((topic) => topic.id === topicId).name : null
      );
      // eslint-disable-next-line
    }, [topicId])

    useEffect(() => {
      document.addEventListener("click", onWrapperClickHandler);
      document.addEventListener("keydown", () => setShowSources(false));
      return () => {
        document.removeEventListener("click", onWrapperClickHandler);
        document.removeEventListener("keydown", () => setShowSources(false));
      };
      // eslint-disable-next-line
    })

    return (
      <div className={styles.body}>
        <div
          className={editable ? styles.container : styles.containerDisabled}
          onClick={editable ? onClickHandler : undefined}
        >
          <p>{topic}</p>
        </div>
        {showSources && (
          <div className={styles.topicsList}>
            {topicsList
              .filter((topicL) => topicL.id !== topicId)
              .map((topic) => (
                <div className={styles.options} onClick={() => onTopicClickHandler(topic.id, topic.name)}>
                  <p className={styles.name}>{topic.name}</p>
                </div>
              ))}
          </div>
        )}
      </div>
    );
  }
);

export { LeadTopic };
