import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./styles/InputDate.module.css";
import { useDispatch, useSelector } from "react-redux";
import { changeTaskDeadlineDate, changeTaskDeadlineDateUserTasks } from "../../state/actions";
import calendarIcon from "../../assets/icons/calendar.svg";
import calendarIconFilled from "../../assets/icons/calendar.png";
import calendarInCircleIcon from "../../assets/icons/calendar_in_circle.svg";
import Calendar from "./Calendar";
import deadlineIcon from "../../assets/icons/deadline.svg";
import { useTranslation } from "react-multi-lang";

const InputDate = React.memo(
  ({
    taskId,
    sectionType,
    value,
    localUpdateFunction,
    showImmutableDate,
    containerClassName,
    disableDeadline,
    editable,
    displayState,
    newTaskDeadlineDateUpdate,
    isCalledFromUserTasks,
    isCalledFromModal,
    isCalledFromReportPage,
    children
  }) => {
    const commonState = useSelector((state) => state.commonReducer);
    const [date, setDate] = useState(value !== null ? value.substring(0, 10) : "");

    const translate = useTranslation();
    const [openCalendar, setOpenCalendar] = useState(false);

    const [deadline, setDeadline] = useState(false);
    const [dateObject, setDateObject] = useState(showImmutableDate ? (value ? new Date(value) : new Date()) : "");
    const [displayableDate, setDisplayableDate] = useState(
      date
        ? `${dateObject.getDate() < 10 ? "0" + dateObject.getDate() : dateObject.getDate()}.${
            dateObject.getMonth() < 9 ? "0" + (+dateObject.getMonth() + 1) : +dateObject.getMonth() + 1
          }.${dateObject.getFullYear()}`
        : ""
    );

    useEffect(() => {
      setDate(value !== null ? value.substring(0, 10) : "");
      if (showImmutableDate) {
        setDateObject(value ? new Date(value) : new Date());
      }
    }, [value, showImmutableDate]);

    useEffect(() => {
      if (date === "" || new Date(new Date(date).toDateString()) >= new Date(new Date().toDateString())) {
        setDeadline(false);
      } else {
        setDeadline(true);
      }
      const nowDate = new Date();
      const taskDate = new Date(date);
      if (
        nowDate.getDate() === taskDate.getDate() &&
        nowDate.getFullYear() === taskDate.getFullYear() &&
        nowDate.getMonth() === taskDate.getMonth()
      )
        setDisplayableDate(translate("taskTracker.commonWords.today"));
      else if (
        nowDate.getDate() - 1 === taskDate.getDate() &&
        nowDate.getFullYear() === taskDate.getFullYear() &&
        nowDate.getMonth() === taskDate.getMonth()
      )
        setDisplayableDate(translate("taskTracker.commonWords.yesterday"));
      else if (
        nowDate.getDate() + 1 === taskDate.getDate() &&
        nowDate.getFullYear() === taskDate.getFullYear() &&
        nowDate.getMonth() === taskDate.getMonth()
      )
        setDisplayableDate(translate("taskTracker.commonWords.tomorrow"));
      else
        setDisplayableDate(
          date
            ? `${dateObject.getDate() < 10 ? "0" + dateObject.getDate() : dateObject.getDate()}.${
                dateObject.getMonth() < 9 ? "0" + (+dateObject.getMonth() + 1) : +dateObject.getMonth() + 1
              }.${dateObject.getFullYear()}`
            : ""
        );

      if (isCalledFromReportPage) {
        setDisplayableDate(
          date
            ? `${dateObject.getDate() < 10 ? "0" + dateObject.getDate() : dateObject.getDate()}.${
                dateObject.getMonth() < 9 ? "0" + (+dateObject.getMonth() + 1) : +dateObject.getMonth() + 1
              }.${dateObject.getFullYear()}`
            : ""
        );
      }
    }, [date, commonState.language]);

    const dispatch = useDispatch();

    const setDateHandler = (value) => {
      if (newTaskDeadlineDateUpdate) {
        newTaskDeadlineDateUpdate(value);
        setDate(value);
      }
      const previousState = date;
      if (taskId) {
        !isCalledFromUserTasks
          ? dispatch(changeTaskDeadlineDate(taskId, previousState, value))
          : dispatch(changeTaskDeadlineDateUserTasks(taskId, previousState, value));
      } else {
        localUpdateFunction(value);
      }
    };

    if (!showImmutableDate) {
      return (
        <div className="tosamoeeeeeeeee">
          <img
            src={date ? (deadline ? deadlineIcon : calendarIcon) : displayState ? calendarIcon : calendarInCircleIcon}
            alt={""}
          />
          <p>{date ? `${date.split("-")[2]}.${date.split("-")[1]}.${date.split("-")[0]}` : ""}</p>
        </div>
      );
    } else {
      return (
        <div className={`${styles.container} ${containerClassName} ${isCalledFromModal ? styles.modalCalendar : ""}`}>
          <div
            className={`${
              date
                ? isCalledFromModal
                  ? displayableDate === translate("taskTracker.commonWords.today")
                    ? styles.todayDateModal
                    : styles.dateModal
                  : editable
                  ? displayableDate === translate("taskTracker.commonWords.today")
                    ? styles.todayDate
                    : styles.date
                  : displayableDate === translate("taskTracker.commonWords.today")
                  ? styles.dateDisabledToday
                  : styles.dateDisabled
                : displayState
                ? isCalledFromModal
                  ? styles.emptyDateModal
                  : editable
                  ? styles.emptyDateList
                  : styles.emptyDateListDisabled
                : editable
                ? styles.emptyDate
                : styles.emptyDateDisable
            }  ${ sectionType!="DONE" ? deadline && !disableDeadline ? styles.deadline:"" :""} ${
              isCalledFromModal || displayState ? styles.addGap : ""
            }`}
            
            onClick={() => (editable ? setOpenCalendar(true) : undefined)}
          >
            {openCalendar && (
              <Calendar
                isCalledFromModal={isCalledFromModal}
                value={setDateHandler}
                className={date ? styles.dateCalendar : styles.calendar}
                setOpenCalendar={setOpenCalendar}
                pickedPreviousDate={
                  date
                    ? `${dateObject.getDate() < 10 ? "0" + dateObject.getDate() : dateObject.getDate()}.${
                        dateObject.getMonth() < 9 ? "0" + (+dateObject.getMonth() + 1) : +dateObject.getMonth() + 1
                      }.${dateObject.getFullYear()}`
                    : ""
                }
              />
            )}
            <img
              style={isCalledFromModal ? { width: "27px", height: "17px" } : {}}
              src={
                !isCalledFromReportPage
                  ? date
                    ? deadline && !disableDeadline && !isCalledFromReportPage
                      ? deadlineIcon
                      : calendarIcon
                    : displayState
                    ? calendarIcon
                    : calendarInCircleIcon
                  : calendarIconFilled
              }
              alt={""}
            />
            {!date && displayState && (
              <p style={{ lineHeight: "30px", paddingLeft: "2px" }}>
                {translate("taskTracker.commonWords.selectDate")}
              </p>
            )}
            <p>{displayableDate}</p>
          </div>
          {children}
        </div>
      );
    }
  }
);

InputDate.propTypes = {
  sectionType:PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  taskStatus:PropTypes.string,
  disabled: PropTypes.bool,
  localUpdateFunction: PropTypes.func,
  showImmutableDate: PropTypes.bool,
  containerClassName: PropTypes.string,
  editable: PropTypes.bool
};

InputDate.defaultProps = {
  value: "",
  className: "",
  disabled: false,
  localUpdateFunction: () => {},
  showImmutableDate: false,
  containerClassName: ""
};

export { InputDate };
