import React from "react";
import styles from "./styles/commonHeader.module.css";
import { useLogOut } from "../../hooks/Common/useLogOut";
import { AccentButton } from "../Buttons/AccentButton";
import { useSelector } from "react-redux";
import { UserRank } from "../Views/UserRank";
import { useTranslation } from "react-multi-lang";

const CommonHeader = React.memo(({ leftSideChildren, rightSideChildren, isUserRankReversed }) => {
  const translate = useTranslation();

  const commonState = useSelector((state) => state.commonReducer);
  const logout = useLogOut();
  return (
    <div className={styles.header}>
      <div className={styles.leftSide}>{leftSideChildren}</div>
      <div className={styles.rightSide}>
        {/*<LanguageSwitcher />*/}
        {commonState.userRank && (
          <UserRank
            grade={commonState.userRank.user_grade}
            sixhandsAmount={commonState.userRank.user_shestiruk_amount}
            percentage={commonState.userRank.percentage}
            isReversed={isUserRankReversed}
          />
        )}
        {rightSideChildren}
        <AccentButton
          displayPlusSymbol={false}
          label={translate("commonWords.exit")}
          className={styles.exitButton}
          onClick={logout}
        />
      </div>
    </div>
  );
});

export { CommonHeader };
