import React from "react";
import PropTypes from "prop-types";

const Input = React.forwardRef(
  ({ placeholder, className, value, onChange, onFocus, onBlur, onKeyPress, type, onKeyDown, readOnly }, ref) => {
    return (
      <input
        placeholder={placeholder}
        className={className}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={ref}
        onKeyPress={onKeyPress}
        type={type}
        onKeyDown={onKeyDown}
        readOnly={readOnly}
      />
    );
  }
);

Input.propTypes = {
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  type: PropTypes.string
};

Input.defaultProps = {
  placeholder: "Введите название задачи...",
  className: ""
};

export default Input;
