import React from "react";
import styles from "./styles/errorModalWindow.module.css";
import { AccentButton } from "../../Buttons/AccentButton";
import { useHistory } from "react-router-dom";
import { hideModalWindow } from "../../../state/actions";
import { useDispatch, useSelector } from "react-redux";

const ErrorModalWindow = ({ error, componentName, onWrapperClickHandler }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authorizationState = useSelector((state) => state.authorizationReducer);

  const onClickHandler = () => {
    dispatch(hideModalWindow());
    history.push(`/1/tasks/user/${authorizationState.user_id}/tasks`);
    onWrapperClickHandler();
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.header}>Ошибка</div>
          <div className={styles.body}>
            В одном из компонентов произошла ошибка, из-за чего не получается нормально его отрисовать, отчёт об этой
            ошибке уже отправлен разработчику
          </div>
          <div className={styles.footer}>
            <AccentButton className={styles.button} displayPlusSymbol={false} label={"ОК"} />
          </div>
        </div>
      </div>
      <div className={styles.wrapper} onClick={onClickHandler}></div>
    </>
  );
};

export { ErrorModalWindow };
