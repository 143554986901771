import { combineReducers } from "redux";

import modalWindow from "./Workspace/modalWindow.js";
import workspaceUsersReducer from "./Workspace/workspaceUsers.js";
import companyUsersReducer from "./Workspace/companyUsers.js";
import workspaceInformationReducer from "./Workspace/workspaceInformation.js";
import workspacesReducer from "./Workspace/workspaces.js";
import authorizationReducer from "./authorization";
import userTasksReducer from "./UserTasks/userTasks";
import leadsReducer from "./Leads/Leads";
import commonReducer from "./commonReducer";
import reportsReducer from "./reportsReducer";

export default combineReducers({
  modalWindow,
  workspaceUsersReducer,
  companyUsersReducer,
  workspaceInformationReducer,
  workspacesReducer,
  authorizationReducer,
  userTasksReducer,
  leadsReducer,
  commonReducer,
  reportsReducer
});
