import React from "react";
import PropTypes from "prop-types";
import { Search } from "./Search";
import styles from "./styles/HeaderSearch.module.css";

const HeaderSearch = React.forwardRef(({ className, onChange, value, eraserClickHandler, haveErase }, ref) => {
  const onKeyPress = (event) => {
    if (event.key === "Enter") event.target.blur();
  };

  return (
    <Search
      className={`${styles.searchBar} ${className}`}
      focusStyles={styles.focus}
      onChange={onChange}
      value={value}
      ref={ref}
      onKeyPress={onKeyPress}
      haveErase={haveErase}
      eraserClickHandler={eraserClickHandler}
    />
  );
});

HeaderSearch.propTypes = {
  className: PropTypes.string
};

HeaderSearch.defaultTypes = {
  className: ""
};

export { HeaderSearch };
