import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeFilterOptionUserTasks,
  clearSearchUserParams,
  filterUserTasks,
  filterUserTasksByFilter,
  filterUserTasksUsers,
  setImplementerToTaskUserTasks,
  setInitiatorToTaskUserTasks,
  setVerificatorToTaskUserTasks
} from "../../state/actions";
import styles from "../Workspace/components/Components/styles/RoleUserChanger.module.css";
import closeIcon from "../../assets/icons/closeDecline.svg";
import { Avatar } from "../../components/Avatars/Avatar";
import { AccentButton } from "../../components/Buttons/AccentButton";
import PropTypes from "prop-types";
import { useTranslation } from "react-multi-lang";

const RoleUserChangerUserTasks = React.forwardRef(
  (
    { className, type, userType, taskId, closeWindowFunction, localUpdateFunction, displayState, onSelectUser },
    ref
  ) => {
    const userTasksState = useSelector((state) => state.userTasksReducer);

    const authorizationState = useSelector((state) => state.authorizationReducer);

    const translate = useTranslation();

    const [isMounted, setIsMounted] = useState(false);

    const [selectedUser, setSelectedUser] = useState(null);
    const [userIndex, setUserIndex] = useState(-1);

    const dispatch = useDispatch();

    if (userType === "verificator")
      userTasksState.users = userTasksState.users.sort((user) =>
        user.user_id === authorizationState.user_id ? -1 : 1
      );

    const userSearchbarInputHandler = (event) => {
      setUserIndex(0);
      dispatch(filterUserTasksUsers(event.target.value));
    };

    const setUserOnTaskRole = (taskId, userId, userType) => {
      if (userType === "verificator") {
        dispatch(setVerificatorToTaskUserTasks(taskId, userId));
      } else if (userType === "implementer") {
        dispatch(setImplementerToTaskUserTasks(taskId, userId));
      } else if (userType === "initiator") {
        dispatch(setInitiatorToTaskUserTasks(taskId, userId));
      }
      closeWindowFunction();
    };

    const setFilterUser = (userId, userType) => {
      onSelectUser(userId);
      dispatch(changeFilterOptionUserTasks(userType, userId));
      dispatch(filterUserTasksByFilter());
      dispatch(filterUserTasks());
    };

    const unsetFilterUser = () => {
      if (type === "filter") {
        dispatch(changeFilterOptionUserTasks(userType, null));
        dispatch(filterUserTasksByFilter());
        dispatch(filterUserTasks());
        onSelectUser(null);
        closeWindowFunction();
      } else if (type === "search") {
        onSelectUser(authorizationState.user_id);
      }
    };

    let searchInputRef = useRef(null);

    useEffect(() => {
      dispatch(clearSearchUserParams());
      setIsMounted(true);
      searchInputRef.current.focus();
      setSelectedUser(
        userTasksState.filteredUsers.length !== 0
          ? userTasksState.filteredUsers[0].user_id
          : userTasksState.users[0].user_id
      );
      // eslint-disable-next-line
        }, [])

    useEffect(() => {
      setSelectedUser(
        userTasksState.filteredUsers.length !== 0
          ? userTasksState.filteredUsers[0].user_id
          : userTasksState.users[0].user_id
      );
    }, [userTasksState]);

    const roleUserChangerKeyPressHandler = (event) => {
      if (event.key === "Escape") closeWindowFunction();
      if (event.key === "ArrowDown") {
        if (
          userIndex ===
          (userTasksState.filteredUsers.length !== 0
            ? userTasksState.filteredUsers.length - 1
            : userTasksState.users.length - 1)
        ) {
          setUserIndex(0);
          setSelectedUser(
            userTasksState.filteredUsers.length !== 0
              ? userTasksState.filteredUsers[0].user_id
              : userTasksState.users[0].user_id
          );
        } else {
          setSelectedUser(
            userTasksState.filteredUsers.length !== 0
              ? userTasksState.filteredUsers[userIndex + 1].user_id
              : userTasksState.users[userIndex + 1].user_id
          );
          setUserIndex(userIndex + 1);
        }
      }
      if (event.key === "ArrowUp") {
        if (userIndex === 0) {
          setUserIndex(
            userTasksState.filteredUsers.length !== 0
              ? userTasksState.filteredUsers.length - 1
              : userTasksState.users.length - 1
          );
          setSelectedUser(
            userTasksState.filteredUsers.length !== 0
              ? userTasksState.filteredUsers[userTasksState.filteredUsers.length - 1].user_id
              : userTasksState.users[userTasksState.users.length - 1].user_id
          );
        } else {
          setSelectedUser(
            userTasksState.filteredUsers.length !== 0
              ? userTasksState.filteredUsers[userIndex - 1].user_id
              : userTasksState.users[userIndex - 1].user_id
          );
          setUserIndex(userIndex - 1);
        }
      }
      if (event.key === "Enter" && selectedUser) {
        if (taskId) {
          setUserOnTaskRole(taskId, selectedUser, userType);
          closeWindowFunction();
        } else if (type === "filter") {
          setFilterUser(selectedUser, userType);
          closeWindowFunction();
        } else if (type === "search") {
          onSelectUser(selectedUser);
          closeWindowFunction();
        } else {
          localUpdateFunction(userTasksState.users.find((user) => user.user_id === selectedUser));
          closeWindowFunction();
        }
      }
    };

    const onAllUserClickHandler = () => {
      onSelectUser(0);
    };

    useEffect(() => {
      document.addEventListener("keydown", roleUserChangerKeyPressHandler);
      return () => {
        document.removeEventListener("keydown", roleUserChangerKeyPressHandler);
      };
    });

    return (
      <div
        className={`${type === "filter" ? styles.containerGrid : styles.container} ${className} ${
          isMounted ? styles.show : ""
        }`}
        ref={ref}
      >
        <h4>
          {type === "adder"
            ? "Добавить"
            : type === "filter"
            ? "Фильтр по"
            : type === "search"
            ? translate("taskTracker.commonWords.search")
            : "Сменить"}{" "}
          {userType === "verificator"
            ? type === "filter"
              ? "верификатору"
              : "верификатора"
            : userType === "implementer"
            ? type === "filter"
              ? "исполнителю"
              : "исполнителя"
            : userType === "initiator"
            ? type === "filter"
              ? "постановщику"
              : type === "search"
              ? ""
              : "постановщика"
            : "его"}
        </h4>
        <div className={styles.closeIcon} onClick={closeWindowFunction}>
          <img src={closeIcon} alt={""} />
        </div>
        <div className={styles.fancyLabelContainer}>
          <div className={styles.fancyTextInputLabel}>Введите имя или фамилию</div>
          <input className={styles.fancyTextInput} onChange={userSearchbarInputHandler} ref={searchInputRef} />
        </div>
        <div
          className={`${styles.usersList} ${
            type === "filter" || type === "search" ? styles.maxHeightWithButton : styles.maxHeightWithoutButton
          }`}
        >
          {userTasksState.filteredUsers.length !== 0 ? (
            userTasksState.filteredUsers.map((user) => (
              <div
                className={`${selectedUser === user.user_id ? styles.userListElementSelected : styles.userListElement}`}
                key={user.user_id}
                onClick={() => {
                  if (taskId) {
                    setUserOnTaskRole(taskId, user.user_id, userType);
                    closeWindowFunction();
                  } else if (type === "filter") {
                    setFilterUser(user.user_id, userType);
                    closeWindowFunction();
                  } else if (type === "search") {
                    onSelectUser(user.user_id);
                    closeWindowFunction();
                  } else {
                    localUpdateFunction(user);
                    closeWindowFunction();
                  }
                }}
              >
                <Avatar
                  src={user.user_photo}
                  alt={`${user.user_first_name} ${user?.user_last_name} ${
                    user.user_middle_name ? user.user_middle_name : ""
                  }`.trim()}
                  type="rounded"
                  className={styles.userPhoto}
                />
                <p>
                  {`${user.user_first_name} ${user?.user_last_name} ${
                    user.user_middle_name ? user.user_middle_name : ""
                  }`.trim()}
                </p>
              </div>
            ))
          ) : userTasksState.searchFilter === "" ? (
            userTasksState.users.map((user) => (
              <div
                className={`${selectedUser === user.user_id ? styles.userListElementSelected : styles.userListElement}`}
                key={user.user_id}
                onClick={() => {
                  if (taskId) {
                    setUserOnTaskRole(taskId, user.user_id, userType);
                    closeWindowFunction();
                  } else if (type === "filter") {
                    setFilterUser(user.user_id, userType);
                    closeWindowFunction();
                  } else if (type === "search") {
                    onSelectUser(user.user_id);
                    closeWindowFunction();
                  } else {
                    localUpdateFunction(user);
                    closeWindowFunction();
                  }
                }}
              >
                <Avatar
                  src={user.user_photo}
                  alt={`${user.user_first_name} ${user?.user_last_name} ${
                    user.user_middle_name ? user.user_middle_name : ""
                  }`.trim()}
                  type="rounded"
                  className={styles.userPhoto}
                />
                <p>
                  {`${user.user_first_name} ${user?.user_last_name} ${
                    user.user_middle_name ? user.user_middle_name : ""
                  }`.trim()}
                </p>
              </div>
            ))
          ) : (
            <div className={styles.userListElement}>
              <p style={{ textAlign: "center", display: "block" }}>Ничего не найдено</p>
            </div>
          )}
        </div>
        {type === "search" && (authorizationState.role_id === 4 || authorizationState.role_id === 5) && (
          <AccentButton
            label={"Все пользователи"}
            displayPlusSymbol={false}
            className={styles.allUsersButton}
            onClick={onAllUserClickHandler}
          />
        )}
        {(type === "filter" || type === "search") && (
          <AccentButton
            label={"Сбросить фильтр"}
            displayPlusSymbol={false}
            className={styles.resetFilterButton}
            onClick={unsetFilterUser}
          />
        )}
      </div>
    );
  }
);

RoleUserChangerUserTasks.propTypes = {
  className: PropTypes.string,
  taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(["adder", "changer", "filter"]),
  userType: PropTypes.oneOf(["implementer", "verificator", "initiator", ""]),
  closeWindowFunction: PropTypes.func,
  localUpdateFunction: PropTypes.func
};

RoleUserChangerUserTasks.defaultProps = {
  className: "",
  type: "changer",
  userType: "",
  closeWindowFunction: () => {},
  localUpdateFunction: () => {}
};

export { RoleUserChangerUserTasks };
