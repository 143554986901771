import {
  CREATE_NEW_WORKSPACE_FAILURE,
  CREATE_NEW_WORKSPACE_START,
  CREATE_NEW_WORKSPACE_SUCCESS,
  DRAG_WORKSPACE_FAILURE,
  FETCH_WORKSPACES_DATA_FAILURE,
  FETCH_WORKSPACES_DATA_START,
  FETCH_WORKSPACES_DATA_SUCCESS,
  MODIFY_WORKSPACE_NAME_FAILURE,
  MODIFY_WORKSPACE_NAME_START,
  MODIFY_WORKSPACE_STATUS_START,
  MODIFY_WORKSPACE_NOTES,
  RESET_NEW_WORKSPACE,
  RESET_SEARCH_WORKSPACES,
  SEARCH_WORKSPACE,
  UPDATE_WORKSPACES
} from "../../actions/actionTypes";

const initialState = {
  loading: false,
  error: null,
  workspaces: [],
  searchQuery: "",
  searchedWorkspaces: []
};

function workspacesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_WORKSPACES_DATA_START: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case FETCH_WORKSPACES_DATA_FAILURE: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case FETCH_WORKSPACES_DATA_SUCCESS: {
      // eslint-disable-next-line
			const new_workspaces = action.workspaces.sort((a,b)=>{
          if (a.workspace_sort > b.workspace_sort) return 1;
          else if (a.workspace_sort < b.workspace_sort) return -1;
          else if (a.workspace_sort === b.workspace_sort) if (a.workspace_id > b.workspace_id) return -1;
        })
        .sort((a, b) => {
          if (a.permissions.can_edit === 0 && b.permissions.can_edit === 1) return 1;
          else if (a.permissions.can_edit === 1 && b.permissions.can_edit === 0) return -1;
          else return 0;
        });
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        workspaces: new_workspaces
      };
    }
    case CREATE_NEW_WORKSPACE_START: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case CREATE_NEW_WORKSPACE_FAILURE: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case CREATE_NEW_WORKSPACE_SUCCESS: {
      let newWorkspaces = [...state.workspaces];
      newWorkspaces.push(action.newWorkspace);
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        workspaces: newWorkspaces,
        newWorkspaceId: action.newWorkspace.workspace_id
      };
    }
    case SEARCH_WORKSPACE: {
      return {
        ...state,
        searchQuery: action.searchQuery.trim().toLowerCase(),
        searchedWorkspaces: state.workspaces.filter(
          (workspace) =>
            workspace.permissions.can_read === 1 &&
            workspace.workspace_name.trim().toLowerCase().includes(action.searchQuery.trim().toLowerCase())
        )
      };
    }

    case RESET_SEARCH_WORKSPACES: {
      return {
        ...state,
        searchQuery: "",
        searchedWorkspaces: []
      };
    }

    case MODIFY_WORKSPACE_NAME_START: {
      let newWorkspaces = [...state.workspaces];
      newWorkspaces = newWorkspaces.map((workspace) =>
        workspace.workspace_id === action.workspaceId
          ? { ...workspace, workspace_name: action.newWorkspaceName }
          : workspace
      );
      return {
        ...state,
        workspaces: newWorkspaces
      };
    }

    case MODIFY_WORKSPACE_NAME_FAILURE: {
      let newWorkspaces = [...state.workspaces];
      newWorkspaces = newWorkspaces.map((workspace) =>
        workspace.workspace_id === action.workspaceId
          ? { ...workspace, workspace_name: action.previousWorkspaceName }
          : workspace
      );
      return {
        ...state,
        workspaces: newWorkspaces
      };
    }

    case RESET_NEW_WORKSPACE: {
      return {
        ...state,
        newWorkspaceId: null
      };
    }

    case MODIFY_WORKSPACE_STATUS_START: {
      let newWorkspaces = [...state.workspaces];
      newWorkspaces = newWorkspaces.map((workspace) =>
        workspace.workspace_id === action.workspaceId
          ? { ...workspace, workspace_status_id: action.newWorkspaceStatus }
          : workspace
      );
      return {
        ...state,
        workspaces: newWorkspaces,
        newWorkspaceId: action.workspaceId
      };
    }

    case MODIFY_WORKSPACE_NOTES: {
      let newWorkspaces = [...state.workspaces];
      newWorkspaces = newWorkspaces.map((workspace) =>
        workspace.workspace_id === action.workspaceId
          ? { ...workspace, workspace_notes: action.newWorkspaceNotes }
          : workspace
      );
      return {
        ...state,
        workspaces: newWorkspaces
      };
    }

    case UPDATE_WORKSPACES: {
      // eslint-disable-next-line
			const new_workspaces = state.workspaces.sort((a,b)=>{
        if (a.workspace_sort < b.workspace_sort) return -1;
        else if (a.workspace_sort === b.workspace_sort) if (a.workspace_id > b.workspace_id) return -1;
      });
      return {
        ...state,
        workspaces: new_workspaces
      };
    }

    case DRAG_WORKSPACE_FAILURE: {
      const newWorkspaces = [...state.workspaces];
      newWorkspaces.find((workspace) => workspace.workspace_id === action.workspaceId).workspace_sort =
        action.workspaceSort;
      return {
        ...state,
        workspaces: newWorkspaces
      };
    }

    // case MODIFY_WORKSPACE_STATUS_SUCCESS: {
    // 	if(action.workspace === null)
    // 		return {
    // 			...state
    // 		}
    // 	else {
    // 		let newWorkspaces = [...state.workspaces];
    // 		newWorkspaces.push(action.workspace)
    // 		return {
    // 			...state,
    // 			workspaces: newWorkspaces,
    // 		}
    // 	}
    //
    // }

    // case MODIFY_WORKSPACE_STATUS_START: {
    // 	let newWorkspaces = [...state.workspaces];
    // 	newWorkspaces = newWorkspaces.map((workspace)=>workspace.workspace_id === action.workspaceId ? {...workspace, workspace_status_id: action.newWorkspaceStatus} : workspace)
    // 	return {
    // 		...state,
    // 		workspaces: newWorkspaces,
    // 	};
    // }
    //
    // case MODIFY_WORKSPACE_STATUS_FAILURE: {
    // 	let newWorkspaces = [...state.workspaces];
    // 	newWorkspaces = newWorkspaces.map((workspace)=>workspace.workspace_id === action.workspaceId ? {...workspace, workspace_status_id: action.previousWorkspaceStatus} : workspace)
    // 	return {
    // 		...state,
    // 		workspaces: newWorkspaces,
    // 	};
    // }

    default: {
      return {
        ...state
      };
    }
  }
}

export default workspacesReducer;
