import React from "react";
import styles from "../../../pages/Workspace/styles/workspace.module.css";
import lockIcon from "../../../assets/icons/lockIcon.svg";

const WorkspaceLockedScreen = () => {
  return (
    <div className={`${styles.locked}`}>
      <div className={`${styles.lockedWindow}`}>
        <div className={styles.lockedBody}>
          <img src={lockIcon} className={styles.lockIcon} alt={""} />
          <h1>Доступ заблокирован</h1>
        </div>
      </div>
    </div>
  );
};

export { WorkspaceLockedScreen };
