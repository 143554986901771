import {
  SHOW_MODAL_WINDOW,
  HIDE_MODAL_WINDOW,
  SET_OPTIONS_FOR_MODAL_WINDOW,
  SET_SHOW_STATUS,
  FETCH_LIST_OF_TASK_DOCUMENTS_START,
  FETCH_LIST_OF_TASK_DOCUMENTS_FAILURE,
  FETCH_LIST_OF_TASK_DOCUMENTS_SUCCESS,
  SET_UPLOADED_DOCUMENT_NAME,
  SET_UPLOADED_DOCUMENT_UPLOAD_PERCENTAGE,
  SET_UPLOADED_DOCUMENT_SHOW_STATUS,
  CHANGE_OPTIONS_FOR_MODAL_WINDOW,
  FETCH_TASK_LOGS_START,
  FETCH_TASK_LOGS_FAILURE,
  FETCH_TASK_LOGS_SUCCESS,
  DELETE_DOCUMENT_FROM_TASK_START,
  DELETE_DOCUMENT_FROM_TASK_FAILURE,
  DELETE_DOCUMENT_FROM_TASK_SUCCESS,
  SHOW_UPPER_MODAL_WINDOW,
  HIDE_UPPER_MODAL_WINDOW
} from "../../actions/actionTypes";
import { MODAL_WINDOW_TYPES } from "../../../components/Screen/ModalWindow";

const initialState = {
  showStatus: false,
  options: {
    type: MODAL_WINDOW_TYPES.NOTIFICATION,
    closeHeaderButton: true,
    closeOnWrapperClick: true,
    text: "Модальное окно",
    header: "Модальное окно",
    showOkButton: true
  },
  showUpperModalStatus: false
};

function modalWindow(state = initialState, action) {
  switch (action.type) {
    case SHOW_MODAL_WINDOW: {
      return Object.assign({}, state, { showStatus: action.showStatus });
    }
    case HIDE_MODAL_WINDOW: {
      return Object.assign({}, state, { showStatus: action.showStatus });
    }
    case SHOW_UPPER_MODAL_WINDOW: {
      return Object.assign({}, state, {
        showUpperModalStatus: action.showStatus,
        typeModal: action.typeModal,
        imageSrc: action.imageSrc,
        imageName: action.imageName,
        documentId: action.documentId
      });
    }
    case HIDE_UPPER_MODAL_WINDOW: {
      return Object.assign({}, state, { showUpperModalStatus: action.showStatus });
    }
    case SET_SHOW_STATUS: {
      return Object.assign({}, state, { showStatus: action.showStatus });
    }
    case SET_OPTIONS_FOR_MODAL_WINDOW: {
      return Object.assign({}, state, { options: action.options });
    }
    case CHANGE_OPTIONS_FOR_MODAL_WINDOW: {
      const newOptionsState = Object.assign({}, state.options, action.options);
      return Object.assign({}, state, { options: newOptionsState });
    }
    case FETCH_LIST_OF_TASK_DOCUMENTS_START: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case FETCH_LIST_OF_TASK_DOCUMENTS_FAILURE: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case FETCH_LIST_OF_TASK_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        options: Object.assign({}, state.options, {
          taskDocuments: action.documents
        })
      };
    }
    case DELETE_DOCUMENT_FROM_TASK_START: {
      const newTaskDocumentState = [...state.options.taskDocuments];
      newTaskDocumentState.splice(action.documentIndex, 1);
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        options: Object.assign({}, state.options, {
          taskDocuments: newTaskDocumentState
        })
      };
    }
    case DELETE_DOCUMENT_FROM_TASK_FAILURE: {
      const newTaskDocumentState = [...state.options.taskDocuments];
      newTaskDocumentState.splice(action.documentIndex, 0, action.documentObject);
      return {
        ...state,
        loading: action.loading,
        error: action.error,
        options: Object.assign({}, state.options, {
          taskDocuments: newTaskDocumentState
        })
      };
    }
    case DELETE_DOCUMENT_FROM_TASK_SUCCESS: {
      return {
        ...state,
        loading: action.loading,
        error: action.error
      };
    }
    case FETCH_TASK_LOGS_START: {
      const newOptionState = Object.assign({}, state.options, {
        logLoading: action.loading,
        logError: action.error,
        logs: [
          {
            updatedAt: new Date().toISOString(),
            log_type_text: "Загрузка...",
            user_id: null,
            log_id: 0
          }
        ]
      });
      return {
        ...state,
        options: newOptionState
      };
    }
    case FETCH_TASK_LOGS_FAILURE: {
      const newOptionState = Object.assign({}, state.options, {
        logLoading: action.loading,
        logError: action.error,
        logs: [
          {
            updatedAt: new Date().toISOString(),
            log_type_text: "Загрузка...",
            user_id: null,
            log_id: 0
          }
        ]
      });
      return {
        ...state,
        options: newOptionState
      };
    }
    case FETCH_TASK_LOGS_SUCCESS: {
      const newOptionState = Object.assign({}, state.options, {
        logLoading: action.loading,
        logError: action.error,
        logs: action.logs.sort((a, b) => {
          if (new Date(a.createdAt) > new Date(b.createdAt)) return -1;
          else return 1;
        })
      });
      return {
        ...state,
        options: newOptionState
      };
    }
    case SET_UPLOADED_DOCUMENT_NAME: {
      const newOptionsState = { ...state.options };
      newOptionsState.taskUploadedDocument.name = action.name;
      return {
        ...state,
        options: newOptionsState
      };
    }
    case SET_UPLOADED_DOCUMENT_UPLOAD_PERCENTAGE: {
      const newOptionsState = { ...state.options };
      newOptionsState.taskUploadedDocument.uploadPercentage = action.percentage;
      return {
        ...state,
        options: newOptionsState
      };
    }
    case SET_UPLOADED_DOCUMENT_SHOW_STATUS: {
      const newOptionsState = { ...state.options };
      newOptionsState.taskUploadedDocument.show = action.show;
      return {
        ...state,
        options: newOptionsState
      };
    }
    default:
      return state;
  }
}

export default modalWindow;
