import {
  GET_LIST_OF_REPORT_STATUSES_SUCCESS,
  GET_LIST_OF_REPORT_TYPES_SUCCESS,
  GET_LIST_OF_REPORTS_START,
  GET_LIST_OF_REPORTS_SUCCESS,
  GET_REPORT_INFO_START,
  GET_REPORT_INFO_SUCCESS,
  REMOVE_REPORT_DATA,
  ADD_ADDITIONAL_WORK_TO_PROJECT_SUCCESS,
  SET_CURRENT_REPORT_ID,
  SET_TEMPORAL_REPORT_DATE,
  UPDATE_LIST_OF_REPORTS_SUCCESS,
  REMOVE_REPORTS_DATA_ITEMS,
  SAVE_REPORT_START,
  SAVE_REPORT_SUCCESS,
  SAVE_REPORT_FAILURE,
  CHANGE_REPORT_PROJECT_INFO_FAILURE,
  GET_PROJECTS_LIST_SUCCESS,
  GET_ADDITIONAL_WORKS_LIST_SUCCESS,
  CHANGE_REPORT_DATA_INFO_FIELD_SUCCESS,
  GET_PROJECTS_LIST_START,
  GET_REPORT_INFO_FAILURE,
  GET_PROGRAMS_NAMES_SUCCESS,
  DELETE_ADDITIONAL_WORK_FROM_PROJECT_SUCCESS
} from "../actions/actionTypes";

const initialState = {
  reportsData: [],
  reportsDataItems: [],
  reportsAmount: 1,
  reportsLoading: false,
  statusesList: [],
  typesList: [],
  reportData: {},
  reportDataLoading: false,
  error: null,
  currentReportID: null,
  reportsTemporalDates: {},
  currentReportsPage: 1,
  nextReportsPage: null,
  saveData: {},
  programsNames: []
};

function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_OF_REPORT_STATUSES_SUCCESS: {
      return {
        ...state,
        statusesList: action.statusesList
      };
    }

    case GET_LIST_OF_REPORT_TYPES_SUCCESS: {
      return {
        ...state,
        typesList: action.typesList
      };
    }

    case GET_LIST_OF_REPORTS_START: {
      return {
        ...state,
        reportsLoading: true
      };
    }

    case GET_LIST_OF_REPORTS_SUCCESS: {
      return {
        ...state,
        reportsLoading: false,
        reportsData: action.reportsData,
        reportsDataItems: action.reportsData.results,
        nextReportsPage: action.reportsData.next,
        reportsAmount: action.reportsData.count
      };
    }

    case UPDATE_LIST_OF_REPORTS_SUCCESS: {
      return {
        ...state,
        reportsDataItems: [...state.reportsDataItems, ...action.reportsData.results],
        nextReportsPage: action.reportsData.next
      };
    }

    case CHANGE_REPORT_DATA_INFO_FIELD_SUCCESS: {
      const currentProject = state.reportData[action.projectType].find((project) => project.id === action.id);
      return {
        ...state,
        reportData: {
          ...state.reportData,
          [action.projectType]: state.reportData[action.projectType].map((project) => {
            if (project.id === currentProject.id) {
              return {
                ...project,
                ...action.data
              };
            }
            return null;
          })
        }
      };
    }

    case SET_CURRENT_REPORT_ID: {
      return {
        ...state,
        currentReportID: action.reportID
      };
    }

    case GET_REPORT_INFO_START: {
      return {
        ...state,
        reportsLoading: action.reportsLoading
      };
    }

    case GET_REPORT_INFO_SUCCESS: {
      return {
        ...state,
        reportData: action.reportData,
        retportsLoading: action.reportsLoading,
        reportDataLoading: action.reportDataLoading
      };
    }

    case GET_REPORT_INFO_FAILURE: {
      return {
        ...state,
        reportsLoading: action.reportsLoading,
        reportDataLoading: action.reportDataLoading
      };
    }

    case GET_PROGRAMS_NAMES_SUCCESS: {
      return {
        ...state,
        programsNames: action.programsNames
      };
    }

    case GET_PROJECTS_LIST_START: {
      return {
        ...state,
        reportDataLoading: action.reportDataLoading
      };
    }

    case GET_PROJECTS_LIST_SUCCESS: {
      return {
        ...state,
        reportDataLoading: action.reportDataLoading
      };
    }

    case GET_ADDITIONAL_WORKS_LIST_SUCCESS: {
      return {
        ...state,
        reportData: {
          ...state.reportData,
          addition_works: action.addition_works
        }
      };
    }

    case ADD_ADDITIONAL_WORK_TO_PROJECT_SUCCESS: {
      return {
        ...state,
        reportData: {
          ...state.reportData,
          addition_works: [...state.reportData.addition_works, action.workData]
        }
      };
    }

    case CHANGE_REPORT_PROJECT_INFO_FAILURE: {
      return {
        ...state,
        error: action.error
      };
    }

    case SET_TEMPORAL_REPORT_DATE: {
      return {
        ...state,
        reportsTemporalDates: {
          ...state.reportsTemporalDates,
          [action.reportID]: action.reportDate
        }
      };
    }

    case SAVE_REPORT_START: {
      return {
        ...state,
        reportDataLoading: action.reportDataLoading
      };
    }

    case SAVE_REPORT_SUCCESS: {
      return {
        ...state,
        reportDataLoading: action.reportDataLoading,
        saveData: action.saveData
      };
    }

    case SAVE_REPORT_FAILURE: {
      return {
        ...state,
        reportDataLoading: action.reportDataLoading,
        saveData: action.saveData
      };
    }

    case REMOVE_REPORT_DATA: {
      return {
        ...state,
        reportData: action.reportData
      };
    }

    case REMOVE_REPORTS_DATA_ITEMS: {
      return {
        ...state,
        reportsDataItems: action.reportsDataItems
      };
    }

    case DELETE_ADDITIONAL_WORK_FROM_PROJECT_SUCCESS: {
      const newReportData = Object.assign({}, state.reportData);
      newReportData.addition_works = newReportData.addition_works.filter((work) => work.id !== action.work_id);
      console.log(newReportData, state.reportData, action.work_id);
      return {
        ...state,
        reportData: newReportData
      };
    }

    default:
      return state;
  }
}

export default reportsReducer;
