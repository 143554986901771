import React, { useEffect, useRef } from "react";
import styles from "./styles/EditableTaskNameModal.module.css";
import { useTranslation } from "react-multi-lang";

const EditableTaskNameModal = React.memo(({ text, permissions, headerIsEdit, onFocus, onBlur, shouldBeOnFocus }) => {
  const inputRef = useRef(null);

  const onBlurHandler = (event) => {
    // let re = /<([^>]+)>.*?<\/\1>|<.*?\/>/g;
    //
    // event.target.innerText = event.target.innerText.replace(re, '');
    event.target.innerText = event.target.textContent;
    onBlur(event);
  };

  const translate = useTranslation();

  useEffect(() => {
    if (shouldBeOnFocus) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line
    }, [])

  useEffect(() => {
    if (inputRef.current.innerText === 0) inputRef.current.style.width = "300px";
    else inputRef.current.style.width = "auto";
  });

  const onKeyPressHandler = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.target.blur();
    }
  };

  const onPasteHandler = (event) => {
    if (!event.clipboardData.getData("Text")) event.preventDefault();
    else {
      event.preventDefault();
      let text = event.clipboardData.getData("text/plain");
      document.execCommand("insertHTML", false, text);
    }
  };

  const listener = (event) => {
    if (event.keyCode === 189 || event.keyCode === 109) {
      replaceHyphens(event.target);
    }
  };

  const replaceHyphens = (target) => {
    let sel = target.selectionStart || getCaretPosition(target),
      replaced = false;
    let key = target.value ? "value" : "textContent";
    target[key] = target[key].replace(/-{2}/g, function (t) {
      replaced = true;
      return "—";
    });
    if (replaced) setCaretPosition(target, sel - 1);
  };

  const getCaretPosition = (editableDiv) => {
    let caretPos = 0,
      sel,
      range;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.rangeCount) {
        range = sel.getRangeAt(0);
        if (range.commonAncestorContainer.parentNode === editableDiv) {
          caretPos = range.endOffset;
        }
      }
    } else if (document.selection && document.selection.createRange) {
      range = document.selection.createRange();
      if (range.parentElement() === editableDiv) {
        let tempEl = document.createElement("span");
        editableDiv.insertBefore(tempEl, editableDiv.firstChild);
        let tempRange = range.duplicate();
        tempRange.moveToElementText(tempEl);
        tempRange.setEndPoint("EndToEnd", range);
        caretPos = tempRange.text.length;
      }
    }
    return caretPos;
  };

  const setCaretPosition = (el, caret) => {
    if (el.value) {
      el.setSelectionRange(caret, caret);
    } else {
      let tNode = el.firstChild;
      let range = document.createRange();
      range.setStart(tNode, caret);
      range.setEnd(tNode, caret);
      let sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  return (
    <div
      className={`${styles.taskName} ${headerIsEdit || shouldBeOnFocus ? styles.isEdit : ""} ${
        permissions.can_edit === 0 && styles.notAllowedCursor
      }`}
      contentEditable={permissions.can_edit === 1}
      onKeyPress={onKeyPressHandler}
      onKeyUp={listener}
      onFocus={onFocus}
      onBlur={onBlurHandler}
      onPaste={onPasteHandler}
      ref={inputRef}
      placeholder={
        permissions.can_edit
          ? `${translate("taskTracker.commonWords.enterTaskName")}...`
          : translate("taskTracker.commonWords.noTaskName")
      }
      suppressContentEditableWarning={true}
    >
      {text}
    </div>
  );
});

export { EditableTaskNameModal };
