import React from "react";
import ContentLoader from "react-content-loader";

export const ProjectInfromationSkeleton = (props) => (
  <div {...props}>
    <ContentLoader
      speed={2}
      width={300}
      height={60}
      viewBox="0 0 300 60"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="6" ry="6" width="60" height="60" />
      <rect x="70" y="42" rx="6" ry="6" width="212" height="18" />
      <rect x="70" y="3" rx="6" ry="6" width="150" height="30" />
    </ContentLoader>
  </div>
);

export const PeoplesStackerSkeleton = (props) => (
  <ContentLoader
    speed={2}
    width={85}
    height={32}
    viewBox="0 0 85 32"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    style={{ marginRight: "8px" }}
    {...props}
  >
    <rect x="0" y="0" rx="16" ry="16" width="85" height="32" />
  </ContentLoader>
);
