import {
  CHANGE_LANGUAGE,
  GET_INTEGRATOR_LEFT_MENU_START,
  GET_INTEGRATOR_LEFT_MENU_SUCCESS,
  GET_KNOWLEDGE_BASE_DATA_STARTED,
  GET_KNOWLEDGE_BASE_DATA_SUCCESS,
  GET_USER_RANK_INFO_SUCCESS
} from "../actions/actionTypes";

const initialState = {
  knowledgeData: [],
  knowledgeDataLoading: false,
  userRank: null,
  integratorLeftMenuData: [{}],
  integratorLoading: true,
  language: "ru"
};

function commonReducer(state = initialState, action) {
  switch (action.type) {
    case GET_KNOWLEDGE_BASE_DATA_STARTED: {
      return {
        ...state,
        knowledgeDataLoading: true
      };
    }

    case GET_KNOWLEDGE_BASE_DATA_SUCCESS: {
      return {
        ...state,
        knowledgeData: action.knowledgeData,
        knowledgeDataLoading: false
      };
    }

    case GET_USER_RANK_INFO_SUCCESS: {
      return {
        ...state,
        userRank: action.rank_info
      };
    }

    case GET_INTEGRATOR_LEFT_MENU_START: {
      return {
        ...state,
        integratorLoading: true
      };
    }

    case GET_INTEGRATOR_LEFT_MENU_SUCCESS: {
      return {
        ...state,
        integratorLeftMenuData: action.menuData,
        integratorLoading: false
      };
    }
    case CHANGE_LANGUAGE: {
      return {
        ...state,
        language: action.language
      };
    }
    default:
      return state;
  }
}

export default commonReducer;
