import React, { useRef, useState } from "react";
import styles from "./styles/loginpage.module.css";
import sixhandsLogo from "../../assets/icons/sixhands_logo.svg";
import { DefaultInput } from "../Inputs/DefaultInput";
import { AccentButton } from "../Buttons/AccentButton";
import { useDispatch, useSelector } from "react-redux";
import { authorizeUser } from "../../state/actions";
import warningIcon from "../../assets/icons/warning.svg";

const LoginPage = () => {
  const emailInput = useRef();
  const passwordInput = useRef();
  const dispatch = useDispatch();

  const [isLogin] = useState(true);

  const authorizationState = useSelector((state) => state.authorizationReducer);

  const [errors, setErrors] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const authorizationHandler = () => {
    setErrors([]);
    const email = emailInput.current.querySelector("input").value;
    const password = passwordInput.current.querySelector("input").value;
    if (email.length > 0 && password.length > 0) dispatch(authorizeUser(email, password));
    else if (email.length === 0 && password.length === 0) {
      emailInput.current.querySelector("input").style = "border-color: var(--red)";
      passwordInput.current.querySelector("input").style = "border-color: var(--red)";
      if (!errors.includes("Email - обязательное поле") && !errors.includes("Password - обязательное поле"))
        setErrors([...errors, "Email - обязательное поле", "Password - обязательное поле"]);
    } else if (email.length === 0) {
      emailInput.current.querySelector("input").style = "border-color: var(--red)";
      if (!errors.includes("Email - обязательное поле")) setErrors([...errors, "Email - обязательное поле"]);
    } else if (password.length === 0) {
      passwordInput.current.querySelector("input").style = "border-color: var(--red)";
      if (!errors.includes("Password - обязательное поле")) setErrors([...errors, "Password - обязательное поле"]);
    }
  };

  if (authorizationState.error) {
    if (!errors.includes("Неверный email или пароль")) setErrors([...errors, "Неверный email или пароль"]);
  }

  const clearBorder = (type) => {
    if (type === "email") {
      emailInput.current.querySelector("input").style = "";
      authorizationState.error = null;
      setErrors([]);
    }
    if (type === "password") passwordInput.current.querySelector("input").style = "";
    setErrors([]);
  };

  const inputKeyPressHandler = (event) => {
    if (event.key === "Enter") {
      authorizationHandler();
    }
  };

  const onClickShowPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={styles.container}>
      <div>
        <object type="image/svg+xml" data={sixhandsLogo} className={styles.LogoSvg}>
          sixhands logo
        </object>
      </div>
      {authorizationState.loading && (
        <div className={styles.loader}>
          <div className={styles.spinner} />
        </div>
      )}
      <div
        className={
          isLogin
            ? authorizationState.loading
              ? styles.loginContainerTransparent
              : styles.loginContainer
            : styles.loginContainerRegistration
        }
      >
        <div className={styles.header}>
          {/*<h1 className={isLogin ? '' : styles.headerInactive} onClick={() => {setIsLogin(true)}}>Вход</h1>*/}
          {/*<h1 className={isLogin ? styles.headerInactive : ''} onClick={() => {setIsLogin(false)}}>Регистрация</h1>*/}
        </div>
        {isLogin && (
          <div className={styles.inputContainer}>
            <DefaultInput
              label="Ваш e-mail"
              placeholder="Введите e-mail..."
              className={styles.dataInput}
              ref={emailInput}
              onChange={() => {
                clearBorder("email");
              }}
              onKeyPress={inputKeyPressHandler}
            />
            <div>
              <DefaultInput
                label="Пароль"
                placeholder="Введите пароль..."
                type={showPassword ? "" : "password"}
                className={styles.dataInput}
                ref={passwordInput}
                onChange={() => {
                  clearBorder("password");
                }}
                onKeyPress={inputKeyPressHandler}
                onClick={onClickShowPasswordHandler}
                isPassword={true}
              />
            </div>
          </div>
        )}
        {!isLogin && (
          <div className={styles.inputContainer}>
            <DefaultInput label="Название компании" placeholder="" className={styles.dataInput} />
            <DefaultInput label="Email администратора" placeholder="" className={styles.dataInput} />
            <DefaultInput label="Пароль" placeholder="" type="password" className={styles.dataInput} />
            <DefaultInput label="Повторите пароль" placeholder="" type="password" className={styles.dataInput} />
          </div>
        )}
        <AccentButton
          label="ВОЙТИ"
          displayPlusSymbol={false}
          className={styles.authorizeButton}
          onClick={isLogin ? authorizationHandler : undefined}
        />
        {/*<div className={styles.forgotPassword}>*/}
        {/*    <p>Забыли пароль? <a>Восстановить</a></p>*/}
        {/*</div>*/}
        <div className={styles.errors}>
          {errors.map((error, index) => (
            <div className={styles.warning}>
              <img src={warningIcon} className={styles.warningIcon} alt={""} />
              <p key={index}>{error}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { LoginPage };
